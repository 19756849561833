import discountWrapper from './discountWrapper';

const wrapper = (couponData, wrapDiscounts) => ({
    couponId: couponData?.id,
    couponName: couponData?.name,
    outputText: couponData?.output_text,
    discounts: wrapDiscounts ? discountWrapper.sortByType(couponData?.discounts) : couponData?.discounts,
    selected: couponData?.selected ? true : false,
    licenseRequired: couponData?.license_required ? true : false,
    active: couponData?.active ? true : false,
    code: couponData?.code
});

const couponWrapper = {
    single: (coupon, wrapDiscounts = false) => {
        if(
            !coupon
            || Array.isArray(coupon)
            || typeof coupon !== 'object'
        ) return {};

        return wrapper(coupon, wrapDiscounts);
    },

    sortByName: (coupons, wrapDiscounts = false) => {
        if(!Array.isArray(coupons)) return [];

        return coupons.map(coupon => wrapper(coupon, wrapDiscounts)).sort((a, b) => {
            if(a.couponName === b.couponName) return 0;
            return a.couponName > b.couponName ? 1 : -1;
        });
    }
};

export default couponWrapper;
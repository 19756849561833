import React, { useEffect, useState } from 'react';
import { Form, FormControl, InputGroup, FormLabel } from 'react-bootstrap';
import CustomButton from '../custom-buttons/CustomButton';

function WebsiteUrlForm({ websiteUrl = "", onChange, onSubmit, onClose, isLoading, isSuccess }) {

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(event.target[0].value);
  }

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <Form className={`website-url d-flex flex-column justify-content-center align-items-center`} inline onSubmit={handleSubmit}>
      <FormLabel>Website URL</FormLabel>
      <InputGroup style={{ minWidth: "500px" }}>
        <FormControl
          type="text"
          placeholder="https://brandname.com/location"
          value={websiteUrl}
          onChange={(event) => onChange(event.target.value)}
        />
      </InputGroup>
      <div className="mt-3">
        <CustomButton
          id='cancel-form-website-button'
          className='dm-button outline-danger mx-2'
          onClick={onClose}
        >
          Cancel
        </CustomButton>
        <CustomButton
          id='form-website-button'
          type='submit'
          className='dm-button outline-secondary mx-2'
          loading={isLoading}
          loadingMessage='Saving...'
        >
          Save
        </CustomButton>
      </div>
    </Form>
  );
}

export default WebsiteUrlForm;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import CampaignsConfigItem from './CampaignsConfigItem';
import Button from 'components/common/button/Button';
import Input from 'components/common/input/Input';
import { connect } from 'react-redux';
import Loader from 'components/common/loader/Loader';

export class CampaignsConfig extends Component {
    state = {
        name: '',
        date: '',
        lockDate: '',
        newCampaignTempIdCounter: 0
    }

    static propTypes = {
        campaignTemplates: PropTypes.array,
        onAddCampaign: PropTypes.func,
        onEditCampaign: PropTypes.func,
        onRemoveCampaign: PropTypes.func
    }

    renderCampaigns = () => {
        const { 
            campaignTemplates,
            onEditCampaign,
            onRemoveCampaign,
            loadingCampaigns
        } = this.props;

        if(loadingCampaigns) {
            return <Loader loadingMessage='Fetching campaigns...' />;
        }

        if(!campaignTemplates || !campaignTemplates.length) {
            return (
                <Fragment>
                    <div>No campaigns configured</div>
                    <small className='error-msg'>You must have at least one campaign configured</small>
                </Fragment>
            );
        }

        return campaignTemplates.map(campaign => {
            return (
                <CampaignsConfigItem 
                    key={campaign.id}
                    campaign={campaign}
                    onEditCampaign={onEditCampaign}
                    onRemoveCampaign={onRemoveCampaign}
                />
            );
        })
    }

    render() {
        const {
            name,
            date,
            lockDate,
            newCampaignTempIdCounter
        } = this.state;

        const { onAddCampaign } = this.props;

        return (
            <div className='d-flex flex-column mt-3'>
                <span className='font-size-16 font-family-bold mb-2'>Campaigns</span>
                {this.renderCampaigns()}
                <div className='d-flex flex-column align-items-center row-gap-1 mt-4 mb-4'>
                    <div className='d-flex justify-content-center font-weight-bold'>Add a campaign</div>
                    <div className='d-flex flex-align-center justify-content-center column-gap-1 flex-wrap'>
                        <Input 
                            label='Name'
                            value={name}
                            type='text'
                            placeholder='Enter campaign name'
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                        <Input 
                            label='Date'
                            value={date}
                            type='date'
                            onChange={(e) => this.setState({ date: e.target.value })}
                        />
                        <Input 
                            label='Lock Date'
                            value={lockDate}
                            type='date'
                            onChange={(e) => this.setState({ lockDate: e.target.value })}
                        />
                    </div>
                    <Button 
                        label='Add'
                        btnType='outline-primary'
                        disabled={!name || !date || !lockDate}
                        action={() => {
                            onAddCampaign({ 
                                campaignName: name, 
                                date, 
                                lockDate, 
                                id: `temporaryId-${newCampaignTempIdCounter}` 
                            });
                            this.setState({ 
                                name: '', 
                                date: '', 
                                lockDate: '', 
                                newCampaignTempIdCounter: this.state.newCampaignTempIdCounter + 1
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // campaignTemplates: state.planConfig?.mailingPlanTemplate?.campaignTemplates,
    loadingCampaigns: state.planConfig?.loadingMailingPlanTemplate
});

export default connect(mapStateToProps, {})(CampaignsConfig);

import formatDate from 'utils/formatDate';

// Plans marked as 'Unsumbitted' do not have a mailing plan ID,
// so we can pass in a customId to allow react tables to assign them a unique "key" property
const mailingPlansColumnsWrapper = ({
    entry = {}, 
    customId = null,
    viewDetailsCallback = null,
    onChangeDmExemptStatus= null,
    yearFilter = null
}) => ({
    id: entry?.mailingPlanId || `customId${customId}`,
    status: entry?.requestStatus.name || '-',
    franchise: entry?.franchiseName || '-',
    franchiseId: entry?.franchiseId,
    year: entry?.year || yearFilter,
    submittedDate: entry?.submissionDate ? formatDate.dateAndTime(entry?.submissionDate) : '-',
    modifiedDate: entry?.modifiedDate ? formatDate.dateAndTime(entry?.modifiedDate) : '-',
    investment: entry?.totalSpend || '-',
    totalAddresses: entry?.totalAddresses || '-',
    campaigns: entry?.campaigns || [],
    isDmExempt : entry?.isDmExempt,
    viewDetailsCallback,
    onChangeDmExemptStatus
});

export default mailingPlansColumnsWrapper;
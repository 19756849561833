import moment from "moment";

const wrapper = data => ({
    year: data.year,
    templateId: data.id
});

const mailingYearWrapper = {
    single: year => {
        if(
            !year
            || Array.isArray(year)
            || typeof year !== 'object'
        ) return {};
        return wrapper(year);
    },

    sortAsc: years => {
        if(!Array.isArray(years)) return [];
        return years.map(year => wrapper(year)).sort((a, b) => a.year - b.year);
    }
};

export default mailingYearWrapper;
const validRulesObj = (rules) => {
    return rules
        && !Array.isArray(rules)
        && typeof rules === 'object'
}

const compareBusinessRules = (prevRules = {}, newRules = {}) => {
    if(!validRulesObj(prevRules) || !validRulesObj(newRules)) return false;
    return JSON.stringify(prevRules) === JSON.stringify(newRules);
}

export default compareBusinessRules;
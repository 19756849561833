const wrapper = data => ({
    discountId: data.id,
    value: data.value,
    outputText: data.output_text,
    discountType: data.discount_type,
    active: data.active ? true : false,
    selected: data.selected ? true : false
});

const sortByValue = (discounts) => {
    return discounts?.sort((a, b) => {
        if(a.value === b.value) return 0;
        return a.value > b.value ? 1 : -1;
    });
}

const sortByType = (discounts = []) => {
    discounts = discounts?.map(discount => {
        return wrapper(discount);
    });

    let amountDiscounts = [];
    let percentageDiscounts = [];
    let otherDiscounts = [];

    discounts.forEach(discount => {
        switch(discount?.discountType) {
            case 'amount':
                amountDiscounts.push(discount);
                break;
            case 'percentage':
                percentageDiscounts.push(discount);
                break;
            default:
                otherDiscounts.push(discount);
        }
    });
    
    amountDiscounts = sortByValue(amountDiscounts);
    percentageDiscounts = sortByValue(percentageDiscounts);
    otherDiscounts = sortByValue(otherDiscounts);

    return [...amountDiscounts, ...percentageDiscounts, ...otherDiscounts];
}

const discountWrapper = {
    single: discount => {
        if(
            !discount
            || Array.isArray(discount)
            || typeof discount !== 'object'
        ) return {};

        return wrapper(discount);
    },

    sortByType: discounts => {
        if(!Array.isArray(discounts)) return [];
        return sortByType(discounts);
    }
};

export default discountWrapper;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import { Modal } from 'react-bootstrap';
import InlineNotification from '../inline-notification/InlineNotification';
import { setHaveUnsavedChangesAction, 
    setMoveToAction, 
    setConfirmModalAcceptedAction 
} from 'actions/statusAccions';

export class ConfirmationModal extends Component {
    state = {
        loading: false,
    };

    static propTypes = {
        type: PropTypes.string, // Uses Bootstrap 4's alert variants (ex. success, danger, etc.)
        headerType: PropTypes.string, // Uses Bootstrap 4's alert variants (ex. success, danger, etc.)
        header: PropTypes.string,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        headerIconLeft: PropTypes.string,
        headerIconRight: PropTypes.string,
        confirmBtnLabel: PropTypes.string,
        cancelBtnLabel: PropTypes.string,
        showConfirmBtn: PropTypes.bool,
        showCancelBtn: PropTypes.bool,
        onConfirm: PropTypes.func,
        onClose: PropTypes.func.isRequired,
        show: PropTypes.bool,
        countDown: PropTypes.number,
        isLoading: PropTypes.bool,
        onLoadingComplete: PropTypes.func,
        centered: PropTypes.bool,
        isCustomTabFlag: PropTypes.bool,
    };

    componentDidUpdate() {
        const { loading } = this.state;

        const { isLoading, onLoadingComplete, setAccepted, confirmModalAccepted } = this.props;

        if (isLoading && !loading) {
            this.setState({ loading: true });
        } else if (!isLoading && loading) {
            this.setState({ loading: false }, () => {
                if (onLoadingComplete) onLoadingComplete();
            });
        }
        if (confirmModalAccepted || confirmModalAccepted == false) {
            setAccepted(null);
        }
    }

    onCloseModal = () => {
        const { isLoading, onClose, setAccepted, setMoveTo } = this.props;
        if (isLoading) return null;
        setMoveTo(null);
        setAccepted(false);
        onClose();
    };

    onConfirmAction = () => {
        const { onConfirm, setHaveUnsavedChanges, setAccepted, setMoveTo } = this.props;
        setMoveTo(null);
        setHaveUnsavedChanges(false);
        setAccepted(true);
        onConfirm();
    }
    onConfirmActionCustomTab = () => {
        const { onConfirm, setMoveTo } = this.props;
        setMoveTo(null);
        onConfirm();
    }

    render() {
        const {
            type,
            headerType,
            header,
            title,
            message,
            headerIconLeft,
            headerIconRight,
            confirmBtnLabel,
            cancelBtnLabel,
            showConfirmBtn,
            showCancelBtn,
            onConfirm,
            countDown,
            show,
            isLoading,
            centered,
            isCustomTabFlag
        } = this.props;

        return (
            <Modal
                className={`confirmation-modal ${
                    centered ? 'confirmation-text-centered' : ''
                }`}
                show={show}
                onHide={this.onCloseModal}
            >
                {(header || headerIconLeft || headerIconRight) && (
                    <Modal.Header
                        className={`alert alert-${headerType || type}`}
                    >
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className='d-flex justify-content-center align-items-baseline column-gap-1 w-100'>
                                {headerIconLeft && (
                                    <i className={headerIconLeft}></i>
                                )}
                                {header && (
                                    <div className='alert-heading'>
                                        {header}
                                    </div>
                                )}
                                {headerIconRight && (
                                    <i className={headerIconRight}></i>
                                )}
                            </div>
                        </div>
                    </Modal.Header>
                )}
                <Modal.Body>
                    <InlineNotification
                        type={type || 'warning'}
                        title={title}
                        message={message}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-center align-items-center column-gap-1 w-100'>
                        {showCancelBtn && (
                            <Button
                                btnType='outline-danger'
                                label={
                                    cancelBtnLabel ? cancelBtnLabel : 'Cancel'
                                }
                                action={this.onCloseModal}
                            />
                        )}
                        {showConfirmBtn && (
                            <Button
                                label={
                                    confirmBtnLabel
                                        ? confirmBtnLabel
                                        : 'Confirm'
                                }
                                disabledCountDown={countDown ? countDown : 0}
                                loading={isLoading}
                                action={ isCustomTabFlag ? this.onConfirmActionCustomTab : this.onConfirmAction }
                            />
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    haveUnsavedChanges: state.status.unsavedBudgetPlanChanges,
    accepted: state.status.confirmModalAccepted,
    moveTo: state.status.moveTo, 
});

ConfirmationModal.defaultProps =
{
    onClose: ()=>{}
}

export default connect(mapStateToProps, {
    setMoveTo: setMoveToAction,
    setAccepted: setConfirmModalAcceptedAction,
    setHaveUnsavedChanges: setHaveUnsavedChangesAction
})(ConfirmationModal);

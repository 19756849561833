import userSession from 'utils/userSession';
import mailingPlanWrapper from 'api/direct-mail/utils/backend-wrappers/mailingPlanWrapper';
import zipCodeWrapper from 'api/direct-mail/utils/backend-wrappers/zipCodeWrapper';

import {
    GET_MAILING_PLAN,
    ADD_COMMENTS,
    LOADING_MAILING_PLAN,
    GET_ZIP_CODES,
    TOGGLE_ZIP_CODE,
    SUBMITING_MAILING_PLAN,
    SUBMITTED_MAILING_PLAN,
    SAVING_MAILING_PLAN,
    SAVED_MAILING_PLAN,
    OVERRIDE_MAILING_PLAN_ENABLED,
    RESET_MAILING_PLAN,
    APPROVED_MAILING_PLAN,
    APPROVING_MAILING_PLAN,
    REJECTED_MAILING_PLAN,
    REJECTING_MAILING_PLAN,
    SET_MAILING_PLAN_STATUS,
    OVERRIDE_MAILING_PLAN_LOADING,
    OVERRIDE_MAILING_PLAN_SHOW_CONFIRMATION,
    GET_ZIP_CODES_LOADING,
    TOGGLE_ALL_ZIP_CODES
} from './types';

import { 
    notifyGetMailingPlanError, 
    notifyGetZipCodeError, 
    notifyMailingPlanSubmitError,
    notifyMailingPlanApproveError,
    notifyMailingPlanRejectError,
    notifyMailingPlanOverrideError
} from './notificationActions';

import { getMailingPlanSubmissions } from './submissionsActions';

import { 
    getMailingPlan as getMailingPlanApi,
    submitMailingPlan as submitMailingPlanApi,
    overrideMailingPlan as overrideMailingPlanApi,
    approveMailingPlan as approveMailingPlanApi,
    rejectMailingPlan as rejectMailingPlanApi,
    saveDraftMailingPlan as saveDraftMailingPlanApi,
    copyDraftMailingPlan as copyDraftMailingPlanApi,
} from 'api/direct-mail/mailingPlan';

import {
    getZipCodes as getZipCodesApi
} from 'api/direct-mail/territories';

import {
    saveFranchiseLicense as saveFranchiseLicenseApi,
    saveFranchiseOwnerDetails as saveFranchiseOwnerDetailsApi
  } from "api/direct-mail/franchises";
const generateCampaigns = campaigns => campaigns?.filter(({ selected }) => selected).map(campaign => ({
    campaignTemplateId: campaign.campaignTemplateId,
    brochureId: campaign.selectedBrochure.brochureId,
    addresses: campaign.addresses,
    coupons: campaign.selectedCoupons.map(coupon => {
        return { 
            couponId: coupon.couponId, 
            discountId: coupon.discount.discountId
        }
    }),
    services: campaign.selectedServices.map(service => {
        return service.serviceId
    }),
    is_no_coupon : campaign.is_no_coupon,
    badges: campaign.selectedBadges.map(badge => {
        return badge.badgeId
    }),
    is_no_badge : campaign.is_no_badge,
    expiration_date :  campaign.expiration_date =="" ?null :campaign.expiration_date,
    desired_in_home_date :  campaign.desired_in_home_date =="" ?null :campaign.desired_in_home_date,
    budget_intention : campaign.budget_intention,
    piece_size : campaign.piece_size
}));

export const getMailingPlan = (
    brandId, 
    franchiseId, 
    mailingPlanId = null,
    year = null
) => async dispatch => {
    dispatch(notifyGetMailingPlanError({ display: false }));
    
    dispatch({
        type: LOADING_MAILING_PLAN,
        payload: true
    });

    let [mailingPlanErr, mailingPlanRes] = await getMailingPlanApi({ 
        brandId, 
        franchiseId, 
        mailingPlanId,
        year
    });

    if(mailingPlanErr) {
        dispatch(notifyGetMailingPlanError({ serverError: mailingPlanErr }));
    }

    dispatch({
        type: LOADING_MAILING_PLAN,
        payload: false
    });

    return dispatch({
        type: GET_MAILING_PLAN,
        payload: mailingPlanWrapper.single(mailingPlanRes)
    });
}

export const submitMailingPlan = (
    brandId, 
    franchiseId, 
    mailingPlan, 
    isCorrectFranchiseInfo = false
) => async dispatch => {
    dispatch(notifyMailingPlanSubmitError({ display: false }));

    const generatedMailingPlan = {
        mailingPlanTemplateId: mailingPlan.mailingPlanTemplateId,
        campaigns: generateCampaigns(mailingPlan.campaigns) || [],
        sendOrder: mailingPlan.mailingOrder.map(order => order.id),
        excludedZips: mailingPlan.excludedZipCodes?.map(code => code),
        comments: mailingPlan.comments,
        businessRules: mailingPlan.templateBusinessRules,
        isCorrectFranchiseInfo
    }

    dispatch({
        type: SUBMITING_MAILING_PLAN,
        payload: true
    });

    //Save License field for MSQ
    if(userSession.isMSQUser()){
        let licenseParams = { id:franchiseId, override_license: mailingPlan.overrideLicense, license_number: mailingPlan.licenseNumber };
        let [saveLicenseErr,Succ] = await saveFranchiseLicenseApi({licenseParams});

        if(saveLicenseErr) {
            let error = {
                message : 'Error while saving the license details',
                serverError : saveLicenseErr,
            }
            dispatch(notifyMailingPlanSubmitError(
                error
            ));
            return dispatch({
                type: SAVING_MAILING_PLAN,
                payload: false
            });
        }
    }

    const [mailingPlanSubmitErr, mailingPlanSubmit] = await submitMailingPlanApi({
        brandId,
        franchiseId,
        mailingPlan: generatedMailingPlan
    });

    if(mailingPlanSubmitErr) {
        dispatch(notifyMailingPlanSubmitError({ serverError: mailingPlanSubmitErr }));
    } else {
        dispatch(setMailingPlanStatus('Submitted'));
        dispatch({
            type: SUBMITTED_MAILING_PLAN,
            payload: true
        });
        dispatch(getMailingPlanSubmissions(brandId, franchiseId));
        dispatch(getMailingPlan(brandId, franchiseId, mailingPlanSubmit?.id));
    }

    return dispatch({
        type: SUBMITING_MAILING_PLAN,
        payload: false
    });
}

export const saveDraftMailingPlan = (
    brandId, 
    franchiseId, 
    mailingPlan, 
    isCorrectFranchiseInfo = false
) => async dispatch => {
    dispatch(notifyMailingPlanSubmitError({ display: false }));
    const generatedMailingPlan = {
        id:mailingPlan.mailingPlanId,
        mailingPlanTemplateId: mailingPlan.mailingPlanTemplateId,
        campaigns: generateCampaigns(mailingPlan.campaigns) || [],
        sendOrder: mailingPlan.mailingOrder.map(order => order.id),
        excludedZips: mailingPlan.excludedZipCodes?.map(code => code),
        comments: mailingPlan.comments,
        businessRules: mailingPlan.templateBusinessRules,
        isCorrectFranchiseInfo
    }

    dispatch({
        type: SAVING_MAILING_PLAN,
        payload: true
    });

    //Save License field for MSQ
    if(userSession.isMSQUser()){
        let licenseParams = { id:franchiseId, override_license: mailingPlan.overrideLicense, license_number: mailingPlan.licenseNumber };
        let [saveLicenseErr,Succ] = await saveFranchiseLicenseApi({licenseParams});

        if(saveLicenseErr) {
            let error = {
                message : 'Error while saving the license details',
                serverError : saveLicenseErr,
            }
            dispatch(notifyMailingPlanSubmitError(
                error
            ));
            return dispatch({
                type: SAVING_MAILING_PLAN,
                payload: false
            });
        }
    }

    const [mailingPlanSubmitErr, mailingPlanSubmit] = await saveDraftMailingPlanApi({
        brandId,
        franchiseId,
        mailingPlan: generatedMailingPlan
    });

    if(mailingPlanSubmitErr) {
        dispatch(notifyMailingPlanSubmitError({
            message: `Error in saving mailing plan`,
            serverError: mailingPlanSubmitErr
        }));
    } else {
        dispatch(setMailingPlanStatus('Saved'));
        dispatch({
            type: SAVED_MAILING_PLAN,
            payload: true
        });
        dispatch(getMailingPlanSubmissions(brandId, franchiseId));
        dispatch(getMailingPlan(brandId, franchiseId, mailingPlanSubmit?.id));
    }

    return dispatch({
        type: SAVING_MAILING_PLAN,
        payload: false
    });
}

export const copyDraftMailingPlan = (
    brandId, 
    franchiseId, 
    mailingPlan, 
    isCorrectFranchiseInfo = false
) => async dispatch => {
    isCorrectFranchiseInfo = isCorrectFranchiseInfo ?isCorrectFranchiseInfo :false;
    dispatch(notifyMailingPlanSubmitError({ display: false }));
    const generatedMailingPlan = {
        mailingPlanTemplateId: mailingPlan.mailingPlanTemplateId,
        campaigns: generateCampaigns(mailingPlan.campaigns) || [],
        sendOrder: mailingPlan.mailingOrder.map(order => order.id),
        excludedZips: mailingPlan.excludedZipCodes?.map(code => code),
        comments: mailingPlan.comments,
        businessRules: mailingPlan.templateBusinessRules,
        isCorrectFranchiseInfo
    }
    dispatch({
        type: SAVING_MAILING_PLAN,
        payload: true
    });

    const [mailingPlanCopyErr, mailingPlanCopy] = await copyDraftMailingPlanApi({
        brandId,
        franchiseId,
        mailingPlan: generatedMailingPlan
    });

    if(mailingPlanCopyErr) {
        dispatch(notifyMailingPlanSubmitError({
            message: `Error in saving draft mailing plan`,
            serverError: mailingPlanCopyErr
        }));
    } else {
        dispatch(getMailingPlanSubmissions(brandId, franchiseId));
        dispatch(getMailingPlan(brandId, franchiseId, mailingPlanCopy?.id));
    }

    dispatch({
        type: SAVING_MAILING_PLAN,
        payload: false
    });
    return mailingPlanCopy;
}
export const confirmMailingPlanSubmission = (value = true) => {
    return {
        type: SUBMITTED_MAILING_PLAN,
        payload: value
    }
}
export const confirmMailingPlanSaveDraft = (value = true) => {
    return {
        type: SAVED_MAILING_PLAN,
        payload: value
    }
}

export const getZipCodes = (franchiseId = userSession.getFranchiseId()) => async dispatch => {
    dispatch(notifyGetZipCodeError({ display: false }));

    dispatch({
        type: GET_ZIP_CODES_LOADING,
        payload: true
    });

    let [zipCodesErr, zipCodes] = await getZipCodesApi({ franchiseId });

    if(zipCodesErr) {
        dispatch(notifyGetZipCodeError({ serverError: zipCodesErr }));
    }

    dispatch({
        type: GET_ZIP_CODES_LOADING,
        payload: false
    });

    return dispatch({
        type: GET_ZIP_CODES,
        payload: zipCodes?.map(code => zipCodeWrapper(code))
    });
}

export const toggleZipCode = (zipCode = null) => {
    if(zipCode) {
        return {
            type: TOGGLE_ZIP_CODE,
            payload: zipCode
        }
    }
}

export const toggleAllZipCodes = (selectAll = false) => {
    return {
        type: TOGGLE_ALL_ZIP_CODES,
        payload: selectAll
    }
}

export const addComments = (comments = null) => {
    return {
        type: ADD_COMMENTS,
        payload: comments
    }
}

export const enableMailingPlanOverride = (value = false) => {
    return {
        type: OVERRIDE_MAILING_PLAN_ENABLED,
        payload: value
    }
}

export const overrideMailingPlan = (
    mailingPlan = null,
    overrideFeedback = null,
    isCorrectFranchiseInfo = false
) => async dispatch => {
    dispatch(notifyMailingPlanOverrideError({ display: false }));

    const generatedMailingPlan = {
        id: mailingPlan.mailingPlanId,
        campaigns: generateCampaigns(mailingPlan.campaigns) || [],
        excludedZips: mailingPlan.excludedZipCodes?.map(code => code),
        feedback: overrideFeedback?.trim() || '',
        comments: mailingPlan.comments,
        isCorrectFranchiseInfo
    }

    dispatch({
        type: OVERRIDE_MAILING_PLAN_LOADING,
        payload: true
    });
    
    const [mailingPlanOverrideErr] = await overrideMailingPlanApi({
        mailingPlan: generatedMailingPlan
    });

    if(mailingPlanOverrideErr) {
        dispatch(notifyMailingPlanOverrideError({ serverError: mailingPlanOverrideErr }));
    } else {
        dispatch(showOverrideConfirmationModal(false));
        dispatch(enableMailingPlanOverride(false));
        dispatch(approveMailingPlan(mailingPlan.mailingPlanId));
    }

    return dispatch({
        type: OVERRIDE_MAILING_PLAN_LOADING,
        payload: false
    });
}

export const showOverrideConfirmationModal = (value = false) => {
    return {
        type: OVERRIDE_MAILING_PLAN_SHOW_CONFIRMATION,
        payload: value
    };
}

export const resetMailingPlan = (mailingPlan = null) => {
    return {
        type: RESET_MAILING_PLAN,
        payload: mailingPlan
    }
}

export const approveMailingPlan = (mailingPlanId = null) => async dispatch => {
    dispatch(notifyMailingPlanApproveError({ display: false }));

    dispatch({
        type: APPROVING_MAILING_PLAN,
        payload: true
    });

    let [approveErr] = await approveMailingPlanApi({ mailingPlanId });

    if(approveErr) {
        dispatch({ type: APPROVED_MAILING_PLAN, payload: false });
        dispatch(notifyMailingPlanApproveError({
            type: 'warning',
            serverError: approveErr
        }));

    } else {
        dispatch(setMailingPlanStatus('Approved'));
        dispatch({ type: APPROVED_MAILING_PLAN, payload: true });
    }

    return dispatch({
        type: APPROVING_MAILING_PLAN,
        payload: false
    });
}

export const confirmMailingPlanApproval = (value = true) => {
    return {
        type: APPROVED_MAILING_PLAN,
        payload: value
    }
}

export const rejectMailingPlan = (mailingPlanId, feedback = null) => async dispatch => {
    dispatch(notifyMailingPlanRejectError({ display: false }));

    dispatch({
        type: REJECTING_MAILING_PLAN,
        payload: true
    });

    let [rejectErr] = await rejectMailingPlanApi({
        mailingPlanId,
        feedback: feedback?.trim() || ''
    });

    if(rejectErr) {
        dispatch({ type: REJECTED_MAILING_PLAN, payload: false });
        dispatch(notifyMailingPlanRejectError({
            type: 'warning',
            serverError: rejectErr
        }));

    } else {
        dispatch(setMailingPlanStatus('Rejected'));
        dispatch({ type: REJECTED_MAILING_PLAN, payload: true });
    }

    return dispatch({
        type: REJECTING_MAILING_PLAN,
        payload: false
    });
}

export const confirmMailingPlanRejection = (value = true) => {
    return {
        type: REJECTED_MAILING_PLAN,
        payload: value
    }
}

export const setMailingPlanStatus = (value = null) => {
    return {
        type: SET_MAILING_PLAN_STATUS,
        payload: value
    };
}

export const saveOwnerDetails = (
    franchiseId,
    mailingPlan
) => async dispatch => {
    dispatch(notifyMailingPlanSubmitError({ display: false }));

    let ownerParams = { id:franchiseId , owner_contact_email: mailingPlan.ownerContactEmail, owner_phone_number: mailingPlan.ownerPhoneNumber, owner_name: mailingPlan.ownerName };
    let [saveOwnerErr,Succ] = await saveFranchiseOwnerDetailsApi({ownerParams});

    if(saveOwnerErr) {
        let error = {
            message : 'Error while saving the owner details',
            serverError : saveOwnerErr,
        }
        dispatch(notifyMailingPlanSubmitError(
            error
        ));
        return dispatch({
            type: SAVING_MAILING_PLAN,
            payload: false
        });
    }
}
import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip as RBTooltip } from 'react-bootstrap';

const Tooltip = ({
    placement = 'top',
    message = '',
    icon = 'fas fa-question-circle',
    iconClassName = null,
    action = null,
    ...props
}) => {
    return (
        <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
                <RBTooltip id={`tooltip-${placement}`}>
                    {message}     
                </RBTooltip>
            }
        >
            <i 
                {...props}
                className={`${icon} brand-primary-color ${iconClassName}`.trim()}
                onClick={() => {
                    if(action) action();
                }}
            ></i>
        </OverlayTrigger>
    );
}

Tooltip.propTypes = {
    placement: PropTypes.string,
    message: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconClassName: PropTypes.string,
    action: PropTypes.func
}

export default Tooltip;

const addressImportResWrapper = addressImportResData => {
    if(
        !addressImportResData
        || Array.isArray(addressImportResData)
        || typeof addressImportResData !== 'object'
    ) return {};

    return {
        total: addressImportResData?.total || 0,
        success: addressImportResData?.success || 0,
        failed: addressImportResData?.failed || 0,
        failedRows: addressImportResData?.failedRows || [],
        importLogId: addressImportResData?.importLogId || null
    }
}

export default addressImportResWrapper;
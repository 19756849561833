import sortBy from 'utils/sortBy';

const budgetEntryWrapper = data => {
    if (!data || Array.isArray(data) || typeof data !== 'object') return {};

    return {
        id: data.entry_id,
        tacticId: data.tactic_id,
        budgetPlanId: data.budget_plan_id,
        franchiseId: data.franchise_id,
        setupFranchiseId: data.franchise_id_setup,
        resComId:data.res_com_id,
        resComValue:data.res_com_desc,
        websiteId: data.website_id,
        website: data.website_name,
        budgets: sortBy({
            data: data.budgets,
            attr: 'month',
            type: 'number',
        })?.map(budget => ({
            id: budget.monthly_budget_id,
            value: budget.budget,
            month: budget.month,
        })),
        isPrefferedTactic: data.is_preffered_tactic,
        isScorpionTactic: data.is_scorpion_tactic,
        isTdmTactic: data.is_tdm_tactic,
        isPulsmTactic: data.is_pulsm_tactic,
        isCAIDirectMail: data.is_cai_dm,
        isMultiviewDigitalB2BAdvertising: data.is_multiview_digital_b2B_advertising,
        isDiscretionaryMarketingSpendTactic: data.is_discretionary_marketing_spend_tactic,
        isNativeAdvertising: data.is_native_advertising,
        isYoutubeAdvertising: data.is_youtube_advertising,
        isMirroredDisplay: data.is_mirrored_display,
        isMultiview: data.is_multiview,
    };
};

export default budgetEntryWrapper;

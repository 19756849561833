import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Input from '../input/Input';
import Select from '../select/Select';

import formatCurrency from 'utils/formatCurrency';

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const MonthlyInput = ({
    title,
    value,
    month,
    validValues,
    displayOnly = false,
    disabled = false,
    onChange,
}) => {
    const renderInput = () => {
        if (displayOnly) {
            return (
                <div className='disabled'>{`$${formatCurrency(value)}`}</div>
            );
        }

        return (
            <Fragment>
                {validValues ? (
                    <Select
                        value={value}
                        options={validValues?.map(val => ({
                            value: val,
                            label: `$${formatCurrency(val)}`,
                        }))}
                        onChange={({ target: { value } }) => {
                            if (onChange) onChange(Number(value));
                        }}
                        disabled={disabled}
                    />
                ) : (
                    <Input
                        value={`$${value}`.replace(/\$+/, '$')}
                        onChange={({ target: { value } }) => {
                            let max=1000000,min=0;
                            value = value
                                .replace(/\D*/g, '')
                                .replace(/^0+/i, '');
                                value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                            value = value || 0;
                            if (onChange) onChange(Number(value));
                        }}
                        disabled={disabled}
                    />
                )}
            </Fragment>
        );
    };

    return (
        <div className='monthly-input-container'>
            <div className='monthly-input-header'>{title || months[month]}</div>
            <div className='monthly-input-field'>{renderInput()}</div>
        </div>
    );
};

MonthlyInput.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    month: PropTypes.number,
    validValues: PropTypes.array,
    displayOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default MonthlyInput;

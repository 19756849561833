const serviceEntryWrapper = service => {
    if(
        !service
        || Array.isArray(service)
        || typeof service !== 'object'
    ) return {};

    return {
        serviceId: service?.id,
        name: service?.name,
        outputText: service?.output_text
    };
}

export default serviceEntryWrapper;
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import userSession from 'utils/userSession';

const authorize = WrappedComponent => {
    class Authorize extends Component {
        verifyUserCredentials = () => {
            const { location } = this.props;

            const lmpbpAuthorizer = location.pathname.includes('/lmpbp');
            const token = location.search.replace('?key=', '');
            const decodedToken = jwtDecode(token);

            userSession.set(decodedToken);

            if (lmpbpAuthorizer) {
                userSession.setLmpbpToken(token);
                userSession.setArrivedAtLmpbp(true);
            } else {
                userSession.setDirectMailToken(token);
            }

            return userSession.isActive();
        };

        render() {
            if (this.verifyUserCredentials()) {
                if (userSession.arrivedAtLmpbp()) {
                    return <Redirect to='/lmpbp/dashboard' />;
                }

                return <Redirect to='/' />;
            }

            return <WrappedComponent {...this.props} />;
        }
    }

    return Authorize;
};

export default authorize;

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import userSession from 'utils/userSession';

const accessLmpbp = WrappedComponent => {
    class AccessLmpbp extends Component {
        render() {
            if(userSession.arrivedAtLmpbp()) {
                return <WrappedComponent {...this.props} />;
            }

            return <Redirect to='/unauthorized' />;
        };
    }
    
    return AccessLmpbp;
}

export default accessLmpbp;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/common/input/Input';
import Switch from 'components/common/switch/Switch';
import Button from 'components/common/button/Button';

export class LicenseSelection extends Component {
    state = {
        licenseNumber: null,
        overrideLicense: false,
        isSaving:null
    }
    
    static propTypes = {
        licenseNumber: PropTypes.string,
        franchiseId: PropTypes.number,
        overrideLicense: PropTypes.bool,
        onSaveLicense: PropTypes.func,
        saving: PropTypes.bool,
    }

    componentDidMount() {
        const { 
            licenseNumber,
            overrideLicense
        } = this.props;

        this.setState({ licenseNumber });      
        this.setState({ overrideLicense });      
    }
    onChangeLicense = (license) => {
        this.setState({ selectedLicense: license });  
    }
    onChangeOverrideLicense = (overrideValue) => {
        const { 
            licenseNumber
        } = this.props;
        this.setState({ overrideLicense: overrideValue }); 
        if(!overrideValue)  {
            this.setState({ licenseNumber: licenseNumber });
        }      
    }
    saveLicense = () => {
        const {franchiseId } = this.props;
        const { licenseNumber,overrideLicense} = this.state;
        let params = { license_number: licenseNumber, override_license: overrideLicense,id: franchiseId }
        const {
            onSaveLicense,
        } = this.props;
       
        this.setState({ 
            isSaving: franchiseId
        },  () => {
             onSaveLicense(params);
          });
    }
    render() {
        const {franchiseId, saving} = this.props;
        const { licenseNumber, overrideLicense, isSaving} = this.state;
        let label = overrideLicense ? "Yes" :"No";
        return (
            <div className='d-flex justify-content-center'>
                    <div className='d-flex flex-column align-items-start'>
                        <Input 
                            type='text' 
                            placeholder='Enter License Number'
                            value={this.state.licenseNumber}
                            containerStyling='w-100'
                            onChange={(e) => {
                                this.setState({ licenseNumber: e.target.value});
                                this.onChangeLicense(e.target.value,franchiseId)
                            }}
                            readOnly={!overrideLicense}
                            error={this.state.error ? true : false}
                            errorMsg={this.state.error}
                        />
                        <br></br>
                        <div class="row ">
                        <div class="col-3 ">
                            <b>Override License</b>
                        </div>
                        <div class="col-3">
                            <Switch
                               id={`license-override-${franchiseId}`}
                               label= {label}
                                labelTop={false}
                                checked={overrideLicense}
                                onToggle={(checked) => this.onChangeOverrideLicense(checked)} 
                                />
                        </div>
                        <div class="col-3">
                        <Button 
                         disabled={!licenseNumber && overrideLicense }
                        btnType='primary save-license-button'
                        action={() => this.saveLicense()}
                        >
                        {(isSaving == saving) && saving!=null ? 'Saving...':'Save' }
                        </Button>
                        </div>
                    </div>
                    </div>
            </div>
        );
    }
}

export default LicenseSelection;

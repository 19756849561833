import {
    GET_MAILING_PLANS,
    GET_MAILING_PLANS_LOADING,
    GET_MAILING_PLAN_STATUSES,
    GET_MAILING_PLANS_OVERVIEW,
    GET_FRANCHISES_FILTER_DATA,
    GET_FRANCHISES_DROPDOWN_FILTER_DATA,
    GET_FRANCHISES_LOADING,
    SAVING_FRANCHISE,
    GET_MAILING_YEARS,
    GET_CAMPAIGNS_TEMPLATES,
    GET_CAMPAIGNS_TEMPLATES_LOADING,
    SET_MAILING_PLANS_END_DATE,
    SET_MAILING_PLANS_START_DATE,
    SET_MAILING_PLANS_STATUS_FILTER,
    SET_MAILING_PLANS_FRANCHISE_FILTER,
    SET_MAILING_PLANS_YEAR_FILTER,
    SET_MAILING_PLANS_CAMPAIGN_DATE_FILTER,
    SET_MAILING_PLANS_BROCHURE_FILTER,
    SET_MAILING_PLANS_COUPON_FILTER,
    SYNCHING_FRANCONNECT,
    CONFIRM_EMAIL_REMINDERS,
    SET_MAILING_PLANS_DM_EXEMPT_STATUS_FILTER,
} from 'actions/types';

const dashboardReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_MAILING_PLANS:
            return { ...state, mailingPlans: { ...state.mailingPlans, entries: [...action.payload] } };

        case GET_MAILING_PLANS_LOADING:
            return { ...state, mailingPlans: { ...state.mailingPlans, loading: action.payload } };

        case GET_MAILING_PLAN_STATUSES:
            return { ...state, statuses: [...action.payload] };

        case GET_FRANCHISES_FILTER_DATA:
            return { ...state, franchises: [...action.payload] };

        case GET_FRANCHISES_DROPDOWN_FILTER_DATA:
                return { ...state, franchisesDropdown: [...action.payload] };
                
        case GET_FRANCHISES_LOADING:
            return { ...state, loadingFranchises: action.payload };

        case SAVING_FRANCHISE:
            return { ...state, savingFranchise: action.payload };

        case GET_MAILING_PLANS_OVERVIEW:
            return { ...state, overview: {...action.payload} };
        
        case GET_MAILING_YEARS:
            return { ...state, mailingYears: [...action.payload] };

        case GET_CAMPAIGNS_TEMPLATES:
            return { ...state, campaignsTemplates: { ...state.campaignsTemplates, entries: [...action.payload] } };

        case GET_CAMPAIGNS_TEMPLATES_LOADING:
            return { ...state, campaignsTemplates: { ...state.campaignsTemplates, loading: action.payload } };

        case SET_MAILING_PLANS_START_DATE:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, startDate: action.payload } } };

        case SET_MAILING_PLANS_END_DATE:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, endDate: action.payload } } };

        case SET_MAILING_PLANS_STATUS_FILTER:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, status: action.payload } } };

        case SET_MAILING_PLANS_FRANCHISE_FILTER:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, franchise: action.payload } } };
        
        case SET_MAILING_PLANS_YEAR_FILTER:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, year: action.payload } } };

        case SET_MAILING_PLANS_CAMPAIGN_DATE_FILTER:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, campaignDate: action.payload } } };

        case SET_MAILING_PLANS_BROCHURE_FILTER:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, brochure: action.payload } } };

        case SET_MAILING_PLANS_COUPON_FILTER:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, coupon: action.payload } } };

        case SYNCHING_FRANCONNECT:
            return { ...state, synchingFranConnect: action.payload };

        case CONFIRM_EMAIL_REMINDERS:
            return {...state, confirmEmailReminders: action.payload};

        case SET_MAILING_PLANS_DM_EXEMPT_STATUS_FILTER:
            return { ...state, mailingPlans: { ...state.mailingPlans, filters: {...state.mailingPlans.filters, isDmExempt: action.payload } } };
    
        default:
            return state;
    }
}

export default dashboardReducer;
import React from "react";
import IconButton from "../IconButton";

function PencilIconButton(props) {
  return (
    <IconButton
      variant="primary"
      aria-label="Delete"
      iconName="fa-pencil-alt"
      {...props} />
  );
}

export default PencilIconButton;

import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({
    required = false,
    error = false,
    errorMsg = 'Please verify this field',
    value = '',
    maxLength = 0,
    displayMaxChars = false,
    label = null,
    ...props
}) => {
    const countChars = (val = '') => {
        if (!val) return 0;

        return val.trim().length;
    };

    const renderError = () => {
        if ((required && !value) || error) {
            return <small className='error-msg'>{errorMsg}</small>;
        }
    };

    const renderMaxCount = () => {
        if (displayMaxChars) {
            return (
                <small className='d-flex justify-content-end'>
                    {countChars(value)}/{maxLength}
                </small>
            );
        }

        return null;
    };

    return (
        <div className='d-flex flex-column column-gap-1'>
            {label ? <div>{label}</div> : null}
            <textarea value={value} maxLength={maxLength} {...props} />
            {renderMaxCount()}
            {renderError()}
        </div>
    );
};

Textarea.propTypes = {
    required: PropTypes.bool,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    displayMaxChars: PropTypes.bool,
    label: PropTypes.string,
};

export default Textarea;

import { 
    getServices as getServicesApi,
    createService as createServiceApi,
    updateService as updateServiceApi,
    deleteService as deleteServiceApi
} from 'api/direct-mail/services';

import { 
    DELETE_SERVICE_LOADING,
    GET_SERVICES_FOR_CONFIG_LIST, 
    GET_SERVICES_FOR_CONFIG_LIST_LOADING, 
    SAVE_SERVICE_LOADING,
    UPDATE_SERVICE_LOADING
} from './types';

import { 
    notifyDeleteServiceError,
    notifyDeleteServiceSuccess,
    notifyGetServicesForConfigListError, 
    notifySaveServiceError, 
    notifySaveServiceSuccess,
    notifyUpdateServiceError,
    notifyUpdateServiceSuccess
} from './notificationActions';

import serviceWrapper from 'api/direct-mail/utils/backend-wrappers/serviceWrapper';

export const resetServicesList = () => {
    return {
        type: GET_SERVICES_FOR_CONFIG_LIST,
        payload: []
    };
}

export const getServices = templateId => async dispatch => {
    dispatch(notifyGetServicesForConfigListError({ display: false }));

    dispatch({
        type: GET_SERVICES_FOR_CONFIG_LIST_LOADING,
        payload: true
    });

    const [servicesErr, servicesRes] = await getServicesApi({ templateId });

    if(servicesErr) {
        dispatch(notifyGetServicesForConfigListError({ serverError: servicesErr }));
    }

    dispatch({
        type: GET_SERVICES_FOR_CONFIG_LIST,
        payload: serviceWrapper.sortByName(servicesRes)
    });

    return dispatch({
        type: GET_SERVICES_FOR_CONFIG_LIST_LOADING,
        payload: false
    });
}

export const createService = (templateId, service = {}, onComplete = null) => async dispatch => {
    dispatch(notifySaveServiceError({ display: false }));
    dispatch(notifySaveServiceSuccess({ display: false }));

    dispatch({
        type: SAVE_SERVICE_LOADING,
        payload: true
    });

    const [saveErr] = await createServiceApi({ templateId, ...service });

    if(saveErr) {
        dispatch(notifySaveServiceError({ serverError: saveErr }));
    } else {
        dispatch(notifySaveServiceSuccess());
        await dispatch(getServices(templateId));
    }

    if(onComplete) onComplete();

    return dispatch({
        type: SAVE_SERVICE_LOADING,
        payload: false
    });
}

export const updateService = (templateId, service = {}, onComplete = null) => async dispatch => {
    dispatch(notifyUpdateServiceError({ display: false }));
    dispatch(notifyUpdateServiceSuccess({ display: false }));

    dispatch({
        type: UPDATE_SERVICE_LOADING,
        payload: true
    });

    const [updateErr] = await updateServiceApi({ ...service });

    if(updateErr) {
        dispatch(notifyUpdateServiceError({ serverError: updateErr }));
    } else {
        dispatch(notifyUpdateServiceSuccess());
        await dispatch(getServices(templateId));
    }

    if(onComplete) onComplete();

    return dispatch({
        type: UPDATE_SERVICE_LOADING,
        payload: false
    });
}

export const deleteService = (templateId, serviceId = null, onComplete = null) => async dispatch => {
    dispatch(notifyDeleteServiceError({ display: false }));
    dispatch(notifyDeleteServiceSuccess({ display: false }));

    dispatch({
        type: DELETE_SERVICE_LOADING,
        payload: true
    });

    const [deleteErr] = await deleteServiceApi({ serviceId });

    if(deleteErr) {
        dispatch(notifyDeleteServiceError({ serverError: deleteErr }));
    } else {
        dispatch(notifyDeleteServiceSuccess());
        await dispatch(getServices(templateId));
    }

    if(onComplete) onComplete();

    return dispatch({
        type: DELETE_SERVICE_LOADING,
        payload: false
    });
}
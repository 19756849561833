import React, { useState, useEffect, useMemo, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import SelectReact from 'react-select';

import MonthlyInput from 'components/common/monthly-budget/MonthlyInput';

import { BudgetPlanContext } from 'components/lmpbp/budget-plan/BudgetPlan';

import validateStatus from 'utils/validateStatus';
import userSession from 'utils/userSession';
import { getResCom } from 'api/lmpbp/resCom';

import {
    LMPBP_GET_RESCOM_ERROR,
    LMPBP_SET_HAVE_UNSAVED_CHANGES
} from 'actions/types';
import {
    closeNotification,
    showNotification,
} from 'actions/notificationActions';
import { Form } from 'react-bootstrap';
import { allEqual } from 'utils/arrayUtils';

const TacticEntry = ({ entry, tactic, onUpdateEntry }) => {
    const {
        budgetPlan,
        template,
        submittingPlan,
        savingPlan,
    } = useContext(BudgetPlanContext);

    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [hiddenEntry, setHiddenEntry] = useState(false);
    const [selectedResCom, setSelectedResCom] = useState({value: entry.resComId, label: entry.resComValue});

    const isResCom = () => {
        if (submittingPlan) return true;
        if (savingPlan) return true;
        if (!validateStatus.isDraft(budgetPlan.status)) return true;
        return false;
    }

    const isLockedMonth = (month, entry) => {
        if (submittingPlan 
            || savingPlan 
            || !validateStatus.isDraft(budgetPlan.status) 
            || tactic.isAnnualOptIn
            || tactic.locked)
        {
            return true;
        }

        const monthlyLockDates = template?.lockDates || {};
        if (userSession.isDcsLmpbpUser()) {
            if (userSession.isDivisionalUser()) {
                return false;
            } else {
                if(entry?.isPulsmTactic || tactic.name === "LocalSocialMediaContent"){
                    let lockdate = monthlyLockDates[month].substring(0, monthlyLockDates[month]);
                    lockdate =  new moment(monthlyLockDates[month]).startOf('day').subtract(1, 'months').format('MM-DD-YYYY');
                    return new moment().isAfter(lockdate, 'day');
                } else if(entry?.isCAIDirectMail){
                    //LMPBP-439 CAI Direct Mail’ as new tactic for Sept & Oct ONLY (for the other months the input fields should appear disabled).
                    let exceptMonths = [8,9];
                    return !exceptMonths.includes(month);
                } else if(tactic.name === "MultiviewDigitalB2BAdvertising"){
                    const lockedMonths = [0];
                    return lockedMonths.includes(month);
                }
            }
        }
        if (userSession.isMSQLmpbpUser() && userSession.isFranchiseOwer() && tactic.name === "SEO") {
                return true;
        }
        else if (userSession.isFranchiseOwer() && !_.isEmpty(monthlyLockDates)) {
            const lockdate = monthlyLockDates[month].substring(0, monthlyLockDates[month].indexOf('T'));
            return new moment().isAfter(lockdate, 'day');
        } else {
            const planYear = template.year;
            const currentDay = new moment().date();
            const currentYear = new moment().year();
            const currentMonth = new moment().month();

            if (userSession.getBrandId() == 1) {
                if (currentMonth > month + 2 && currentYear == planYear && userSession.isDivisionalUser()) return true;
            }
            else {
                // TODO: Return this validation for all the brands for divisional user
                if (currentMonth > month && currentYear == planYear && userSession.isDivisionalUser()) return true;
            }

            if (userSession.isFranchiseOwer()) {
                if (currentDay > 15 && month >= currentMonth && currentYear == planYear) return true;
                if (month == 0 && currentMonth == 11 && planYear == (currentYear + 1) && currentDay > 15) return true;
            }
        }

        return false;
    };

    const lockedMonths = _.sortBy(entry?.budgets, ['month'])
        .map(({ month }) => isLockedMonth(month, entry));

    const onMonthyBudgetChange = (budgetId, month, value) => {
        if (validateStatus.isDraft(budgetPlan.status)) {
            const updatedBudgets = _.sortBy(
                [
                    ...entry.budgets.filter(({ id }) => budgetId !== id),
                    { id: budgetId, value, month },
                ],
                ['month']
            );
            updateEntryBudgets(entry, updatedBudgets, lockedMonths);
        }
    };

    const updateEntryBudgets = (entry, updatedBudgets, lockedMonths) => {
        dispatch({type:LMPBP_SET_HAVE_UNSAVED_CHANGES, payload: true});
        onUpdateEntry({
            ...entry,
            budgets: [...updatedBudgets],
            lockedMonths: lockedMonths
        });
    }

    const handleOptIn = (event) => {
        const commonBudget = event.target.checked ? tactic.validValues[1] : 0;
        updateEntryBudgets(entry, generateBudgetsFromEntries(commonBudget), lockedMonths);
    }

    const generateBudgetsFromEntries = (value) => {
        return _.sortBy(
            entry.budgets.map(b => {
                return {
                    ...b,
                    value: value
                }
            }),
            ['month']
        );
    }

    const renderBudgetFields = () => {
        if (hiddenEntry)
            return (
                <div className='font-size-16 c-neutral'>
                    This tactic and its related website are setup on a different
                    territory
                </div>
            );

        return _.sortBy(entry?.budgets, ['month']).map(
            ({ id, month, value, tacticId, websiteId }) => (
                <MonthlyInput
                    key={`monthly-input-${month}`}
                    entryId={id}
                    tacticId={tacticId}
                    websiteId={websiteId}
                    value={value}
                    month={month}
                    validValues={tactic.validValues}
                    disabled={ lockedMonths[month] }
                    onChange={budget => onMonthyBudgetChange(id, month, budget)}
                />
            )
        );
    };
    useEffect(() => {
        if(userSession.getBrandId() == 3 && entry.tacticId == 3){
            resCom.refetch();
        }
    }, []);

    useEffect(() => {
        const { setupFranchiseId, franchiseId } = entry;

        // If setupFranchiseId is not equal to franchiseId,
        // then the entry has been configured in a different franchise
        // and must be hidden from the current franchise

        setHiddenEntry(setupFranchiseId && setupFranchiseId !== franchiseId);

        // return () => autoSaveEntry.cancel();
    }, [template, entry]);

    useEffect(() => {
        setTotal(
            entry.budgets?.map(({ value }) => value).reduce((a, b) => a + b)
        );
    }, [entry]);

    const resCom = useQuery(
        ['resCom',userSession.getBrandId(),tactic.id],
        async () => await getResCom({
            brandID: userSession.getBrandId(),
            tacticID: tactic.id,
        }),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_RESCOM_ERROR,
                        message: 'Error fetching Market Segment Ratio',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_GET_RESCOM_ERROR));
            },
        }
    );

    const generateResComOptions = () => {
        let newOptions = [
        ];

        if (resCom.data) {
            resCom.data.forEach(({ id, name,brandId,tacticId }) => {
                if(tacticId == tactic.id)
                {
                    newOptions.push({
                        value: id,
                        label: name,
                        brand: brandId,
                        tactic: tacticId
                    });
                }
            });
        }

        if(entry?.resComId && entry?.resComValue === null && newOptions.length > 0 &&
            (!selectedResCom || selectedResCom?.label === null))
        {
            const selected = newOptions.find((item) => item.value == entry.resComId);
            setSelectedResCom(selected);
        }
        // setOptions(options);
        return newOptions;
    };

    useEffect(() => {
        // if((entry.tacticId == 3 || entry.tacticId == 6 || entry.tacticId == 7) && selectedResCom){
        if((entry.tacticId == 3) && selectedResCom){
            onUpdateEntry({
                ...entry,
                resComId: selectedResCom?.value, 
                resComValue: selectedResCom?.label,
                lockedMonths: lockedMonths
            });
        }
    }, [selectedResCom]);

    function areAllBudgetsEqualAndNonZero(budgets) {
        return budgets[0].value !== 0 && allEqual(budgets.map(b => b.value));
    }

    return (
        <div className='d-flex flex-column w-100' id="tactic-entry">
            <div className='d-flex align-items-center mb-2'>
                {entry.website ? (
                    <div className='flex-grow-2 m-0'>
                        <i className='fas fa-globe font-size-24 brand-primary-color mr-2'></i>
                        <a href={entry.website} target='_blank' rel='noreferrer'>
                            {entry.website}
                        </a>
                    </div>
                ) : <div className='flex-grow-2 m-0'></div>}
                {/* {(tactic.name =='ANGI' || tactic.name =='Thumbtack') && userSession.getBrandId() == 5 && 
                    !hiddenEntry && validateStatus.isDraft(budgetPlan.status) ?
                    <Fragment>
                        <label className="font-size-14 c-neutral mb-1 mr-1">
                        Select Exterior vs. Interior split here
                        </label>
                        <div className='flex-shrink-1 m-0'>
                            <SelectReact
                                placeholder = {'Select'}
                                value={selectedResCom}
                                onChange={setSelectedResCom}
                                options={generateResComOptions()}
                                isDisabled={isResCom()}
                            />
                        </div> 
                    </Fragment>
                : null} */}
                {tactic.name =='Scorpion' && userSession.getBrandId() == 3 && 
                    !hiddenEntry && validateStatus.isDraft(budgetPlan.status) ?
                    <Fragment>
                        <label className="font-size-14 c-neutral mb-1 mr-1">
                            Select Residential vs Commercial split here
                        </label>
                        <div className='flex-shrink-1 m-0'>
                            <SelectReact
                                placeholder = {'Select'}
                                value={selectedResCom}
                                onChange={setSelectedResCom}
                                options={generateResComOptions().filter(value => value.tactic === tactic.id)}
                                isDisabled={isResCom()}
                            />
                        </div> 
                    </Fragment>
                : null}
                {tactic.name =='Scorpion' && userSession.getBrandId() == 3 && 
                    !hiddenEntry && !validateStatus.isDraft(budgetPlan.status) && 
                    selectedResCom ?
                    <Fragment>
                        <label className="c-neutral font-size-14 mb-1 mr-1">
                            Select Residential vs Commercial: 
                        </label>
                        <div className='flex-shrink-1 m-1 c-neutral'>
                            {generateResComOptions().filter(v => v.value === entry.resComId)[0]?.label}
                        </div>
                    </Fragment>
                : null}
                {/* {(tactic.name =='ANGI' || tactic.name =='Thumbtack') && userSession.getBrandId() == 5 && 
                    !hiddenEntry && !validateStatus.isDraft(budgetPlan.status) && 
                    selectedResCom ?
                    <Fragment>
                        <label className="c-neutral font-size-14 mb-1 mr-1">
                        Select Exterior vs. Interior: 
                        </label>
                        <div className='flex-shrink-1 m-1 c-neutral'>
                            {generateResComOptions().filter(v => v.value === entry.resComId)[0]?.label}
                        </div>
                    </Fragment>
                : null} */}
            </div>
            <div className='position-relative'>
                {tactic.isAnnualOptIn 
                    && ((entry.website && entry.franchiseId === entry.setupFranchiseId) || !entry.website)
                    && <Form.Check
                        type='checkbox'
                        label='Opt-in'
                        onChange={handleOptIn}
                        disabled={!validateStatus.isDraft(budgetPlan.status)}
                        defaultChecked={areAllBudgetsEqualAndNonZero(entry.budgets)}
                    />
                }
                <div className='d-flex align-items-center'>
                    {renderBudgetFields()}
                    {!hiddenEntry ? 
                        <MonthlyInput
                            title={'Total'}
                            value={total}
                            displayOnly={true}
                        />
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

TacticEntry.propTypes = {
    entry: PropTypes.object.isRequired,
    tactic: PropTypes.object.isRequired,
    onUpdateEntry: PropTypes.func.isRequired,
};

export default TacticEntry;

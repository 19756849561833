const validateStatus = {
    isRejected: status => {
        if (String(status).toLowerCase().trim() === 'rejected') {
            return true;
        }

        return false;
    },
    isApproved: status => {
        if (String(status).toLowerCase().trim() === 'approved') {
            return true;
        }

        return false;
    },
    isSubmitted: status => {
        if (String(status).toLowerCase().trim() === 'submitted') {
            return true;
        }

        return false;
    },
    // TODO: remove conditional for "draft" once statuses have been updated in the backend
    isDraft: status => {
        if (
            String(status).toLowerCase().trim() === 'draft' ||
            String(status).toLowerCase().trim() === 'in progress'
        ) {
            return true;
        }

        return false;
    },
    isUnsubmitted: status => {
        if (
            String(status).toLowerCase().trim() === 'not submitted' ||
            status === undefined
        ) {
            return true;
        }

        return false;
    },
};

export default validateStatus;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Table from 'components/common/table/Table';
import campaignsReportColumns from './config/campaignsReportColumns';
import Loader from 'components/common/loader/Loader';
import Select from 'components/common/select/Select';
import PaginatedItems from "components/common/paginated/PaginatedItems";
import Button from 'components/common/button/Button';

import formatDate from 'utils/formatDate';
import userSession from 'utils/userSession';

import { 
    getCampaignsReport as getCampaignsReportAction, 
    resetCampaignsReport as resetCampaignsReportAction,
    downloadCampaignReport as downloadCampaignReportAction,
    exportCampaignsReport as exportCampaignsReportAction
} from 'actions/reportsActions';

import { 
    getActivePlanCampaignTemplates as getActivePlanCampaignTemplatesAction,
    initializeAwsIoT
} from 'actions/campaignExportsActions';
import store from 'store/store';
import campaignsReportWrapper from 'api/direct-mail/utils/backend-wrappers/campaignsReportWrapper';

export class CampaignsReport extends Component {
    state = {
        campaignId: '',
        campaignDate: '',
        paginationData:  {
            "itemsPerPage": 10,
            "page": 1,
            "totalItems": 0,
            "totalPages": 0
        },
        isExport:false,
        campaignList:[],
        iotEndpoint: null,
        exportingId: null,
        downloadingFileName: null,
        fileName:null
    }

    generateCampaignDateOptions = () => {
        const { campaignsTemplates } = this.props;

        if(!Array.isArray(campaignsTemplates)) return [];

        return campaignsTemplates?.map(({ id, date, templateId }) => ({
            id: `${date}-${templateId}`,
            label: formatDate.dateUTC(date),
            value: id
        }));
    };
    setFileName = (id = null) =>{
        const { campaignsTemplates,exportingId,lastExport } = this.props;

        if(!Array.isArray(campaignsTemplates)) return [];
        let fileName = campaignsTemplates?.find(element => element.id === Number(id));
        this.setState({ fileName : fileName?.summary_export_file_name });
        if(exportingId == Number(id) && lastExport){
            this.setState({ isExport : true });
        }

    }
    componentDidMount = async () => {
        const {
            getActivePlanCampaignTemplates
        } = this.props;
        
        getActivePlanCampaignTemplates();
        const iotEndpoint = await initializeAwsIoT(store.dispatch);
        this.setState({ iotEndpoint });
    }

    componentWillUnmount() {
        const { resetCampaignsReport } = this.props;
        resetCampaignsReport();
    }
    UNSAFE_componentWillReceiveProps = (props) => {
    
        //get campaigns List
        if (Object.keys(props?.campaigns).length > 0 && JSON.stringify(props?.campaigns) !== JSON.stringify(this.prop?.campaigns)) {
            this.setState({campaignList: props?.campaigns?.data, paginationData: props?.campaigns?.metadata});
        }
      }
    
    onExport = (id = null) => {
        const { iotEndpoint } = this.state;

        this.setState({ exportingId: id, isExport: true });
        this.props.exportCampaignsReport(id, iotEndpoint);
    }
    onDownload = (fileName = null) => {
        this.setState({ downloadingFileName: fileName });
        this.props.downloadCampaignReport(fileName);
    }
 
    setActivePage = (val)=>{
        const {
            getCampaignsReport
        } = this.props;
        let {
            paginationData,
            campaignId
        } = this.state
        Object.assign(paginationData, { page:val});
        this.setState({ paginationData });
        getCampaignsReport(campaignId,paginationData.itemsPerPage,paginationData.page);
        return val;
    }
    setItemsPerPage = (op)=>{
        const {
            getCampaignsReport
        } = this.props;
        let {
            paginationData,
            campaignId
        } = this.state
        Object.assign(paginationData, { itemsPerPage:op,page:1});

        this.setState({ paginationData });
        getCampaignsReport(campaignId,paginationData.itemsPerPage,paginationData.page);
        return op;
    }
    render() {
        const { 
            campaignId,
            campaignList,
            paginationData,
            exportingId,
            fileName,
            isExport
        } = this.state;

        const {
            loading,
            getCampaignsReport,
            exporting,
            downloading,
        } = this.props;

        //Code to Show specific columns per Brand
        let newColumns = campaignsReportColumns;
        if(userSession.isMSQUser()){
            newColumns = campaignsReportColumns.filter(object => {
                return object.dataField != "totalAddresses" && object.dataField != "minAddressesRequired"  && object.dataField != "estimatedSpend";
              });
        }
        if(userSession.isMTUser()){
            newColumns = campaignsReportColumns.filter(object => {
                return object.dataField != "minAddressesRequired";
              });
              newColumns.map((obj)=>{
                if(obj.dataField=="marketingUrl"){
                    obj.text = "Location URL";
                }
              })
        }
        if(!userSession.isMSQUser()){
            newColumns = newColumns.filter(object => {
                return object.dataField != "expiration_date_msq" && object.dataField != "desired_in_home_date" && object.dataField != "budget_intention" && object.dataField != "piece_size";
              });
        }

        return (
            <div>
                <div className='d-flex mb-3 pb-3 border-bottom'>
                    <Select
                        label='Campaign Date'
                        value={campaignId}
                        selectStyling={'filter'}
                        placeholder='Campaign Date'
                        options={this.generateCampaignDateOptions()}
                        onChange={(e) => {
                            const value = e.target.value;
                            this.setState({ 
                                campaignId: value,
                                isExport: false 
                            }, () => {
                                this.setFileName(value)
                                Object.assign(paginationData, { page:1});
                                this.setState({ paginationData});
                                getCampaignsReport(value,paginationData.itemsPerPage,paginationData.page);
                            });
                        }}
                    />
                </div>
                {
                    loading ? 
                        <Loader loadingMessage='Generating campaigns report...'/>       
                    :
                    <PaginatedItems
                            itemsPerPage={paginationData.itemsPerPage}
                            setItemsPerPage={this.setItemsPerPage}
                            activePage={paginationData.page}
                            setActivePage={this.setActivePage}
                            totalPages={paginationData.totalPages}
                            totalItems={paginationData.totalItems}
                            disableSelectPerPage={campaignId ? false : true}
                        >
                            <Table
                                keyField={'franchiseId'}
                                columns={newColumns}
                                data={campaignsReportWrapper.sortByFranchiseId(campaignList)}
                                enablePagination={false} />
                        </PaginatedItems>
                        
                }
                {
                        campaignId &&  
                        <Button
                            btnType='primary'
                            label='Export Report'
                            action={() => this.onExport(campaignId)}
                            loading={exporting && exportingId === campaignId}
                            loadingMessage='Exporting...' />
                }
                {
                        campaignId &&  
                            <Button
                                btnType='outline-orange'
                                label='Download'
                                btnStyling = {{'margin-left':'20px'}}
                                action={() => this.onDownload(fileName)}
                                loading={downloading}
                                loadingMessage='Downloading...'
                                disabled={!isExport}
                            />
                    }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    campaigns: state.reports?.campaignsReport?.data || [],
    loading: state.reports?.campaignsReport?.loading,
    campaignsTemplates: state.campaignExports?.campaigns,
    exporting: state.campaignExports?.exportLoading,
    downloading: state.campaignExports?.downloadExportFileUrl,
    lastExport: state.campaignExports?.lastExport,
});

export default connect(mapStateToProps, {
    getCampaignsReport: getCampaignsReportAction,
    getActivePlanCampaignTemplates: getActivePlanCampaignTemplatesAction,
    resetCampaignsReport: resetCampaignsReportAction,
    downloadCampaignReport: downloadCampaignReportAction,
    exportCampaignsReport: exportCampaignsReportAction
})(CampaignsReport);

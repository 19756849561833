import { 
    ADD_COMMENTS, 
    GET_MAILING_PLAN,
    TOGGLE_CAMPAIGN_SELECTION,
    SAVE_CAMPAIGN_CONFIG,
    LOADING_MAILING_PLAN,
    GET_ZIP_CODES,
    TOGGLE_ZIP_CODE,
    SUBMITING_MAILING_PLAN,
    SUBMITTED_MAILING_PLAN,
    SAVING_MAILING_PLAN,
    SAVED_MAILING_PLAN,
    OVERRIDE_MAILING_PLAN_ENABLED,
    RESET_MAILING_PLAN,
    CONFIRM_FRANCHISE_INFO,
    APPROVING_MAILING_PLAN,
    APPROVED_MAILING_PLAN,
    REJECTING_MAILING_PLAN,
    REJECTED_MAILING_PLAN,
    SET_MAILING_PLAN_STATUS,
    OVERRIDE_MAILING_PLAN_LOADING,
    OVERRIDE_MAILING_PLAN_SHOW_CONFIRMATION,
    GET_ZIP_CODES_LOADING,
    TOGGLE_ALL_ZIP_CODES,
    CHANGEFRANCHISELICENSE,
    CHANGEOWNERDETAILS
} from 'actions/types';

const toggleCampaignSelection = (selectedId, state = {}) => {
    return state.campaigns?.map(campaign => {
        if(campaign.campaignId === selectedId) {
            return { ...campaign, selected: !campaign.selected };
        }
        return {...campaign };
    })
};

const updateCampaignConfig = (updatedCampaign, state = {}) => {
    return state.campaigns?.map(campaign => {
        if(campaign.campaignId === updatedCampaign?.campaignId) {
            return { ...updatedCampaign }
        }

        return { ...campaign };
    });
}

const toggleZipCode = (zipCode, state = {}) => {
    let excludedZipCodes = state.excludedZipCodes || [];
    let wasExcluded = false;

    excludedZipCodes = excludedZipCodes?.filter(code => {
        if(Number(code) === Number(zipCode)) {
            wasExcluded = true;
            return false;
        }
        return true;
    });

    if(!wasExcluded) excludedZipCodes?.push(zipCode);

    return excludedZipCodes;
}

const mailingPlanReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_MAILING_PLAN:
            return { ...state, ...action.payload };

        case SUBMITING_MAILING_PLAN:
            return { ...state, submitting: action.payload };
        
        case SUBMITTED_MAILING_PLAN:
            return { ...state, submitted: action.payload };
        
        case LOADING_MAILING_PLAN:
            return { ...state, loading: action.payload };

        case ADD_COMMENTS:
            return { ...state, comments: action.payload };
        
        case TOGGLE_CAMPAIGN_SELECTION:
            return { ...state, campaigns: toggleCampaignSelection(action.payload, state) };

        case SAVE_CAMPAIGN_CONFIG:
            return { ...state, campaigns: updateCampaignConfig(action.payload, state) };

        case GET_ZIP_CODES:
            return { ...state, zipCodes: action.payload };

        case GET_ZIP_CODES_LOADING:
            return { ...state, loadingZipCodes: action.payload };

        case TOGGLE_ZIP_CODE:
            const excludedZipCodes = toggleZipCode(action.payload, state);

            return { ...state, 
                allZipCodesSelected: excludedZipCodes?.length ? false : true,
                excludedZipCodes: [...excludedZipCodes]
            };

        case TOGGLE_ALL_ZIP_CODES:
            let selectAll = action.payload;

            return {
                ...state,
                allZipCodesSelected: selectAll,
                excludedZipCodes: selectAll ? [] : [...state.zipCodes.map(zip => zip.zipCode)]
            };

        case CONFIRM_FRANCHISE_INFO:
            return {
                ...state,
                isFranchiseInfoConfirmed: action.payload.confirmed,
                isCorrectFranchiseInfo: action.payload.correct
            };

        case OVERRIDE_MAILING_PLAN_ENABLED:
            return { ...state, overrideEnabled: action.payload };

        case OVERRIDE_MAILING_PLAN_LOADING:
            return { ...state, overrideLoading: action.payload };

        case OVERRIDE_MAILING_PLAN_SHOW_CONFIRMATION:
            return { ...state, showOverrideConfirmation: action.payload };

        case RESET_MAILING_PLAN:
            if(action.payload) {
                return { ...state, ...action.payload };
            } 
            return {};
        
        case APPROVING_MAILING_PLAN:
            return { ...state, approving: action.payload };
        
        case APPROVED_MAILING_PLAN:
            return { ...state, approved: action.payload };

        case REJECTING_MAILING_PLAN:
            return { ...state, rejecting: action.payload };
        
        case REJECTED_MAILING_PLAN:
            return { ...state, rejected: action.payload };

        case SET_MAILING_PLAN_STATUS:
            return { ...state, status: action.payload };
        
        case SAVING_MAILING_PLAN:
            return { ...state, saving: action.payload };
        
        case SAVED_MAILING_PLAN:
            return { ...state, saved: action.payload };

        case CHANGEFRANCHISELICENSE:
            if( action.payload.check == "license"){
                return {
                    ...state,
                    licenseNumber: action.payload.licenseNumber,
                    
                };
            }else{
                return {
                    ...state,
                    overrideLicense: action.payload.overrideLicense
                }; 
            }
        case CHANGEOWNERDETAILS:
            if( action.payload.check == "email"){
                return {
                    ...state,
                    ownerContactEmail: action.payload.ownerContactEmail,
                    
                };
            }else if(action.payload.check == "name"){
                return {
                    ...state,
                    ownerName: action.payload.ownerName
                }; 
            } else{
                return {
                ...state,
                ownerPhoneNumber: action.payload.ownerPhoneNumber
            }; 
        } 

        default:
            return state;
    }
}

export default mailingPlanReducer;
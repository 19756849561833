const wrapper = data => ({
    franchiseId: data?.id,
    franchiseName: data?.name,
    franchiseCity: data?.city,
    franchiseExternalId: data?.externalId,
    isDmExempt: data?.isDmExempt,
    isQrCode: data?.isQrCode,
    overrideLicense: data?.override_license,
    licenseNumber: data?.license_number,
});

const franchiseEntryWrapper = {
    sortByName: franchises => {
        if(!Array.isArray(franchises)) return [];

        return franchises.map(franchise => wrapper(franchise))
            .sort((a, b) => {
                if(!a.franchiseName && !b.franchiseName) {
                    return a.franchiseExternalId > b.franchiseExternalId ? 1 : -1;
                }
                
                if(!b.franchiseName) return -1;
                if(!a.franchiseName) return 1;
                
                return a.franchiseName > b.franchiseName ? 1 : -1;
            });
    },
    wrap: franchises => franchises.map(f => wrapper(f)),
}

export default franchiseEntryWrapper;

const zipCodeWrapper = zipCode => {
    if(
        !zipCode
        || Array.isArray(zipCode)
        || typeof zipCode !== 'object'
    ) return {};

    return {
        zipCode: zipCode?.zip_code,
        addresses: zipCode?.addresses
    }
}

export default zipCodeWrapper;
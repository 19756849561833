import { 
    GET_COUPONS, 
    GET_COUPONS_LOADING 
} from 'actions/types';

const couponsReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_COUPONS:
            return { ...state, coupons:  [...action.payload] };
        
        case GET_COUPONS_LOADING:
            return { ...state, loading: action.payload };

        default:
            return state;
    }
}

export default couponsReducer;
import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getServices = ({
    templateId: mailingPlanTemplateID
} = {}) => {
    return handleResponse(
        api().post('/corporate/services/get', {
            mailingPlanTemplateID
        })
    );
}

export const createService = ({
    templateId: mailingPlanTemplateID = null,
    serviceName: name,
    outputText = null,
    active = false,
    licenseRequired = false
} = {}) => {
    return handleResponse(
        api().post('/corporate/services/add', {
            mailingPlanTemplateID,
            name,
            outputText: outputText || name,
            active,
            licenseRequired
        })
    );
}

export const updateService = ({
    serviceId: id = null,
    serviceName: name,
    outputText = null,
    active = false,
    licenseRequired = false
} = {}) => {
    return handleResponse(
        api().post('/corporate/services/update', {
            id,
            name,
            outputText: outputText || name,
            active,
            licenseRequired,
            remove: false
        })
    );
}

export const deleteService = ({
    serviceId: id = null,
} = {}) => {
    return handleResponse(
        api().post('/corporate/services/update', {
            id,
            remove: true
        })
    );
}
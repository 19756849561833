import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getBadge = ({
    brandId = null,
    templateId: mailingPlanTemplateID = null,
    badges = [],
    templateYear: mailingPlanTemplateYear = null
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/badge`, {
            mailingPlanTemplateID,
            badges,
            mailingPlanTemplateYear
        })
    );
}

export const postBadge = ({
    templateId: mailingPlanTemplateID,
    name,
    active = false,
    code,
    badgeText
} = {}) => {
    return handleResponse(
        api().post('/corporate/badge/create', {
            mailingPlanTemplateID,
            name,
            active,
            code,
            badgeText
        })
    );
}

export const deleteBadge = (badges = []) => {
    return handleResponse(
        api().post('/corporate/badge/delete', {
            badges
        })
    );
}

export const updateBadge = ({
    badgeId: id,
    active = false,
    name,
    code,
    badgeText
} = {}) => {
    return handleResponse(
        api().post('/corporate/badge/update', {
            id,
            name,
            code,
            badgeText,
            active
        })
    );
}
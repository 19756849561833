import moment from 'moment';

const campaignsWithExcessAddresses = (campaigns, zipCodes, excludedZipCodes) => {
    if(!Array.isArray(campaigns)) return null;
    if(!Array.isArray(zipCodes)) return null;
    if(!Array.isArray(excludedZipCodes)) return null;

    let availableAddresses = 0;
    zipCodes.forEach(({ zipCode, addresses }) => {
        if(excludedZipCodes.indexOf(zipCode) === -1) {
            availableAddresses += addresses;
        }
    });

    const excessAddressesCampaigns = campaigns.filter(({ selected, addresses ,lockDate}) => {
        return selected && ( addresses > availableAddresses ) && !isCampaignLocked(lockDate)
    });

    return excessAddressesCampaigns.length ? excessAddressesCampaigns : null;
}
const  isCampaignLocked = (lockDate) => {
    if(lockDate) {
        return moment().utc().startOf('day').valueOf() > moment(lockDate).utc().valueOf();
    }
    return false;
}
export default campaignsWithExcessAddresses;
import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const saveCampaignTemplates = ({
    planTemplateId: mailingPlanTemplateID,
    campaigns
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/campaigns/templates/create', {
            mailingPlanTemplateID,
            campaigns
        })
    );
}

export const updateCampaignTemplates = ({
    campaigns
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/campaigns/templates/update', {
            campaigns
        })
    );
}

export const deleteCampaignTemplates = ({
    campaigns
} = {}) => {
    return handleResponse(
        api().post('corporate/mailing-plans/campaigns/templates/delete', {
            campaigns
        })
    );
}
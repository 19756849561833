import formatCurrency from 'utils/formatCurrency';
import formatDate from 'utils/formatDate';
import formatNumber from 'utils/formatNumber';
import userSession from 'utils/userSession';

function moneyFormatter(cell) {
    if(isNaN(cell)) return cell;

    return `$${formatCurrency(Number(cell))}`;
}

function cellFormatter(cell) {
    return <span 
        style={{ overflowWrap: 'break-word' }}
    >
        {cell || '-'}
    </span>;
}

let campaignsReportColumns = [
    {
        dataField: 'franchiseId',
        text: 'Franchise ID',
        formatter: cellFormatter
    },
    {
        dataField: 'franchiseName',
        text: 'Franchise Name',
        formatter: cellFormatter
    },
    {
        dataField: 'returnAddress',
        text: 'Return Address',
        formatter: cellFormatter,
    },
    {
        dataField: 'netSuiteId',
        text: 'NetSuite ID',
        formatter: cellFormatter
    },
    {
        dataField: 'marketingPhone',
        text: 'Marketing Phone',
        formatter: cellFormatter
    },
    {
        dataField: 'marketingUrl',
        text: userSession.isMTUser()?'Location URL' :'Marketing URL',
        formatter: cellFormatter
    },
    {
        dataField: 'brochureCode',
        text: 'Brochure Code',
        formatter: cellFormatter
    },
    {
        dataField: 'coupons',
        text: 'Coupons',
        formatter: cellFormatter
    },
    {
        dataField: 'couponsExpirationDate',
        text: 'Coupons Expiration Date',
        formatter: (cell) => formatDate.date(cell) || '-'
    },
    {
        dataField: 'services',
        text: 'Services',
        formatter: cellFormatter
    },
    {
        dataField: 'badges',
        text: 'Badges',
        formatter: cellFormatter
    },
    {
        dataField: 'minAddressesRequired',
        text: 'Min. Addresses Required',
        formatter: (cell) => cellFormatter(formatNumber(cell)),
    },
    {
        dataField: 'minSpendAmount',
        text: 'Min. Dollar Spend',
        formatter: (cell) => moneyFormatter(cell) || '-'
    },
    {
        dataField: 'expiration_date_msq',
        text: 'Expiration Date',
        formatter: (cell) => formatDate.date(cell) || '-'
    },
    {
        dataField: 'desired_in_home_date',
        text: 'Desired In Home Date',
        formatter: (cell) => formatDate.date(cell) || '-'
    },
    {
        dataField: 'budget_intention',
        text: 'Intended Spend',
        formatter: (cell) => cell || '-'
    },
    {
        dataField: 'piece_size',
        text: 'Piece Size',
        formatter: (cell) => cell || '-'
    }
];

export default campaignsReportColumns;
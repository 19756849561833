import Tooltip from 'components/common/tooltip/Tooltip';
import { Link } from 'react-router-dom';
import formatCurrency from 'utils/formatCurrency';
import formatNumber from 'utils/formatNumber';
import validateStatus from 'utils/validateStatus';
import Switch from 'components/common/switch/Switch';

function mailingPlanIdFormatter(cell, row) {
    const franchiseId = row.franchiseId;
    const mailingPlanId = row.id;
    const year = row?.year;
    const mailingStatus = row?.status

    if(String(cell).includes('customId')) {
        return (
            <span className='mailing-plan-link'></span>
        );
    }

    return (
        <Link 
            id='dashboard-table-row-mailing-plan-id'
            to={ mailingStatus == undefined || validateStatus.isDraft(mailingStatus)?`/mailing-plan/edit/${franchiseId}/${mailingPlanId}/${year}` : `/mailing-plan/${franchiseId}/${mailingPlanId}/${year}` }
            className='mailing-plan-link'
        >
            {cell || '-'}
        </Link>
    );
}

function investmentFormatter(cell, row) {
    if(isNaN(cell)) return cell;

    return `$${formatCurrency(Number(cell))}`;
}

function actionsFormatter(cell, row) {
    if(!row) return null;

    const franchiseId = row?.franchiseId;
    const year = row?.year || row?.yearFilter;

    return (
        <div id='dashboard-table-row-actions'>
            {
                validateStatus.isUnsubmitted(row?.status) &&
                    <Link
                        id='dashboard-table-row-action-create'
                        to={`/mailing-plan/create/${franchiseId}/${year}`}
                        className='create-plan-btn'
                    >
                        Create Plan
                    </Link>
            }
            {
                row.viewDetailsCallback && !validateStatus.isUnsubmitted(row?.status) &&
                    <Tooltip 
                        id='dashboard-table-row-action-summary'
                        icon='far fa-eye'
                        iconClassName='cursor-pointer'
                        message='View summary'
                        action={() => row.viewDetailsCallback(row)}
                    />
            }
        </div>
    );
}
function dmExemptFormatter(cell, row) {
    if(!row) return null;
    const mailingPlanId = row?.id;
    let isDmExempt = row?.isDmExempt;
    let label = isDmExempt ? "Yes" :"No";
        return (
            label
            );
}
function addressesFormatter(cell, row) {
    if(!cell) return '-';

    return formatNumber(cell);
}


const  mailingPlanColumns = [
    {
        dataField: 'id',
        text: 'Request ID',
        sort: true,
        formatter: mailingPlanIdFormatter
    },
    {
        dataField: 'status',
        text: 'Request Status',
        sort: true
    },
    {
        dataField: 'franchise',
        text: 'Franchise Name',
        sort: true
    },
    {
        dataField: 'submittedDate',
        text: 'Submitted Date',
        sort: true
    },
    {
        dataField: 'modifiedDate',
        text: 'Modified Date',
        sort: true
    },
    {
        dataField: 'investment',
        text: 'Investment',
        sort: true,
        formatter: investmentFormatter
    },
    {
        dataField: 'totalAddresses',
        text: 'Total Addresses',
        sort: true,
        formatter: addressesFormatter
    },
    {
        dataField: 'actions',
        text: 'Actions',
        isDummyField: true,
        csvExport: false,
        formatter: actionsFormatter
    },
    {
        dataField: 'dmExempt',
        text: 'DM Exempt',
        isDummyField: true,
        csvExport: false,
        formatter: dmExemptFormatter
    }
];

export default mailingPlanColumns;
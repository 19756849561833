import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/common/input/Input';
import IconButton from 'components/common/icon-button/IconButton';

export class MinimumCampaignsInput extends Component {
    state = {
        yearFieldValue: '',
        campaignsFieldValue: '',
        isInvalidYear: false
    }

    static propTypes = {
        ruleKey: PropTypes.string.isRequired,
        rule: PropTypes.object.isRequired,
        onChangeBusinessRule: PropTypes.func.isRequired
    }

    onAdd = () => {
        const {
            yearFieldValue,
            campaignsFieldValue
        } = this.state;

        const { 
            ruleKey,
            rule,
            onChangeBusinessRule 
        } = this.props;

        const updatedRule = { 
            ...rule, 
            value: [ 
                ...rule.value, 
                { years: yearFieldValue, minimumCampaigns: campaignsFieldValue },
            ]
        };

        this.setState({ 
            yearFieldValue: '', 
            campaignsFieldValue: ''
        }, () => {
            onChangeBusinessRule(ruleKey, updatedRule);
        });
    }

    onDelete = valueIndex => {
        const { 
            ruleKey, 
            rule,
            onChangeBusinessRule
        } = this.props;

        const updatedValues = rule?.value?.filter((val, index) => {
            if(index === valueIndex) {
                return false;
            }

            return val;
        });

        const updatedRule = { ...rule, value: [...updatedValues] };

        onChangeBusinessRule(ruleKey, updatedRule);
    }

    onEditCampaignsNumber = (valueIndex, newValue = 0) => {
        const { 
            ruleKey,
            rule,
            onChangeBusinessRule
        } = this.props;

        const updatedValues = rule?.value?.map((val, index) => {
            if(index === valueIndex) {
                return { ...val, minimumCampaigns: newValue };
            }

            return { ...val };
        });

        const updatedRule = { ...rule, value: [...updatedValues] };

        onChangeBusinessRule(ruleKey, updatedRule);
    }

    isValidYear = year => {
        const { 
            rule: { value }
        } = this.props;

        return value?.filter(({ years }) => 
            Number(years) === Number(year)
        ).length ? false : true;
    }

    sortConfiguredYears = years => {
        return years?.sort((a, b) => a.years - b.years);
    }

    renderFields = () => {
        const { 
            rule: { value, active, editable }
        } = this.props;

        if(!value) return null;

        return this.sortConfiguredYears(value).map((val, index) => {
            let displayError = false;
            displayError = !val?.years || !val?.minimumCampaigns ? true : false;

            return (
                <div key={`minimumCampaignsPerYearRuleEntry-${index}`} className='d-flex flex-column'>
                    <div className={`d-flex column-gap-1 row-gap-1 flex-wrap ${displayError ? 'align-items-center' : 'align-items-end'}`}>
                        <div className='d-flex column-gap-1 row-gap-1 flex-wrap align-items-start'>
                            <div className='d-flex flex-column column-gap-1'>
                                <span className='font-size-12'>Franchise age within:</span>
                                <span className='font-family-bold mt-2'>{val?.years ? `${val.years} years` : 'Undefined'}</span>
                            </div>
                            <Input 
                                label='Number of campaigns'
                                placeholder='Campaigns'
                                type='number'
                                disabled={!active || !editable}
                                value={val?.minimumCampaigns}
                                onChange={({ target: { value } }) => {
                                    value = !value || value > 1 ? value : 1;
                                    this.onEditCampaignsNumber(index, value)
                                }}
                                min={1}
                                error={!val?.minimumCampaigns}
                                errorMsg='Please enter a minimum number of campaigns'
                            />
                        </div>
                        {
                            active && editable ? 
                                <IconButton 
                                    btnType='danger'
                                    icon='fas fa-times'
                                    shape='circle'
                                    action={() => this.onDelete(index)}
                                />
                            :
                                null
                        }
                    </div>
                </div>
            );
        })
    }

    renderNewEntryFields = () => {
        const { 
            yearFieldValue, 
            campaignsFieldValue,
            isInvalidYear
        } = this.state;

        const { rule: { active, editable } } = this.props;

        if(active && editable) {
            return (
                <div className='d-flex flex-column column-gap-1'>
                    <div className='d-flex column-gap-1 row-gap-1 flex-wrap align-items-end pt-2 mt-2 border-top'>
                        <Input 
                            label='Franchise age within'
                            placeholder='Years'
                            type='number'
                            value={yearFieldValue}
                            onChange={({ target: { value } }) => {
                                this.setState({ 
                                    yearFieldValue: !value || value > 1 ? value : 1,
                                    isInvalidYear: !this.isValidYear(value)
                                });
                            }}
                            min={0}
                        /> 
                        <Input 
                            label='Number of campaigns'
                            placeholder='Campaigns'
                            type='number'
                            value={campaignsFieldValue}
                            onChange={({ target: { value }}) => {
                                this.setState({ 
                                    campaignsFieldValue: !value || value > 1 ? value : 1
                                });
                            }}
                            min={0}
                        />
                        <IconButton 
                            btnType='primary'
                            icon='fas fa-plus'
                            disabled={!yearFieldValue || !campaignsFieldValue || isInvalidYear}
                            action={() => this.onAdd()}
                        />  
                    </div>
                    {
                        isInvalidYear &&
                            <small className='error-msg'>This year already has a minimum of campaigns</small>
                    }                      
                </div>
            );
        }
    }

    render() {
        const { rule } = this.props;

        return (
            <div className='d-flex flex-column row-gap-1'>
                <div>{rule?.description}</div>
                {this.renderFields()}
                {this.renderNewEntryFields()}
            </div>
        );
    }
}

export default MinimumCampaignsInput;

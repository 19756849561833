import businessRulesWrapper from 'api/direct-mail/utils/backend-wrappers/businessRulesWrapper';
import mailingPlanTemplateWrapper from 'api/direct-mail/utils/backend-wrappers/mailingPlanTemplateWrapper';
import sendOrderWrapper from 'api/direct-mail/utils/backend-wrappers/sendOrderWrapper';

import { 
    notifyActivateMailingPlanError,
    notifyActivateMailingPlanSuccess,
    notifyGetBusinessRulesError,
    notifyGetMailingPlanTemplateError, 
    notifySaveNewCampaignTemplatesError,
    notifyUpdateCampaignTemplatesError,
    notifyUpdateMailingPlanSuccess,
    notifyUpdateMailingPlanTemplateError,
    notifyDeleteCampaignsFromTemplateError,
    notifyGetSendOrderOptionsError,
    notifyDeleteMailingPlanTemplateError,
    notifyDeleteMailingPlanTemplateSuccess
} from './notificationActions';

import {
    ACTIVATE_MAILING_PLAN_TEMPLATE,
    ACTIVATE_MAILING_PLAN_TEMPLATE_LOADING,
    GET_BUSINESS_RULES,
    GET_BUSINESS_RULES_LOADING,
    GET_MAILING_PLAN_TEMPLATE,
    GET_MAILING_PLAN_TEMPLATE_LOADING,
    POST_NEW_CAMPAIGN_TEMPLATES,
    POST_NEW_CAMPAIGN_TEMPLATES_LOADING,
    UPDATE_CAMPAIGN_TEMPLATES,
    UPDATE_CAMPAIGN_TEMPLATES_LOADING,
    UPDATE_MAILING_PLAN_TEMPLATE,
    UPDATE_MAILING_PLAN_TEMPLATE_LOADING,
    RESET_PLAN_CONFIG_STATE,
    DELETE_CAMPAIGNS_FROM_TEMPLATE_LOADING,
    GET_SEND_ORDER_OPTIONS,
    DELETE_MAILING_PLAN_TEMPLATE_LOADING,
    ON_UPDATE_PLAN_CONFIG
} from './types';

import { 
    activatePlanTemplate,
    deletePlanTemplate,
    getMailingPlanTemplates, 
    updatePlanTemplate
} from 'api/direct-mail/planTemplates';

import {
    getBusinessRules as getBusinessRulesApi
} from 'api/direct-mail/businessRules';

import {
    getSendOrderOptions as getSendOrderOptionsApi
} from 'api/direct-mail/sendOrder';

import { 
    deleteCampaignTemplates,
    saveCampaignTemplates,
    updateCampaignTemplates as updateCampaignTemplatesApi 
} from 'api/direct-mail/campaignTemplates';

const generatePlanTemplateObj = mailingPlanTemplate => {
    const configuredBusinessRules = [];
    
    mailingPlanTemplate?.businessRules?.forEach(rule => {
        if(rule.active) {
            configuredBusinessRules.push({
                key: rule.key,
                active: rule.active,
                strict: rule.strict,
                value: rule.value
            });
        }
    });

    return {
        year: mailingPlanTemplate?.planYear,
        businessRules: configuredBusinessRules,
        sendOrder: mailingPlanTemplate?.sendOrder,
        costPerAddress: mailingPlanTemplate?.costPerPiece,
        active: mailingPlanTemplate?.active,
        brochure_sizes: mailingPlanTemplate?.pieceSizes,
    }
}

export const onUpdatePlanConfig = (planConfig = {}) => ({
    type: ON_UPDATE_PLAN_CONFIG,
    payload: planConfig    
});

export const resetPlanConfigState = (planConfig = null) => {
    if(!planConfig) {
        return {
            type: RESET_PLAN_CONFIG_STATE,
            payload: null
        };
    }

    return {
        type: RESET_PLAN_CONFIG_STATE,
        payload: { ...planConfig }
    };
}

export const getMailingPlanTemplate = templateId => async dispatch => {
    dispatch(notifyGetMailingPlanTemplateError({ display: false }));

    dispatch({
        type: GET_MAILING_PLAN_TEMPLATE_LOADING,
        payload: true
    });

    let [planErr, planRes] = await getMailingPlanTemplates({
        templateId,
        withCampaigns: true
    });

    if(planErr) {
        dispatch(notifyGetMailingPlanTemplateError({ serverError: planErr }));
    }

    dispatch({
        type: GET_MAILING_PLAN_TEMPLATE_LOADING,
        payload: false
    });
    
    return dispatch({
        type: GET_MAILING_PLAN_TEMPLATE,
        payload: mailingPlanTemplateWrapper(planRes)
    });
}

export const getBusinessRules = () => async dispatch => {
    dispatch(notifyGetBusinessRulesError({ display: false }));

    dispatch({
        type: GET_BUSINESS_RULES_LOADING,
        payload: true
    });

    const [businessRulesErr, businessRules] = await getBusinessRulesApi();

    if(businessRulesErr) {
        dispatch(notifyGetBusinessRulesError({ serverError: businessRulesErr }));
    }

    dispatch({
        type: GET_BUSINESS_RULES_LOADING,
        payload: false
    });

    return dispatch({
        type: GET_BUSINESS_RULES,
        payload: businessRulesWrapper.sortByName(businessRules) || null
    });
}

export const getSendOrderOptions = () => async dispatch => {
    dispatch(notifyGetSendOrderOptionsError({ display: false }));

    const [orderOptsErr, orderOpts] = await getSendOrderOptionsApi();

    if(orderOptsErr) {
        dispatch(notifyGetSendOrderOptionsError({ serverError: orderOptsErr }));
    }

    return dispatch({
            type: GET_SEND_ORDER_OPTIONS,
            payload: sendOrderWrapper(orderOpts) || null
        });
}

export const saveNewCampaignTemplates = (templateId = null, campaigns = []) => async dispatch => {
    dispatch(notifySaveNewCampaignTemplatesError({ display: false }));

    let newCampaigns = campaigns.filter(
        campaign => campaign.isNew
    )
    .map(campaign => ({
        date: campaign.date,
        name: campaign.campaignName,
        lockDate: campaign.lockDate
    }));

    if(!newCampaigns.length) return;

    dispatch({
        type: POST_NEW_CAMPAIGN_TEMPLATES_LOADING,
        payload: true
    });

    const [saveErr, saveRes] = await saveCampaignTemplates({
        planTemplateId: templateId,
        campaigns: newCampaigns
    });

    if(saveErr) {
        dispatch(notifySaveNewCampaignTemplatesError({ serverError: saveErr }));
    }

    dispatch({
        type: POST_NEW_CAMPAIGN_TEMPLATES_LOADING,
        payload: false
    });

    return dispatch({
        type: POST_NEW_CAMPAIGN_TEMPLATES,
        payload: saveRes || null
    });
}

export const updateCampaignTemplates = (campaigns = []) => async dispatch => {
    dispatch(notifyUpdateCampaignTemplatesError({ display: false }));

    let editedCampaigns = campaigns?.filter(campaign =>
        campaign.isEdited && !campaign.markedForRemoval
    ).map(campaign => ({
        id: campaign.id,
        date: campaign.date,
        name: campaign.campaignName,
        lockDate: campaign.lockDate
    }));

    if(!editedCampaigns.length) return;

    dispatch({
        type: UPDATE_CAMPAIGN_TEMPLATES_LOADING,
        payload: true
    });

    const [updateErr, updateRes] = await updateCampaignTemplatesApi({ 
        campaigns: editedCampaigns 
    });

    if(updateErr) {
        dispatch(notifyUpdateCampaignTemplatesError({ serverError: updateErr }));
    }

    dispatch({
        type: UPDATE_CAMPAIGN_TEMPLATES_LOADING,
        payload: false
    });

    return dispatch({
        type: UPDATE_CAMPAIGN_TEMPLATES,
        payload: updateRes
    });
}

export const deleteCampaignsFromTemplate = (campaigns = []) => async dispatch => {
    dispatch(notifyDeleteCampaignsFromTemplateError({ display: false }));

    let campaignIds = campaigns?.filter(
        ({ markedForRemoval }) => markedForRemoval
    ).map(campaign => campaign.id);

    if(!campaignIds.length) return;

    dispatch({
        type: DELETE_CAMPAIGNS_FROM_TEMPLATE_LOADING,
        payload: true
    });

    const [deleteErr] = await deleteCampaignTemplates({ 
        campaigns: campaignIds 
    });

    if(deleteErr) {
        dispatch(notifyDeleteCampaignsFromTemplateError({ serverError: deleteErr }));
    }

    return dispatch({
        type: DELETE_CAMPAIGNS_FROM_TEMPLATE_LOADING,
        payload: false
    });
}

export const deleteMailingPlanTemplate = (templateId = null, templateYear = null) => async dispatch => {
    dispatch(notifyDeleteMailingPlanTemplateError({ display: false }));

    dispatch({
        type: DELETE_MAILING_PLAN_TEMPLATE_LOADING,
        payload: true
    });

    const [deleteErr] = await deletePlanTemplate({ templateId });

    if(deleteErr) {
        dispatch(notifyDeleteMailingPlanTemplateError({ serverError: deleteErr }));
    } else {
        dispatch(notifyDeleteMailingPlanTemplateSuccess({
            title: 'Plan has been deleted',
            message: `Plan for ${templateYear} has been successfully deleted`,
            type: 'success'
        }));
    }

    return dispatch({
        type: DELETE_MAILING_PLAN_TEMPLATE_LOADING,
        payload: false
    });
}

export const activateMailingPlan = (templateId, planYear) => async dispatch => {
    dispatch(notifyActivateMailingPlanError({ display: false }));
    dispatch(notifyActivateMailingPlanSuccess({ display: false }));

    dispatch({
        type: ACTIVATE_MAILING_PLAN_TEMPLATE_LOADING,
        payload: true
    });

    const [activateErr, activateRes] = await activatePlanTemplate({ templateId });

    if(activateErr) {
        dispatch(notifyActivateMailingPlanError({ serverError: activateErr }));
    } else {
        dispatch(notifyActivateMailingPlanSuccess({
            title: 'Plan is now live!',
            message: `Plan for ${planYear} has been activated and is now available for Franchise Owners`,
            type: 'success'
        }));
    }

    dispatch({
        type: ACTIVATE_MAILING_PLAN_TEMPLATE_LOADING,
        payload: false
    });

    return dispatch({
        type: ACTIVATE_MAILING_PLAN_TEMPLATE,
        payload: activateRes
    });
}

export const saveMailingPlanConfig = (planTemplate) => async dispatch => {
    dispatch(notifyUpdateMailingPlanTemplateError({ display: false }));
    dispatch(notifyUpdateMailingPlanSuccess({ display: false }));

    dispatch({
        type: UPDATE_MAILING_PLAN_TEMPLATE_LOADING,
        payload: true 
    });

    const [updateErr, updateRes] = await updatePlanTemplate({
        templateId: planTemplate?.id,
        planTemplate: generatePlanTemplateObj(planTemplate)
    });

    if(updateErr) {
        dispatch(notifyUpdateMailingPlanTemplateError({ serverError: updateErr }));

    } else {
        if(planTemplate?.active && !planTemplate?.currentlyActive) {
            await dispatch(activateMailingPlan(planTemplate?.id, planTemplate?.planYear));
        }
        await dispatch(saveNewCampaignTemplates(planTemplate?.id, planTemplate?.campaignTemplates));
        await dispatch(updateCampaignTemplates(planTemplate?.campaignTemplates));
        await dispatch(deleteCampaignsFromTemplate(planTemplate?.campaignTemplates));
        await dispatch(getMailingPlanTemplate(planTemplate?.id));
        dispatch(notifyUpdateMailingPlanSuccess());
    }

    dispatch({
       type: UPDATE_MAILING_PLAN_TEMPLATE_LOADING,
       payload: false 
    });

    return dispatch({
        type: UPDATE_MAILING_PLAN_TEMPLATE,
        payload: updateRes
    });
}

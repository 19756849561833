import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'components/common/switch/Switch';
import Panel from 'components/common/panel/Panel';
import { updateLockDateReminderDm as updateLockDateReminderDmAction, getBrandInfo as getBrandInfoAction  } from 'actions/brandActions';
import userSession from 'utils/userSession';

export class ToggleReminder extends Component {
    
    state = {
        toggle_lock_date_reminder: 0
    };
    constructor(props) {
        super(props);
        this.state = {
            toggle_lock_date_reminder: 0,
        }
    
      }
    async componentDidMount() {
        const { getBrandInfo } = this.props;
       await getBrandInfo(userSession.getBrandId());
        const { 
            toggle_lock_date_reminder_dm
        } = this.props;
        this.setState({ toggle_lock_date_reminder: toggle_lock_date_reminder_dm});
    }
    onToggleLockDateReminder= (toggle_lock_date_reminder) => {
        const {
            updateLockDateReminderDm,
        } = this.props;
        this.setState({ toggle_lock_date_reminder: toggle_lock_date_reminder });
        toggle_lock_date_reminder = toggle_lock_date_reminder ? 1 : 0;
        this.setState({ toggle_lock_date_reminder: toggle_lock_date_reminder });
        updateLockDateReminderDm( toggle_lock_date_reminder );
    };
    render() {
        const { 
            loading,
        } = this.props;
        let { toggle_lock_date_reminder } = this.state;

        return (
            <div className='overview'>
            <Panel containerStyling={{ "border-top" : "none", "border-radius" : "5px", "height" : "109px" , "padding-top" : "20px" }}>
                
                <div className='toggle-header'>Toggle Budget lock Date Reminder</div>
                <div className='plan-progress'>
                {
                     loading &&
                        <i className='d-flex align-items-center justify-content-center'>
                            <div className='spinner-border spinner-border-sm' role='status'></div>
                        </i>
                    }
                    <div className='plan-counters'>
                            <Switch
                            id={`toggle-budget-lock-date-reminder`}
                            label= {`${toggle_lock_date_reminder?'On':'Off'}`}
                            labelTop={false}
                             checked={toggle_lock_date_reminder}
                            onToggle={(checked) => this.onToggleLockDateReminder(checked) } 
                            />
                    </div>
                </div>
            </Panel>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.lockDateReminderReducer?.loading,
    toggle_lock_date_reminder_dm: state?.brand?.toggle_lock_date_reminder
});

export default connect(mapStateToProps, {
    updateLockDateReminderDm: updateLockDateReminderDmAction,
    getBrandInfo: getBrandInfoAction,
})(ToggleReminder);


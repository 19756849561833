import moment from 'moment';

const wrapper = (data) => ({
    mailingPlanId: data?.id,
    mailingPlanTemplateId: data?.mailing_plan_template_id,
    createdDate: data?.created_date,
    mailingPlanYear: data?.year,
    status: data?.request_status_name,
    updatedDate: data?.updated_date,  
});

const planSubmissionWrapper = {
    single: submission => {
        if(
            !submission
            || Array.isArray(submission)
            || typeof submission !== 'object'
        ) return {};

        return wrapper(submission);
    },

    sortByDate: (submissions, ascending = true) => {
        if(!Array.isArray(submissions)) return [];

        return submissions.map(plan => wrapper(plan))
            .sort((a, b) => {
                if(ascending) {
                    return new moment(a.updatedDate).valueOf() - new moment(b.updatedDate).valueOf();
                }

                return new moment(b.updatedDate).valueOf() - new moment(a.updatedDate).valueOf();
            });
    }
}

export default planSubmissionWrapper;
import React from 'react';
import PropTypes from 'prop-types';

const LmpbpRule = ({ children }) => {
    return (
        <div className='d-flex'>
            <i className='fas fa-play mt-1 mr-2 brand-primary-color'></i>
            <p>
                {children}
            </p>
        </div>
    );
}

LmpbpRule.propTypes = {
    children: PropTypes.node
}

export default LmpbpRule;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-circular-progressbar/dist/styles.css';
import './index.scss';

import App from 'components/App';
import store from 'store/store';
import * as FullStory from '@fullstory/browser';
import userSession from 'utils/userSession';

const enableFullstory = process.env.REACT_APP_ENABLE_FULLSTORY === 'true' ? true : false;
if(userSession.arrivedAtLmpbp()){
    FullStory.init({
        orgId: '16ES6H',
        debug: false,
        namespace: 'FS',
        devMode: !enableFullstory
    });
}else{
    FullStory.init({
        orgId: '11ASKG',
        debug: false,
        namespace: 'FS',
        devMode: !enableFullstory
    });
}

FullStory.identify(userSession.getUserId());
//add firstname and last name
if(userSession.isDivisionalUser()) {
    FullStory.setUserVars({
        displayName: userSession.getUserFirstName()+" "+userSession.getUserLastName(),
        email: userSession.getUserEmail(),
        Username_str: userSession.getUserId(),
        User_Role_str: userSession.getUserRole(),
        Brand_Symbol_str: userSession.getBrandSymbol()
    });
}

if(userSession.isFranchiseOwer()) {
    FullStory.setUserVars({
        displayName: userSession.getUserFirstName()+"-"+userSession.getUserLastName(),
        email: userSession.getUserEmail(),
        Username_str: userSession.getUserId(),
        Franchise_ID_str: userSession.getFranchiseExternalId(),
        Franchise_Name_str: userSession.getFranchiseName(),
        User_Role_str: userSession.getUserRole(),
        Brand_Symbol_str: userSession.getBrandSymbol()
    });
}

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
document.getElementById('root'));

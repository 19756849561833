const brandWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    let brandColors = JSON.parse(data?.theme);

    return {
        brandId: data?.id,
        symbol: data?.symbol,
        brandName: data?.name,
        prefix: data?.prefix,   
        primaryColor: brandColors?.primaryColor,
        secondaryColor: brandColors?.secondaryColor,
        tertiaryColor: brandColors?.tertiaryColor,
        icon: data?.logos?.small
    }
}

export default brandWrapper;
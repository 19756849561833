import React from 'react';
import PropTypes from 'prop-types';
import validateStatus from 'utils/validateStatus';
import formatDate from 'utils/formatDate';

function PlanSubmissionCard({
    year = null,
    status = null,
    date = null,
    selected = null,
    onSelectSubmission = null
}) {
    const getStatusColor = () => {
        switch(true) {
            case validateStatus.isSubmitted(status):
                return 'c-primary';
            case validateStatus.isApproved(status):
                return 'c-success';
            case validateStatus.isRejected(status):
                return 'c-danger';
            case validateStatus.isDraft(status):
                return 'c-info'
            default:
                return 'c-neutral';
        }
    }

    return (
        <div 
            className='plan-submission-card card cursor-pointer'
            onClick={() => onSelectSubmission()}
        >
            <div className='card-body'>
                <h5 className='card-title font-family-bold'>{`Plan ${year}`}</h5>
                <div className='d-flex align-items-baseline'>
                    {
                        selected &&
                            <i className={`fas fa-circle mr-2 font-size-12 ${getStatusColor()}`}></i>
                    }
                    <h6 className={`card-subtitle mb-2 ${getStatusColor()}`}>{status}</h6>
                </div>
                <p className='card-text font-size-13'>{`${status=="In Progress"?"Saved":status}: ${formatDate.descriptiveDateAndTime(date)}`}</p>
            </div>
        </div>
    );
}

PlanSubmissionCard.propTypes = {
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    status: PropTypes.string,
    date: PropTypes.string,
    selected: PropTypes.bool,
    onSelectSubmission: PropTypes.func
}

export default PlanSubmissionCard;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from 'react-bootstrap';
import Panel from 'components/common/panel/Panel';
import Button from 'components/common/button/Button';
import Input from 'components/common/input/Input';
import Select from 'components/common/select/Select';
import Switch from 'components/common/switch/Switch';
import discountTypes from 'utils/discountTypes';

import { 
    deleteDiscount as deleteDiscountAction,
    saveDiscount as saveDiscountAction,
    updateDiscount as updateDiscountAction
} from 'actions/discountsConfigActions';

export class DiscountConfigModal extends Component {
    state = {
        isEditing: false,
        discount: {
            discountType: discountTypes.percentage
        }
    }

    static propTypes = {
        templateId: PropTypes.number,
        onHide: PropTypes.func.isRequired,
        selectedDiscount: PropTypes.object
    }

    onSelectType = (type = discountTypes.percentage) => {
        this.setState({ discount: { ...this.state.discount, discountType: type } });
    }
    
    onChangeValue = (value = '') => {
        this.setState({ discount: { ...this.state.discount, value } });
    }
    
    onChangeOutputText = (outputText = '') => {
        this.setState({ discount: { ...this.state.discount, outputText } });
    }

    onActivate = (active = false) => {
        this.setState({ discount: { ...this.state.discount, active } });
    }

    onDelete = () => {
        const { discount } = this.state;

        const {
            templateId,
            onHide,
            deleteDiscount
        } = this.props;

        deleteDiscount(templateId, discount.discountId, onHide);
    }

    onSave = (discount = null) => {
        const { isEditing } = this.state;

        const {
            templateId,
            onHide,
            saveDiscount,
            updateDiscount
        } = this.props;

        if(isEditing) {
            updateDiscount(templateId, discount, onHide)
        } else {
            saveDiscount(templateId, discount, onHide);
        }
    }

    componentDidMount() {
        const {
            selectedDiscount
        } = this.props;

        this.setState({ 
            discount: { ...this.state.discount, ...selectedDiscount },
            isEditing: selectedDiscount?.discountId ? true : false 
        });
    }

    render() {
        const {
            discount,
            isEditing
        } = this.state;

        const { 
            onHide,
            saving,
            updating,
            deleting
        } = this.props;

        return (
            <Modal
                className='discount-config-modal'
                onHide={onHide}
                show
            >
                <Modal.Body>
                    <Panel
                        header={isEditing ? 'Edit Discount' : 'New Discount'}
                        headerStyling={{
                            fontSize: '20px',
                            paddingTop: '0.75rem',
                            paddingBottom: '0.75rem'
                        }}
                        hasShadow={false}
                    >
                        <div className='d-flex flex-wrap column-gap-1 row-gap-1 w-100'>
                            <Select
                                label='Discount Type'
                                options={[
                                    {value: discountTypes.percentage, label: '%'},
                                    {value: discountTypes.amount, label: '$'}
                                ]}
                                onChange={({ target: {value} }) => this.onSelectType(value)}
                                value={discount?.discountType}
                            />
                            <div className='flex-grow-1'>
                                <Input 
                                    label='Value'
                                    type='number'
                                    min={0}
                                    value={discount?.value}
                                    onChange={({ target: {value} }) => this.onChangeValue(value)}
                                    placeholder='Example: 10'
                                    error={!discount?.value}
                                    errorMsg='This field is required'
                                />
                            </div>
                            <div className='flex-grow-1'>
                                <Input 
                                    label='Display Text'
                                    value={discount?.outputText}
                                    onChange={({ target: {value} }) => this.onChangeOutputText(value)}
                                    placeholder='Example: 10% OFF'
                                    error={!discount?.outputText}
                                    errorMsg='This field is required'
                                />
                            </div>
                            <div className='d-flex justify-content-center flex-grow-2'>
                                <Switch 
                                    id='couponsConfigItemActive'
                                    label='Active'
                                    labelTop
                                    checked={discount?.active}
                                    onToggle={(checked) => this.onActivate(checked)}
                                />
                            </div>
                        </div>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button 
                            btnType='outline-danger'
                            label={isEditing ? 'Delete Discount' : 'Cancel'}
                            action={() => {
                                if(isEditing) {
                                    this.onDelete(discount?.discountId);
                                } else {
                                    onHide();
                                }
                            }}
                            loading={deleting}
                            loadingMessage='Deleting discount...'
                            disabled={saving || updating}
                        />
                        <Button
                            btnType='primary'
                            label={isEditing ? 'Save Changes' : 'Create Discount'}
                            disabled={ 
                                !discount?.discountType ||
                                !discount?.value ||
                                !discount?.outputText || 
                                deleting
                            }
                            action={() => this.onSave(discount)}
                            loading={saving || updating}
                            loadingMessage={saving ? 'Saving discount...' : 'Updating discount...'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    saving: state.discountsConfig?.saving,
    updating: state.discountsConfig?.updating,
    deleting: state.discountsConfig?.deleting,
});

export default connect(mapStateToProps, {
    saveDiscount: saveDiscountAction,
    updateDiscount: updateDiscountAction,
    deleteDiscount: deleteDiscountAction
})(DiscountConfigModal);

import React from "react";
import PropTypes from 'prop-types';

import LmpbpRule from '../LmpbpRule';
import { Modal } from 'react-bootstrap';
import Button from 'components/common/button/Button';

const MSQBusinessRulesModal = ({
    show,
    onHide
}) => {
    return (
        <Modal
            show={show}
            onHide={() => onHide()}
            className='business-rules-modal'
        >
            <Modal.Header>
                <div className='modal-title'>
                    Budget Guide
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <LmpbpRule>
                        <strong>Website fee</strong> The website fee is required for any MSQ website and covers listing, hosting and management of your website.
                    </LmpbpRule>
                    <LmpbpRule>
                        For Direct Mail and Digital marketing fields with open inputs, please enter whole dollar amounts.
                    </LmpbpRule>
                    <LmpbpRule>
                        <strong>Consumer Fusion</strong> This service sends review requests with technician information to drive google reviews.
                        At the price listed, we recommend all locations use consumer fusion in 2023 for the months they are actively servicing customers.
                        The cost for review management is $150 per month and opt-in is required on an annual basis.
                    </LmpbpRule>
                    <LmpbpRule>
                        <strong>Digital Marketing</strong> can be <strong>INCREASED</strong> or <strong>DECREASED</strong> for any upcoming month as long as
                        the input is made by the <strong>15th</strong> of the month prior. (Ex: May budget will be lock as of April 15th). Contact your digital marketing vendor if a mid-month increase is needed due to your budget running out.
                        <br />
                    </LmpbpRule>
                    <LmpbpRule>
                        Due to <strong>Spectrum pre-printing</strong> their mailers, budget amounts cannot be <strong>DECREASED</strong> once a mail plan has been approved and invoiced by Spectrum.
                        Spectrum budgets can be <strong>INCREASED</strong> and additional mail plans can be put into production as long as the input is made before the <strong>15th</strong> of the month.
                        (Ex: a July Spectrum Mail Plan could be entered until June 15).
                    </LmpbpRule>
                    <LmpbpRule>
                    Due to preprinting, budgets for <strong>Direct Mail</strong> cannot be <strong>decreased</strong> after confirmed and approved with your direct mail vendors.
                    </LmpbpRule>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button
                    btnType='outline-primary'
                    label='Got it'
                    action={() => onHide()}
                />
            </Modal.Footer>
        </Modal>
    );
}

MSQBusinessRulesModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func
}

export default MSQBusinessRulesModal;

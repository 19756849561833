import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';

const InlineNotification = ({
    title = '',
    message = '',
    type = 'danger',
    icon = null,
    headerStyle = false,
    action = null,
    actionText = '',
    displayAction = false,
}) => {
    const renderNotification = () => {
        if (headerStyle) {
            return (
                <div
                    className={`d-flex align-items-center alert alert-${type} mb-4`}
                    role='alert'
                >
                    {icon && <i className={`${icon} mr-4 font-size-32`}></i>}
                    <span>
                        {title}
                        <small className='d-block'>{message}</small>
                    </span>
                    <small className='d-flex justify-content-end flex-grow-1 ml-1'>
                        {displayAction && action && (
                            <strong
                                className='font-family-bold cursor-pointer'
                                onClick={() => action()}
                            >
                                {actionText}
                            </strong>
                        )}
                    </small>
                </div>
            );
        }

        return (
            <div className='alert-container'>
                <Alert show={true} variant={type}>
                    {title ? (
                        <Fragment>
                            <div className='d-flex align-items-baseline column-gap-1'>
                                {icon ? (
                                    <i className={`font-size-16 ${icon}`}></i>
                                ) : null}
                                <Alert.Heading>{title}</Alert.Heading>
                            </div>
                            {message ? message : null}
                        </Fragment>
                    ) : (
                        <div className='d-flex align-items-baseline column-gap-1'>
                            {icon ? (
                                <i className={`font-size-16 ${icon}`}></i>
                            ) : null}
                            {message}
                        </div>
                    )}
                </Alert>
            </div>
        );
    };

    return renderNotification();
};

InlineNotification.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
    icon: PropTypes.string,
    headerStyle: PropTypes.bool,
    action: PropTypes.func,
    actionText: PropTypes.string,
    displayAction: PropTypes.bool,
};

export default InlineNotification;

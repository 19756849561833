import React, { Component } from 'react';
import { connect } from 'react-redux';

import BusinessRule from 'components/common/business-rule/BusinessRule';

import userSession from 'utils/userSession';
import formatNumber from 'utils/formatNumber';
import formatCurrency from 'utils/formatCurrency';
import validateMailingPlan from 'utils/mailing-plan/validateMailingPlan';

export class BusinessRules extends Component {
    configureRules = () => {
        const { mailingPlan } = this.props;
        const { templateBusinessRules = {} } = mailingPlan || {};

        const {
            minimumCampaigns,
            minimumAddressesPerCampaign,
            minimumAmountPerYear
        } = templateBusinessRules || {};

        const {
            validMinCampaigns,
            validAddressesPerCampaign,
            validMinSpend
        } = validateMailingPlan(mailingPlan);

        let configuredRules = [];

        if(minimumCampaigns?.active) {
            const { calculatedValue, strict } = minimumCampaigns;
            
            configuredRules.push({
                message: `You must submit a minimum of ${calculatedValue} campaigns this year`,
                completed: () => validMinCampaigns,
                strict
            });
        }

        if(minimumAddressesPerCampaign?.active) {
            const { calculatedValue, strict } = minimumAddressesPerCampaign;

            configuredRules.push({
                message: `You must send a minimum of ${formatNumber(calculatedValue)} of your addresses per campaign`,
                completed: () => validAddressesPerCampaign,
                strict
            });
        }

        if(minimumAmountPerYear?.active) {
            const { calculatedValue, strict } = minimumAmountPerYear;

            configuredRules.push({
                message: `You must reach a minimum spend of $${formatCurrency(calculatedValue)} for the entire plan`,
                completed: () => validMinSpend,
                strict
            });
        }

        return configuredRules;
    }

    render() {
        return (
            <div className='flex flex-col mt-1'>
                {
                    this.configureRules().map(({ message, completed, strict }) => {
                        return (
                            <span key={message}>
                                <BusinessRule
                                    text={message}
                                    fulfilled={completed()}
                                    softRule={!strict || userSession.isDivisionalUser()}
                                />
                            </span>
                        );
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    mailingPlan: state.mailingPlan
});

export default connect(mapStateToProps, {})(BusinessRules);

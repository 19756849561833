import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';
export const getFranchises = ({
    franchiseId =null,
    franchiseName=null,
    isDmExempt=null,
    isQrCode=null
} = {}) => {
    return handleResponse(
        api().get('/corporate/franchises', {
            params: {
                franchiseId: franchiseId,
                franchiseName: franchiseName,
                isDmExempt: isDmExempt,
                isQrCode: isQrCode
            },
        })
    );
}
export const changeFranchiseDmExemptStatus = ({
    id,isDmExempt
} = {}) => {
    return handleResponse(
        api().post('/corporate/franchise/update-dm-exempt-status', {
            id,isDmExempt
        })
    );
}
export const changeFranchiseQrCode = ({
    id,isQrCode
} = {}) => {
    return handleResponse(
        api().post('/corporate/franchise/update-qr-code', {
            id,isQrCode
        })
    );
}
export const saveFranchiseLicense = ({
    licenseParams
} = {}) => {
    return handleResponse(
        api().post('/franchise/update-license', {
            ...licenseParams
        })
    );
}
export const saveFranchiseOwnerDetails = ({
    ownerParams
} = {}) => {
    return handleResponse(
        api().post('/franchise/update-owner-details', {
            ...ownerParams
        })
    );
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ServiceConfigModal from './service-config-modal/ServiceConfigModal';
import AddButton from 'components/common/add-button/AddButton';
import Loader from 'components/common/loader/Loader';
import ServiceItem from './ServiceItem';

import { 
    getServices as getServicesAction,
    resetServicesList as resetServicesListAction
} from 'actions/servicesConfigActions';

export class ServicesList extends Component {
    state = {
        showConfigModal: false,
        selectedService: null
    }

    static propTypes = {
        templateId: PropTypes.number.isRequired
    }

    onShowConfigModal = (service = null) => {
        this.setState({
            showConfigModal: true,
            selectedService: { ...service }
        });
    }

    onHideConfigModal = () => {
        this.setState({
            showConfigModal: false,
            selectedService: null
        });
    }
    
    renderServices = () => {
        const { services } = this.props;

        if(!Array.isArray(services) || !services) return null;

        return services.map(service => {
            return <ServiceItem 
                name={service.serviceName}
                onEditService={() => this.onShowConfigModal(service)}
            />;
        })
    }

    componentDidMount() {
        const {
            templateId,
            getServices
        } = this.props;
        
        getServices(templateId);
    }

    componentWillUnmount() {
        this.props.resetServicesList();
    }

    render() {
        const {
            showConfigModal,
            selectedService
        } = this.state;

        const { 
            templateId,
            loading
        } = this.props;

        return (
            <div className='d-flex column-gap-1 row-gap-1 flex-wrap'>
                {
                    showConfigModal &&
                        <ServiceConfigModal 
                            templateId={templateId}
                            onHide={this.onHideConfigModal}
                            selectedService={selectedService}
                        />
                }
                <AddButton 
                    style={{width: '200px', height: '60px'}}
                    title='Add New'
                    action={this.onShowConfigModal}
                />
                {this.renderServices()}
                {
                    loading &&
                        <Loader loadingMessage='Loading services...' />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    services: state.servicesConfig?.services,
    loading: state.servicesConfig?.loading
});

export default connect(mapStateToProps, {
    getServices: getServicesAction,
    resetServicesList: resetServicesListAction
})(ServicesList);

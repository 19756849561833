import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getDiscounts = ({
    templateId: mailingPlanTemplateID,
    discountIds = null
} = {}) => {
    return handleResponse(
        api().post('/corporate/discounts/get', {
            mailingPlanTemplateID,
            id: discountIds
        })
    );
}

export const createDiscount = ({
    templateId: mailingPlanTemplateID = null,
    value = null,
    outputText = null,
    discountType = null,
    active = false
} = {}) => {
    return handleResponse(
        api().post('/corporate/discounts/add', {
            mailingPlanTemplateID,
            value,
            outputText,
            discountType,
            active
        })
    );
}

export const updateDiscount = ({
    discountId: id = null,
    value = null,
    outputText = null,
    discountType = null,
    active = null,
    templateId: mailingPlanTemplateID = null
} = {}) => {
    return handleResponse(
        api().post('/corporate/discounts/update', {
            id,
            value,
            outputText,
            discountType,
            active,
            remove: false,
            mailingPlanTemplateID
        })
    );
}

export const deleteDiscount = ({
    discountId: id = null,
    templateId: mailingPlanTemplateID = null
} = {}) => {
    return handleResponse(
        api().post('/corporate/discounts/update', {
            id,
            remove: true,
            mailingPlanTemplateID
        })
    );
}
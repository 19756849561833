import moment from 'moment';
import userSession from './userSession';
const convert = require('xml-js');

const generateSAML = (authorizerURL, ssoURL) => ({
    declaration: {
        attributes: {
            version: '1.0',
            encoding: 'UTF-8',
        },
    },
    elements: [
        {
            type: 'element',
            name: 'samlp:AuthnRequest',
            attributes: {
                AssertionConsumerServiceURL: authorizerURL,
                IssueInstant: new moment().utc(),
                ProtocolBinding:
                    'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
                Version: '2.0',
                'xmlns:samlp':
                    'urn:oasis:names:tc:SAML:2.0:protocol',
            },
            elements: [
                {
                    type: 'element',
                    name: 'saml:Issuer',
                    attributes: {
                        Format: 'urn:oasis:names:tc:SAML:2.0:nameid-format:entity',
                        'xmlsn:saml':
                            'urn:oasis:names:tc:SAML:2.0:assertion',
                    },
                    elements: [
                        {
                            type: 'text',
                            text: ssoURL,
                        },
                    ],
                },
            ],
        },
    ],
});

const post = (encoded, authorizerURL, ssoURL) => {
    let form = document.createElement('form');
    form.method = 'post';
    form.action = ssoURL;

    let SAMLRequest = document.createElement('input');
    SAMLRequest.type = 'hidden';
    SAMLRequest.name = 'SAMLRequest';
    SAMLRequest.value = encoded;

    let RelayState = document.createElement('input');
    RelayState.type = 'hidden';
    RelayState.name = 'RelayState';
    RelayState.value = authorizerURL;

    form.appendChild(SAMLRequest);
    form.appendChild(RelayState);

    document.body.appendChild(form);
    form.submit();
    form.onsubmit(updateFranchise);
};

const updateFranchise = () => {
    const franchise = sessionStorage.getItem('lmpbp_franchise');
    if(franchise)
    {
        const sessionJSON = sessionStorage.getItem('user_session');
        const sessionData =  JSON.parse(sessionJSON);

        sessionStorage.set('user_session', JSON.stringify({ 
            ...sessionData,
            FranchiseExternalID: franchise.externalId,
            FranchiseID: franchise.id 
        }));

        sessionStorage.removeItem('lmpbp_franchise');
    }
}

const loginUser = ({ authorizerURL, ssoURL } = {}) => {
    const samlJson = generateSAML(authorizerURL, ssoURL);
    const xml = convert.js2xml(samlJson);
    const buff = Buffer.from(xml.toString(), 'utf-8');
    const xmlz = buff.toString('base64');

    post(xmlz, authorizerURL, ssoURL);
};

export default loginUser;
import { 
    GET_LOCK_DATE_REMINDER_SCCESS, 
    GET_LOCK_DATE_REMINDER_LOADNING 
} from 'actions/types';

const lockDateReminderReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_LOCK_DATE_REMINDER_SCCESS:
            return { ...state, coupons:  [...action.payload] };
        
        case GET_LOCK_DATE_REMINDER_LOADNING:
            return { ...state, loading: action.payload };

        default:
            return state;
    }
}

export default lockDateReminderReducer;
import  {
    SET_CURRENT_ROUTE
} from  'actions/types';

const utilitiesReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case SET_CURRENT_ROUTE:
            return {...state, currentRoute: action.payload };
            
        default:
            return state;
    }
}

export default utilitiesReducer;
const formatNumber = (number) => {
    if(number === null
        || number === undefined
        || number === ''
        || typeof number === 'boolean'
        || typeof number === 'object'
        || isNaN(Number(number))
    ) return '-';

    return Number(number).toLocaleString();
}

export default formatNumber;
import campaignWrapper from './campaignWrapper';

import businessRulesWrapper from './businessRulesWrapper';

const isFranchiseInfoConfirmed = isCorrectInfo => {
    return isCorrectInfo !== null && isCorrectInfo !== undefined;
};

const planWrapper = plan => {
    const excludedZipCodes = plan?.excluded_zips ? JSON.parse(plan?.excluded_zips) : [];
    return {
        mailingPlanId: plan?.id,
        mailingPlanTemplateId: plan?.mailing_plan_template_id,
        isCorrectFranchiseInfo: plan?.is_correct_franchise_info ? true : false,
        isFranchiseInfoConfirmed: isFranchiseInfoConfirmed(plan?.is_correct_franchise_info),
        createdBy: plan?.created_by,
        createdDate: plan?.created_date,
        savedBusinessRules: businessRulesWrapper.allAsObject(plan?.businessRules?.mailingPlanBusinessRules),
        templateBusinessRules: businessRulesWrapper.allAsObject(plan?.businessRules?.templateBusinessRules),
        mailingPlanYear: plan?.plan_year,
        costPerPiece: plan?.brochure_sizes ? plan?.brochure_sizes[0]?.price : 0,
        brochureSizes: plan?.brochure_sizes,
        comments: plan?.comments || '',
        excludedZipCodes,
        mailingOrder: plan?.send_order || [],
        campaigns: campaignWrapper.sortByDate(plan?.campaigns),
        status: plan?.status,
        feedback: plan?.feedback,
        overriden: plan?.overriden ? true : false,
        allZipCodesSelected: excludedZipCodes?.length ? false : true,
        updatedDate : plan?.updated_date,
        isTemplate : plan?.isTemplate,
        licenseNumber : plan?.licenseNumber,
        overrideLicense : plan?.overrideLicense,
        ownerContactEmail : plan?.ownerContactEmail,
        ownerPhoneNumber : plan?.ownerPhoneNumber,
        ownerName : plan?.ownerName
    }
};

const mailingPlanWrapper = {
    single: plan => {
        if(
            !plan
            || Array.isArray(plan)
            || typeof plan !== 'object'
        ) return {};

        return planWrapper(plan);
    }
};

export default mailingPlanWrapper;

const franchiseWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    return {
        id: data.id,
        name: data.name,
        brandId: data.brand_id,
        externalId: data.external_id,
        email: data.contact_email,
        annualRequiredSpendAmount: data.annual_required_spend_amount,
        monthlyRequiredSpendAmount: data.monthly_required_spend_amount,
        directmailAccess: Boolean(data.directmail_access),
        tacticsMinimumAnnualSpend: data.tactics_minimum_annual_spend,        
    }
}

export default franchiseWrapper;
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import userSession from 'utils/userSession';

const protectedRoute = WrappedComponent => {
    class ProtectedRoute extends Component {
        render() {
            if(userSession.isActive()) {
                return <WrappedComponent {...this.props} />;
            }

            return <Redirect to='/login' />;
        }
    }
    
    return ProtectedRoute;
}

export default protectedRoute;
const wrapper = data => ({
    franchiseId: data.external_id,
    franchiseName: data.franchise_name,
    returnAddress: data.return_address,
    marketingPhone: data.tracking_phone,
    marketingUrl: data.url,
    brochureCode: data.brochure_code,
    coupons: data.coupons,
    couponsExpirationDate: data.expiration_date,
    services: data.services,
    minAddressesRequired: data.min_addresses_required,
    minSpendAmount: data.min_spend_amount,
    netSuiteId: data.netsuiteId,
    badges: data.badges,
    expiration_date_msq: data.expiration_date_msq,
    desired_in_home_date: data.desired_in_home_date,
    budget_intention: data.budget_intention,
    piece_size: data.piece_size
});

const campaignsReportWrapper = {
    single: campaign => {
        if(
            !campaign
            || Array.isArray(campaign)
            || typeof campaign !== 'object'
        ) return {};
        
        return wrapper(campaign);
    },

    sortByFranchiseId: campaigns => {
        if(!Array.isArray(campaigns)) return [];

        return campaigns.map(campaign => wrapper(campaign))
            .sort((a, b) => {
                if(a.franchiseId === b.franchiseId) return 0;
                return a.franchiseId > b.franchiseId ? 1 : -1;
            });
    }
}

export default campaignsReportWrapper;
import React from 'react';
import PropTypes from 'prop-types';

import ConfigCard from 'components/common/config-card/ConfigCard';

const ServiceItem = ({
    name = 'No output text',
    onEditService = null
}) => {
    return (
        <ConfigCard
            onClickEditButton={() => onEditService()}
        >
            <div className='service-item-card'>
                <span className='output-text'>{name}</span>
            </div>
        </ConfigCard>
    );
}

ServiceItem.propTypes = {
    name: PropTypes.string,
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onEditService: PropTypes.func
}

export default ServiceItem;
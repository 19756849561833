import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Panel from 'components/common/panel/Panel';
import Input from 'components/common/input/Input';
import Switch from 'components/common/switch/Switch';
import Button from 'components/common/button/Button';

import { 
    createService as createServiceAction, 
    deleteService as deleteServiceAction,
    updateService as updateServiceAction
} from 'actions/servicesConfigActions';

export class ServiceConfigModal extends Component {
    state = {
        service: null,
        isEditing: false
    }

    static propTypes = {
        templateId: PropTypes.number.isRequired,
        onHide: PropTypes.func.isRequired,
        selectedService: PropTypes.object
    }

    onChangeServiceName = (serviceName = '') => {
        this.setState({ service: { ...this.state.service, serviceName }});
    }

    onRequiresLicense = (licenseRequired = false) => {
        this.setState({ service: { ...this.state.service, licenseRequired }});
    }

    onActivate = (active = false) => {
        this.setState({ service: { ...this.state.service, active }});
    }

    onDelete = (serviceId) => {
        const {
            onHide,
            deleteService,
            templateId
        } = this.props;

        deleteService(templateId, serviceId, onHide);
    }

    onSave = () => {
        const { 
            isEditing,
            service 
        } = this.state;

        const { 
            templateId,
            onHide,
            createService,
            updateService
        } = this.props;

        if(isEditing) {
            updateService(templateId, service, onHide);
        } else {
            createService(templateId, service, onHide);
        }
    }

    componentDidMount() {
        const {
            selectedService
        } = this.props;

        this.setState({
            service: { ...selectedService },
            isEditing: selectedService?.serviceId ? true : false
        });
    }

    render() {
        const { 
            service,
            isEditing
        } = this.state;

        const {
            onHide,
            saving,
            updating,
            deleting
        } = this.props;

        return (
            <Modal 
                className='service-config-modal'
                onHide={onHide}
                show
            >
                <Panel
                    header={isEditing ? 'Edit Service' : 'New Service'}
                    headerStyling={{
                        fontSize: '20px',
                        paddingTop: '0.75rem',
                        paddingBottom: '0.75rem'
                    }}
                    hasShadow={false}
                >
                    <div className='d-flex column-gap-1 align-items-start flex-wrap'>
                        <div className='flex-grow-2'>
                            <Input 
                                label='Service Name'
                                placeholder='Enter service name'
                                value={service?.serviceName}
                                onChange={({ target: {value} }) => this.onChangeServiceName(value)}
                                error={!service?.serviceName}
                                errorMsg='This field is required'
                            />
                        </div>
                        <div className='flex-grow-1'>
                            <Switch 
                                id='serviceConfigLicenseRequired'
                                label='License Required'
                                labelTop
                                checked={service?.licenseRequired}
                                onToggle={checked => this.onRequiresLicense(checked)}
                            />
                        </div>
                        <div className='flex-grow-1'>
                            <Switch 
                                id='serviceConfigActiveService'
                                label='Active'
                                labelTop
                                checked={service?.active}
                                onToggle={checked => this.onActivate(checked)}
                            />
                        </div>
                    </div>
                </Panel>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button 
                            btnType='outline-danger'
                            label={isEditing ? 'Delete Service' : 'Cancel'}
                            action={() => {
                                if(isEditing) {
                                    this.onDelete(service?.serviceId);
                                } else {
                                    onHide();
                                }
                            }}
                            loading={deleting}
                            loadingMessage='Deleting service...'
                            disabled={saving || updating}
                        />
                        <Button
                            btnType='primary'
                            label={isEditing ? 'Save Changes' : 'Create Service'}
                            disabled={ 
                                !service?.serviceName ||
                                deleting
                            }
                            action={() => this.onSave()}
                            loading={saving || updating}
                            loadingMessage={saving ? 'Saving service...' : 'Updating service...'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    saving: state.servicesConfig?.saving,
    updating: state.servicesConfig?.updating,
    deleting: state.servicesConfig?.deleting,
});

export default connect(mapStateToProps, {
    createService: createServiceAction,
    updateService: updateServiceAction,
    deleteService: deleteServiceAction
})(ServiceConfigModal);

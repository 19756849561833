import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Input from 'components/common/input/Input';
import Button from 'components/common/button/Button';
import Select from 'components/common/select/Select';

import { createYearlyPlan as createYearlyPlanAction } from 'actions/yearlyPlansActions';

export class NewPlanModal extends Component {
    state = {
        planYear: '',
        suggestedYear: null,
        isUnique: true,
        selectedPlan :null
    }

    static propTypes = {
        onHide: PropTypes.func
    }

    isUniqueYear = year => {
        const { yearlyPlans } = this.props;
        let isUnique = true;

        for(let plan of yearlyPlans) {
            if(Number(plan.planYear) === Number(year)) {
                isUnique = false;
                break;
            }
        }

        return isUnique;
    }

    getNextSuggestedYear = () => {
        const { yearlyPlans } = this.props;

        return Number(yearlyPlans[yearlyPlans?.length-1]?.planYear) + 1;
    }
    generateYearList = () => {
        if(!this.props.yearlyPlans?.length) return [];
        return this.props.yearlyPlans?.map(({ id, planYear }) => {
            return {
                label: "Plan "+planYear,
                value: id,
                id: id
            }
        });
    }
    onShowHidePlanYearDropdown = () => {
        
        if(this.state.selectedCopyPlan == true){
            document.getElementById("year-plan-dropdown").style.display = '';
        }else{
            document.getElementById("year-plan-dropdown").style.display = 'none';
        }
    }
    render() {
        const { 
            planYear,
            isUnique,
            selectedPlan,
            selectedCopyPlan
        } = this.state;
        
        const { 
            onHide,
            createYearlyPlan,
            creating
        } = this.props;

        return (
            <Modal
                className='new-plan-modal'
                onHide={onHide}
                show={true}
            >
                <Modal.Header
                    className='d-flex justify-content center font-family-bold font-size-24'
                >
                    New Yearly Plan
                </Modal.Header>
                <Modal.Body>
               
                            <div className='item-details m-0'>
                            <input
                                type='checkbox'
                                label= {'Duplicate data from previous plan'}
                                checked={!!selectedCopyPlan}
                                onChange={(e) => {
                                                this.setState({
                                                    selectedCopyPlan: e.target.checked
                                                }, () => {
                                                    this.onShowHidePlanYearDropdown();
                                                });
                                            } } >
                            </input>
                            <span className='campaign-date'> Duplicate data from previous plan</span>
                            </div>

                                <Select id="year-plan-dropdown"
                                    value = {selectedPlan}
                                    placeholder='Select a Plan'
                                    options={this.generateYearList()}
                                    onChange={(e) => {
                                        this.setState({
                                             selectedPlan: e.target.value
                                        }, () => {
                                        });
                                    } } style={{display:"none"}} />
                                
                    <Input 
                        label='Enter year for new plan:'
                        placeholder={`Suggestion for next available year: ${this.getNextSuggestedYear()}`}
                        type='number'
                        min={0}
                        value={planYear}
                        onChange={({ target: {value} }) => {
                            this.setState({ 
                                planYear: value,
                                isUnique: this.isUniqueYear(value)
                            });
                        }}
                        error={!isUnique}
                        errorMsg='A mailing plan for this year already exists'
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-center w-100'>
                        <Button 
                            btnType='primary'
                            label='Create new plan'
                            disabled={!planYear || !isUnique}
                            action={() => createYearlyPlan(planYear, onHide, selectedPlan !==null ?this.props.yearlyPlans.filter(yearData => selectedPlan==yearData.id):null)}
                            loading={creating}
                            loadingMessage='Creating new plan...'
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    yearlyPlans: state.yearlyPlans?.templates || [],
    creating: state.yearlyPlans?.creating
});

export default connect(mapStateToProps, {
    createYearlyPlan: createYearlyPlanAction
})(NewPlanModal);

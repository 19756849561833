import couponEntryWrapper from './couponEntryWrapper';
import serviceEntryWrapper from './serviceEntryWrapper';
import badgeEntryWrapper from './badgeEntryWrapper';

const campaignEntryWrapper = (campaignEntryData) => {
    if(
        !campaignEntryData
        || Array.isArray(campaignEntryData)
        || typeof campaignEntryData !== 'object'
    ) return {};

    return {
        campaignId: campaignEntryData?.id,
        campaignName: campaignEntryData?.name,
        addresses: campaignEntryData?.addresses,
        investment: campaignEntryData?.investment,
        brochureId: campaignEntryData?.brochure_id,
        brochureName: campaignEntryData?.brochure_name,
        campaignTemplateId: campaignEntryData?.campaign_template_id,
        date: campaignEntryData?.date,
        exportDate: campaignEntryData?.export_date,
        lockDate: campaignEntryData?.lock_date,
        mailingPlanId: campaignEntryData?.mailing_plan_id,
        mailingPlanTemplateId: campaignEntryData?.mailing_plan_template_id,
        coupons: campaignEntryData?.coupons?.map(coupon => couponEntryWrapper(coupon)),
        services: campaignEntryData?.services?.map(service => serviceEntryWrapper(service)),
        badges: campaignEntryData?.badges?.map(badge => badgeEntryWrapper(badge)),
        expiration_date:campaignEntryData?.expiration_date,
        desired_in_home_date:campaignEntryData?.desired_in_home_date,
        budget_intention:campaignEntryData?.budget_intention,
        piece_size:campaignEntryData?.piece_size,
    }
}

export default campaignEntryWrapper;
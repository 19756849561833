import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from 'utils/formatNumber';

const ZipCodesItem = ({ 
    zipCode,
    addresses = 0,
    checked,
    disabled = false,
    ...props
}) => {
    return (
        <div className='d-flex align-items-center mb-2'>
            <input className='mr-2' type='checkbox' disabled={disabled} checked={checked} {...props}/>
            <div className='d-flex align-items-baseline'>
                <span className='zip-code pr-1 border-right'>{zipCode}</span>
                <div className='d-flex align-items-baseline pl-1'>
                    <i className='zip-code-address-icon fas fa-home mr-1'></i>
                    <span className='zip-code-address-text'>{formatNumber(addresses)}</span>
                </div>
            </div>
        </div>
    );
}

ZipCodesItem.propTypes = {
    zipCode: PropTypes.number.isRequired,
    addresses: PropTypes.number,
    checked: PropTypes.bool,
    disabled: PropTypes.bool
}

export default ZipCodesItem;

import { subscribeToAwsIotLMPBP } from 'api/lmpbp/awsIot';
import { getBudgetPlansTemplates, getTemplateTactics } from 'api/lmpbp/budgetPlans';
import { getExportedFiles } from 'api/lmpbp/exports';
import awsIoTConnect from 'api/utils/awsIoTConnect';
import Loader from 'components/common/loader/Loader';
import Panel from 'components/common/panel/Panel';
import { AWS_IOT_EXPORT_ERROR_TEMPLATE, AWS_IOT_ERROR_TEMPLATE, AWS_IOT_TIMEOUT_ERROR_TEMPLATE, GET_EXPORED_FILES_ERROR_TEMPLATE, GET_TEMPLATES_ERROR_TEMPLATE, GET_TEMPLATE_TACTICS_ERROR_TEMPLATE } from 'constants/notificationTemplates';
import useAlert from 'hooks/useAlert';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import userSession from 'utils/userSession';
import CustomSelect from '../custom-inputs/CustomSelect';
import PlanExportsItem from './PlanExportsItem';

function MonthlyBudgetsExportByTactic() {
    const [template, setTemplate] = useState(null);
    const [selectedTactic, setTactic] = useState(null);

    const templates = useQuery({
        queryKey: ['templates'],
        queryFn: getBudgetPlansTemplates,
        onError: useAlert(GET_TEMPLATES_ERROR_TEMPLATE),
        onSuccess: (data) => setTemplate(data[0]),
    });

    const exportedFiles = useQuery({
        queryKey: ['exported-files', selectedTactic?.value.name, template?.year],
        queryFn: () => getExportedFiles(selectedTactic?.value.name, template?.year),
        onError: useAlert(GET_EXPORED_FILES_ERROR_TEMPLATE),
    });

    const iotAwsTimeoutErrorAlert = useAlert(AWS_IOT_TIMEOUT_ERROR_TEMPLATE);
    const iotAwsExportError = useAlert(AWS_IOT_EXPORT_ERROR_TEMPLATE);
    const iotCredentials = useQuery(['iotEndpoint'], subscribeToAwsIotLMPBP, {
        onError: useAlert(AWS_IOT_ERROR_TEMPLATE),
        onSuccess: (data) => {
            awsIoTConnect.connect(
                `tactic-budgets-export-${userSession.getUserId()}-${userSession.getBrandId()}`,
                data.iotEndpoint,
                data.region,
                data.accessKey,
                data.secretKey,
                data.sessionToken
            );

            awsIoTConnect.onMessageCallback = ({ timeout ,success,message}) => {
                if (timeout) {
                    iotAwsTimeoutErrorAlert(timeout);
                }else if(!timeout && !success ){
                    let erorMessage = {
                        message: message,
                        serverError: null, // fill this with the request error
                    };
                    iotAwsExportError(erorMessage);
                }
            };
        }
    });

    const templateTactics = useQuery(['template-tactics', template?.id], () => getTemplateTactics(template?.id), {
        enabled: !!template,
        onError: useAlert(GET_TEMPLATE_TACTICS_ERROR_TEMPLATE),
    });

    const campaigns = [
        { value: 'January', id: 0 },
        { value: 'February', id: 1 },
        { value: 'March', id: 2 },
        { value: 'April', id: 3 },
        { value: 'May', id: 4 },
        { value: 'June', id: 5 },
        { value: 'July', id: 6 },
        { value: 'August', id: 7 },
        { value: 'September', id: 8 },
        { value: 'October', id: 9 },
        { value: 'November', id: 10 },
        { value: 'December', id: 11 },
    ];

    function joinCampaignsWithFiles(campaigns, exportedFiles) {
        return campaigns.map(campaign => {
            const fileMetadata = exportedFiles.data?.find(({ filename }) => filename.includes(campaign.value));
            return {
                ...campaign,
                fileMetadata: fileMetadata,
                tactic: selectedTactic?.value,
                year: template?.year,
                iotEndpoint: iotCredentials.data?.iotEndpoint
            };
        });
    }

    function renderCampaigns() {
        if (iotCredentials.isLoading) {
            return <Loader loadingMessage='Loading campaings'></Loader>
        }
        const enrichedCampaings = joinCampaignsWithFiles(campaigns, exportedFiles);
        return enrichedCampaings.map((campaign, index) => {
            return <PlanExportsItem
                key={index}
                campaign={campaign}
            />;
        });
    }

    const templateOptions = templates.data?.map((template) => {
        return { value: template, label: template.year };
    });

    const templateTacticOptions = templateTactics.data?.map((tactic) => {
        return { value: tactic, label: tactic.displayName };
    });

    const resetTacticIfNotPresentInSelectedTemplate = (options) => {
        if (!options.find(op => op.value.name === selectedTactic?.value.name)) {
            setTactic(options[0]);
        }
    }

    useEffect(() => {
        if (!templateTacticOptions) return;
        resetTacticIfNotPresentInSelectedTemplate(templateTacticOptions);
    }, [templateTacticOptions]);


    return (
        <div className="exports-page">
            <Panel
                header={"Monthly Export"}
                headerStyling={{ fontSize: '20px' }}
                refreshing={templateTactics.isLoading || exportedFiles.isLoading}
                loading={templates.isLoading}
            >
                <Container>
                    <Row>
                        <Col>
                            <CustomSelect
                                id="select-year"
                                label="Year"
                                options={templateOptions}
                                defaultValue={templateOptions && templateOptions[0]}
                                onChange={({ value }) => setTemplate(value)} />
                        </Col>
                        <Col>
                            <CustomSelect
                                id="select-tactic"
                                label="Tactic"
                                options={templateTacticOptions}
                                value={selectedTactic}
                                onChange={(tactic) => setTactic(tactic)} />
                        </Col>
                    </Row>
                </Container>
                {renderCampaigns()}
            </Panel>
        </div>
    );
}

export default MonthlyBudgetsExportByTactic;

import { GET_BRAND_INFO } from 'actions/types';

const brandReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_BRAND_INFO:
            return action.payload;

        default:
            return state;
    }
}

export default brandReducer;
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import MailingOrderItem from './MailingOrderItem';

export class MailingOrder extends Component {
    renderMailingOrder = () => {
        const orderWithPositions = this.props.mailingOrder?.map((order, i) => {
            return {...order, position: i+1};
        });

        return orderWithPositions.map(item => {
            return <MailingOrderItem key={item.name} label={item.name} position={item.position}/>
        });
    }

    render() {
        return (
            <Fragment>
                {this.renderMailingOrder()}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    mailingOrder: state.mailingPlan?.mailingOrder || []
});

export default connect(mapStateToProps, {})(MailingOrder);

import {
    DOWNLOAD_CAMPAIGN_EXPORT,
    DOWNLOAD_CAMPAIGN_EXPORT_LOADING,
    EXPORT_CAMPAIGN,
    EXPORT_CAMPAIGN_LOADING,
    GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES,
    GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_LOADING
} from 'actions/types';

const campaignExportsReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case EXPORT_CAMPAIGN:
            return { ...state, lastExport: action.payload };

        case EXPORT_CAMPAIGN_LOADING:
            return { ...state, exportLoading: action.payload };

        case GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES:
            return { ...state, campaigns: action.payload };
        
        case GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_LOADING:
            return { ...state, campaignsLoading: action.payload };

        case DOWNLOAD_CAMPAIGN_EXPORT:
            return { ...state, campaignExportFile: action.payload };

        case DOWNLOAD_CAMPAIGN_EXPORT_LOADING:
            return { ...state, downloadExportFileUrl: action.payload };
        
        default:
            return state;
    }
}

export default campaignExportsReducer;
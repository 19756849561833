import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getZipCodes = ({
    franchiseId: franchiseID
} = {}) => {
    return handleResponse(
        api().get(`/territories`, {
            params: {
                franchiseID
            }
        })
    );
}
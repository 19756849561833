import React from 'react';
import CustomButton from '../CustomButton';

const TrashIconButton = (props) => {
  return (
    <CustomButton
      variant="danger"
      className="delete-button"
      aria-label="Delete"
      {...props}>
      <i className="fas fa-trash"></i>
    </CustomButton>
  );
};

export default TrashIconButton;

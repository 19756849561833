import React, { useState } from "react";
import SearchBar from "components/common/search/SearchBar";
import { useQuery } from "react-query";
import { searchWebsitesRequest } from "api/lmpbp/websites";
import { Accordion, Container, Row, Col } from "react-bootstrap";
import WebsiteCard from "./WebsiteCard";
import Loader from "components/common/loader/Loader";
import PaginatedItems from "components/common/paginated/PaginatedItems";

function SearchByWebsite({
  selectedTemplate,
  activePage,
  setActivePage,
  itemsPerPage,
  setItemsPerPage
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const searchQuery = useQuery(
    ['search-websites', itemsPerPage, activePage],
    () => searchWebsitesRequest(searchTerm, itemsPerPage, activePage),
  );

  return (
    <Container>
      <Row>
        <Col>
          <SearchBar
            icon="fa-globe"
            searchTerm={searchTerm}
            searchBy="Website"
            setSearchTerm={setSearchTerm}
            onSubmit={() => searchQuery.refetch()}
            className="mb-3"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PaginatedItems
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            activePage={activePage}
            setActivePage={setActivePage}
            totalPages={searchQuery.data?.metadata?.totalPages}
            totalItems={searchQuery.data?.metadata?.totalItems}
          >
            {
              searchQuery.isFetching
                ? <Loader loadingMessage="searching websites ..." />
                : <Accordion defaultActiveKey="0">
                  {searchQuery.data?.data.length !== 0
                    ? searchQuery.data?.data.map((websiteData, index) => (
                      <WebsiteCard
                        key={index}
                        eventKey={index.toString()}
                        website={websiteData.website}
                        parent={websiteData.parent}
                        children={websiteData.children}
                        tactics={websiteData.tactics}
                        franchiseWebsiteSubsets={websiteData.franchises}
                        template={selectedTemplate}
                      />
                    ))
                    : <div>No websites found</div>
                  }
                </Accordion>
            }
          </PaginatedItems>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchByWebsite;

import React from 'react';
import CustomButton from './CustomButton';
import "./IconButton.scss"

function IconButton({ iconName, ...props }) {
  return (
    <CustomButton
      className="icon-btn"
      variant="primary"
      aria-label="icon-button"
      {...props}>
      <i className={`fa ${iconName}`}></i>
    </CustomButton>
  );
}

export default IconButton;

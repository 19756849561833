import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const exportAddresses = ({
    campaignTemplateId,
    iotEndpoint
} = {}) => {
    return handleResponse(
        api().get('/corporate/addresses/export', {
            params: {
                campaignTemplateId,
                iotEndpoint
            }
        })
    );
}

export const downloadAddressesExport = ({
    filename
} = {}) => {
    return handleResponse(
        api().get('/corporate/export-file-download/get', {
            params: {
                filename
            }
        })
    );
}
import { 
    GET_FRANCHISE_INFO, 
    INCORRECT_FRANCHISE_INFO_COMMENTS_REQUIRED, 
    RESET_FRANCHISE_INFO
} from 'actions/types';

const franchiseReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_FRANCHISE_INFO:
            return { ...state, ...action.payload };

        case RESET_FRANCHISE_INFO:
            if(action.payload) {
                return { ...state, ...action.payload };
            } 
            return {};
        
        case INCORRECT_FRANCHISE_INFO_COMMENTS_REQUIRED:
            return { ...state, commentsRequired: action.payload };

        default:
            return state;
    }
}

export default franchiseReducer;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { OVERRIDE_MAILING_PLAN_ERROR } from 'actions/types';

import Button from 'components/common/button/Button';
import InlineNotification from 'components/common/inline-notification/InlineNotification';

export class OverrideConfirmation extends Component {
    state = { 
        feedbackMaxSize: 500,
        feedback: ''
    }

    static propTypes = {
        onHide: PropTypes.func,
        show: PropTypes.bool,
        onOverrideConfirmation: PropTypes.func
    }

    countChars = (value = '') => {
        if(!value) return 0;

        return value.trim().length;
    }

    render() {
        const {
            onHide,
            show,
            onOverrideConfirmation,
            overriding,
            overrideError
        } = this.props;

        const { 
            feedbackMaxSize,
            feedback
        } = this.state;

        return (
            <Modal show={show} onHide={onHide} className='mailing-plan-reject-confirmation'>
                <Modal.Header>
                    <i onClick={() => onHide()} className='far fa-times-circle'></i>
                    <div className='modal-title'>
                        Override Mailing Plan
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='font-size-12 pt-1 pb-1'>Please enter reason for overriding this mailing plan:</div>
                    <textarea 
                        className='comment-box pb-2'
                        value={feedback}
                        maxLength={feedbackMaxSize}
                        onChange={({target: {value}}) => this.setState({ feedback: value })}
                    ></textarea>
                    <small className='d-flex justify-content-end'>{this.countChars(feedback)}/{feedbackMaxSize}</small>
                </Modal.Body>
                <Modal.Footer className='d-flex flex-column align-items-center'>
                    <Button 
                        btnType='primary' 
                        label='Save changes'
                        loading={overriding}
                        loadingMessage='Sending feedback...'
                        action={() => onOverrideConfirmation(feedback)}
                        disabled={!feedback.trim()}
                    />
                    {
                        overrideError?.display &&
                            <InlineNotification
                                title={overrideError.name}
                                message={overrideError.message}
                                type={overrideError.type}
                            />
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    overriding: state.mailingPlan?.overrideLoading,
    overrideError: state.notifications[OVERRIDE_MAILING_PLAN_ERROR]
});

export default connect(mapStateToProps, {})(OverrideConfirmation);

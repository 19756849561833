import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from 'components/common/input/Input';
import { changeOwnerDetails } from 'actions/franchiseActions';
import validateStatus from 'utils/validateStatus';
import { validateEmail } from 'utils/validateInputsFields';
import userSession from 'utils/userSession';
export class OwnerInfo extends Component {
    state = {
        ownerContactEmail: '',
        ownerPhoneNumber: '',
        ownerName: '',
        hideOwnerInputs: false,
    }
    componentWillReceiveProps(props) {
        const {
            mailingPlan,
        } = this.props;
        this.setState({ hideOwnerInputs: (mailingPlan?.status == undefined || mailingPlan?.status == "Saved" || validateStatus.isDraft(mailingPlan?.status)) ?false:true });
     }

    onChangeOwnerDetails = (type) => {
        const {
            ownerContactEmail,
            ownerPhoneNumber,
            ownerName
        } = this.state;
        this.props.changeOwnerDetails(ownerContactEmail,ownerPhoneNumber,type,ownerName);
    }

    render() {
        const {
            mailingPlan,
            franchise
        } = this.props;
        const {
            hideOwnerInputs
        } = this.state;
        return (
            <Fragment>
                {
                    !franchise?.franchiseId ?
                        <div>Owner information is not available</div>
                    :
                        <Fragment>
                            <div className='franchise-info-container'>
                                <div div className={`info-group`}>
                                    <div class="row ">
                                        <div class="col-12">
                                            <Input 
                                                type='text' 
                                                label='Contact Email'
                                                placeholder='Enter Contact Email'
                                                value={mailingPlan?.ownerContactEmail}
                                                containerStyling='w-100'
                                                readOnly = {hideOwnerInputs}
                                                onChange={(e) => {
                                                    this.setState({ 
                                                        ownerContactEmail: e.target.value
                                                    }, () => {
                                                        this.onChangeOwnerDetails("email");
                                                    });
                                                }}
                                                error={(mailingPlan?.ownerContactEmail  && !validateEmail(mailingPlan?.ownerContactEmail))}
                                                errorMsg={'Please enter valid Email ID'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div div className={`info-group`}>
                                    <div class="row ">
                                        <div class="col-12">
                                            <Input 
                                                type='text' 
                                                label='Cell Phone Number'
                                                placeholder='Enter Cell Phone Number'
                                                value={mailingPlan?.ownerPhoneNumber}
                                                containerStyling='w-100'
                                                readOnly = {hideOwnerInputs}
                                                onChange={(e) => {
                                                    this.setState({ 
                                                        ownerPhoneNumber: e.target.value
                                                    }, () => {
                                                        this.onChangeOwnerDetails("phone");
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                ( userSession.isMTUser() ) &&  
                                    <div div className={`info-group`}>
                                        <div class="row ">
                                            <div class="col-12">
                                                <Input 
                                                    type='text' 
                                                    label='Owner Name'
                                                    placeholder='Enter Owner Name'
                                                    value={mailingPlan?.ownerName}
                                                    containerStyling='w-100'
                                                    readOnly = {hideOwnerInputs}
                                                    onChange={(e) => {
                                                        this.setState({ 
                                                            ownerName: e.target.value
                                                        }, () => {
                                                            this.onChangeOwnerDetails("name");
                                                        });
                                                    }}
                                                    error={(!mailingPlan?.ownerName)}
                                                    errorMsg={'Owner Name field is required'}
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                           
                        </Fragment>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    mailingPlan: state.mailingPlan,
    franchise: state.franchise,
    ownerContactEmail: state.mailingPlan?.ownerContactEmail,
    ownerPhoneNumber:  state.mailingPlan?.ownerPhoneNumber,
    ownerName:  state.mailingPlan?.ownerName,
});

export default connect(mapStateToProps, {
    changeOwnerDetails
})(OwnerInfo);
import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from './Layout';
import authorize from './common/hocs/authorize';
import arrivedAtLmpbp from './common/hocs/arrivedAtLmpbp';

import Login from './login/Login';
import protectedRoute from './common/hocs/protectedRoute';
export class Router extends Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/authorize' component={authorize(Login)} />
                    <Route exact path='/lmpbp' component={arrivedAtLmpbp()} />
                    <Route exact path='/lmpbp/authorize' component={authorize(Login)} />
                    <Route path='/' component={protectedRoute(Layout)} />
                </Switch>
            </Fragment>
        );
    }
}

export default Router;

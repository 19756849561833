const planTemplateWrapper = data => {
    if (!data || Array.isArray(data) || typeof data !== 'object') return {};

    return {
        id: data.id,
        brandId: data.brand_id,
        year: data.year,
        lockDates: {
            0: data.lockdates['January'],
            1: data.lockdates['February'],
            2: data.lockdates['March'],
            3: data.lockdates['April'],
            4: data.lockdates['May'],
            5: data.lockdates['June'],
            6: data.lockdates['July'],
            7: data.lockdates['August'],
            8: data.lockdates['September'],
            9: data.lockdates['October'],
            10: data.lockdates['November'],
            11: data.lockdates['December'],
        },
    };
};

export default planTemplateWrapper;

const awsIot = require('aws-iot-device-sdk');

let client, iotTopic;

const awsIoTConnect = {
    connect: (topic, iotEndpoint, region, accessKey, secretKey, sessionToken) => {

        iotTopic = topic;

        // connect
        client = awsIot.device({
            region: region,
            protocol: 'wss',
            accessKeyId: accessKey,
            secretKey: secretKey,
            sessionToken: sessionToken,
            port: 443,
            host: iotEndpoint
        });

        client.on('connect', onConnect);
        client.on('message', onMessage);
        client.on('close', onClose);
    },
    onMessageCallback: null,
    onCloseCallback: null
};

const onConnect = () => {
    client.subscribe(iotTopic); // subscribe to a topic
};

const onMessage = (topic, message) => {
    awsIoTConnect.onMessageCallback(JSON.parse(message));
};

const onClose = () => {
    awsIoTConnect.onCloseCallback();
};


export default awsIoTConnect;

import { 
    CREATE_YEARLY_PLAN_LOADING,
    GET_YEARLY_PLANS,
    GET_YEARLY_PLANS_LOADING
} from 'actions/types';

const yearlyPlansReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_YEARLY_PLANS:
            return { ...state, templates: action.payload };

        case GET_YEARLY_PLANS_LOADING:
            return { ...state, loading: action.payload };

        case CREATE_YEARLY_PLAN_LOADING:
            return { ...state, creating: action.payload };
            
        default:
            return state;
    }
}

export default yearlyPlansReducer;
const formatCurrency = (amount) => {
    if(!amount 
        || typeof amount === 'boolean'
        || typeof amount === 'object'
        || isNaN(Number(amount))
    ) return '0.00';

    return Number(String(amount).match(/^\d*(\.?\d{0,2})?/g))
        .toLocaleString(
            undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        );
}

export default formatCurrency;
import api from 'api/lmpbp';
import handleResponse from './utils/handleResponse';

import moment from 'moment';

import userSession from 'utils/userSession';
import budgetPlanWrapper from './utils/backend-wrappers/budgetPlanWrapper';
import budgetEntryWrapper from './utils/backend-wrappers/budgetEntryWrapper';
import planTemplateWrapper from './utils/backend-wrappers/planTemplateWrapper';
import tacticWrapper from './utils/backend-wrappers/tacticWrapper';
import createEntryWrapper from './utils/backend-wrappers/createEntryWrapper';
import planStatusWrapper from './utils/backend-wrappers/planStatusWrapper';
import createPlanWrapper from './utils/backend-wrappers/createPlanWrapper';
import franchiseOwnersWrapper from './utils/backend-wrappers/franchiseOwnersWrapper';

export const getBudgetPlansTemplates = () => {
    return handleResponse({
        promise: api().get('budgets/templates'),
        wrapper: planTemplateWrapper,
        asArray: true,
        sortBy: 'year',
        sortType: 'string',
        sortDesc: true,
    });
};

export const getBudgetPlans = (year) => {
    return handleResponse({
        promise: api().get('budgets', {
            params: {
                year,
                latest: false,
                unsubmitted: false,
            },
        }),
        wrapper: budgetPlanWrapper,
        sortBy: 'id',
        sortType: 'number',
    });
};

export const getHistoric = ({ franchiseId, year = moment().year() } = {}) => {
    return handleResponse({
        promise: api().get(`budgets/${franchiseId}`, {
            params: { year },
        }),
        wrapper: budgetPlanWrapper,
        sortBy: 'updatedOn',
        sortType: 'date',
        sortDesc: true,
    });
};

export const getBudgetEntries = ({
    tacticId,
    budgetPlanId,
    franchiseId,
} = {}) => {
    return handleResponse({
        promise: api().get(`budgets/entries/${franchiseId}`, {
            params: {
                tactic_id: tacticId,
                budget_plan_id: budgetPlanId,
            },
        }),
        wrapper: budgetEntryWrapper,
        sortBy: 'id',
        sortType: 'number',
    });
};

export const saveBudgetEntry = ({
    franchiseId,
    setupFranchiseId,
    budgetPlanId,
    resComId=null,
    tacticId,
    budgets = [],
    entryId = null,
    websiteId = null,
} = {}) => {
    if (budgets) {
        budgets = budgets?.map(({ month, value }) => ({
            index: Number(month),
            budget: Number(value),
        }));
    }

    return handleResponse({
        promise: api().post(
            `budgets/entry/create/${userSession.getBrandId()}/${franchiseId}`,
            {
                budget_entry_id: entryId,
                budget_plan_id: budgetPlanId,
                res_com_id:resComId,
                tactic_id: tacticId,
                monthly_budget: budgets,
                website_id: websiteId,
                setupFranchiseId:setupFranchiseId,
            }
        ),
        wrapper: createEntryWrapper,
    });
};

export const getBudgetPlanTactics = (budgetPlanId) => {
    return handleResponse({
        promise: api().get(`budgets/template/tactics`, {
            params: { budget_plan_id: budgetPlanId },
        }),
        wrapper: tacticWrapper,
        sortBy: 'sortingOrder',
        sortType: 'number',
    });
};

export const getTemplateTactics = (templateId) => {
    return handleResponse({
        promise: api().get(`budget-plans/templates/${templateId}/tactics`),
        wrapper: tacticWrapper,
        sortBy: 'sortingOrder',
        sortType: 'number',
    });
};

export const createBudgetPlan = ({ templateId, franchiseId } = {}) => {
    return handleResponse({
        promise: api().post(
            `budgets/create/${userSession.getBrandId()}/${franchiseId}`,
            {
                budget_plan_template_id: templateId,
            }
        ),
        wrapper: createPlanWrapper,
    });
};

export const copyBudgetPlan = ({ budgetPlanId } = {}) => {
    return handleResponse({
        promise: api().get('budgets/copy', {
            params: { budget_plan_copy: budgetPlanId },
        }),
        wrapper: createPlanWrapper,
    });
};

export const submitBudgetPlan = ({ franchiseId, budgetPlanId } = {}) => {
    return handleResponse({
        promise: api().put(
            `budgets/submit/${userSession.getBrandId()}/${franchiseId}`,
            null,
            {
                params: { budget_plan_id: budgetPlanId },
            }
        ),
    });
};

export const approveBudgetPlan = ({ budgetPlanId, comments } = {}) => {
    return handleResponse({
        promise: api().post('budgets/complete', {
            budget_plan_id: budgetPlanId,
            comments,
            approve: 1,
        }),
    });
};

export const rejectBudgetPlan = ({ budgetPlanId, comments } = {}) => {
    return handleResponse({
        promise: api().post('budgets/complete', {
            budget_plan_id: budgetPlanId,
            comments,
            approve: 0,
        }),
    });
};

export const getPlanStatuses = () => {
    return handleResponse({
        promise: api().get('budgets/statuses'),
        wrapper: planStatusWrapper,
        sortBy: 'name',
        sortType: 'string',
    });
};

// TODO: LMPBP-66 - Implement GET method to fetch the latest budget plan status for a franchise
// - URL: unknow, use a placeholder for now
// - Required parameters: 'franchiseId'
// - Use planStatusWrapper

export const getLatestPlanStatus = ({ franchiseId } = {}) => {
    return handleResponse({
        promise: api().get(`URL`, {
            params: { franchise_id: franchiseId },
        }),
        wrapper: planStatusWrapper,
    });
};

export const getFranchiseOwners = () => {
    return handleResponse({
        promise: api().get('franchises/owners/details'),
        wrapper: franchiseOwnersWrapper,
        sortBy: 'name',
        sortType: 'string',
    });
};

export const exportBudgetPlan = ({ budgetPlanIds } = {}) => {
    return handleResponse({
        promise: api().post(`budgets/export/file`, 
        { budget_plan_ids: budgetPlanIds },
        ),
    });
};

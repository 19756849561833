const resComWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    return {
        id: data.id,
        name: data.description,
        brandId:data.brand_id,
        tacticId:data.tactic_id
    }
}

export default resComWrapper;
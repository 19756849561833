const wrapper = ruleData => ({
    key: String(ruleData?.key),
    name: String(ruleData?.name),
    description: String(ruleData?.description),
    active: Boolean(ruleData?.active),
    strict: Boolean(ruleData?.strict),
    value: ruleData?.value,
    calculatedValue: ruleData?.calculatedValue || ruleData?.value,
    editable: Boolean(ruleData?.editable),
    valueType: String(ruleData?.valueType)
});

const businessRulesWrapper = {
    single: rule => {
        if(!rule
            || Array.isArray(rule)
            || typeof rule !== 'object'
        ) return {};

        return wrapper(rule);
    },
    
    allAsObject: rules => {
        if(!rules
            || Array.isArray(rules)
            || typeof rules !== 'object'
        ) return {};

        let wrappedRules = {};
        Object.keys({ ...rules }).filter(key => Object.keys(rules[key]).length)
            .forEach(key => {
                wrappedRules[key] = { ...wrapper(rules[key]) };
            });

        return wrappedRules;
    },
    
    sortByName: rules => {
        if(!rules || typeof rules !== 'object') return [];
        
        const wrappedRules = Object.keys({ ...rules })?.filter(key => Object.keys(rules[key]).length)
            .map(key => {
                return wrapper(rules[key]);
            });
    
        return wrappedRules.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            
            return 0;
        });
    }
}

export default businessRulesWrapper;
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Panel from 'components/common/panel/Panel';
import CampaignExportsItem from './CampaignExportsItem';

import { setCurrentRoute } from 'actions/utilityActions';
import { 
    downloadCampaignExport,
    exportCampaign, 
    getActivePlanCampaignTemplates,
    initializeAwsIoT
} from 'actions/campaignExportsActions';
import store from 'store/store';

export class ExportCampaigns extends Component {
    state = {
        exportingId: null,
        downloadingFileName: null,
        iotEndpoint: null
    }

    onExport = (id = null) => {
        const { iotEndpoint } = this.state;

        this.setState({ exportingId: id });
        this.props.exportCampaign(id, iotEndpoint);
    }

    onDownload = (fileName = null) => {
        this.setState({ downloadingFileName: fileName });
        this.props.downloadCampaignExport(fileName);
    }

    renderCampaigns = () => {
        const { 
            exportingId,
            downloadingFileName
        } = this.state;
        const { 
            campaigns,
            exporting,
            downloading
        } = this.props;

        if(!exporting && exportingId) {
            this.setState({ exportingId: null });
        }

        if(!campaigns || !campaigns?.length) {
            return <div>No campaigns available for export</div>
        }

        return campaigns.map(campaign => {
            return <CampaignExportsItem 
                key={campaign?.id}
                campaign={campaign}
                onExport={this.onExport}
                onDownload={this.onDownload}
                exporting={exporting}
                exportingId={exportingId}
                downloading={downloading}
                downloadingFileName={downloadingFileName}
            />;
        });
    }

    componentDidMount = async () => {
        this.props.setCurrentRoute(this.props.match);
        this.props.getActivePlanCampaignTemplates();

        const iotEndpoint = await initializeAwsIoT(store.dispatch);
        this.setState({ iotEndpoint });
    }

    render() {
        const { 
            campaigns,
            loading 
        } = this.props;

        return (
            <Panel
                header='Campaigns'
                headerStyling={{fontSize: '20px'}}
                refreshing={loading && campaigns ? true : false}
                loading={loading && !campaigns ? true : false}
            >
                {this.renderCampaigns()}
            </Panel>
        );
    }
}

const mapStateToProps = state => ({
    campaigns: state.campaignExports?.campaigns || [],
    loading: state.campaignExports?.campaignsLoading,
    exporting: state.campaignExports?.exportLoading,
    downloading: state.campaignExports?.downloadingCampaignExportFile
});

export default connect(mapStateToProps, {
    setCurrentRoute,
    getActivePlanCampaignTemplates,
    exportCampaign,
    downloadCampaignExport
})(ExportCampaigns);
import { 
    GET_DISCOUNTS, 
    GET_DISCOUNTS_LOADING 
} from 'actions/types';

const discountsReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_DISCOUNTS:
            return { ...state, discounts:  [...action.payload] };
        
        case GET_DISCOUNTS_LOADING:
            return { ...state, loading: action.payload };

        default:
            return state;
    }
}

export default discountsReducer;
import React from 'react';
import PropTypes from 'prop-types';
import ConfigCard from 'components/common/config-card/ConfigCard';

const BadgeItem = ({
    name = 'No name',
    code = 'None',
    onEditBadge = null,
    markedForRemoval = false
}) => {
    return (
        <ConfigCard
            style={{
                width: '200px',
                height: '90px'
            }}
            onClickEditButton={() => onEditBadge()}
            markedForRemoval={markedForRemoval}
        >
            <div className='coupon-item-card'>
                <div className='coupon-item-title font-family-bold'>{name}</div>
                {
                    code &&
                        <div className='brand-primary-color text-uppercase font-size-14'>{`CODE - ${code}`}</div>
                }
            </div>
        </ConfigCard>
    );
}

BadgeItem.propTypes = {
    name: PropTypes.string,
    onEditBadge: PropTypes.func,
    markedForRemoval: PropTypes.bool
}

export default BadgeItem;
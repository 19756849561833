import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BadgeItem from './BadgeItem';
import AddButton from 'components/common/add-button/AddButton';
import BadgeConfigModal from './badge-config-modal/BadgeConfigModal';
import Loader from 'components/common/loader/Loader';

import userSession from 'utils/userSession';
import { 
    getBadgesForConfig as getBadgesForConfigAction,
    resetBadgesList as resetBadgesListAction
} from 'actions/badgesConfigActions';

export class BadgesList extends Component {
    state = {
        showConfigModal: false,
        selectedBadge: null
    }

    static propTypes = {
        templateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }

    onShowConfigModal = (badge = null) => {
        this.setState({ 
            showConfigModal: true,
            selectedBadge: {...badge}
        });
    }

    onHideConfigModal = () => {
        this.setState({ 
            showConfigModal: false, 
            selectedBrochure: null
        });
    }

    renderBadges = () => {
        const { 
            badges
        } = this.props;
        if(!Array.isArray(badges) || !badges.length) return null;

        return badges.map(badge => {
            return <BadgeItem 
            key={badge.badgeId}
            name={badge.badgeName}
            code={badge.code}
            onEditBadge={() => this.onShowConfigModal(badge)}
            />;
        });
    }

    componentDidMount() {
        const { 
            templateId,
            getBadgesForConfig 
        } = this.props;

        getBadgesForConfig(userSession.getBrandId(), templateId);
    }

    componentWillUnmount() {
        this.props.resetBadgesList();
    }

    render() {
        const { 
            showConfigModal,
            selectedBadge
        } = this.state;

        const {
            loading,
            saving,
            templateId
        } = this.props;
        
        return (
            <div className='d-flex column-gap-1 row-gap-1 flex-wrap mt-3 mb-3'>
                { showConfigModal &&
                    <BadgeConfigModal
                        onHide={this.onHideConfigModal}
                        selectedBadge={selectedBadge}
                        templateId={templateId}
                    />
                }
                {
                    <Fragment>
                        <AddButton 
                            title='Create new'
                            loading={false}
                            action={this.onShowConfigModal}
                        />
                        {this.renderBadges()}
                        {
                            (loading || saving) &&
                                <Loader loadingMessage={loading ? 'Loading badges...' : 'Refreshing badges...'} />
                        }
                    </Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    badges: state.badgesConfig?.badges,
    loading: state.badgesConfig?.loading,
    saving: state.badgesConfig?.saving
});

export default connect(mapStateToProps, {
    getBadgesForConfig: getBadgesForConfigAction,
    resetBadgesList: resetBadgesListAction
})(BadgesList);

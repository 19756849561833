const budgetPlanWrapper = data => {
    if (!data || Array.isArray(data) || typeof data !== 'object') return {};
    return {
        id: data.budget_plan_id,
        brandId: data.brand_id,
        externalId: data.external_id,
        franchiseId: data.franchise_id,
        franchiseName: data.franchise_name,
        requiredAnnualAmount: data.annual_required_spend_amount,
        requiredMonthlyAmount: data.monthly_required_spend_amount,
        directMailAccess: Boolean(data.directmail_access),
        contactEmail: data.contact_email,
        templateId: data.template_id,
        statusId: data.status_id,
        status: data.status_name || 'Not Submitted',
        createdOn: data.created_on,
        updatedOn: data.updated_on,
        owners: data.owners
            ? data.owners?.map(owner => ({
                  id: owner.id,
                  franchiseExternalId: owner.franchise_external_id,
                  name: owner.name,
                  lastName: owner.lastname,
                  franchiseId: owner.franchise_id,
              }))
            : null,
        totalSpend: data.budget_grand_total,
        tacticsMinimumAnnualSpend: data.tactics_minimum_annual_spend,
        scorpionMinMonthlySpendAmount: data.scorpion_min_monthly_spend_amount,
        targatedDmMinMonthlySpendAmount: data.targeted_dm_min_monthly_spend_amount,
        nativeAdvertisingMinMonthlySpendAmount: data.native_advertising_min_monthly_spend_amount,
        mirroredDisplayMinMonthlySpendAmount: data.mirrored_display_min_monthly_spend_amount,
        multiviewMinMonthlySpendAmount: data.multiview_min_monthly_spend_amount,
        youtubeAdvertisingMinMonthlySpendAmount: data.youtube_advertising_min_monthly_spend_amount,
    };
};

export default budgetPlanWrapper;

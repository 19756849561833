import couponWrapper from './couponWrapper';
import badgeWrapper from './badgeWrapper';

const wrapper = (brochure, selected) => ({
    brochureId: brochure?.id,
    src: brochure?.src,
    image1: brochure?.image_1,
    image2: brochure?.image_2,
    image3: brochure?.image_3,
    minServices: brochure?.min_services || 0,
    maxServices: brochure?.max_services || 0,
    brochureName: brochure?.name,
    selected: selected,
    numberOfCoupons: brochure?.coupon_amount,
    licenseRequired: brochure?.license_required ? true : false,
    internalUse: brochure?.internal_use ? true : false,
    coupons: couponWrapper.sortByName(brochure?.coupons, true),
    active: brochure?.active ? true : false,
    numberOfBadges: brochure?.badge_amount,
    badges: badgeWrapper.sortByName(brochure?.badges, false),
    minBadges: brochure?.min_badges || 0,
    maxBadges: brochure?.max_badges || 0,
});

const brochureWrapper = {
    single: (brochure, selected = false) => {
        if(
            !brochure
            || Array.isArray(brochure)
            || typeof brochure !== 'object'
        ) return {};

        return wrapper(brochure, selected);
    },

    sortByName: (brochures, selected = false) => {
        if(!Array.isArray(brochures) || !brochures.length) return [];

        return brochures.map(brochure => wrapper(brochure, selected)).sort((a, b) => {
            if(a.brochureName === b.brochureName) return 0;
            return a.brochureName > b.brochureName ? 1 : -1;
        });
    }
}

export default brochureWrapper;
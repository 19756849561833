const { 
    GET_MAILING_PLAN_SUBMISSIONS, 
    GET_MAILING_PLAN_SUBMISSIONS_LOADING
} = require('actions/types');

const submissionsReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_MAILING_PLAN_SUBMISSIONS:
            return { ...state, submissions: [...action.payload] };
        
        case GET_MAILING_PLAN_SUBMISSIONS_LOADING:
            return { ...state, loading: action.payload };

        default:
            return state;
    }
}

export default submissionsReducer;
import hasAtLeastOneCampaign from '../business-rules/hasAtLeastOneCampaign';
import {validateMinSpend,validateMinSpendMsq} from '../business-rules/validateMinSpend';
import validateMinCampaigns from '../business-rules/validateMinCampaigns';
import validateMinAddressesPerCampaign from '../business-rules/validateMinAddressesPerCampaign';
import userSession from 'utils/userSession';
import validateCampaigns from '../campaigns/validateCampaigns';
import excessAddressesCampaigns from '../campaigns/campaignsWithExcessAddresses';
import { validateEmail } from 'utils/validateInputsFields';

const validateMailingPlan = mailingPlan => {
    const {
        campaigns,
        costPerPiece,
        templateBusinessRules,
        zipCodes,
        excludedZipCodes,
        overrideLicense,
        licenseNumber,
        ownerPhoneNumber,
        ownerContactEmail,
        ownerName
    } = mailingPlan || {};

    const {
        minimumAmountPerYear = {},
        minimumCampaigns = {},
        minimumAddressesPerCampaign = {},
        requireServices = {},
    } = templateBusinessRules || {};
    const validMinSpend = userSession.isMSQUser() || userSession.isMTUser()?validateMinSpendMsq(campaigns,minimumAmountPerYear,costPerPiece,userSession.isMTUser()): validateMinSpend(
        campaigns,
        costPerPiece,
        minimumAmountPerYear
    );
    const validMinCampaigns = validateMinCampaigns(campaigns, minimumCampaigns);
    const validAddressesPerCampaign = validateMinAddressesPerCampaign(
        campaigns,
        minimumAddressesPerCampaign
    );
    const hasOneCampaign = hasAtLeastOneCampaign(campaigns);
    const hasCompleteCampaigns = validateCampaigns(
        campaigns,
        requireServices.active,
        minimumAddressesPerCampaign
    );
    const campaignsWithExcessAddresses = excessAddressesCampaigns(
        campaigns,
        zipCodes,
        excludedZipCodes
    );

    let enablePlanSubmission = true;

    if (minimumAmountPerYear?.active && !validMinSpend && minimumAmountPerYear.strict) {
        enablePlanSubmission = false;
    }

    if (minimumCampaigns?.active && !validMinCampaigns && minimumCampaigns.strict) {
        enablePlanSubmission = false;
    }

    if (minimumAddressesPerCampaign?.active && !validAddressesPerCampaign && 
            minimumAddressesPerCampaign.strict) {
        enablePlanSubmission = false;
    }
    if (userSession.isMSQUser() && ( overrideLicense && !licenseNumber) ) {
        enablePlanSubmission = false;
    }
    if ( !ownerContactEmail || !ownerPhoneNumber ) {
        enablePlanSubmission = false;
    }
    if (userSession.isDivisionalUser()) enablePlanSubmission = true;
    if(ownerContactEmail){ 
        enablePlanSubmission =  validateEmail(ownerContactEmail)
    }
    if(!ownerName && userSession.isMTUser()){ 
        enablePlanSubmission = false;
    }
    if (!hasOneCampaign) enablePlanSubmission = false;
    if (!hasCompleteCampaigns) enablePlanSubmission = false;
    if (campaignsWithExcessAddresses && !userSession.isMTUser()) enablePlanSubmission = false;
   
    return {
        hasOneCampaign,
        validMinSpend,
        validMinCampaigns,
        validAddressesPerCampaign,
        hasCompleteCampaigns,
        campaignsWithExcessAddresses,
        enablePlanSubmission,
    };
};

export default validateMailingPlan;

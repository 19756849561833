const statusWrapper = status => {
    if(
        !status
        || Array.isArray(status)
        || typeof status !== 'object'
    ) return {};

    return {
        statusId: status?.id,
        statusName: status?.name,
        statusCode: status?.status_code,
        description: status?.description
    }
}

export default statusWrapper;
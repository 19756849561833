import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import ZipCodesItem from './ZipCodesItem';
import Checkbox from 'components/common/checkbox/Checkbox';

import campaignsWithExcessAddresses from 'utils/campaigns/campaignsWithExcessAddresses';
import userSession from 'utils/userSession';
import formatNumber from 'utils/formatNumber';

import { 
    getZipCodes as getZipCodesAction,
    toggleZipCode as toggleZipCodeAction,
    toggleAllZipCodes as toggleAllZipCodesAction
} from 'actions/mailingPlanActions';

import { 
    notifyInsufficientAddresses as notifyInsufficientAddressesAction
} from 'actions/notificationActions';

export class ZipCodes extends Component {
    selectionDisabled = () => {
        const { 
            overrideEnabled,
            zipCodes
        } = this.props;

        if(userSession.isDivisionalUser()) {
            return !overrideEnabled ? true : false;
        }

        if(!zipCodes.length) return true;

        return false;
    }

    calculateTotalAddresses = () => {
        const { zipCodes } = this.props;
        if(!zipCodes.length) return 0;

        let totalAddresses = 0;

        zipCodes?.forEach(({ zipCode, addresses }) => {
            const excluded = this.verifyExcluded(zipCode);

            if(!excluded) {
                totalAddresses += addresses
            }
        });

        return totalAddresses;
    }

    verifyExcluded = (zipCode) => {
        const { excluded } = this.props;

        let isExcluded = false;
        
        excluded?.forEach(code => {
            if(Number(zipCode) === Number(code)) {
                isExcluded = true;
            }
        });

        return isExcluded;
    }

    renderZipCodes = () => {
        const { 
            zipCodes,
            toggleZipCode
        } = this.props;

        return zipCodes?.map(({ zipCode, addresses }) => {
            const excluded = this.verifyExcluded(zipCode);
            
            return (
                <ZipCodesItem 
                    key={zipCode}
                    zipCode={Number(zipCode)}
                    addresses={Number(addresses)}
                    checked={!excluded}
                    onChange={() => toggleZipCode(zipCode)}
                    disabled={this.selectionDisabled()}
                />
            );
        });
    }

    onSelectAll = (selectAll = false) => {
        const { toggleAllZipCodes } = this.props;
        toggleAllZipCodes(selectAll);
    }

    componentDidUpdate() {
        const { 
            campaigns,
            zipCodes,
            excluded,
            notifyInsufficientAddresses
        } = this.props;

        const excessAddressesCampaigns = campaignsWithExcessAddresses(campaigns, zipCodes, excluded);

        if(excessAddressesCampaigns) {
            notifyInsufficientAddresses({  
                customConfigObj: { campaigns: excessAddressesCampaigns } 
            });
        } else notifyInsufficientAddresses({ display: false });
    }

    render() {
        const { 
            zipCodes,
            allSelected
        } = this.props;

        return (
            <Fragment>
                <div className='row two-elements'>
                    <div className='font-size-12 mb-3'>
                        {zipCodes?.length ?
                            <div className='d-flex'>
                                <span className='mr-1'>Total Addresses:</span>
                                <span className='font-family-bold'>{formatNumber(this.calculateTotalAddresses())}</span>
                            </div> : null
                        }
                    </div>
                    <div className='font-size-12 mb-2'>
                        {zipCodes?.length ?
                            <Checkbox 
                                id='selectAllZipCodes'
                                label='Select All'
                                checked={allSelected}
                                onChange={({ target: {checked} }) => this.onSelectAll(checked)}
                                disabled={this.selectionDisabled()}
                            /> : null
                        }
                    </div>
                </div>
                <div className='zip-code-list'>
                    {this.renderZipCodes()}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    campaigns: state.mailingPlan?.campaigns,
    zipCodes: state.mailingPlan?.zipCodes,
    excluded: state.mailingPlan?.excludedZipCodes,
    overrideEnabled: state.mailingPlan?.overrideEnabled,
    allSelected: state.mailingPlan?.allZipCodesSelected ?? false
});

export default connect(mapStateToProps, {
    getZipCodes: getZipCodesAction,
    toggleZipCode: toggleZipCodeAction,
    toggleAllZipCodes: toggleAllZipCodesAction,
    notifyInsufficientAddresses: notifyInsufficientAddressesAction
})(ZipCodes);

import { 
    DELETE_COUPON_LOADING,
    GET_COUPONS_FOR_CONFIG_LIST, 
    GET_COUPONS_FOR_CONFIG_LIST_LOADING,
    GET_COUPON_DISCOUNTS,
    GET_COUPON_DISCOUNTS_LOADING,
    RESET_COUPON_DISCOUNTS,
    SAVE_COUPON_LOADING,
    UPDATE_COUPON_LOADING
} from 'actions/types';

const couponsConfigReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_COUPONS_FOR_CONFIG_LIST:
            return { ...state, coupons: [...action.payload] };

        case GET_COUPONS_FOR_CONFIG_LIST_LOADING:
            return { ...state, loading: action.payload };

        case SAVE_COUPON_LOADING:
            return { ...state, saving: action.payload };

        case UPDATE_COUPON_LOADING:
            return { ...state, updating: action.payload };

        case DELETE_COUPON_LOADING:
            return { ...state, deleting: action.payload };

        case GET_COUPON_DISCOUNTS_LOADING:
            return { ...state, loadingCouponDiscounts: action.payload };

        case GET_COUPON_DISCOUNTS:
            return { ...state, selectedCouponDiscounts: action.payload };

        case RESET_COUPON_DISCOUNTS:
            return { ...state, selectedCouponDiscounts: action.payload };

        default:
            return state;
    }
}

export default couponsConfigReducer;
const tableDataWrapper = (budgetPlans, templateYear, templateId) => {
    if (!budgetPlans || !Array.isArray(budgetPlans)) return [];

    return budgetPlans.map((plan, index) => ({
        key: plan.id || `customId-${index}`,
        id: plan.id,
        franchise: plan.franchiseName,
        owners: plan.owners
            ? plan.owners
                  .map(({ name, lastName }) => `${name} ${lastName}`)
                  .join(', ')
            : null,
        status: plan.status,
        total: plan.totalSpend,
        onCreateNewPlan: plan.onCreateNewPlan,
        budgetPlan: plan,
        templateYear,
        templateId,
    }));
};

export default tableDataWrapper;

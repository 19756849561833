const wrapper = (option) => {
	return {
        id: option.id,
        value: option.name,
        description: option.description
    };
}

const sendOrderWrapper = template => {
    if(
        !template
        || !Array.isArray(template)
        || typeof template !== 'object'
    ) return {};

    return template.map(sendOrder => wrapper(sendOrder));
}

export default sendOrderWrapper;
import { closeNotification } from "actions/notificationActions";
import { useDispatch } from "react-redux";

const useCloseAlert = () => {
    const dispatch = useDispatch();

    return (notification) => {
        dispatch(closeNotification(notification));
    }
}

export default useCloseAlert;
import { 
    GET_BROCHURES, 
    GET_BROCHURES_LOADING
} from 'actions/types';

const brochuresReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_BROCHURES:
            return { ...state, brochures: [...action.payload] };

        case GET_BROCHURES_LOADING:
            return { ...state, loading: action.payload };
        
        default:
            return state;
    }
}

export default brochuresReducer;
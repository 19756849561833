import discountWrapper from 'api/direct-mail/utils/backend-wrappers/discountWrapper';

import { 
    createDiscount as createDiscountApi,
    deleteDiscount as deleteDiscountApi,
    getDiscounts as getDiscountsApi,
    updateDiscount as updateDiscountApi
} from 'api/direct-mail/discounts';

import { 
    notifyDeleteDiscountError,
    notifyDeleteDiscountSuccess,
    notifyGetDiscountsForConfigListError, 
    notifySaveDiscountError, 
    notifySaveDiscountSuccess, 
    notifyUpdateDiscountError,
    notifyUpdateDiscountSuccess
} from './notificationActions';

import { 
    DELETE_DISCOUNT_LOADING,
    GET_DISCOUNTS_FOR_CONFIG_LIST,
    GET_DISCOUNTS_FOR_CONFIG_LIST_LOADING, 
    SAVE_DISCOUNT_LOADING,
    UPDATE_DISCOUNT_LOADING
} from './types';

export const resetDiscountsList = () => {
    return {
        type: GET_DISCOUNTS_FOR_CONFIG_LIST,
        payload: []
    };
}

export const getDiscounts = templateId => async dispatch => {
    dispatch(notifyGetDiscountsForConfigListError({ display: false }));

    dispatch({
        type: GET_DISCOUNTS_FOR_CONFIG_LIST_LOADING,
        payload: true
    });

    const [discountsErr, discountsRes] = await getDiscountsApi({ templateId });

    if(discountsErr) {
        dispatch(notifyGetDiscountsForConfigListError({ serverError: discountsErr }));
    } 

    dispatch({
        type: GET_DISCOUNTS_FOR_CONFIG_LIST,
        payload: discountWrapper.sortByType(discountsRes)
    });

    return dispatch({
        type: GET_DISCOUNTS_FOR_CONFIG_LIST_LOADING,
        payload: false
    });
}

export const saveDiscount = (templateId = null, discount = {}, onComplete = null) => async dispatch => {
    dispatch(notifySaveDiscountError({ display: false }));
    dispatch(notifySaveDiscountSuccess({ display: false }));

    dispatch({
        type: SAVE_DISCOUNT_LOADING,
        payload: true
    });

    const [saveErr] = await createDiscountApi({ templateId, ...discount });

    if(saveErr) {
        dispatch(notifySaveDiscountError({ serverError: saveErr }));
    } else {
        dispatch(notifySaveDiscountSuccess());
        await dispatch(getDiscounts(templateId));
    }

    if(onComplete) onComplete();

    return  dispatch({
        type: SAVE_DISCOUNT_LOADING,
        payload: false
    });
}

export const updateDiscount = (templateId = null, discount = {}, onComplete = null) => async dispatch => {
    dispatch(notifyUpdateDiscountError({ display: false }));
    dispatch(notifyUpdateDiscountSuccess({ display: false }));

    dispatch({
        type: UPDATE_DISCOUNT_LOADING,
        payload: true
    });

    const [updateErr] = await updateDiscountApi({ templateId, ...discount });

    if(updateErr) {
        dispatch(notifyUpdateDiscountError({ serverError: updateErr }));
    } else {
        dispatch(notifyUpdateDiscountSuccess());
        await dispatch(getDiscounts(templateId));
    }

    if(onComplete) onComplete();

    return  dispatch({
        type: UPDATE_DISCOUNT_LOADING,
        payload: false
    });
}

export const deleteDiscount = (templateId = null, discountId = null, onComplete = null) => async dispatch => {
    dispatch(notifyDeleteDiscountError({ display: false }));
    dispatch(notifyDeleteDiscountSuccess({ display: false }));

    dispatch({
        type: DELETE_DISCOUNT_LOADING,
        payload: true
    });

    const [deleteErr] = await deleteDiscountApi({ templateId, discountId });

    if(deleteErr) {
        dispatch(notifyDeleteDiscountError({ serverError: deleteErr }));
    } else {
        dispatch(notifyDeleteDiscountSuccess());
        await dispatch(getDiscounts(templateId));
    }

    if(onComplete) onComplete();

    return dispatch({
        type: DELETE_DISCOUNT_LOADING,
        payload: false
    });
}
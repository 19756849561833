const franchiseOwnersWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    return {
        id: data.id,
        franchiseExternalId: data.franchise_external_id,
        name: data.name,
        lastName: data.lastname,
        franchiseId: data.franchise_id,
    }
}

export default franchiseOwnersWrapper;
import { showNotification } from 'actions/notificationActions';
import { useDispatch } from 'react-redux';
import useCloseAlert from './useCloseNotification';

const useAlert = (template) => {
  const dispatch = useDispatch();
  const closeAlert = useCloseAlert();

  return (info) => {
    const notificationWithDetails = { ...template, serverError: info };
    dispatch(showNotification(notificationWithDetails));
    setTimeout(() => closeAlert(template.id), 8000);
  };
};

export default useAlert;
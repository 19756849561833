import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ExportButton from '../export-button/ExportButton';
import Loader from '../loader/Loader';

import paginationRenderer from './config/paginationRenderer';

const Table = ({
    keyField = 'id',
    loading = false,
    loadingMessage = 'Loading table...',
    data = null,
    columns = null,
    enableExport = false,
    enablePagination = false,
    defaultSorted = [],
    exportFileName = 'export.csv',
    idExportBtn = null, // This ID is used for testing automation
    selectRow = null,
}) => {
    return (
        <div className='direct-mail-table'>
            {loading ? (
                <Loader loadingMessage={loadingMessage} />
            ) : (
                <ToolkitProvider
                    keyField={keyField}
                    data={data}
                    columns={columns}
                    exportCSV={{
                        enableExport,
                        fileName: exportFileName,
                    }}
                >
                    {props => (
                        <div>
                            {selectRow ? (
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    defaultSorted={defaultSorted}
                                    pagination={
                                        enablePagination
                                            ? paginationFactory({
                                                  showTotal: true,
                                                  paginationRenderer,
                                              })
                                            : null
                                    }
                                    selectRow={selectRow}
                                />
                            ) : (
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    defaultSorted={defaultSorted}
                                    pagination={
                                        enablePagination
                                            ? paginationFactory({
                                                  showTotal: true,
                                                  paginationRenderer,
                                              })
                                            : null
                                    }
                                />
                            )}
                            {props.baseProps?.data?.length && enableExport ? (
                                <div className='d-flex justify-content'>
                                    <ExportButton
                                        id={idExportBtn}
                                        label='Export'
                                        onExport={props.csvProps?.onExport}
                                    />
                                </div>
                            ) : null}
                        </div>
                    )}
                </ToolkitProvider>
            )}
        </div>
    );
};

Table.propTypes = {
    keyField: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    data: PropTypes.array,
    columns: PropTypes.array,
    enableExport: PropTypes.bool,
    enablePagination: PropTypes.bool,
    exportFileName: PropTypes.string,
    idExportBtn: PropTypes.string,
    selectRow: PropTypes.object,
};

export default Table;

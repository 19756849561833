import {
    GET_CAMPAIGNS_REPORT,
    GET_CAMPAIGNS_REPORT_LOADING
} from 'actions/types';

const reportsReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_CAMPAIGNS_REPORT:
            return { ...state, campaignsReport: { ...state.campaignsReport, data: action.payload } };

        case GET_CAMPAIGNS_REPORT_LOADING:
            return { ...state, campaignsReport: { ...state.campaignsReport, loading: action.payload } };
            
        default:
            return state;
    }
}

export default reportsReducer;
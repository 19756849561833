
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import "./CustomButton.scss";

const LoadingSpinner = ({ loadingMessage }) => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <div className='spinner-border spinner-border-sm' role='status'></div>
      <span className='font-family-bold ml-2'>{loadingMessage}</span>
    </div>
  );
}

const CustomButton = ({
  disabled,
  loading,
  loadingMessage,
  disableConditions,
  children,
  className,
  ...props }) => {

  const metDisabledConditions = disableConditions?.filter(r => r.disabled);
  const isDisabled = metDisabledConditions?.length || loading;

  const button = (
    <Button className={`${className}`} disabled={isDisabled || disabled} {...props}>
      {loading
        ? <LoadingSpinner loadingMessage={loadingMessage} />
        : children}
    </Button>
  );


  if (metDisabledConditions?.length) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 400 }}
        overlay={
          <Popover color="info">
            <ul>
              {metDisabledConditions.map((r, index) => <li key={index}>{r.reason}</li>)}
            </ul>
          </Popover>}
      >
        {button}
      </OverlayTrigger>
    );
  } else {
    return button;
  }
};

export default CustomButton;


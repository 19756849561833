import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import AddButton from 'components/common/add-button/AddButton';
import Panel from 'components/common/panel/Panel';
import Loader from 'components/common/loader/Loader';
import NewPlanModal from './new-plan-modal/NewPlanModal';

import { setCurrentRoute as setCurrentRouteAction } from 'actions/utilityActions';
import { getYearlyPlans as getYearlyPlansAction } from 'actions/yearlyPlansActions';
export class YearlyPlans extends Component {
    state = {
        showNewPlanModal: false
    }
    
    renderYearlyPlans = () => {
        const { 
            yearlyPlans,
            loading
        } = this.props;

        if(loading && !yearlyPlans?.length) return (
            <div className='d-flex justify-content-center align-items-center flex-grow-1'>
                <Loader loadingMessage='Loading plans...'/>
            </div>
        );

        if(!yearlyPlans || !yearlyPlans.length) return null;

        return yearlyPlans?.map(plan => {
            return (
                <Link 
                    key={plan?.id}
                    className={`yearly-plan-card ${plan?.active ? 'active' : ''}`}
                    to={{
                        pathname: `/yearly-plans/edit/${plan?.id}`,
                        state: {
                            selectedPlanId: plan?.id,
                            planYear: plan?.planYear
                        }
                    }}>
                    {`Plan ${plan?.planYear}`}
                    {
                        plan?.active &&
                            <small>Active</small>
                    }
                </Link>
            );
        });
    }

    componentDidMount() {
        const {
            setCurrentRoute,
            getYearlyPlans
        } = this.props;

        setCurrentRoute(this.props.match);
        getYearlyPlans();
    }

    render() {
        const { showNewPlanModal } = this.state;

        const {
            loading,
            yearlyPlans
        } = this.props;

        return (
            <Panel
                header='Yearly plans'
                headerStyling={{ fontSize: '20px' }}
                refreshing={loading && yearlyPlans?.length ? true : false}
            >
                {
                    showNewPlanModal &&
                        <NewPlanModal 
                            onHide={() => this.setState({ showNewPlanModal: false })}
                        />
                }
                <div className='d-flex row-gap-1 column-gap-1 flex-wrap align-items-center'>
                    <AddButton 
                        style={{height: '70px'}}
                        title='Create new'
                        action={() => this.setState({ showNewPlanModal: true })}
                    />
                    {this.renderYearlyPlans()}
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = state => ({
    yearlyPlans: state.yearlyPlans?.templates || [],
    loading: state.yearlyPlans?.loading
});

export default connect(mapStateToProps, {
    setCurrentRoute: setCurrentRouteAction,
    getYearlyPlans: getYearlyPlansAction
})(YearlyPlans);

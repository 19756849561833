import React, { Component } from 'react';

import Loader from 'components/common/loader/Loader';

import userSession from 'utils/userSession';
import loginUser from 'utils/loginUser';
export class Login extends Component {
    componentDidMount() {
        const authorizerURL = userSession.arrivedAtLmpbp() ? 
            process.env.REACT_APP_LMPBP_AUTHORIZER_URL :
            process.env.REACT_APP_AUTHORIZER_URL;

        const ssoURL = userSession.arrivedAtLmpbp() ? 
            process.env.REACT_APP_LMPBP_SSO_URL :
            process.env.REACT_APP_SSO_URL;


        if (userSession.isActive()) {
            const { history } = this.props;
            history.replace('/');
        } else {
           loginUser({ authorizerURL, ssoURL });
        }
    }

    render() {
        return (
            <div className='p-3 pt-5'>
                <Loader loadingMessage='Hang on, we are logging you in...' />
            </div>
        );
    }
}

export default Login;

import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getMailingPlans = ({
    year = null
} = {}) => {
    return handleResponse(
        api().get('/corporate/mailing-plans', {
            params: {
                year
            }
        })
    );
}

export const getPlanStatuses = () => {
    return handleResponse(
        api().get('/corporate/mailing-plans/statuses')
    );
}

export const getPlansSubmissionsOverview = ({
    year = null
}) => {
    return handleResponse(
        api().get('/corporate/mailing-plans/overview', {
            params: {
                year
            }
        })
    );
}

export const getMailingYears = () => {
    return handleResponse(
        api().get('/corporate/mailing-plans/years')
    );
}

export const changeDmExemptStatus = ({
    id,isDmExempt
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/update-dm-exempt-status', {
            id,isDmExempt
        })
    );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BrochureSelection from './BrochureSelection';
import ServiceSelection from './ServiceSelection';
import CouponSelection from './CouponSelection';
import BadgeSelection from './BadgeSelection';
import AddressesSelection from './AddressesSelection';
import CampaignWizardForm from './CampaignWizardForm';
import Switch from 'components/common/switch/Switch';
import Input from 'components/common/input/Input';
import moment from 'moment';
import formatDate from 'utils/formatDate';

import userSession from 'utils/userSession';

import { saveCampaignConfig } from 'actions/campaignActions';

import {
    CAMPAIGN_ITEM_SELECT_BROCHURE,
    CAMPAIGN_ITEM_SELECT_SERVICES,
    CAMPAIGN_ITEM_SELECT_COUPONS,
    CAMPAIGN_ITEM_ADD_ADDRESSES,
    CAMPAIGN_ITEM_SELECT_BADGES
} from './config/campaignFieldsIds';

export class CampaignWizardConfig extends Component {
    state = {
        wizardStepTitle: 'Select a Brochure',
        wizardFirstStep: 1,
        wizardLastStep: 5,
        wizardCurrentStep: 1,
        numberOfCoupons: 0,
        numberOfBadges: 0,
        errors: {
            addressesErr: null
        },
        wizardCampaignState: null,
        couponFields: null,
        lockNextStep: false,
    }

    static propTypes = {
        campaign: PropTypes.object,
        show: PropTypes.bool,
        onHide: PropTypes.func,
        selectedFieldId: PropTypes.string
    }

    onLockNextStep = (lockNextStep) => {
        if(lockNextStep) {
            this.setState({ lockNextStep: true });
        } else {
            this.setState({ lockNextStep: false });
        }
    }

    onBrochureSelected = selectedBrochure => {
        const { wizardCampaignState } = this.state;
        const prevBrochureId = wizardCampaignState?.selectedBrochure?.brochureId;
        const prevSelectedCoupons = wizardCampaignState?.selectedCoupons
        const prevSelectedBadges = wizardCampaignState?.selectedBadges

        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                brochures: wizardCampaignState.brochures?.map(brochure => {
                    if(brochure.brochureId === selectedBrochure.brochureId) {
                        return {...brochure, selected: true};
                    }
                    
                    return {...brochure, selected: false};
                }),
                selectedBrochure: {...selectedBrochure, selected: true },
                selectedCoupons: prevBrochureId === selectedBrochure?.brochureId ? [...prevSelectedCoupons] : [],
                selectedBadges: prevBrochureId === selectedBrochure?.brochureId ? [...prevSelectedBadges] : []
            } 
        });
    }

    onSetServiceSelected = (serviceId, selected, callback) => {
        const { wizardCampaignState } = this.state;
        
        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                services: wizardCampaignState.services?.map(service => {
                    if(service.serviceId === serviceId) {
                        return {...service, selected: selected}
                    }

                    return {...service};
                })
            }
        }, () => {
            if(callback) {
                callback();
            }
        });
    }

    unselectAllServices = (callback) => {
        const { wizardCampaignState } = this.state;
        
        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                services: wizardCampaignState.services?.map(service => {
                    return {...service, selected: false};
                })
            }
        }, () => {
            if(callback) {
                callback();
            }
        });
    }

    getSelectedCoupons = mappedCoupons => {
        let coupons = [];

        for(const[key, value] of mappedCoupons?.entries()) {
            coupons.push({key, ...value});
        }

        return coupons;
    }

    onCouponsUpdated = selectedCouponsPerField => {
        const { wizardCampaignState } = this.state;

        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                selectedCoupons: this.getSelectedCoupons(selectedCouponsPerField)
            }
        });
    }

    onAddressesChange = (enteredAddresses = null) => {
        const { wizardCampaignState } = this.state;
        const { 
            minAddressesPerCampaign,
            minAddressesPerCampaignIsStrict
        } = this.props;

        if(minAddressesPerCampaignIsStrict && !userSession.isDivisionalUser()) {
            const invalidAmount = enteredAddresses < minAddressesPerCampaign;

            this.setState(({ errors }) => {
                errors.addressesErr = invalidAmount ? 'Minimum addresses not met' : null
            }, () => {
                this.setState({ lockNextStep: invalidAmount });
            });
            
        } else {
            this.setState({ lockNextStep: enteredAddresses < 1 });
        }

        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                addresses: enteredAddresses
            } 
        });
    }

    onSaveCampaignConfig = () => {
        const { wizardCampaignState } = this.state;

        let updatedSelectedBrochure = wizardCampaignState.selectedBrochure;
        
        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState, 
                selectedBrochure: {...updatedSelectedBrochure}}
        }, () => {
            this.props.saveCampaignConfig(this.state.wizardCampaignState);
            this.props.onHide();
        });
    }

    filterLicenseRequired = items => {
        const { franchise } = this.props;

        if(!items || !items.length) return [];

        if(!franchise?.licenseRequired) { return [...items]; }
        
        return items?.filter(item => {
            if(!item.licenseRequired) { return true; }
            return franchise?.licenseNumber;
        });
    }

    filterActiveItems = items => {
        if(!items || !items.length) return [];

        return items.filter(item => item.active);
    }

    initSelectedBrochure = () => {
        const { 
            selectedBrochure,
            brochures
        } = this.props.campaign;

        if(!Object.keys(selectedBrochure).length) {
            return this.filterLicenseRequired(brochures)[0];
        }
        
        return selectedBrochure;
    }
    
    initBrochures = () => {
        const { brochures } = this.props.campaign;

        const availableBrochures = this.filterLicenseRequired(brochures);

        if(!Object.keys(this.props.campaign?.selectedBrochure).length) {
            let brochuresWithDefault = [...availableBrochures];
            brochuresWithDefault[0].selected = true;
            return brochuresWithDefault;
        }

        return [...availableBrochures];
    }

    mapStepsToIndex = (steps) => {
        let mappedSteps = new Map();
        let stepIndex = 0;

        steps?.map(step => {
            mappedSteps.set(stepIndex, step);
            stepIndex += 1;
            return stepIndex;
        });

        return mappedSteps;
    }
    onChangeNoCoupon = (noCouponValue) => {
        const { wizardCampaignState } = this.state;
        document.getElementById("campaignConfigStep3").style.display = noCouponValue ?'none':'';
        if(!noCouponValue){
            this.setState({ 
                wizardCampaignState: {
                    ...wizardCampaignState,
                    is_no_coupon :1,
                    selectedBrochure:{...wizardCampaignState?.selectedBrochure,coupons:wizardCampaignState?.selectedBrochure?.coupons?.map(coupon => {
                        return {...coupon,selected:false}
                })},
                    selectedCoupons :[]
                },
            }, () => {
                this.setState({ lockNextStep: false });
              });
        }else{
            this.setState({ 
                wizardCampaignState: {
                    ...wizardCampaignState,
                    is_no_coupon :0,
                    selectedBrochure:{...wizardCampaignState?.selectedBrochure,coupons:wizardCampaignState?.selectedBrochure?.coupons?.map(coupon => {
                        return {...coupon}
                })},
                }
            }, () => {
                let isCouponSelected = wizardCampaignState?.selectedBrochure?.coupons?.find(({ selected }) => selected);
                isCouponSelected ?this.setState({ lockNextStep: false }) :this.setState({ lockNextStep: true });
              });
           
        }
    }
    onChangeNoBadge = (noBadgeValue) => {
        let { wizardCampaignState } = this.state;
        document.getElementById("campaignConfigStep5").style.display = noBadgeValue ?'none':'';

        if(!noBadgeValue){
            this.setState({ 
                wizardCampaignState: 
                {
                    ...wizardCampaignState,
                    selectedBrochure:{...wizardCampaignState?.selectedBrochure,badges:wizardCampaignState?.selectedBrochure?.badges?.map(badge => {
                        return {...badge,selected:false}
                    })},
                    is_no_badge :1,
                    selectedBadges :[]
                },
                lockNextStep: false
            });
        } else{
            Object.assign(wizardCampaignState, {
                    ...wizardCampaignState,
                    selectedBrochure:{...wizardCampaignState?.selectedBrochure,badges:wizardCampaignState?.selectedBrochure?.badges?.map(badge => {
                        return {...badge}
                    })},
                    is_no_badge :0,
                }
            );
            let isBadgeSelected = wizardCampaignState?.selectedBrochure?.badges?.find(({ selected }) => selected);
            if(isBadgeSelected)
            {
                this.setState({wizardCampaignState, lockNextStep: false });
            } else { 
                this.setState({wizardCampaignState, lockNextStep: true });
            }
        }
    }

    onSetBadgeSelected = (badgeId, selected, callback) => {
        const { wizardCampaignState } = this.state;
        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                selectedBrochure:{...wizardCampaignState?.selectedBrochure,badges:wizardCampaignState?.selectedBrochure?.badges?.map(badge => {
                    if(badge.badgeId === badgeId) {
                        return {...badge, selected: selected}
                    }

                    return {...badge};
                })},
                badges: wizardCampaignState.badges?.map(badge => {
                    if(badge.badgeId === badgeId) {
                        return {...badge, selected: selected}
                    }

                    return {...badge};
                })
            }
        }, () => {
            if(callback) {
                callback();
            }
        });
    }
    
    unselectAllBadges = (callback) => {
        const { wizardCampaignState } = this.state;
        
        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                selectedBrochure:{...wizardCampaignState?.selectedBrochure,badges:wizardCampaignState?.selectedBrochure?.badges?.map(badge => {
                  //  if(badge.badgeId === badgeId) {
                        return {...badge, selected: false}
                    //}

                    return {...badge};
                })},
                badges: wizardCampaignState.badges?.map(badge => {
                    return {...badge, selected: false};
                })
            }
        }, () => {
            if(callback) {
                callback();
            }
        });
    }
    onExpirationDateSelect = (expDate) => {
        const { wizardCampaignState } = this.state;
        this.setState({ 
            wizardCampaignState: {
                ...wizardCampaignState,
                expiration_date :expDate,
            },
        });
    }
    setWizardSteps = () => {
        const {
            servicesRequired,
            minAddressesPerCampaign
        } = this.props;

        const {
            campaignId,
            brochures,
            selectedBrochure,
            services,
            addresses,
            selectedCoupons,
            is_no_coupon,
            is_no_badge,
            selectedBadges,
            expiration_date
        } = this.state?.wizardCampaignState || {};
        const {
            minServices,
            maxServices,
            numberOfCoupons,
            coupons,
            badges,
            maxBadges,
            minBadges
        } = this.state?.wizardCampaignState?.selectedBrochure || {};
        let wizardSteps = [];
        wizardSteps.push({
            title: 'Select a Brochure',
            content: (
                <div id='campaignConfigStep1'>
                    <BrochureSelection 
                        campaignId={campaignId}
                        brochures={this.filterActiveItems(brochures)}
                        selectedBrochure={selectedBrochure}
                        onBrochureSelected={this.onBrochureSelected}
                        onLockNextStep={this.onLockNextStep}
                    />
                </div>
            ),
            id: CAMPAIGN_ITEM_SELECT_BROCHURE
        });
        
        if(servicesRequired) {
            wizardSteps.push({
                title: 'Select Services',
                content: (
                    <div id='campaignConfigStep2'>
                        <ServiceSelection 
                            campaignId={campaignId}
                            services={this.filterLicenseRequired(this.filterActiveItems(services))}
                            minServices={minServices}
                            maxServices={maxServices}
                            onSetServiceSelected={this.onSetServiceSelected}
                            onLockNextStep={this.onLockNextStep}
                            unselectAllServices={this.unselectAllServices}
                        />
                    </div>
                ),
                id: CAMPAIGN_ITEM_SELECT_SERVICES
            });
        }
        let couponLabel = !is_no_coupon ? 'Yes' :'No';
        let showExpDateField = userSession.isMSQUser() ? true : false;

        wizardSteps.push({
            title: 'Select Coupons',
            content: (
                <>  
                    <div class="row ">
                        <div class="col-2 offset-4">
                            <b>Use Coupon</b>
                        </div>
                        <div class="col-2">
                            <Switch
                                id={`no-coupon-active`}
                                label={couponLabel}
                                labelTop={false}
                                checked={!is_no_coupon}
                                onToggle={(checked) => this.onChangeNoCoupon(checked)} />
                        </div>
                    </div>
                    <div className={`d-flex flex-column align-items-center coupon-exp-date ${showExpDateField ? '':'hidden'}`} >
                        <div class="row w-75 pb-4 pt-3 ">
                            <div class="mt-2 col-lg-12">
                                <div class="d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                    <Input
                                        label='Coupon expiration date'
                                        value={formatDate.UTCtoISOFormat(expiration_date)}
                                        type='date'
                                        min={moment().format("YYYY-MM-DD")}
                                        labelStyling='font-size-16 font-family-bold'
                                        onChange={(e) => this.onExpirationDateSelect(e.target.value)}
                                        containerStyling='w-100'
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  id='campaignConfigStep3' style={{display :is_no_coupon ?"none":""}}>
                        <CouponSelection
                                prevSelectedCoupons={selectedCoupons}
                                brochureCoupons={this.filterLicenseRequired(coupons)}
                                numberOfCoupons={numberOfCoupons}
                                onCouponsUpdated={this.onCouponsUpdated}
                                onLockNextStep={this.onLockNextStep}
                                isNoCoupon={is_no_coupon} />
                    </div>
                </>
            ),
            id: CAMPAIGN_ITEM_SELECT_COUPONS
        });
        let badgeLabel = !is_no_badge ? 'Yes' :'No';
        wizardSteps.push({
            title: 'Select Badges',
            content: (
                <>
                    <div class="row ">
                        <div class="col-2 offset-4">
                            <b>Use Badge</b>
                        </div>
                        <div class="col-2">
                            <Switch
                                    id={`no-badge-active`}
                                    label={badgeLabel}
                                    labelTop={false}
                                    checked={!is_no_badge}
                                    onToggle={(checked) => this.onChangeNoBadge(checked)} />
                        </div>
                    </div>
                    <div  id='campaignConfigStep5' style={{display :is_no_badge ?"none":""}}>
                        <BadgeSelection
                            campaignId={campaignId}
                            badges={badges}
                            onSetBadgeSelected={this.onSetBadgeSelected}
                            maxBadges={maxBadges}
                            minBadges={minBadges}
                            onLockNextStep={this.onLockNextStep}
                            selectedBadges={selectedBadges}
                            unselectAllBadges={this.unselectAllBadges}
                        />
                    </div>
                </>
            ),
            id: CAMPAIGN_ITEM_SELECT_BADGES
        });
        if(!userSession.isMSQUser()){
            wizardSteps.push({
                title: 'Specify the number of addresses',
                content: (
                    <div id='campaignConfigStep4'>
                        <AddressesSelection
                            addresses={Number(addresses)}
                            minAddresses={minAddressesPerCampaign}
                            onAddressesChange={this.onAddressesChange}
                            error={this.state.errors.addressesErr ? true : false}
                            errorMsg={this.state.errors.addressesErr}
                            onLockNextStep={this.onLockNextStep}
                        />
                    </div>
                ),
                id: CAMPAIGN_ITEM_ADD_ADDRESSES
            });
        }
        
        return wizardSteps;
    }

    componentDidMount() {
        this.setState({ 
            wizardCampaignState: {
                ...this.props.campaign,
                selectedBrochure: this.initSelectedBrochure(),
                brochures: this.initBrochures()
            }
        });
    }

    render() {      
        return (
            <CampaignWizardForm 
                campaignName={this.props.campaign.campaignName}
                steps={this.setWizardSteps()}
                show={this.props.show}
                onHide={this.props.onHide}
                onComplete={this.onSaveCampaignConfig}
                formSteps={this.mapStepsToIndex(this.setWizardSteps())}
                selectedStepId={this.props.selectedFieldId}
                lockNextStep={this.state.lockNextStep}
            />
        );
    }
}

const mapStateToProps = state => ({
    franchise: state.franchise,
    servicesRequired: state.mailingPlan?.templateBusinessRules?.requireServices?.active,
    minAddressesPerCampaign: state.mailingPlan?.templateBusinessRules?.minimumAddressesPerCampaign?.calculatedValue || 0,
    minAddressesPerCampaignIsStrict: state.mailingPlan?.templateBusinessRules?.minimumAddressesPerCampaign?.strict
});

export default connect(mapStateToProps, {
    saveCampaignConfig
})(CampaignWizardConfig);

import { 
    DELETE_BROCHURE_LOADING,
    GET_BROCHURES_FOR_CONFIG_LIST,
    GET_BROCHURES_FOR_CONFIG_LIST_LOADING,
    SAVE_BROCHURE_LOADING,
    UPDATE_BROCHURE_LOADING
} from 'actions/types';

const brochuresConfigReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_BROCHURES_FOR_CONFIG_LIST:
            return { ...state, brochures: [...action.payload] };

        case GET_BROCHURES_FOR_CONFIG_LIST_LOADING:
            return { ...state, loading: action.payload };

        case SAVE_BROCHURE_LOADING:
            return { ...state, savingBrochure: action.payload };

        case UPDATE_BROCHURE_LOADING:
            return { ...state, updatingBrochure: action.payload };

        case DELETE_BROCHURE_LOADING:
            return { ...state, deletingBrochure: action.payload };

        default:
            return state;
    }
}

export default brochuresConfigReducer;
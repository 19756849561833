const planStatusWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    return {
        id: data?.id,
        name: data?.name,
        description: data?.description,
    }
}

export default planStatusWrapper;
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import userSession from 'utils/userSession';

export class BadgeSelection extends Component {
    state = {
        selectedBadgesCount: 0
    }

    static propTypes = {
        badges: PropTypes.array,
        onSetBadgeSelected: PropTypes.func,
        minBadges: PropTypes.number,
        maxBadges: PropTypes.number,
        onLockNextStep: PropTypes.func,
        unselectAllBadges: PropTypes.func
    }

    countServicesSelected = () => {
        const { badges } = this.props;

        let badgesCount = 0;
        badges?.forEach(badge => {
            if(badge.selected) {
                badgesCount += 1;
            }
        });

        return badgesCount;
    }

    onUpdateSelectedBadgesCounter = (valueToAdd) => {
        const {
            minBadges,
            maxBadges,
            onLockNextStep
        } = this.props;

        this.setState({ selectedBadgesCount: this.state.selectedBadgesCount + valueToAdd }, () => {
            if(!minBadges && !maxBadges) {
                onLockNextStep(false);
            } else if(maxBadges && this.state.selectedBadgesCount > maxBadges) {
                onLockNextStep(true);
            } else if(minBadges && this.state.selectedBadgesCount < minBadges) {
                onLockNextStep(true);
            } else if(maxBadges==1 && this.state.selectedBadgesCount != maxBadges) {
                onLockNextStep(true);
            }else {
                onLockNextStep(false);
            }
        });
        
    }

    isValidServiceCount = () => {
        const {
            minBadges,
            maxBadges
        } = this.props;
        
        const badgesCount = this.countServicesSelected();

        if(!minBadges && !maxBadges) { 
            return true; 
        }

        if(minBadges && maxBadges) {
            return badgesCount >= minBadges && badgesCount <= maxBadges;
        }

        if(minBadges) {
            return badgesCount >= minBadges;
        }

        return badgesCount <= maxBadges;
    }

    isSelectionComplete = () => {
        const {
            minBadges,
            maxBadges
        } = this.props;
        
        const { selectedBadgesCount } = this.state;

        if(!minBadges && !maxBadges) {
            return false;
        }

        if(minBadges && maxBadges) {
            return selectedBadgesCount >= minBadges && selectedBadgesCount <= maxBadges;
        }

        if(minBadges && selectedBadgesCount >= minBadges) {
            return true;
        }

        if(maxBadges && selectedBadgesCount === maxBadges) {
            return true;
        }

        return false;
    }

    isDisabledService = () => {
        const { 
            minBadges,
            maxBadges,
        } = this.props;

        const { selectedBadgesCount } = this.state;
        if(!minBadges && !maxBadges) {
            return false;
        }

        if(
            (minBadges === 1 && maxBadges === 1) ||
            (!minBadges && maxBadges === 1) ||
            (minBadges === 1 && !minBadges) ||
            (minBadges && !maxBadges)
        ) {
            return false;
        }

        if(maxBadges && selectedBadgesCount < maxBadges) {
            return false;
        }

        return true;
    }

    onSingleBadge = (badgeId, selected) => {
        const { selectedBadgesCount } = this.state;
        const {
            onSetBadgeSelected,
            unselectAllBadges
        } = this.props;

        if(selected) {
            onSetBadgeSelected(badgeId, false);
            this.onUpdateSelectedBadgesCounter(-1);
        } else {
            unselectAllBadges(() => {
                onSetBadgeSelected(badgeId, true, () => {
                    if(selectedBadgesCount === 0) {
                        this.onUpdateSelectedBadgesCounter(1);
                    }
                });
            });
        }
    }

    onUnlimitedBadges = (badgeId, selected) => {
        const { onSetBadgeSelected } = this.props;
        if(selected) {
            onSetBadgeSelected(badgeId, false);
            this.onUpdateSelectedBadgesCounter(-1);
        } else {
            onSetBadgeSelected(badgeId, true, () => {
                this.onUpdateSelectedBadgesCounter(1);
            });
        }
    }

    onBadgesRange = (badgeId, selected) => {
        const { selectedBadgesCount } = this.state;
        const { 
            maxBadges,
            onSetBadgeSelected 
        } = this.props;

        if(selected) {
            onSetBadgeSelected(badgeId, false, () => {
                this.onUpdateSelectedBadgesCounter(-1);
            });
        } else {
            if(!maxBadges || selectedBadgesCount < maxBadges) {
                onSetBadgeSelected(badgeId, true, () => {
                    this.onUpdateSelectedBadgesCounter(1);
                });
            }
        }
    }

    onBadgeClicked = (badgeId, selected) => {
        const { maxBadges } = this.props;

        if(maxBadges === 1) {
            this.onSingleBadge(badgeId, selected);
        } else if(!maxBadges) {
            this.onUnlimitedBadges(badgeId, selected);
        } else {
            this.onBadgesRange(badgeId, selected);
        }
    }

    renderBadgeCounter = () => {
        const {
            minBadges,
            maxBadges
        } = this.props;

        if(minBadges && maxBadges) {
            return (`${minBadges} Min / ${maxBadges} Max Badges`);
        }

        if(maxBadges) {
            return (`${maxBadges} Max Badges`);
        }

        if(minBadges) {
            return (`${minBadges} Min Badges`);
        }
    }

    renderBadges = () => {
        const { 
            badges
        } = this.props;
        if(!badges.length) {
            return <h4>No Badges available</h4>;
        }

        return badges.map(({ badgeId, badgeName, selected }) => {
            return (
                <div 
                    key={badgeId} 
                    className={`
                        service-item rounded border 
                        ${selected ? 'selected' : ''}
                        ${this.isDisabledService() ? 'disabled' : ''}
                    `.trim()}
                    onClick={() => this.onBadgeClicked(badgeId, selected)}
                >
                    {badgeName}
                </div>
            );
        });
    }

    componentDidMount() {
        const result = (this.props.badges)
        .map(({ 'badgeId': badgeId }) => badgeId)
        .filter(value => (this.props.selectedBadges)
            .map(({ 'badgeId': badgeId }) => badgeId)
            .includes(value)
    );
    (this.props.badges).map(el => {
        if((result).includes(el.badgeId )){
            el.selected = true;
        }
    });
        this.props.onLockNextStep(!this.isValidServiceCount());
        this.setState({ selectedBadgesCount: this.countServicesSelected() });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedBadges !== null &&
           prevProps.selectedBadges !== this.props.selectedBadges)
        {
            this.setState({ selectedBadgesCount:0 });
        }
    }

    render() {
        return (
            <Fragment>
                <div className={`services-counter ${this.isSelectionComplete() ? 'completed' : ''} ${userSession.isMTUser() ? '' : 'hidden'}`.trim()  }>
                    {this.renderBadgeCounter()}
                </div>
                { !userSession.isMTUser() &&
                    <br></br>
                }
                <div className='services-grid'>
                    {this.renderBadges()}
                </div>
            </Fragment>
        );
    }
}

export default BadgeSelection;
import { OverlayTrigger, Tooltip, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
    LMPBP_SET_HAVE_UNSAVED_CHANGES
} from 'actions/types';

const CustomTabHeader = props => {
    const dispatch = useDispatch();
    return props.isVisible ? <Nav.Item>
        <Nav.Link eventKey={props.tab.index} className="nav-item" onClick={()=> dispatch({type:LMPBP_SET_HAVE_UNSAVED_CHANGES, payload: false}) }>
            <OverlayTrigger  
                placement="right"
                overlay={(tooltipProps) => 
                    {return <Tooltip id={`${props.subclass}-tab-tooltip-${props.tab.index}`} {...tooltipProps}>{props.tab.name}</Tooltip>}
                }
            >
                <div
                    id={`${props.subclass}-tab-${props.tab.index}`}
                    className={`${props.subclass}-tab-name`}
                >
                    <p className="text-overflow">{props.tab.name}</p>
                    <div className={`${props.subclass}-tab-underline`}></div>
                </div>
            </OverlayTrigger>
        </Nav.Link>
    </Nav.Item> : null;
};

export default CustomTabHeader;
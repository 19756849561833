import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CouponFields from './CouponFields';

export class CouponSelection extends Component {
    state = {
        couponFields: null,
        selectedCouponsPerField: null
    }

    static propTypes = {
        prevSelectedCoupons: PropTypes.array,
        brochureCoupons: PropTypes.array,
        numberOfCoupons: PropTypes.number,
        onCouponsUpdated: PropTypes.func,
        onLockNextStep: PropTypes.func,
        isNoCoupon: PropTypes.bool
    }

    countSelectedDiscounts = () => {
        const { couponFields } = this.state;
        let discountCounter = 0;

        if(!couponFields) { return 0; }

        for(const[, couponField] of couponFields?.entries()) {
            if(couponField?.selectedCoupon?.discount?.discountId) {
                discountCounter += 1;
            }
        }

        return discountCounter;
    }

    completedCouponSelection = () => {
        const { couponFields } = this.state;
        const { numberOfCoupons } = this.props;
        let discountCounter = this.countSelectedDiscounts();
        let couponCounter = couponFields?.size;

        return (
            couponCounter === numberOfCoupons && 
            couponCounter === discountCounter
        );
    }

    getSelectedCoupons = () => {
        const { selectedCouponsPerField } = this.state;
        let coupons = [];

        for(const[key, value] of selectedCouponsPerField?.entries()) {
            coupons.push({key, ...value});
        }

        return coupons;
    }

    getCouponDiscounts = couponId => {
        const { brochureCoupons } = this.props;
        let discounts = [];

        if(!couponId) return [];

        brochureCoupons?.forEach(coupon => {
            if(Number(coupon.couponId) === Number(couponId)) {
                discounts = coupon.discounts; 
            }
        });

        return [...discounts] || [];
    }

    filterSelectedCoupons = (conserveId) => {
        const { brochureCoupons } = this.props;
        let selectedCoupons = this.getSelectedCoupons();

        return brochureCoupons?.filter(coupon => {
            let available = true;

            selectedCoupons?.forEach(({ couponId }) => {
                if(couponId === coupon.couponId && conserveId !== couponId) {
                    available = false;
                }
            });

            return available;
        });
    }

    onUpdateCouponFields = () => {
        const { onCouponsUpdated, onLockNextStep } = this.props;
        const { selectedCouponsPerField } = this.state;

        onCouponsUpdated(selectedCouponsPerField);
        this.setState({ couponFields: this.generateCouponFields()}, () => {
            onLockNextStep(!this.completedCouponSelection());
        });
    }

    onCouponSelected = (fieldId, selectedId) => {
        const { brochureCoupons } = this.props;
        const { selectedCouponsPerField } = this.state;

        let selectedCoupon = null;
        brochureCoupons?.forEach(coupon => {
            if(coupon.couponId === selectedId) {
                selectedCoupon = {...coupon};
            }
        });

        this.setState({ 
            selectedCouponsPerField: new Map(selectedCouponsPerField.set(fieldId, {...selectedCoupon, discount: null})) 
        }, () => this.onUpdateCouponFields());
    }

    getSelectedDiscount = (couponId, discountId) => {
        return this.getCouponDiscounts(couponId)?.filter(discount => {
            if(discount.discountId === discountId) {
                return true;
            }

            return false;
        })[0];
    }

    onDiscountSelected = (fieldId, couponId, discountId) => {
        const { selectedCouponsPerField } = this.state;

        this.setState({ 
            selectedCouponsPerField: new Map(selectedCouponsPerField.set(fieldId, {
                ...selectedCouponsPerField.get(fieldId),
                discount: this.getSelectedDiscount(couponId, discountId)
            })) 
        }, () => this.onUpdateCouponFields());
    }

    initSelectedCouponsPerField = () => {
        const { prevSelectedCoupons } = this.props;
        const { numberOfCoupons } = this.props;

        let mappedSelectedCoupons = new Map();

        for(let i = 0; i < numberOfCoupons; i++) {
            mappedSelectedCoupons.set(i, 
                prevSelectedCoupons[i]?.couponId ? 
                    {
                        ...prevSelectedCoupons[i],
                        discount: prevSelectedCoupons[i]?.discount 
                    } 
                : 
                    null
            );
        }

        return mappedSelectedCoupons;
    }

    generateCouponFields = () => {
        const {
            selectedCouponsPerField
        } = this.state;

        const { numberOfCoupons } = this.props;

        let couponFields = new Map();

        if(!selectedCouponsPerField) { return new Map(); }

        for(let i = 0; i < numberOfCoupons; i++) {
            let selectedCoupon = selectedCouponsPerField?.get(i);

            couponFields.set(i, {
                availableCoupons: this.filterSelectedCoupons(selectedCoupon?.couponId),
                selectedCoupon: selectedCoupon?.couponId ? selectedCoupon : null,
            });
        }

        return new Map(couponFields);
    }

    renderCouponFields = (couponFields) => {
        let couponFieldsArray = [];

        if(couponFields) {
            for(const[key, fieldData] of couponFields?.entries()) {
                couponFieldsArray.push({key, fieldData});
            }
        }
        
        if(!couponFieldsArray.length) {
            return <h4>No Coupons available</h4>;
        }

        return couponFieldsArray.map(({key, fieldData}) => {
            return (
                <div key={key} className='row w-75 pb-4 pt-3 border-bottom'>
                    <CouponFields
                        fieldId={key}
                        coupons={fieldData?.availableCoupons}
                        selectedCoupon={fieldData?.selectedCoupon}
                        onCouponSelected={this.onCouponSelected}
                        onDiscountSelected={this.onDiscountSelected}
                    />
                </div>
            );
        });
    }

    componentDidMount() {
        this.setState({ 
            selectedCouponsPerField: this.initSelectedCouponsPerField()
        }, () => {
            this.setState({ couponFields: this.generateCouponFields() }, () => {
                if(this.props.isNoCoupon){
                    this.props.onLockNextStep(false);
                }else{
                    this.props.onLockNextStep(!this.completedCouponSelection());
                }
            });
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.prevSelectedCoupons !== this.props.prevSelectedCoupons) {
            this.setState({ 
                selectedCouponsPerField: this.initSelectedCouponsPerField()
            }, () => {
                this.setState({ couponFields: this.generateCouponFields() }, () => {
                    if(this.props.isNoCoupon){
                        this.props.onLockNextStep(false);
                    }else{
                        this.props.onLockNextStep(!this.completedCouponSelection());
                    }
                });
            });
        }
    }
    render() {
        return (
            <div className='d-flex flex-column align-items-center'>
                {this.renderCouponFields(this.state.couponFields)}
            </div>
        );
    }
}

export default CouponSelection;
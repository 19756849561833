import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';

import Table from 'components/common/table/Table';
import Panel from 'components/common/panel/Panel';
import Button from 'components/common/button/Button';
import ImportAddressesModal from './ImportAddressesModal';

import { setCurrentRoute as setCurrentRouteAction } from 'actions/utilityActions';
import {
    deleteAddressesFile as deleteAddressesFileAction,
    downloadAddressesFile as downloadAddressesFileAction,
    getAddressesImportsLog as getAddressesImportsLogAction,
    initializeAwsIoT
} from 'actions/importAddressesActions';
import {
    getFranchises as getFranchisesAction,
    setMailingPlansFranchiseFilter as setMailingPlansFranchiseFilterAction,
} from 'actions/dashboardActions';
import importsColumns from './config/importsColumns';
import CustomSelect from 'components/lmpbp/custom-inputs/CustomSelect';
export class ImportAddresses extends Component {
    state = {
        showImportModal: false,
        showDeleteConfForRow: null,
        deletingAddresses: false,
        iotEndpoint: null,
        selectedFranchise: null,
    }

    onOpenImportModal = () => {
        this.setState({ showImportModal: true });
    }

    onCloseImportModal = () => {
        const { getAddressesImportsLog } = this.props;

        this.setState({ showImportModal: false });
        getAddressesImportsLog();
    }

    onDelete = rowId => {
        this.setState({ showDeleteConfForRow: rowId });
    }

    onCancelDelete = () => {
        this.setState({ showDeleteConfForRow: null });
    }

    onConfirmDelete = importLogId => {
        this.props.deleteAddressesFile(importLogId);
    }

    wrapActions = importsData => {
        if (!importsData || !importsData.length) return [];

        const {
            deletingAddressesFile,
            downloadAddressesFile
        } = this.props;
        const { showDeleteConfForRow } = this.state;

        return importsData.map(log => {
            return {
                ...log,
                showDeleteConfForRow,
                deletingAddresses: deletingAddressesFile,
                onDelete: this.onDelete,
                onCancelDelete: this.onCancelDelete,
                onConfirmDelete: this.onConfirmDelete,
                onDownload: downloadAddressesFile
            }
        }) || [];
    }

    generateFranchiseOptions = () => {
        const { franchises } = this.props;
        if (!franchises || !franchises.length) return [];
        const result = franchises.map(f => {
            return {
                label: `${f.franchiseExternalId} - ${f.franchiseCity}`,
                value: f.franchiseId,
            };
        });
        result.unshift({
            label: 'All',
            value: null,
        });
        return result;
    };

    handleChange = (selectedFranchise) => {
        const { getAddressesImportsLog } = this.props;
        this.setState(prevState => ({
            ...prevState,
            selectedFranchise: selectedFranchise
        }));
        getAddressesImportsLog({ franchiseId: selectedFranchise.value });
    };

    componentDidMount = async () => {
        const {
            setCurrentRoute,
            getAddressesImportsLog,
            getFranchises,
            match
        } = this.props;

        setCurrentRoute(match);
        getAddressesImportsLog();
        getFranchises();
        const iotEndpoint = await initializeAwsIoT(store.dispatch);
        this.setState({ iotEndpoint });
    }

    componentDidUpdate() {
        this.props.setCurrentRoute(this.props.match);
    }


    render() {
        const {
            importsLog,
            loadingImportsLog,
        } = this.props;
        const {
            showImportModal,
            iotEndpoint,
            selectedFranchise,
        } = this.state;

        return (
            <Panel
                header='Address List'
                headerStyling={{ fontSize: '20px' }}
                refreshing={loadingImportsLog && importsLog?.length}
            >
                <ImportAddressesModal
                    show={showImportModal}
                    iotEndpoint={iotEndpoint}
                    onClose={() => this.onCloseImportModal()}
                />
                <div className='d-flex mb-3 pb-3'>
                    <div className="d-flex flex-column">
                        <div className='d-flex align-items-center'>
                            <i className="mr-2 brand-primary-color font-size-20 fas fa-globe"></i>
                            <CustomSelect
                                id='dashboard-franchise-id-filter'
                                label='Franchise'
                                value={ selectedFranchise || this.generateFranchiseOptions()[0]?.value }
                                placeholder='Select Franchise'
                                options={this.generateFranchiseOptions()}
                                onChange={this.handleChange}
                                style={{ minWidth: '300px' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex justify-content-end w-100'>
                        <Button
                            btnType='primary'
                            label='Import List'
                            action={() => this.onOpenImportModal()}
                        />
                    </div>
                    <div className='mt-3'>
                        <Table
                            keyField='importLogId'
                            data={this.wrapActions(importsLog)}
                            columns={importsColumns}
                            enableExport={true}
                            enablePagination={true}
                            loading={loadingImportsLog && !importsLog?.length}
                            loadingMessage={'Loading imports log...'}
                            exportFileName={`address_imports_log.csv`}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = state => ({
    importsLog: state.addressesImport?.importsLog,
    loadingImportsLog: state.addressesImport?.loadingImportsLog,
    deletingAddressesFile: state.addressesImport?.deletingAddressesFile,
    franchises: state.dashboard?.franchises,
});

export default connect(mapStateToProps, {
    setCurrentRoute: setCurrentRouteAction,
    getAddressesImportsLog: getAddressesImportsLogAction,
    deleteAddressesFile: deleteAddressesFileAction,
    downloadAddressesFile: downloadAddressesFileAction,
    getFranchises: getFranchisesAction,
    setMailingPlansFranchiseFilter: setMailingPlansFranchiseFilterAction,

})(ImportAddresses);

import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../loader/Loader';

const AddButton = ({
    title = '',
    loading = false,
    action = null,
    disabled = false,
    ...props
}) => {
    const renderContent = () => {
        if(loading) return <Loader loadingMessage />;

        return (
            <div className='d-flex align-items-center justify-content-center'>
                <i className={`fas fa-plus ${title ? 'mr-2' : ''}`}></i>
                {
                    title &&
                        <span>{title}</span>
                }
            </div>
        );
    }
    return (
        <button 
            {...props}
            className='add-button'
            onClick={() => {
                if(disabled) return;
                if(!action) return;
                action();
            }}
        >
            {renderContent()}

        </button>
    );
}

AddButton.propTypes = {
    title: PropTypes.string,
    loading: PropTypes.bool,
    action: PropTypes.func,
    disabled: PropTypes.bool
}

export default AddButton;


import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import Button from 'components/common/button/Button';

import thumbsUp from 'assets/images/thumbs-up.png';
import ImageView from 'components/common/image-view/ImageView';

const ApproveConfirmation = ({
    onHide,
    show,
    resetMailingPlan,
    resetFranchiseInfo
}) => {
    const history = useHistory();

    const onGoToDashboard = () => {
        resetMailingPlan(null);
        resetFranchiseInfo(null);
        onHide();
        history.push('/');
    }

    return (
        <Modal show={show} onHide={onHide} className='mailing-plan-approve-confirmation'>
            <Modal.Header>
                <i onClick={() => onHide()} className='far fa-times-circle'></i>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <ImageView
                        src={thumbsUp}
                        width='250px'
                        height='250px'
                        maxWidth='250px'
                        maxHeight='250px'
                        minWidth='250px'
                        minHeight='250px'
                    />
                    <div className='confirmation-text'>
                        Mailing Plan Approved    
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button 
                    btnType='primary' 
                    label='Return to Dashboard'
                    action={() => onGoToDashboard()}
                />
            </Modal.Footer>
        </Modal>
    );
}

ApproveConfirmation.propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    resetMailingPlan: PropTypes.func.isRequired,
    resetFranchiseInfo: PropTypes.func.isRequired
}

export default ApproveConfirmation;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const HeaderBackButton = ({
    route = '/',
    label = 'Back',
    disabled = false,
    onClick = null
}) => {
    return (
        <div className='pr-3 pl-3'>
            <Link 
                to={route} 
                className='header-back-btn'
                onClick={() => {
                    if(disabled) return;
                    if(!onClick) return;
                    onClick();
                }}
            >
                <i className='fas fa-arrow-left'></i>
                <span className='header-title'>{label}</span>
            </Link>
        </div>
    );
}

HeaderBackButton.propTypes = {
    route: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}

export default HeaderBackButton;

import React, { useState, useContext, useEffect,useLayoutEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import _ from 'lodash';
import TacticEntry from './tacticEntry/TacticEntry';
import Loader from 'components/common/loader/Loader';
import ToggleButton from '../../../../common/toggle-button/ToggleButton';
import InlineNotification from 'components/common/inline-notification/InlineNotification';

import { getBudgetEntries } from 'api/lmpbp/budgetPlans';
import { BudgetPlanContext } from 'components/lmpbp/budget-plan/BudgetPlan';

import {
    closeNotification,
    showNotification,
} from 'actions/notificationActions';

import { LMPBP_GET_BUDGET_ENTRY_ERROR } from 'actions/types';

const Tactic = ({ tactic, instructions = null }) => {
    const dispatch = useDispatch();

    const { budgetPlan, onUpdateBudgetEntries, onResetBudgetEntries } =
        useContext(BudgetPlanContext);

    const [budget, setBudget] = useState(budgetPlan);
    const [expanded, setExpanded] = useState(true);
    const [entries, setEntries] = useState([]);


    const getEntries = useQuery(
        ['getEntries', tactic.id, budgetPlan.id, budgetPlan.franchiseId],
        () => getBudgetEntries({
            tacticId: tactic.id,
            budgetPlanId: budgetPlan.id,
            franchiseId: budgetPlan.franchiseId,
        }),
        {
            enabled: !!(tactic.id && budgetPlan.id && budgetPlan.franchiseId),
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_BUDGET_ENTRY_ERROR,
                        message: 'Error fetching budget entry',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: res => {
                dispatch(closeNotification(LMPBP_GET_BUDGET_ENTRY_ERROR));
                const filteredBudgetsEntries = res.filter((item) => item.budgetPlanId == budget.id);
                setEntries([...filteredBudgetsEntries]);
                filteredBudgetsEntries.forEach(e => onUpdateBudgetEntries(e, true));
            },
        }
    );

    const renderToggle = () => {
        return entries?.length >= 2 ? (
            <div className='font-size-24 cursor-pointer'>
                <ToggleButton onToggle={expanded => setExpanded(expanded)} />
            </div>
        ) : null;
    };

    const onUpdateEntry = entry => {
        setEntries(
            _.sortBy(
                [...entries.filter(({ id }) => id !== entry.id), entry],
                ['id']
            )
        );
        onUpdateBudgetEntries(entry);
    };

    useLayoutEffect(() => {
        setEntries([]);
        onResetBudgetEntries();
    }, [budgetPlan, budget]);

    useEffect(() => {
        setBudget(budgetPlan);
    },[budgetPlan]);

    const haveWebsite = () => {
        return entries.some((entry) => entry.websiteId && entry.website);
    }

    useEffect(() => {
        if(budgetPlan.id && budgetPlan.franchiseId)
            getEntries.refetch();
    }, [budgetPlan, budget]);

    const renderEntries = () => {
        if(!tactic.requiresWebsite || haveWebsite())
        {
            return <Fragment>
                {getEntries.isLoading ? (
                    <Loader loadingMessage='Loading entries...' />
                ) : (
                    <div className='d-flex flex-column w-100'>
                        {entries?.map((entry, index) => {
                            return (
                                <div className='mb-3' key={`${budgetPlan.franchiseId}-${tactic.id}-${index}`}>
                                    <TacticEntry
                                        key={`${budgetPlan.franchiseId}-${tactic.id}-${index}`}
                                        entry={entry}
                                        tactic={tactic}
                                        onUpdateEntry={onUpdateEntry}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </Fragment>
        }
    }

    return (
        <div className='tactic-container border rounded py-3 px-4'>
            <div className='d-flex align-items-center'>
                <span className='tactic-title'>
                    {tactic.displayName}
                </span>
                {tactic.isFranchiseCustom
                    && <small>&nbsp;(custom tactic)</small>}
                {renderToggle()}
            </div>
            {expanded && (
                <div className='d-flex flex-column'>
                    {
                        instructions && ( !tactic.requiresWebsite || haveWebsite() ) ? (
                            <div className='mt-2'>
                                <InlineNotification
                                    type='primary'
                                    message={instructions}
                                />
                            </div>
                        ) : null
                    }
                    {tactic.isFranchiseCustom &&
                        <InlineNotification
                            type='warning'
                            message={"Once your plan is approved for the year, no deletions can be made without contacting your Marketing Manager."}
                        />
                    }
                    {
                        tactic.requiresWebsite && !haveWebsite() ?  (
                            <div className='mt-2'>
                                <InlineNotification
                                    type='primary'
                                    message="This territory does not have a website set up for this tactic."
                                />
                            </div>
                        ) : null
                    }
                    <div
                        className={`d-flex align-items-center ${
                            !instructions ? 'mt-3' : ''
                        }`}
                    >
                        {renderEntries()}
                    </div>
                </div>
            )}
        </div>
    );
};

Tactic.propTypes = {
    tactic: PropTypes.object.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Tactic;

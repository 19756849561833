import api from 'api/lmpbp';
import handleResponse from './utils/handleResponse';

import tacticWebsiteWrapper from './utils/backend-wrappers/tacticWebsiteWrapper';
import userSession from 'utils/userSession';
import { capitalizeWords, removeSpaces } from 'utils/StringUtils';

export const getWebsites = ({ 
    tacticId,
    franchiseId
} = {}) => {
    return handleResponse({
        promise: api().get(`websites/${userSession.getBrandId()}/${franchiseId}`, {
            params: { tactic: tacticId }
        }),
        wrapper: tacticWebsiteWrapper
    });
}

export const addFranchiseCustomTactic = async ({budgetPlanId, displayName}) => {
    await handleResponse({
        promise: api().post(`/budget-plans/${budgetPlanId}/tactics`, {
            name: removeSpaces(capitalizeWords(displayName)),
            display_name: displayName,
            default_values: "",
            sorting_order: 99999,
        })
    });
}

export const deleteFranchiseCustomTactic = async ({budgetPlanId, tacticId}) => {
    await handleResponse({
        promise: api().delete(`/budget-plans/${budgetPlanId}/tactics/${tacticId}`)
    });
}
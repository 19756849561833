import React, { useContext, useState } from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AddFranchiseForm from './AddFranchiseForm';
import CustomButton from '../custom-buttons/CustomButton';
import ConfirmationModal from 'components/common/confirmation-modal/ConfirmationModal';
import IconButton from '../custom-buttons/IconButton';
import { WebsiteContext } from "./WebsiteCard";
import useDeleteFranchiseWebsite from "hooks/useDeleteFranchiseWesite";

const FranchiseItem = ({ franchise }) => {
  const [show, setShow] = useState(false);
  const { tactics } = useContext(WebsiteContext);
  const { mutate } = useDeleteFranchiseWebsite("search-websites");

  const modal =
    <ConfirmationModal
      show={show}
      type='none'
      headerType='warning'
      header='Confirm website removal from franchise'
      title='Are your sure you want to remove this website from this franchise?'
      message='This action will permanently remove this website from this franchises and its children (if it has).'
      headerIconRight='fas fa-trash-alt'
      confirmBtnLabel='Remove'
      cancelBtnLabel='Cancel'
      showConfirmBtn={true}
      showCancelBtn={true}
      onConfirm={() => {
        for (const tactic of tactics) {
          mutate({franchiseId: franchise.id, tacticId: tactic.id});
        }
        setShow(false);
      }}
      onClose={() => setShow(false)}
      centered={true}
    />;

  return (
    <div className="franchise-item">
      {modal}
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 200 }}
        overlay={
          <Popover color="info">
            <Popover.Title as="h3">Franchise Details</Popover.Title>
            <Popover.Content>
              <div>
                <strong>External ID:</strong> {franchise.externalId}
              </div>
              <div>
                <strong>Name:</strong> {franchise.name}
              </div>
              <div>
                {franchise.city && <><strong>City:</strong> {franchise.city}</>}
              </div>
              <br />
            </Popover.Content>
          </Popover>
        }
      ><span >
          {franchise.externalId}
        </span>
      </OverlayTrigger>
      <IconButton
        id="remove-franchise-website"
        iconName="fa-times"
        onClick={() => setShow(true)}
      />
    </div>
  );
}

function FranchiseWebsiteSubset({ franchiseSubset }) {
  const [addingParentFranchise, setAddingParentFranchise] = useState(false);
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ border: '1px solid #cccccc', padding: '10px', borderRadius: '4px' }}>
      <strong>Tactics that use this website</strong>
      <small className="text-muted">
        <ul>
          {franchiseSubset.tactics.map((tactic, index) => (
            <li key={index}>{tactic.displayName}</li>
          ))}
        </ul>
      </small>
      <strong>Franchises</strong>
      Parent <FranchiseItem franchise={franchiseSubset.parent} isParent />
      {franchiseSubset.children.length > 0 && "Children"}
      <ul className="list-unstyled list-inline">
        {
          franchiseSubset.children
            .map((childFranchise, index) => (
              <li key={index} className="list-inline-item"><FranchiseItem franchise={childFranchise} /></li>
            ))
        }
      </ul>
      {addingParentFranchise
        ? <AddFranchiseForm
          onClose={() => setAddingParentFranchise(false)}
          parent={franchiseSubset.parent}
          tactics={franchiseSubset.tactics}
        />
        : <CustomButton
          className="dm-button outline-primary"
          style={{ height: 'fit-content' }}
          onClick={() => setAddingParentFranchise(true)}
        >
          Link website to new child franchise
        </CustomButton>
      }
    </div>
  );
}

export default FranchiseWebsiteSubset;

const isString = (value) => {
    return typeof value === 'string';
}

const formatAddress = (
    address = '', 
    city = '',
    state = '', 
    zipCode = ''
) => {
    let completeAddress = [];

    if(address && isString(address)) completeAddress.push(address);
    if(city && isString(city)) completeAddress.push(city);
    if(state && isString(state)) completeAddress.push(state);
    if(zipCode && isString(zipCode)) completeAddress.push(zipCode);

    if(!completeAddress.length) return '';

    return completeAddress.join(', ');
}

export default formatAddress;
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip/Tooltip';

const Switch = ({
    id,
    label = '',
    labelTop = false,
    checked = false,
    disabled = false,
    onToggle = null,
    tooltip = ''
}) => {
    return (
        <div className='d-flex flex-column align-items-start'>
            {
                label && labelTop &&
                    <div className={`cursor-pointer ${tooltip && 'd-flex align-items-center'}`}>
                        <label className={`switch-label font-size-12 mb-0 ${tooltip && 'mr-2'}`} htmlFor={id}>{label}</label>
                        {
                            tooltip &&
                                <Tooltip
                                    placement='top'
                                    message={tooltip}
                                />
                        }
                    </div>
            }
            <div className='switch custom-control custom-switch'>
                <input 
                    type='checkbox' 
                    className='custom-control-input' 
                    id={id}
                    checked={checked}
                    disabled={disabled}
                    onChange={(e) => {
                        if(disabled) return;
                        if(!onToggle) return;
                        onToggle(e.target.checked);
                    }}
                />
                {
                    label &&
                        <label className='custom-control-label cursor-pointer' htmlFor={id}>
                            {
                                !labelTop &&
                                    <div className={`${tooltip && 'd-flex align-items-center'}`}>
                                        <span className={`switch-label ${tooltip && 'mr-2'}`}>{label}</span>
                                        {
                                            tooltip &&
                                                <Tooltip
                                                    placement='top'
                                                    message={tooltip}
                                                />
                                        }
                                    </div>
                            }
                        </label>
                }
            </div>
        </div>
    );
}

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelTop: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onToggle: PropTypes.func,
    tooltip: PropTypes.string
}

export default Switch;

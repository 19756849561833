import React, { createContext, useState } from 'react';
import { Card, Accordion, Container, Col, Row } from 'react-bootstrap';
import EditWebsite from './EditWebsite';
import AddButton from '../custom-buttons/AddButton/AddButton';
import AddParentFranchiseForm from './AddFranchiseGroup';
import PencilIconButton from '../custom-buttons/PencilIconButton/PencilIconbutton';
import "./FranchiseItem.scss";
import FranchiseWebsiteSubset from './FranchiseWebsiteSubset';

export const WebsiteContext = createContext();

function truncateURL(url, maxLength = 60) {
  return (url.length > maxLength) ? url.substring(0, maxLength) + '...' : url;
}

const WebsiteCard = ({ website, parent, children, tactics, template, eventKey }) => {
  const [editingUrl, setEditingUrl] = useState(false);
  const [addingFranchiseGroup, setAddingParentFranchise] = useState(false);

  return (
    <WebsiteContext.Provider value={{ template, website, tactics }}>
      <div>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={eventKey} role="button">
            {editingUrl
              ? <EditWebsite
                website={website}
                onCancel={() => setEditingUrl(false)}
              />
              : (<Container>
                <Row>
                  <Col className="d-flex justify-content-start align-items-center" >
                    <a href={website.url} target="_blank" rel="noopener noreferrer">{truncateURL(website.url)}</a>
                  </Col>
                  <Col xs={1} className="d-flex justify-content-end align-items-center"  >
                    <PencilIconButton onClick={() => setEditingUrl(true)} />
                  </Col>
                </Row>
              </Container>)
            }
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>
              <div className="d-flex justify-content-around">
                {parent &&
                  <FranchiseWebsiteSubset
                    franchiseSubset={{
                      tactics: tactics,
                      parent: parent,
                      children: children,
                    }} />
                }
                {addingFranchiseGroup
                  && <AddParentFranchiseForm
                    onClose={() => setAddingParentFranchise(false)}
                  />
                }
                {!parent && !addingFranchiseGroup &&
                  < AddButton onClick={() => setAddingParentFranchise(true)}>
                    Add parent franchise
                  </AddButton>
                }
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    </WebsiteContext.Provider >
  );
};

export default WebsiteCard;

const franchiseWrapper = franchise => {
    if(
        !franchise
        || Array.isArray(franchise)
        || typeof franchise !== 'object'
    ) return {};

    return {
        franchiseId: franchise.id,
        brandId: franchise.brand_id,
        externalId: franchise.external_id,
        name: franchise.name,
        licenseRequired: franchise.license_required ? true : false,
        licenseNumber: franchise.license_number,
        address1: franchise.address_1,
        city: franchise.city,
        state: franchise.state,
        zipCode: franchise.zip_code,
        phone: franchise.phone,
        launchYear: franchise.launch_year,
        url: franchise.url,
        email: franchise.email,
        receivingNumber: franchise.receiving_number,
        overrideLicense: franchise?.override_license
    }
}

export default franchiseWrapper;
import moment from 'moment';

const wrapper = exportData => ({
    id: exportData.id,
    addresses: exportData.addresses,
    campaignTemplateId: exportData.campaign_template_id,
    createdBy: exportData.created_by,
    createdDate: exportData.created_date,
    fileName: exportData.filename
});

const campaignExportWrapper = ({
    single: exportData => {
        if(
            !exportData
            || Array.isArray(exportData)
            || typeof exportData !== 'object'
        ) return {};

        return wrapper(exportData);
    },

    sortByDate: exportsData => {
        if(!Array.isArray(exportsData)) return [];

        return exportsData.map(exportEntry => wrapper(exportEntry))
            .sort((a, b) => {
                return new moment(b.createdDate).valueOf() - new moment(a.createdDate).valueOf();
            });
    }
});

export default campaignExportWrapper;
import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import "./PaginatedItems.scss";
import SelectReact from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';


// This function prepares the pagination items based on the active page and total pages
// by always showing 5 items and keeping the active page in the middle. Also handles the
// case when the active page is near the first or last page to show or hide navigation buttons
function preparePaginationItems(activePage, totalPages, setActivePage) {
  const totalPaginationItemsToShow = 5;
  const m = Math.floor(totalPaginationItemsToShow / 2);
  let first = Math.max(activePage - m, 1);
  let last = Math.min(activePage + m, totalPages);
  if (last - first < totalPaginationItemsToShow) {
    if (first === 1) {
      last = Math.min(totalPaginationItemsToShow, totalPages);
    }
    else {
      first = Math.max(last - totalPaginationItemsToShow + 1, 1);
    }
  }
  let result = [];
  if (first !== 1) {
    result.push(
      <Pagination.Item
        key={"<<"}
        className="pagination-item"
        onClick={() => setActivePage(1)}>
        {"<<"}
      </Pagination.Item>
    );
  }
  if (activePage !== 1) {
    result.push(
      <Pagination.Item
        key={"<"}
        className="pagination-item"
        onClick={() => setActivePage(Math.max(activePage - 1, 1))}>
        {"<"}
      </Pagination.Item>
    );
  }
  for (let number = first; number <= last; number++) {
    result.push(
      <Pagination.Item
        key={number}
        className="pagination-item"
        active={number === activePage}
        onClick={() => setActivePage(number)}>
        {number}
      </Pagination.Item>,
    );
  }
  if (activePage !== totalPages) {
    result.push(
      <Pagination.Item
        key={">"}
        className="pagination-item"
        onClick={() => setActivePage(Math.min(activePage + 1, totalPages))}>
        {">"}
      </Pagination.Item>
    );
  }
  if (last !== totalPages) {
    result.push(
      <Pagination.Item
        key={">>"}
        className="pagination-item"
        onClick={() => setActivePage(totalPages)}>
        {">>"}
      </Pagination.Item>
    );
  }
  return result;
}

const PaginatedItems = ({
  itemsPerPage,
  setItemsPerPage,
  activePage,
  setActivePage,
  totalItems,
  totalPages,
  children,
  disableSelectPerPage = false }) => {

  const paginationItems = preparePaginationItems(activePage, totalPages, setActivePage);
  const firstItemPositon = (activePage - 1) * itemsPerPage + 1;
  const lastItemPosition = totalItems ? Math.min(activePage * itemsPerPage, totalItems) : activePage * itemsPerPage;

  const handleChange = (option) => {
    setItemsPerPage(option.value);
  }

  return (
    <>
      {children}
      <Container className="mt-2">
        <Row>
          <Col xs={1} className="d-flex justify-content-center align-items-center p-0">
            <SelectReact
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              value={{ value: itemsPerPage, label: itemsPerPage }}
              options={[10, 20, 100].map((item) => ({ value: item, label: item }))}
              onChange={handleChange}
              styles={{
                control: (styles) => ({
                  ...styles,
                  width: '50px',
                  fontSize: '13px',
                })
              }}
              isDisabled ={disableSelectPerPage}
            />
          </Col>
          <Col className="d-flex justify-content-start align-items-center p-0">
            <small>Showing results {firstItemPositon} to {lastItemPosition} of {totalItems}</small>
          </Col>
          <Col className="d-flex justify-content-end">
          { totalItems !==0 &&
            <Pagination >{paginationItems}</Pagination>
          }
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default PaginatedItems;

import api from 'api/direct-mail';
import mailingPlanTemplateWrapper from 'api/direct-mail/utils/backend-wrappers/mailingPlanTemplateWrapper';
import handleResponse from 'api/direct-mail/utils/handleResponse';

import {
    notifyCreateYearlyPlanError,
    notifyCreateYearlyPlanSuccess,
    notifyGetYearlyPlansError ,
    notifyUpdateMailingPlanTemplateError,
    notifySaveNewCampaignTemplatesError
} from './notificationActions';

import {
    CREATE_YEARLY_PLAN_LOADING,
    GET_YEARLY_PLANS, 
    GET_YEARLY_PLANS_LOADING
} from './types';

import { 
    getMailingPlanTemplates, 
    savePlanTemplate ,
    updatePlanTemplate,
    copyDataFromPreviousMailingPlan
} from 'api/direct-mail/planTemplates';
import { 
    saveCampaignTemplates,
} from 'api/direct-mail/campaignTemplates';

export const getYearlyPlans = () => async dispatch => {
    dispatch(notifyGetYearlyPlansError({ display: false }));

    dispatch({
        type: GET_YEARLY_PLANS_LOADING,
        payload: true
    });

    let [yearlyPlansErr, yearlyPlans] = await getMailingPlanTemplates();
    
    handleResponse(
        api().get('/corporate/mailing-plans/templates/get')
    );

    if(yearlyPlansErr) {
        dispatch(notifyGetYearlyPlansError({ serverError: yearlyPlansErr }));
    }

    dispatch({
        type: GET_YEARLY_PLANS_LOADING,
        payload: false
    });

    return yearlyPlansErr ? 
        dispatch({ type: GET_YEARLY_PLANS, payload: null })
    :
        dispatch({
            type: GET_YEARLY_PLANS,
            payload: Array.isArray(yearlyPlans) ? yearlyPlans.map(plan => mailingPlanTemplateWrapper(plan)) : [mailingPlanTemplateWrapper(yearlyPlans)]
        });
}

export const createYearlyPlan = (planYear = null, onComplete = null,copyPlanOption) => async dispatch => {
    dispatch(notifyCreateYearlyPlanError({ display: false }));
    dispatch(notifyCreateYearlyPlanSuccess({ display: false }));

    dispatch({
        type: CREATE_YEARLY_PLAN_LOADING,
        payload: true  
    });

    const [createErr,planSaveRes] = await savePlanTemplate({
        planTemplate: {
            year: planYear,
            businessRules: {},
            sendOrder: [],
            costPerAddress: null,
            active: false
        }
    });

    if(createErr) {
        dispatch(notifyCreateYearlyPlanError({ serverError: createErr }));
    } else {
        if(copyPlanOption != null){
            //1. Save mailingtemplate Data
            let mailingPlanTemplateData = {
                year : copyPlanOption[0].year,
                businessRules : copyPlanOption[0].businessRules,
                costPerAddress : copyPlanOption[0].costPerPiece,
                sendOrder : copyPlanOption[0].sendOrder,
            }
            let copyTemplateData = {
                templateId: planSaveRes.templateID,
                planTemplate: mailingPlanTemplateData
            }
         
            const [updateErr, updateRes] = await updatePlanTemplate(copyTemplateData);
        
            if(updateErr) {
                dispatch(notifyUpdateMailingPlanTemplateError({ serverError: updateErr }));
            }
    
            //2.Campaign Data
            let newCampaigns = copyPlanOption[0].campaignTemplates.map(({ campaignName, date,lockDate }) => {
                return {
                     name:campaignName,
                     date: date,
                     lockDate: lockDate
                }
            });
            console.log("in saving campaign template");

            const [saveErr, saveRes] = await saveCampaignTemplates({
                planTemplateId: planSaveRes.templateID,
                campaigns: newCampaigns
            });
        
            if(saveErr) {
                dispatch(notifySaveNewCampaignTemplatesError({ serverError: saveErr }));
            }
            //.3 copy services,discounts,coupons,brochures

            const [copyErr,copySave] = await copyDataFromPreviousMailingPlan({
                newMailingPlanTemplateID: planSaveRes.templateID,
                oldMailingPlanTemplateID: copyPlanOption[0].id

            });
        
            if(copyErr) {
                dispatch(notifyCreateYearlyPlanError({ serverError: copyErr }));
            }

        }
        dispatch(notifyCreateYearlyPlanSuccess());
        dispatch(getYearlyPlans());
    }

    if(onComplete) onComplete();

    return dispatch({
        type: CREATE_YEARLY_PLAN_LOADING,
        payload: false
    });
}
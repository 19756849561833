import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';
import axios from 'axios';

// export const getImportsLog = () => {
//     return handleResponse(
//         api().get('/corporate/mailing-plans/imports/previous-imported')
//     );
// }
export const getImportsLog = ({
    franchiseId,
} = {}) => {
    return handleResponse(
        api().get('/corporate/mailing-plans/imports/previous-imported', {
            params: {
                franchiseId: franchiseId,
            },
        })
    );
}

export const uploadAddresses = ({
    file,
    iotEndpoint,
    onUploadProgress
} = {}) => {
    return handleResponse(
        api()
            .get('/corporate/addresses/import/url', {
                params: {
                    filename: file.name
                }
            })
            .then(({ data }) => {
                const uploadUrl = data?.data;
                const s3Config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (event) => {
                        if (onUploadProgress) {
                            const uploadPercentage = parseInt(
                                Math.round((event.loaded / event.total) * 100)
                            );

                            onUploadProgress(uploadPercentage);
                        }
                    },
                };

                return axios.put(uploadUrl, file, s3Config);
            })
            .then(() => {
                return api().post('/corporate/addresses/import/process', {
                    filename: file?.name,
                    iotEndpoint
                });
            })
    );
}

export const deleteImportedFile = ({
    importLogId
} = {}) => {
    return handleResponse(
        api().delete('/corporate/addresses/import', {
            params: {
                importLogId
            }
        })
    );
}

export const downloadImportedFile = ({
    filename
} = {}) => {
    return handleResponse(
        api().get('corporate/addresses/import', {
            params: {
                filename
            }
        })
    );
}

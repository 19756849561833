import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import ImageView from 'components/common/image-view/ImageView';
import Input from 'components/common/input/Input';
import MultiSelect from 'components/common/multi-select/MultiSelect';
import CampaignWizardConfig from './CampaignWizardConfig';
import Select from 'components/common/select/Select';
import { saveCampaignConfig } from 'actions/campaignActions';
import validateStatus from 'utils/validateStatus';

import {
    toggleCampaignSelection
} from 'actions/campaignActions';

import {
    CAMPAIGN_ITEM_SELECT_BROCHURE,
    CAMPAIGN_ITEM_SELECT_SERVICES,
    CAMPAIGN_ITEM_SELECT_COUPONS,
    CAMPAIGN_ITEM_ADD_ADDRESSES,
    CAMPAIGN_ITEM_SELECT_BADGES
} from './config/campaignFieldsIds';
import formatCurrency from 'utils/formatCurrency';
import formatDate from 'utils/formatDate';
import userSession from 'utils/userSession';

export class CampaignsListItem extends Component {
    state = {
        displayConfigWizard: false,
        selectedFieldId: null,
        selectedCampaignListId :null,
    }

    static propTypes = {
        campaign: PropTypes.object,
        campaignsList :PropTypes.array,
        onCampaignCopySelected: PropTypes.func,
    }
    filterServicesNameTags = (services = []) => {
        return services?.map(service => {
            return service.serviceName;
        });
    }

    getCouponDiscountValue = discounts => {
        let discountValue = '[Select Discount]';

        discounts?.forEach(discount => {
            if(discount.selected) {
                discountValue = discount.outputText;
            }
        })

        return discountValue;
    }

    generateCouponNameTags = (coupons = []) => {
        return coupons?.map(coupon => {
            return `${coupon.couponName} / ${coupon.discount?.outputText || '[Select Discount]'}`;
        });
    }

    getSelectedCoupons = (coupons = []) => {
        return coupons?.filter(coupon => {
            if(coupon.selected) {
                return true;
            }

            return false;
        });
    }

    enableCampaignConfig = (show = false) => {
        this.setState({ displayConfigWizard: show });
    }

    isValidServiceSelection = () => {
        const {
            selectedBrochure,
            selectedServices
        } = this.props.campaign;

        if(!selectedBrochure?.minServices && !selectedBrochure?.maxServices) {
            return true;
        }

        if(selectedBrochure?.minServices && selectedBrochure?.maxServices) {
            return (
                selectedBrochure?.minServices <= selectedServices?.length &&
                selectedServices?.length <= selectedBrochure?.maxServices
            );
        }

        if(selectedBrochure?.minServices && selectedServices?.length >= selectedBrochure?.minServices) {
            return true;
        }

        if(selectedBrochure?.maxServices && selectedServices?.length <= selectedBrochure?.maxServices) {
            return true;
        }

        return false;
    }

    onFieldSelected = (fieldId = null) => {
        if(!this.isCampaignLocked()) {
            this.enableCampaignConfig(true);
            this.setState({ selectedFieldId: fieldId });
        }
    }

    onCampaignSelected = () => {
        const { campaignId } = this.props.campaign;
        
        if(!this.isCampaignLocked()) {
            this.props.toggleCampaignSelection(campaignId);
            this.onShowHideCampaignListDropdown();
        }
    }

    isCampaignLocked = () => {
        const { lockDate } = this.props.campaign;
        const { internalUse } = this.props.campaign?.selectedBrochure;
        const { planOverrideActive ,mailingPlan} = this.props;
        if(userSession.isDivisionalUser()) {
            return !planOverrideActive;
        }
        if(userSession.isFranchiseOwer() && mailingPlan?.status && !validateStatus.isDraft(mailingPlan?.status)){
            return true;
        }
        
        if(internalUse) return true;

        if(lockDate) {
            return moment().utc().startOf('day').valueOf() > moment(lockDate).utc().valueOf();
        }

        return false;
    }

    isFieldLocked = () => {
        const { campaign } = this.props;

        if(!campaign.selected) return true;
        if(this.isCampaignLocked()) return true;
        return false;
    }

    renderLastEditDate = () => {
        const { lockDate } = this.props.campaign;

        if(!lockDate) return null;
        
        return (
            <small className='d-block'>
                Last date to edit: {formatDate.dateUTC(lockDate)}
            </small>
        );
    }
    generateCampaignList = () => {
        const { campaignsList } = this.props;
        if(!this.props.campaignsList?.length) return [];
        if(campaignsList)
        {
            let newCampaignList = campaignsList.filter( item =>(item.brochures).find(({ brochureId }) => brochureId === item.brochureId));
            return newCampaignList?.map(({ campaignId, campaignName }) => {
                return {
                    label: campaignName,
                    value: campaignId,
                    id: campaignId
                }
            });
        }
    }
    onCampaignCopySelected = copyCampaignId => {
        let selectedCopiedCampaign = this.props.campaignsList.filter(item => item.campaignId == copyCampaignId);
        //Passed expiration_date,desired_in_home_date for MSQ should not copy
        let selected_expiration_date = null;
        let selected_desired_in_home_date = null;
        if(selectedCopiedCampaign[0] && selectedCopiedCampaign[0]?.expiration_date && new Date(selectedCopiedCampaign[0]?.expiration_date) > new Date()){
            selected_expiration_date = selectedCopiedCampaign[0]?.expiration_date;
        }
        if(selectedCopiedCampaign[0] && selectedCopiedCampaign[0]?.desired_in_home_date && new Date(selectedCopiedCampaign[0]?.desired_in_home_date) > new Date()){
            selected_desired_in_home_date = selectedCopiedCampaign[0]?.desired_in_home_date;
        }

        let reslutedCopiedCampaign =   {
            selectedBrochure :selectedCopiedCampaign[0].selectedBrochure,
            selectedCoupons : selectedCopiedCampaign[0].selectedCoupons,
            selectedServices:selectedCopiedCampaign[0].selectedServices,
            selectedBadges : selectedCopiedCampaign[0].selectedBadges,
            addresses:selectedCopiedCampaign[0].addresses,
            is_no_badge:selectedCopiedCampaign[0]?.is_no_badge,
            is_no_coupon: selectedCopiedCampaign[0]?.is_no_coupon,
            services: selectedCopiedCampaign[0]?.services,
            expiration_date:selected_expiration_date,
            desired_in_home_date:selected_desired_in_home_date,
            budget_intention:selectedCopiedCampaign[0]?.budget_intention,
            piece_size:selectedCopiedCampaign[0]?.piece_size
        };
        Object.assign( this.props.campaign , reslutedCopiedCampaign) ;
        this.onCampaignSelected();
        this.onCampaignSelected();
    }
    onShowHideCampaignListDropdown = () => {
        const { campaignId } = this.props.campaign;
        if(this.state.selectedCopyCampaignOption == true){
            document.getElementById("copy-campaign-dropdwon"+campaignId).style.display = '';
        }else{
            document.getElementById("copy-campaign-dropdwon"+campaignId).style.display = 'none';
        }
    }
    getSelectedBadges = (badges = []) => {
        return badges?.filter(badge => {
            if(badge.selected) {
                return true;
            }

            return false;
        });
    }
    generateBadgeNameTags = (badges = []) => {
        return badges?.map(badge => {
           if(badge?.selected == true){
            return `${badge.badgeName}`;
           }
        });
    }
    filterBadgesNameTags = (badges = []) => {
        return badges?.map(badge => {
            return badge.badgeName;
        });
    }
    onExpirationDateSelect = (expDate) => {
        const { campaign } = this.props;
        campaign.expiration_date= expDate;
        this.setState({ expDateAfterSelect: expDate });  
    }
    onDesiredInHomeDateSelect = (selectedDate) => {
        const { campaign } = this.props;
        campaign.desired_in_home_date= selectedDate;
        this.setState({ desiredInHomeDateAfterSelect: selectedDate });  
    }
    onBudgetIntentionSelect = (selectedValue) => {
        const { campaign ,mailingPlan} = this.props;
        campaign.budget_intention= selectedValue;
        this.setState({ bIAfterSelect: selectedValue }); 
        this.props.saveCampaignConfig(mailingPlan);
    }
    onPieceSizeSelect = (selectedValue) => {
        const { campaign } = this.props;
        campaign.piece_size= selectedValue;
        this.setState({ pSizeAfterSelect: selectedValue });  
    }
    generatePieceSizes = () => {
        const { mailingPlan } = this.props;
        const pieceSizes =  mailingPlan.brochureSizes;
        let mappedSizes = pieceSizes.map(({ size, price }) => ({
            label: `${size} - $${price}`,
            value: size
        }));
        return mappedSizes;
    };
    render() {
        const {
            servicesRequired
        } = this.props;
        
        const {
            campaignId,
            campaignName,
            date,
            selected,
            selectedBrochure,
            selectedCoupons,
            selectedServices,
            addresses,
            minAddresses,
            is_no_coupon,
            selectedBadges,
            is_no_badge,
            expiration_date,
            desired_in_home_date,
            budget_intention,
            piece_size
        } = this.props.campaign;
        const { 
            costPerPiece,
            planOverrideActive
        } = this.props;
       
        const {
            selectedCampaignListId,
            selectedCopyCampaignOption,
            expDateAfterSelect,
            desiredInHomeDateAfterSelect,
            bIAfterSelect,
            pSizeAfterSelect
        } = this.state;
        let showExpDateField = userSession.isMSQUser() ? 'block' : "none";
        let hideFieldForMSQ = userSession.isMSQUser() ? 'none' : "block";
        return (
            <div className={`campaigns-list-item ${selected ? 'selected' : ''}`.trim()}>
                {
                    this.state.displayConfigWizard && 
                        <CampaignWizardConfig
                            campaign={this.props.campaign}
                            show={this.state.displayConfigWizard}
                            onHide={() => this.enableCampaignConfig(false)}
                            selectedFieldId={this.state.selectedFieldId}
                        />
                }
                <div className='item-details'>
                    {
                        this.isCampaignLocked() ?
                            <i className='fas fa-lock'></i>
                        :
                            <input 
                                type='checkbox'
                                checked={selected}
                                onChange={() => this.onCampaignSelected()}>
                            </input>
                    }
                    <div>
                        <div className='item-details m-0'>
                            <i className='far fa-calendar-alt'></i>
                            <span className='campaign-date'>{formatDate.dateUTC(date)}</span>
                            <span className='campaign-name'>{campaignName}</span>
                        </div>
                        {this.renderLastEditDate()}
                    </div>
                </div>
                <div className='item-details'>
                    {
                        this.isCampaignLocked() ?
                           ""
                        :
                        <>
                            <input
                                type='checkbox'
                                disabled={this.isFieldLocked()}
                                checked={!selected && planOverrideActive ?false:selectedCopyCampaignOption }
                                onChange={(e) => {
                                                this.setState({
                                                    selectedCopyCampaignOption: e.target.checked
                                                }, () => {
                                                    this.onShowHideCampaignListDropdown();
                                                });
                                            } } >
                            </input>
                            <div className='item-details m-0'>
                                <span className='campaign-date'>Copy Existing Campaign</span>
                                <Select id = {"copy-campaign-dropdwon" + campaignId}
                                    value = {selectedCampaignListId}
                                    placeholder='Select Campaign'
                                    labelStyling='font-size-16 font-family-bold'
                                    options={this.generateCampaignList()}
                                    onChange={(e) => {
                                        this.setState({
                                            selectedCampaignListId: e.target.value
                                        }, () => {
                                            this.onCampaignCopySelected(Number(e.target.value));
                                        });
                                    } } style={{display:"none"}} />
                            </div>
                        </>
                    }
                </div>
                <div className='item-config border-top'>
                    <div className='campaign-brochure'>
                        <div className='campaign-brochure-img'>
                            <ImageView 
                                imgStyling={{maxWidth: '85px', maxHeight: '85px'}}
                                src={selectedBrochure?.src}
                                fit='cover'
                                clickable={true}
                                onClick={(e) => {
                                    if(!this.isCampaignLocked()) {
                                        this.onFieldSelected(e.currentTarget.id);
                                        if(!selected) {
                                            this.onCampaignSelected();
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-grow-1'>
                        <div className='d-flex flex-wrap'>
                            <div className='p-2 flex-grow-1' style={{display:showExpDateField}}>
                                <Input 
                                        disabled={this.isFieldLocked()}
                                        label='Intended Spend'
                                        value={budget_intention ?budget_intention:bIAfterSelect}
                                        type='number'
                                        containerStyling='w-100'
                                        min={0}
                                        onChange={(e) => this.onBudgetIntentionSelect(e.target.value)}
                                    />
                            </div>
                            <div className='p-2 flex-grow-1' style={{display:showExpDateField}}>
                                <Select 
                                    value={piece_size ?piece_size:pSizeAfterSelect}
                                    disabled={this.isFieldLocked()}
                                    label='Piece size - price'
                                    placeholder='Select Piece Size'
                                    options={this.generatePieceSizes()}
                                    onChange={(e) => this.onPieceSizeSelect(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='p-2 flex-grow-1'>
                                <Input 
                                    id={CAMPAIGN_ITEM_SELECT_BROCHURE}
                                    disabled={this.isFieldLocked()}
                                    label={'Select a Brochure'}
                                    type='button'
                                    selectionName={selectedBrochure?.brochureName}
                                    onClick={(e) => this.onFieldSelected(e.currentTarget.id)}
                                    error={selected && !selectedBrochure?.brochureId}
                                    errorMsg={'Brochure not selected'}
                                />
                            </div>
                            <div className='p-2 flex-grow-1' style={{display:showExpDateField}}>
                                <Input
                                    disabled={this.isFieldLocked()}
                                    label='Desired In Home Date'
                                    value={desired_in_home_date ?formatDate.UTCtoISOFormat(desired_in_home_date):desiredInHomeDateAfterSelect}
                                    type='date'
                                    min={moment().format("YYYY-MM-DD")}
                                    onChange={(e) => this.onDesiredInHomeDateSelect(e.target.value)}
                                    containerStyling='w-100'
                                />
                            </div>
                            <div className='p-2' style={{display:hideFieldForMSQ}}>
                                <Input 
                                    id={CAMPAIGN_ITEM_ADD_ADDRESSES}
                                    disabled={this.isFieldLocked()}
                                    label='Number of addresses'
                                    type='number'
                                    value={addresses}
                                    onClick={(e) => this.onFieldSelected(e.currentTarget.id)}
                                    error={selected && (addresses < minAddresses)}
                                    errorMsg='Minimum addresses not met'
                                    containerStyling='w-100'
                                />
                            </div>
                            <div className='p-2 '  style={{display:hideFieldForMSQ}}>
                                <Input
                                    disabled
                                    label='Estimated Spend'
                                    value={`$${formatCurrency(addresses*costPerPiece)}`}
                                    onClick={(e) => this.onFieldSelected(e.currentTarget.id)}
                                    containerStyling='w-100'
                                />
                            </div>
                        </div>
                        {
                            servicesRequired && 
                                <div className='p-2'>
                                    <MultiSelect 
                                        id={CAMPAIGN_ITEM_SELECT_SERVICES}
                                        disabled={this.isFieldLocked()}
                                        label='Add services'
                                        placeholder='Click here to add services'
                                        selectedOptions={this.filterServicesNameTags(selectedServices)}
                                        onClick={(e) => this.onFieldSelected(e.currentTarget.id)}
                                        error={selected && !this.isValidServiceSelection()}
                                        errorMsg={'Services not selected'}
                                    />
                                </div>
                        }
                        <div className='p-2'>
                            <MultiSelect 
                                id={CAMPAIGN_ITEM_SELECT_COUPONS}
                                disabled={this.isFieldLocked()}
                                label='Add a coupon'
                                placeholder='Click here to add coupons'
                                selectedOptions={
                                    this.generateCouponNameTags(selectedCoupons)
                                }
                                onClick={(e) => this.onFieldSelected(e.currentTarget.id)}
                                error={selected && !selectedCoupons?.length && !is_no_coupon}
                                errorMsg={'Coupons not selected'}
                            />
                        </div>
                        <div className='p-2 flex-grow-1' style={{display:showExpDateField}}>
                                <Input
                                    label='Coupon expiration date'
                                    disabled={this.isFieldLocked()}
                                    value={expiration_date ?formatDate.UTCtoISOFormat(expiration_date):expDateAfterSelect}
                                    type='date'
                                    min={moment().format("YYYY-MM-DD")}
                                    onChange={(e) => this.onExpirationDateSelect(e.target.value)}
                                    containerStyling='w-100'
                                />
                            </div>
                        <div className='p-2'>
                            <MultiSelect 
                                id={CAMPAIGN_ITEM_SELECT_BADGES}
                                disabled={this.isFieldLocked()}
                                label='Add a Badge'
                                placeholder='Click here to add badges'
                                selectedOptions={this.filterBadgesNameTags(selectedBadges)}
                                onClick={(e) => this.onFieldSelected(e.currentTarget.id)}
                                error={selected && !selectedBadges?.length && !is_no_badge}
                                errorMsg={'Badges not selected'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    costPerPiece: state.mailingPlan?.costPerPiece,
    mailingPlan: state.mailingPlan,
    planOverrideActive: state.mailingPlan?.overrideEnabled,
    servicesRequired: state.mailingPlan?.templateBusinessRules?.requireServices?.active
});

export default connect(mapStateToProps, {
    toggleCampaignSelection,
    saveCampaignConfig
})(CampaignsListItem);

import React from 'react';
import PropTypes from 'prop-types';

import LmpbpRule from '../LmpbpRule';
import { Modal } from 'react-bootstrap';
import userSession from 'utils/userSession';
import Button from 'components/common/button/Button';

const BusinessRulesModal = ({
    show,
    onHide
}) => {
    return (
        <Modal 
            show={show} 
            onHide={() => onHide()} 
            className='business-rules-modal'
        >
            <Modal.Header>
                <div className='modal-title'>
                    Budget Guide
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <LmpbpRule>
                        <strong>SEO & Local Services</strong> costs are per website and are <strong>$350/month</strong>.
                        This tactic must be utilized for <strong>12 months</strong> if <strong>Scorpion One Digital Marketing</strong> is utilized. 
                        If you have multiple territories that share a website, you only need to enter this cost on one of those territories.
                    </LmpbpRule>
                    <LmpbpRule>
                        Enter your preferred dollar amount, by month, for Scorpion One Digital Marketing.
                        {
                            userSession.isDcsLmpbpUser() &&  
                            <strong>The minimum amount is $350 per month.</strong>
                        }
                    </LmpbpRule>
                    <LmpbpRule>
                        <strong>PulseM</strong> costs are per Google My Business page.
                        If you have multiple territories and just one GMB page, you only need to enter costs once. 
                        The tactic is <strong>$450/month</strong> in your first month of usage if you are starting/restarting service and <strong>$350/month</strong> thereafter.
                    </LmpbpRule>
                    {
                        userSession.isDcsLmpbpUser() &&  
                    <LmpbpRule>
                        <strong>Local Social Media Content </strong> is <strong>$750/month </strong> in your first month of usage.
                        If you are starting/restarting service and <strong>$600/month </strong> thereafter. Please verify your selection accordingly.
                    </LmpbpRule>
                    }
                    {
                        userSession.getBrandId() == 1 && 
                        <LmpbpRule>
                            Enter your preferred dollar amount for <strong>Direct Mail</strong> (it is $.3495 /mailer).
                            Upon submission of this plan, you must use the <strong>Direct Mail Platform</strong> on <strong>FranConnect</strong> 
                            to select your mail design, coupon offer, and verify your owner contact and mail plan information.
                        </LmpbpRule>
                    }
                    {
                        userSession.getBrandId() != 1 && 
                        <LmpbpRule> Enter your preferred dollar amount for <strong>Direct Mail</strong> (it is $.XX/mailer). 
                            If you enter a selection in this tactic, you will automatically be taken to the <strong>Direct Mail Platform</strong> 
                            upon hitting “Submit Plan” to make your creative, coupon, and other Direct Mail selections.
                            {
                                userSession.isDcsLmpbpUser() &&  
                                <strong>The minimum amount is $900 per month.</strong>
                            }
                        </LmpbpRule>
                    }
                    {
                        userSession.getBrandId() == 1 &&
                        <LmpbpRule>
                            <strong>S1, SEO, Wilen, and PulseM</strong> amounts can be <strong>INCREASED</strong> or <strong>DECREASED</strong> for any upcoming month as long as 
                            the input is made by the <strong>15th</strong> of the month prior. (Ex: May budget will be lock as of April 15th).
                            <br/>
                            Due to <strong>Spectrum pre-printing</strong> their mailers, budget amounts cannot be <strong>DECREASED</strong> once a mail plan has been approved and invoiced by Spectrum. 
                            Spectrum budgets can be <strong>INCREASED</strong> and additional mail plans can be put into production as long as the input is made before the <strong>15th</strong> of the month. 
                            (Ex: a July Spectrum Mail Plan could be entered until June 15).
                        </LmpbpRule>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button 
                    btnType='outline-primary'
                    label='Got it'
                    action={() => onHide()}
                />
            </Modal.Footer>
        </Modal>
    );
}

BusinessRulesModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func
}

export default BusinessRulesModal;

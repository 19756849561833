import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Modal } from 'react-bootstrap';

import thumbsUp from 'assets/images/thumbs-up.png';

import Button from 'components/common/button/Button';
import ImageView from 'components/common/image-view/ImageView';
import userSession from 'utils/userSession';

const SubmitConfirmation = ({
    onHide,
    show,
    planYear
}) => {
    const history = useHistory();

    return (
        <Modal show={show} onHide={onHide} className='mailing-plan-submit-confirmation'>
            <Modal.Header>
                <i onClick={() => onHide()} className='far fa-times-circle  '></i>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <ImageView
                        src={thumbsUp}
                        width='250px'
                        height='250px'
                        maxWidth='250px'
                        maxHeight='250px'
                        minWidth='250px'
                        minHeight='250px'
                    />
                    <div className='confirmation-text'>
                        {`Your ${planYear} Mailing Plan has been submitted for review and you will receive a confirmation email once it is approved.`}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button 
                    btnType='primary' 
                    label='Return to Dashboard'
                    action={() => {
                        if(userSession.isDivisionalUser()) {
                            history.push('/');
                        } else {
                            onHide();
                        }
                    }}
                />
            </Modal.Footer>
        </Modal>
    );
}

SubmitConfirmation.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool,
    planYear: PropTypes.number
}

export default SubmitConfirmation;

import React from 'react';
import LmpbpBudgetGuide from './LmpbpBudgetGuide';
import MSQBusinessRulesModal from './lmpbp-business-rules-modal/LmpbpMSQBusinessRulesModal';
import AssistancePopover from './AssistancePopover';

function LmpbpBudgetGuideMSQ() {
  return (
    <LmpbpBudgetGuide
      modalComponent={MSQBusinessRulesModal}
      popoverComponent={AssistancePopover}
    />
  );
}

export default LmpbpBudgetGuideMSQ;

const wrapper = data => ({
    serviceId: data.id,
    serviceName: data.name,
    selected: data.selected ? true : false,
    licenseRequired: data.license_required ? true : false,
    active: data.active ? true : false
});

const serviceWrapper = {
    single: service => {
        if(
            !service
            || Array.isArray(service)
            || typeof service !== 'object'
        ) return {};

        return wrapper(service);
    },

    sortByName: services => {
        if(!Array.isArray(services)) return [];

        return services.map(service => wrapper(service)).sort((a, b) => {
            if(a.serviceName === b.serviceName) return 0;
            return a.serviceName > b.serviceName ? 1 : -1;
        });
    }
}

export default serviceWrapper;
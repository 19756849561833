import React from 'react';
import PropTypes from 'prop-types';

import ConfigCard from 'components/common/config-card/ConfigCard';

const DiscountItem = ({
    outputText = 'No output text',
    onEditDiscount = null
}) => {
    return (
        <ConfigCard
            onClickEditButton={() => onEditDiscount()}
        >
            <div className='discount-item-card'>
                <span className='output-text'>{outputText}</span>
            </div>
        </ConfigCard>
    );
}

DiscountItem.propTypes = {
    name: PropTypes.string,
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onEditDiscount: PropTypes.func
}

export default DiscountItem;

const wrapper = (badge, selected) => ({
    badgeId: badge?.id,
    badgeName: badge?.name,
    code: badge?.code,
    badgeText: badge.badge_text ,
    selected: selected,
    active: badge?.active ? true : false
});

const badgeWrapper = {
    single: (badge, selected = false) => {
        if(
            !badge
            || Array.isArray(badge)
            || typeof badge !== 'object'
        ) return {};

        return wrapper(badge, selected);
    },

    sortByName: (badges, selected = false) => {
        if(!Array.isArray(badges) || !badges.length) return [];

        return badges.map(badge => wrapper(badge, selected)).sort((a, b) => {
            if(a.badgeName === b.badgeName) return 0;
            return a.badgeName > b.badgeName ? 1 : -1;
        });
    }
}

export default badgeWrapper;
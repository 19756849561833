import axios from 'axios';
import userSession from 'utils/userSession';

const axiosInstance = () => axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/lmpbp`,
    headers: {
        'Authorization': `Bearer ${userSession.getLmpbpToken()}`
    }
});

export default axiosInstance;
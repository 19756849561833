const validateRole = {
    isFranchiseOwner: function(userRole) {
        userRole = String(userRole).toLowerCase().trim();

        if(userRole === 'franchise owner' || userRole === 'franchise user') {
            return true;
        }

        return false;
    },
    isDivisionalUser: function(userRole) {
        userRole = String(userRole).toLowerCase().trim();

        if(userRole === 'corporate user' || userRole === 'divisional user') {
            return true;
        }

        return false;
    },

    // Direct mail
    isMSQDMUser: function(brandId) {
        if(brandId == 3) {
            return true;
        }
        return false;
    },
    isASPUser: function(brandId) {
        if(brandId == 2) {
            return true;
        }
        return false;
    },
    isMTUser: function(brandId) {
        if(brandId == 8) {
            return true;
        }
        return false;
    },

    // LMPBP
    isMSQLmpbpUser: function(brandId) {
        if(brandId == 1) {
            return true;
        }
        return false;
    },
    isDcsLmpbpUser: function(brandId) {
        if(brandId == 3) {
            return true;
        }
        return false;
    },
    isCWPLmpbpUser: function(brandId) {
        if(brandId == 5) {
            return true;
        }
        return false;
    },
    isWPVLmpbpUser: function(brandId) {
        if(brandId == 6) {
            return true;
        }
        return false;
    },
    isOHALmpbpUser: function(brandId) {
        if(brandId == 8) {
            return true;
        }
        return false;
    },
    isBFPLmpbpUser: function(brandId) {
        if(brandId == 9) {
            return true;
        }
        return false;
    },
    isMSELmpbpUser: function(brandId) {
        if(brandId == 10) {
            return true;
        }
        return false;
    },
    isHWCLmpbpUser: function(brandId) {
        if(brandId == 11) {
            return true;
        }
        return false;
    },
};

export default validateRole;
import {
    postBrochure, 
    postBrochureImage, 
    updateBrochure as updateBrochureApi,
    getBrochure,
    getBrochureCoupons,
    getBrochureBadges,
    deleteBrochure as deleteBrochureApi
} from 'api/direct-mail/brochures';
import badgeWrapper from 'api/direct-mail/utils/backend-wrappers/badgeWrapper';

import brochureWrapper from 'api/direct-mail/utils/backend-wrappers/brochureWrapper';
import couponWrapper from 'api/direct-mail/utils/backend-wrappers/couponWrapper';
import userSession from 'utils/userSession';

import {
    notifyDeleteBrochureError,
    notifyGetBrochureCouponsError,
    notifyGetBrochuresForConfigError,
    notifySaveBrochureImageError,
    notifySaveBrochureError, 
    notifyUpdateBrochureError,
    notifySaveBrochureSuccess,
    notifyUpdateBrochureSuccess,
    notifyDeleteBrochureSuccess
} from './notificationActions';

import {
    DELETE_BROCHURE_LOADING,
    GET_BROCHURES_FOR_CONFIG_LIST,
    GET_BROCHURES_FOR_CONFIG_LIST_LOADING,
    SAVE_BROCHURE_LOADING, 
    UPDATE_BROCHURE_LOADING
} from './types';

export const resetBrochuresList = () => {
    return {
        type: GET_BROCHURES_FOR_CONFIG_LIST,
        payload: []
    };
}

export const getBrochuresForConfig = (brandId = null, templateId = null) => async dispatch => {
    dispatch(notifyGetBrochuresForConfigError({ display: false }));
    dispatch(notifyGetBrochureCouponsError({ display: false }));

    dispatch({
        type: GET_BROCHURES_FOR_CONFIG_LIST_LOADING,
        payload: true
    });

    const [brochuresErr, brochuresRes] = await getBrochure({
        brandId,
        templateId,
        images: true
    });

    let brochuresWithCoupons = brochureWrapper.sortByName(brochuresRes);
    if(brochuresErr) {
        dispatch(notifyGetBrochuresForConfigError({ serverError: brochuresErr }));
    } else {
        if(Array.isArray(brochuresWithCoupons) && brochuresWithCoupons.length) {
            for(let i = 0; i < brochuresWithCoupons.length; i++) {
                const [couponsErr, couponsRes] = await getBrochureCoupons({
                    brandId: userSession.getBrandId(),
                    brochureId: brochuresWithCoupons[i]?.brochureId
                });
                
                if(couponsErr) {
                    dispatch(notifyGetBrochureCouponsError({ serverError: couponsErr }));
                } else {
                    brochuresWithCoupons[i] = { 
                        ...brochuresWithCoupons[i], 
                        coupons: couponWrapper.sortByName(couponsRes)
                    };
                }

                //Fetching brochures badges
                const [badgesErr, badgesRes] = await getBrochureBadges({
                    brandId: userSession.getBrandId(),
                    brochureId: brochuresWithCoupons[i]?.brochureId
                });
                
                if(badgesErr) {
                    dispatch(notifyGetBrochureCouponsError({
                        message: `Error fetching brochure badges`,
                        serverError: badgesErr
                    }));
                } else {
                    brochuresWithCoupons[i] = { 
                        ...brochuresWithCoupons[i], 
                        badges: badgeWrapper.sortByName(badgesRes)
                    };
                }
            }
        }
    }

    dispatch({
        type: GET_BROCHURES_FOR_CONFIG_LIST_LOADING,
        payload: false
    });

    return dispatch({
        type: GET_BROCHURES_FOR_CONFIG_LIST,
        payload: brochuresWithCoupons
    });
}

export const saveBrochure = (templateId = null, brochure = {}, onComplete = null) => async dispatch => {
    dispatch(notifySaveBrochureError({ display: false }));
    dispatch(notifySaveBrochureSuccess({ display: false }));
    dispatch(notifySaveBrochureImageError({ display: false }));

    dispatch({
        type: SAVE_BROCHURE_LOADING,
        payload: true
    });

    const [postBrochureErr, newBrochureRes] = await postBrochure({
        templateId,
        name: brochure?.brochureName,
        numberOfCoupons: brochure?.numberOfCoupons,
        minServices: brochure?.minServices,
        maxServices: brochure?.maxServices,
        active: brochure?.active,
        licenseRequired: brochure?.licenseRequired,
        isInternalUse: brochure?.internalUse,
        coupons: brochure?.coupons?.map(coupon => coupon.couponId),
        badges: brochure?.badges?.map(badge => badge.badgeId),
        minBadges: brochure?.minBadges,
        maxBadges: brochure?.maxBadges,
    });

    if(postBrochureErr) {
        dispatch(notifySaveBrochureError({
            title: 'Network Error',
            message: `Could not save brochure: ${brochure?.brochureName}`,
            type: 'danger',
            serverError: postBrochureErr
        }));
        
    } else {
        dispatch(notifySaveBrochureSuccess());
        dispatch(getBrochuresForConfig(userSession.getBrandId(), templateId));
        
        const [saveImageErr] = await postBrochureImage({
            brochureId: newBrochureRes?.brochureID,
            image: brochure?.src?.replace('data:image/png;base64,', '')
        });
        
        if(saveImageErr) {
            dispatch(notifySaveBrochureImageError({
                title: 'Server Error',
                message: `Error saving image for brochure: ${brochure?.brochureName}`,
                type: 'danger',
                serverError: saveImageErr
            }));
        } else {
            dispatch(getBrochuresForConfig(userSession.getBrandId(), templateId));
        }
        
        if(onComplete) onComplete();
    }

    return dispatch({
        type: SAVE_BROCHURE_LOADING,
        payload: false
    });
}

export const updateBrochure = (templateId = null, brochure = {}, onComplete = null) => async dispatch => {
    dispatch(notifyUpdateBrochureError({ display: false }));
    dispatch(notifyUpdateBrochureSuccess({ display: false }));
    dispatch(notifySaveBrochureImageError({ display: false }));

    dispatch({
        type: UPDATE_BROCHURE_LOADING,
        payload: true
    });

    const [postBrochureErr] = await updateBrochureApi({
        brochureId: brochure?.brochureId,
        name: brochure?.brochureName,
        numberOfCoupons: brochure?.numberOfCoupons,
        minServices: brochure?.minServices,
        maxServices: brochure?.maxServices,
        active: brochure?.active,
        licenseRequired: brochure?.licenseRequired,
        isInternalUse: brochure?.internalUse,
        coupons: brochure?.coupons?.map(coupon => coupon.couponId),
        badges: brochure?.badges?.map(badge => badge.badgeId),
        minBadges: brochure?.minBadges,
        maxBadges: brochure?.maxBadges,
    });

    if(postBrochureErr) {
        dispatch(notifyUpdateBrochureError({
            title: 'Network Error',
            message: `Could not update brochure: ${brochure?.brochureName}`,
            type: 'danger',
            serverError: postBrochureErr
        }));

    } else {
        dispatch(notifyUpdateBrochureSuccess());
        dispatch(getBrochuresForConfig(userSession.getBrandId(), templateId));

        if(brochure?.srcUpdated) {
            const [saveImageErr] = await postBrochureImage({
                brochureId: brochure?.brochureId,
                image: brochure?.src?.replace('data:image/png;base64,', '')
            });
    
            if(saveImageErr) {
                dispatch(notifySaveBrochureImageError({
                    title: 'Server Error',
                    message: `Error saving image for brochure: ${brochure?.brochureName}`,
                    type: 'danger',
                    serverError: saveImageErr
                }));
            } else {
                dispatch(getBrochuresForConfig(userSession.getBrandId(), templateId));
            }
        }

        if(onComplete) onComplete();
    }

    return dispatch({
        type: UPDATE_BROCHURE_LOADING,
        payload: false
    });
}

export const deleteBrochure = (templateId = null, brochureId = null, onComplete = null) => async dispatch => {
    dispatch(notifyDeleteBrochureError({ display: false }));
    dispatch(notifyDeleteBrochureSuccess({ display: false }));

    dispatch({
        type: DELETE_BROCHURE_LOADING,
        payload: true
    });

    const [deleteErr] = await deleteBrochureApi({ brochureId });
    
    if(deleteErr) {
        dispatch(notifyDeleteBrochureError({ serverError: deleteErr }));
    } else {
        dispatch(notifyDeleteBrochureSuccess());
        dispatch(getBrochuresForConfig(userSession.getBrandId(), templateId));
    }
    
    if(onComplete) onComplete();

    return dispatch({
        type: DELETE_BROCHURE_LOADING,
        payload: false
    });
}
import LmpbpBudgetGuideDefault from "./LmpbpBudgetGuideDefault";
import LmpbpBudgetGuideMSQ from "./LmpbpBudgetGuideMSQ";

function BudgetGuideFactory(brand) {
  if (!brand) {
    return;
  }

  switch (brand.symbol) {
    case 'MSQ':
      return <LmpbpBudgetGuideMSQ />
    default:
      return <LmpbpBudgetGuideDefault />
  }
}

export default BudgetGuideFactory;

import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
    label = '',
    type = 'text',
    selectionName = 'Select an option',
    containerStyling = '',
    inputStyling = '',
    labelStyling = '',
    error = false,
    errorMsg = 'Please verify this field',
    disabled = false,
    value = '',
    readOnly = false,
    labelHint = '',
    icon = null,
    primary = true,
    iconClass = null,
    onChange = (e) => {console.log("hello "); return e},
    ...props
}) => {

    const onChangeCleanup = (e) => {
        return onChange({
            ...e,
            target: { 
                ...e.target, 
                value: e.target.value.trim() ? e.target.value : '',
                name: e.nativeEvent.target.name,
            },
        })
    }

    const renderInputField = () => {
        if(type === 'button') {
            return (
                <button 
                    className='form-control button-input'
                    disabled={disabled}
                    onChange={onChange}
                    {...props}
                >
                    <span>{selectionName}</span>
                    <i className='fas fa-angle-right'></i>
                </button>
            );
        }

        if(type === 'file') {
            return (
                <input 
                    className={`form-control h-fit-content ${inputStyling || ''}`.trim()} 
                    type={type}
                    readOnly={disabled || readOnly}
                    disabled={disabled}
                    onChange={onChange}
                />
            );
        }

        return (
            <input 
                className={`form-control h-fit-content ${inputStyling || ''}`.trim()} 
                type={type}
                readOnly={disabled || readOnly}
                disabled={disabled}
                value={value}
                onChange={onChangeCleanup}
                {...props}
            />
        );
    }

    return (
        <div className={`d-flex flex-column align-items-center ${containerStyling}`.trim()}>
            {label &&
                <label className={`w-100 font-size-12 ${icon ? 'input-icon-margin' : ''} ${labelStyling}`}>{label}</label>
            }
            <div className='d-flex flex-column w-100'>
                <div className='d-flex align-items-center'>
                    {icon && 
                        <i className={`mr-2 font-size-20 ${icon} ${primary ? 'brand-primary-color' : iconClass}`}></i>
                    }
                    {renderInputField()}
                </div>
                {labelHint &&
                    <small className='text-left w-100'>{labelHint}</small>
                }
                {error && 
                    <div className='error-msg text-left w-100'>{errorMsg}</div>
                }
            </div>
        </div>
    );
}

Input.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    selectionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    containerStyling: PropTypes.string,
    inputStyling: PropTypes.string,
    labelStyling: PropTypes.string,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    readOnly: PropTypes.bool,
    labelHint: PropTypes.string,
    icon: PropTypes.string
}

export default Input;

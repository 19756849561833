import React, { useState } from "react";
import { useMutation, useQueryClient } from 'react-query'
import api from 'api/lmpbp';
import useAlert from 'hooks/useAlert';
import WebsiteUrlForm from "./WebsiteUrlForm";


function AddWebsite({ onClose }) {
  const [url, setUrl] = useState('');

  const queryClient = useQueryClient();

  const successAlert = useAlert({
    id: 'add-website-success',
    message: 'Website added successfully',
    type: 'success',
  });

  const errorAlert = useAlert({
    id: 'add-website-error',
    message: 'Error adding website',
    type: 'danger',
    error: null
  });

  const addWebsiteMutation = useMutation(
    'add-website',
    (url) => api().post(`website`, { url: url }),
    {
      onError: (error) => {
        const serverError = {
          message: error.response.data.error,
          code: error.response.status
        }
        errorAlert(serverError);
      },
      onSuccess: () => {
        successAlert();
        queryClient.invalidateQueries('search-websites');
      }
    }
  );

  const handleSubmit = (url) => {
    addWebsiteMutation.mutate(url);
  }

  return (
    <WebsiteUrlForm
      websiteUrl={url}
      onChange={setUrl}
      onSubmit={handleSubmit}
      isLoading={addWebsiteMutation.isLoading}
      isSuccess={addWebsiteMutation.isSuccess}
      onClose={onClose}
    />
  );
}

export default AddWebsite;

const campaignsSelected = campaigns => {
    return campaigns.filter(({ selected }) => selected).length;
}

const validateMinCampaigns = (campaigns, businessRule) => {
    if(!Array.isArray(campaigns)) return false;

    const minCampaigns = Number(businessRule?.calculatedValue);
    if(isNaN(minCampaigns)) return false;
    
    if (campaignsSelected(campaigns) >= minCampaigns) return true;
    
    return false; 
}

export default validateMinCampaigns;
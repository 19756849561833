import franchiseWrapper from 'api/direct-mail/utils/backend-wrappers/franchiseWrapper';

import {
    GET_FRANCHISE_INFO,
    CONFIRM_FRANCHISE_INFO,
    RESET_FRANCHISE_INFO,
    INCORRECT_FRANCHISE_INFO_COMMENTS_REQUIRED,
    CHANGEFRANCHISELICENSE,
    CHANGEOWNERDETAILS
} from './types';

import { notifyGetFranchiseInfoError } from './notificationActions';
import { getFranchise } from 'api/direct-mail/franchise';

export const getFranchiseInfo = (brandId, franchiseId) => async dispatch => {
    dispatch(notifyGetFranchiseInfoError({ display: false }));

    let [franchiseInfoErr, franchiseInfo] = await getFranchise({
        brandId,
        franchiseId
    });
    
    if(franchiseInfoErr) {
        dispatch(notifyGetFranchiseInfoError({ serverError: franchiseInfoErr }));
    }

    return dispatch({
        type: GET_FRANCHISE_INFO,
        payload: franchiseWrapper(franchiseInfo)
    });
}

export const confirmFranchiseInfo = (confirmed = false, correct = null) => dispatch => {
    dispatch({
        type: INCORRECT_FRANCHISE_INFO_COMMENTS_REQUIRED,
        payload: (confirmed && !correct) ? true : false
    });

    return dispatch({
        type: CONFIRM_FRANCHISE_INFO,
        payload: { confirmed, correct }
    });
}

export const resetFranchiseInfo = (franchiseInfo = null) => {
    return {
        type: RESET_FRANCHISE_INFO,
        payload: franchiseInfo
    };
}
export const changeLicenseInputs = (overrideLicense = false, licenseNumber = null,check) => dispatch => {
    return dispatch({
        type: CHANGEFRANCHISELICENSE,
        payload: { overrideLicense, licenseNumber,check }
    });
}
export const changeOwnerDetails = (ownerContactEmail = null, ownerPhoneNumber = null,check,ownerName = null) => dispatch => {
    return dispatch({
        type: CHANGEOWNERDETAILS,
        payload: { ownerContactEmail, ownerPhoneNumber,check,ownerName }
    });
}
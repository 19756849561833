import React, { useState, Fragment } from 'react';
import Button from 'components/common/button/Button';

function LmpbpBudgetGuide({ modalComponent: ModalComponent, popoverComponent: PopoverComponent }) {
  const [showRules, setShowRules] = useState(false);

  const onClose = () => setShowRules(false);
  const modal = ModalComponent ? <ModalComponent show={showRules} onHide={onClose} /> : null;
  const popover = PopoverComponent ? <PopoverComponent /> : null;

  return (
    <Fragment>
      {modal}
      {popover}
      <Button
        action={() => setShowRules(true)}
        btnType='outline-primary'
        label='Budget Guide'
      />
    </Fragment>
  );
}

export default LmpbpBudgetGuide;

import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({
    loadingMessage = '',
    loaderStyling = null
}) => {
    return (
        <div className='d-flex justify-content-center align-items-center brand-primary-color'>
            <div 
                className='spinner-border' 
                role='status'
                style={ loaderStyling }
            >
                <span className='sr-only'>Loading...</span>
            </div>
            { 
                loadingMessage &&
                    <div className='ml-3'>{loadingMessage}</div>
            }
        </div>
    );
}

Loader.propTypes = {
    loadingMessage: PropTypes.string,
    loaderStyling: PropTypes.object
}

export default Loader;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import userSession from 'utils/userSession';

const arrivedAtLmpbp = () => {
    class ArrivedAtLmpbp extends Component {
        render() {
            if(!userSession.isActive()) {
                const { match } = this.props;
                const arrivedAtLmpbp = match?.path?.includes('/lmpbp');

                userSession.setArrivedAtLmpbp(arrivedAtLmpbp);
                return <Redirect to='/login' />;
            }
            
            if(userSession.arrivedAtLmpbp()) {
                return userSession.isDivisionalUser() ?
                    <Redirect to='/lmpbp/dashboard' />
                :
                    <Redirect to='/lmpbp/territories' />;
            } 
            
            return <Redirect to='/' />;
        }
    }

    return ArrivedAtLmpbp;
}

export default arrivedAtLmpbp;

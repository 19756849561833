import React, { useState, useContext, Fragment, useEffect } from 'react';
import { ReactReduxContext, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import Select from 'components/common/select/Select';
import BudgetPlan from 'components/lmpbp/budget-plan/BudgetPlan';

import formatCurrency from 'utils/formatCurrency';
import formatDate from 'utils/formatDate';

import { LMPBP_GET_BUDGET_HISTORIC_ERROR, LMPBP_MOVE_TO } from 'actions/types';

import { getHistoric } from 'api/lmpbp/budgetPlans';

import {
    closeNotification,
    showNotification,
} from 'actions/notificationActions';

const ReviewBudgetPlan = ({ location }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { store } = useContext(ReactReduxContext);

    const { budgetPlan, templateYear, templateId, owners, franchiseName } =
        location.state || {};

    const [selectedPlan, setSelectedPlan] = useState(budgetPlan || {});
    const [initialLoad, setInitialLoad] = useState(true);

    const historicData = useQuery(
        ['submissions', selectedPlan?.franchiseId],
        async () =>
            await getHistoric({
                franchiseId: selectedPlan?.franchiseId,
                year: templateYear,
            }),
        {
            enable: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_BUDGET_HISTORIC_ERROR,
                        message: 'Error fetching budget plans historic',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: res => {
                dispatch(closeNotification(LMPBP_GET_BUDGET_HISTORIC_ERROR));
                if (initialLoad) {
                    const planHistoric = _.find(res, ['id', selectedPlan.id]);
                    setSelectedPlan(planHistoric ? planHistoric : res[0]);
                    setInitialLoad(false);
                } else {
                    setSelectedPlan(res[0]);
                }
            },
        }
    );

    const generatePlanOptions = plans => {
        if (!plans || !plans.length) return [];

        return plans.map(plan => ({
            value: plan.id,
            label: `${plan.status !== 'Draft' ? plan.status : 'In Progress'} - ${formatDate.descriptiveDateAndTime(
                plan.updatedOn
            )}`,
        }));
    };

    const onGoToDashboard = () => {
        if (store.getState().status.unsavedBudgetPlanChanges) {
            dispatch({ type: LMPBP_MOVE_TO, payload: "Dashboard" });
        }
        else {
            history.push('/lmpbp/dashboard');
        }
    };

    useEffect(() => {
        document.title = 'Local Marketing Plan Budget Platform';
        document.getElementById("favicon").href = window.location.origin + '/lmpbp.png';
        if (!budgetPlan?.id) {
            onGoToDashboard();
        } else {
            historicData.refetch();
        }
    }, []);

    return <Fragment>
        <div className='bg-white border rounded px-4 py-5' id="review-budget-plan">
            <div className='d-flex justify-content-center align-items-end'>
                <div 
                    onClick={() => onGoToDashboard()}
                    className='review-budget-plan-label brand-primary-color p-2 mr-2'
                >
                    <i
                        className='fas fa-chevron-left'
                    />
                </div>
                <table className='review-budget-plan-table'>
                    <thead>
                        <tr>
                            <th>Plan ID</th>
                            <th>Franchise Name</th>
                            <th>Year</th>
                            <th>Owners</th>
                            <th>Budget Plan</th>
                            <th>{`Required ${selectedPlan.requiredAnnualAmount
                                    ? 'Annual'
                                    : 'Monthly'
                                } Budget`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{selectedPlan.id}</td>
                            <td>{franchiseName}</td>
                            <td>{templateYear}</td>
                            <td>{owners}</td>
                            <td>
                                <Select
                                    id="review-budget-plan-select-historic"
                                    value={selectedPlan.id}
                                    options={generatePlanOptions(
                                        historicData.data
                                    )}
                                    disabled={historicData.isLoading}
                                    onChange={({ target: { value } }) =>
                                        setSelectedPlan(
                                            _.find(historicData.data, [
                                                'id',
                                                Number(value),
                                            ])
                                        )
                                    }
                                />
                            </td>
                            <td>
                                {`$ ${formatCurrency(
                                    selectedPlan.requiredAnnualAmount
                                        ? selectedPlan.requiredAnnualAmount
                                        : selectedPlan.requiredMonthlyAmount
                                )}`}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <BudgetPlan
                franchiseId={selectedPlan.franchiseId}
                budgetPlan={selectedPlan}
                template={
                    {
                        id: templateId,
                        year: templateYear
                    }}
                onCreateNewDraftSuccess={() => historicData.refetch()}
                onSubmitSuccess={() => historicData.refetch()}
                onApproveSuccess={() => historicData.refetch()}
                onRejectSuccess={() => historicData.refetch()}
            />
        </div>
    </Fragment>
};

export default ReviewBudgetPlan;

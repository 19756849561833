import { 
    UPLOAD_ADDRESSES_FILE,
    UPLOADING_ADDRESSES,
    SET_ADDRESSES_IMPORT_SUCCESS,
    SET_ADDRESSES_IMPORT_ERROR,
    GET_ADDRESS_IMPORTS_LOG,
    GET_ADDRESS_IMPORTS_LOG_LOADING,
    DELETE_ADDRESSES_FILE,
    DELETE_ADDRESSES_FILE_LOADING,
    SET_ADDRESSES_UPLOAD_PROGRESS,
    DOWNLOAD_ADDRESSES_FILE
} from 'actions/types';

const addressesImportReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case UPLOAD_ADDRESSES_FILE:
            return {
                ...state, 
                total: state.total,
                success: state.success,
                failed: state.failed
            };

        case UPLOADING_ADDRESSES:
            return { ...state, uploadingAddresses: action.payload };

        case SET_ADDRESSES_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.payload };

        case SET_ADDRESSES_IMPORT_SUCCESS:
            return { ...state, uploadSuccessful: action.payload };

        case SET_ADDRESSES_IMPORT_ERROR:
            return { ...state, uploadFailed: action.payload };

        case GET_ADDRESS_IMPORTS_LOG:
            return { ...state, importsLog: action.payload };

        case GET_ADDRESS_IMPORTS_LOG_LOADING:
            return { ...state, loadingImportsLog: action.payload };

        case DELETE_ADDRESSES_FILE:
            return { ...state, deletedAddressesFile: action.payload };
        
        case DELETE_ADDRESSES_FILE_LOADING:
            return { ...state, deletingAddressesFile: action.payload };

        case DOWNLOAD_ADDRESSES_FILE:
            return { ...state, downloadedFile: action.payload };

        default:
            return state;
    }
}

export default addressesImportReducer;
import sendOrderWrapper from './sendOrderWrapper';
import businessRulesWrapper from './businessRulesWrapper';
import campaignTemplateWrapper from './campaignTemplateWrapper';

const mailingPlanTemplateWrapper = template => {
    if(
        !template
        || Array.isArray(template)
        || typeof template !== 'object'
    ) return {};

    return {
        id: template?.id,
        active: template?.active ? true : false,
        currentlyActive: template?.active ? true : false,
        brandId: template?.brand_id,
        businessRules: businessRulesWrapper.sortByName(template?.business_rules),
        campaignTemplates: campaignTemplateWrapper.sortByDate(template?.campaign_templates),
        costPerPiece: template?.brochure_sizes?.length ? template?.brochure_sizes[0]?.price : 0,
        createdBy: template?.created_by,
        createdDate: template?.created_date,
        defaultSendOrder: template?.default_send_order,
        planYear: template?.plan_year,
        sendOrder: sendOrderWrapper(template?.send_order),
        updatedBy: template?.updated_by,
        updatedDate: template?.updated_date,
        pieceSizes: template?.brochure_sizes,
    };
}

export default mailingPlanTemplateWrapper;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/common/input/Input';
import formatNumber from 'utils/formatNumber';
import userSession from 'utils/userSession';

export class AddressesSelection extends Component {
    state = {
        addresses: 0
    }
    
    static propTypes = {
        addresses: PropTypes.number,
        error: PropTypes.bool,
        errorMsg: PropTypes.string,
        onLockNextStep: PropTypes.func,
        onAddressesChange: PropTypes.func,
        minAddresses: PropTypes.number
    }

    componentDidMount() {
        const { 
            addresses,
            minAddresses,
            onLockNextStep,
            error,
            errorMsg
        } = this.props;

        this.setState({ addresses });

        if((error || errorMsg || minAddresses > addresses) && !userSession.isDivisionalUser()) {
            onLockNextStep(true);
        } else {
            onLockNextStep(false);
        }
    }

    render() {
        const { minAddresses } = this.props;

        return (
            <div className='d-flex justify-content-center'>
                <div className='w-50'>
                    <div className='d-flex flex-column align-items-start'>
                        <Input 
                            type='number' 
                            min={0}
                            placeholder='Enter number of addresses'
                            labelHint={minAddresses ? `Minimum addresses required: ${formatNumber(minAddresses)}` : null}
                            value={this.state.addresses}
                            containerStyling='w-100'
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, '');
                                value = value.replace(/^0*/g, '');
                                this.setState({ addresses: value});
                                this.props.onAddressesChange(value)
                            }}
                            error={this.props.error ? true : false}
                            errorMsg={this.props.errorMsg}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AddressesSelection;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Panel from 'components/common/panel/Panel';
import CampaignsListItem from './CampaignsListItem';

export class Campaigns extends Component {
    selectedCampaignsCount = () => {
        return this.props.campaignsList?.filter(campaign => {
            if(campaign.selected) {
                return campaign;
            }
            
            return false;
        })?.length || 0;
    }
    
    renderCampaigns = () => {
        return this.props.campaignsList?.map((campaign) => {
            return (
                <CampaignsListItem 
                    key={campaign.campaignId}
                    campaign={campaign}
                    campaignsList= {this.props.campaignsList}
                />
            );
        });
    }

    render() {
        const {
            campaignsList,
            loadingMailingPlan
        } = this.props;

        return (
            <Panel loading={loadingMailingPlan} loadingMessage={'Fetching campaigns...'}>
                {
                    campaignsList?.length ?
                        <div className='campaigns-container'>
                            <div className='campaigns-list-title'>
                                {this.selectedCampaignsCount()}/{campaignsList?.length || 0} Campaigns
                            </div>
                            <div className='campaigns-list'>
                                {this.renderCampaigns()}
                            </div>
                        </div>
                    :
                        <h4>No campaigns configured</h4>
                }
            </Panel>
        );
    }
}

const mapStateToProps = state => ({
    campaignsList: state.mailingPlan?.campaigns,
    loadingMailingPlan: state.mailingPlan?.loading
});

export default connect(mapStateToProps, {})(Campaigns);

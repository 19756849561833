import React, { useState } from "react";
import { useMutation, useQueryClient } from 'react-query'
import api from 'api/lmpbp';
import useAlert from 'hooks/useAlert';
import WebsiteUrlForm from "./WebsiteUrlForm";

function EditWebsite({ website, onCancel: onClose }) {
  const [url, setUrl] = useState(website.url);
  const queryClient = useQueryClient();

  const successAlert = useAlert({
    title: 'Success',
    id: 'edit-website-success',
    message: 'Website edited successfully',
    type: 'success',
  });

  const errorAlert = useAlert({
    id: 'edit-website-error',
    message: 'Error editing website',
    type: 'danger',
    error: null
  });

  const editWebsiteMutation = useMutation(
    (url) => api().put(`website/${website.id}`, { url: url }),
    {
      onError: (error) => {
        const serverError = {
          message: error.response.data.error,
          code: error.response.status
        }
        errorAlert(serverError);
      },
      onSuccess: (_) => {
        successAlert()
        queryClient.invalidateQueries("search-websites");
      }
    }
  );

  const handleSubmit = (url) => {
    editWebsiteMutation.mutate(url);
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <WebsiteUrlForm
        websiteUrl={url}
        onChange={setUrl}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={editWebsiteMutation.isLoading}
        isSuccess={editWebsiteMutation.isSuccess}
      />
    </div>
  );
}

export default EditWebsite;

import React, { useState } from "react";
import { useQuery } from "react-query";
import { getTemplateTactics } from "api/lmpbp/budgetPlans";
import { getBrandWebsites, getWebsiteParent } from "api/lmpbp/websites";
import CustomButton from "../custom-buttons/CustomButton";
import SelectReact from "react-select";
import useAddFranchiseWebsite from "hooks/useAddFranchiseWebsite";
import Loader from "components/common/loader/Loader";

function AddTacticWebsiteForm({ franchise, selectedTemplate, onClose }) {
  const [selectedTactic, setSelectedTactic] = useState(null);
  const [selectedWebsite, setSelectedWebsite] = useState(null);

  const { data: parent, isLoading: parentIsLoading } = useQuery(
    ['get-website-parent', selectedWebsite?.value?.id],
    () => getWebsiteParent(selectedWebsite?.value?.id),
    {
      enabled: !!selectedWebsite?.value?.id
    }
  )

  const templateTacticsQuery = useQuery(
    ['template_tactics', selectedTemplate.id],
    () => getTemplateTactics(selectedTemplate.id)
  );

  const { data: websites } = useQuery(
    ['brand_websites'],
    () => getBrandWebsites()
  );

  const { mutate, isLoading } = useAddFranchiseWebsite(
    selectedWebsite?.value.id,
    onClose,
    "get-franchise-websites"
  );

  return (
    <div>
      { isLoading && <Loader />}
      <SelectReact
        value={selectedTactic}
        onChange={setSelectedTactic}
        options={templateTacticsQuery.data?.map(t => ({
          label: t.displayName,
          value: t,
        }))}
        placeholder="Choose the tactic"
        className="mb-2"
      />
      <SelectReact
        value={selectedWebsite}
        onChange={setSelectedWebsite}
        options={websites?.map(t => ({
          label: t.url,
          value: t,
        }))}
        placeholder="Choose the website"
        className="mb-2"
      />
      {parent && <div>Parent: {parent.externalId}</div>}
      <CustomButton
        id='cancel-add-franchise-website-button'
        className='dm-button outline-danger mx-2'
        onClick={() => onClose(false)}
      >
        Cancel
      </CustomButton>
      <CustomButton
        id='add-franchise-website-button'
        className='dm-button outline-primary mx-2'
        onClick={() => {
          mutate({
            franchiseId: franchise.id,
            tacticId: selectedTactic.value.id,
            isParent: !parent
          })
        }}
        loading={isLoading}
        loadingMessage='Adding...'
        disabled={parentIsLoading || !selectedTactic || !selectedWebsite}
      >
        Add
      </CustomButton>
    </div>
  );
}

export default AddTacticWebsiteForm;

import api from 'api/lmpbp';
import handleResponse  from 'api/direct-mail/utils/handleResponse';
import handleResponseLmpbp from './utils/handleResponse';
export const getBrand = ({ brandId = null } = {}) => {
    return handleResponse(
        api().get(`brand/${brandId}`)
    );
}
export const getBrandLmpbp = ({ brandId = null } = {}) => {
    return handleResponseLmpbp({
        promise: api().get(`brand/${brandId}`),
    });
}
export const updateLockDateReminder = ({ toggle_lock_date_reminder } = {}) => {
        sessionStorage.setItem("toggle_lock_date_reminder", toggle_lock_date_reminder)
        return handleResponseLmpbp({
            promise: api().post(`brand/toggle-lock-date-reminder`,{
                toggle_lock_date_reminder: toggle_lock_date_reminder,
            }),
        });
  
};
export const updateBrandInAuthorizeApi = ({ BrandID } = {}) => {
    return handleResponse(
     api().post(`brand/update-brand-in-authorize`,{
            BrandID: BrandID,
        }),
    );

};
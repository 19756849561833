import React from 'react';
import PropTypes from 'prop-types';

const ExportButton = ({
    label = 'Export',
    icon = 'fas fa-file-export',
    onExport = null,
    ...props
}) => {
    return (
        <button
            {...props}
            className='export-btn'
            onClick={() => {
                if(onExport) onExport();
            }}
        >
            <div className='d-flex align-items-center justify-content-center'>
                {label}
                {
                    icon &&
                        <i className={`${icon} ml-2`}></i>
                }
            </div>
        </button>
    );
}

ExportButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    onExport: PropTypes.func,
}

export default ExportButton;

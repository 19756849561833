import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Button from 'components/common/button/Button';
import validateStatus from 'utils/validateStatus';

const FeedbackModal = ({
    show,
    onClose,
    feedback,
    year,
    status,
    overriden,
}) => {
    const renderFeedback = () => {
        if (validateStatus.isRejected(status)) {
            return (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <i className='feedback-icon danger far fa-times-circle'></i>
                    <div className='feedback-title'>
                        {`Your ${year} Mailing Plan has been rejected`}
                    </div>
                    {feedback && <div className='feedback-msg'>{feedback}</div>}
                </div>
            );
        }

        if (overriden) {
            return (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <i className='feedback-icon success far fa-check-circle'></i>
                    <div className='feedback-title'>
                        {`Your ${year} Mailing Plan has been approved`}
                    </div>
                    {feedback && <div className='approved-msg'>{feedback}</div>}
                </div>
            );
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            className='mailing-plan-reject-modal'
        >
            <Modal.Header>
                <i
                    onClick={() => onClose()}
                    className='far fa-times-circle'
                ></i>
            </Modal.Header>
            <Modal.Body>{renderFeedback()}</Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button
                    btnType='primary'
                    label='Go to my plan'
                    action={() => onClose()}
                />
            </Modal.Footer>
        </Modal>
    );
};

FeedbackModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    feedback: PropTypes.string,
    year: PropTypes.string,
    status: PropTypes.string,
    overriden: PropTypes.bool,
};

export default FeedbackModal;

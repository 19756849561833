import React, { Component } from 'react';
import Notification from '../common/notification/Notification';
import { connect } from 'react-redux';

export class Notifications extends Component {
    renderServerError = serverErrorMessage => {
        if (!serverErrorMessage) return null;

        if (Array.isArray(serverErrorMessage)) {
            return (
                <ul>
                    {serverErrorMessage.map(message => (
                        <li>{message}</li>
                    ))}
                </ul>
            );
        }

        return serverErrorMessage;
    };

    displayNotifications() {
        const { notifications } = this.props;

        let notificationKeys = notifications ? Object.keys(notifications) : [];
        let notificationStack = [];

        for (let key of notificationKeys) {
            notificationStack.push({
                id: key,
                title: notifications[key]?.title,
                message: notifications[key]?.message,
                type: notifications[key]?.type,
                customConfigObj: notifications[key]?.customConfigObj,
                customConfigKey: notifications[key]?.customConfigKey,
                serverError: notifications[key]?.serverError,
                display: notifications[key]?.display,
            });
        }

        return notificationStack?.map(notification => {
            if (
                notification.display &&
                (notification.title || notification.message)
            ) {
                return (
                    <Notification
                        key={notification.id}
                        notificationId={notification.id}
                        variant={notification.type}
                        title={notification.title}
                        content={
                            <div>
                                {notification.message}
                                <small className='d-block mt-2'>
                                    {notification.serverError?.errorCode
                                        ? `Error ${notification.serverError?.errorCode}: `
                                        : ''}
                                    {this.renderServerError(
                                        notification.serverError?.message
                                    )}
                                </small>
                            </div>
                        }
                        notify={true}
                        customConfigObj={notification.customConfigObj}
                        customConfigKey={notification.customConfigKey}
                    />
                );
            }

            return null;
        });
    }

    render() {
        return (
            <div className='notifications-container'>
                {this.displayNotifications()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    notifications: state.notifications,
});

export default connect(mapStateToProps, {})(Notifications);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from 'react-bootstrap';
import Panel from 'components/common/panel/Panel';
import Input from 'components/common/input/Input';
import Switch from 'components/common/switch/Switch';
import MultiSelect from 'components/common/multi-select/MultiSelect';
import Button from 'components/common/button/Button';
import Loader from 'components/common/loader/Loader';

import { 
    getDiscounts as getDiscountsAction
} from 'actions/discountsActions';

import { 
    deleteCoupon as deleteCouponAction,
    getCouponDiscounts as getCouponDiscountsAction,
    saveCoupon as saveCouponAction,
    updateCoupon as updateCouponAction,
    resetCouponDiscounts as resetCouponDiscountsAction
} from 'actions/couponsConfigActions';

export class CouponConfigModal extends Component {
    state = {
        coupon: null,
        isEditing: false,
        isUniqueName: true,
        selectedDiscounts: []
    }

    static propTypes = {
        templateId: PropTypes.number.isRequired,
        selectedCoupon: PropTypes.object.isRequired,
        onHide: PropTypes.func
    }

    generateDiscountOptions = () => {
        const {discountOptions} = this.props;
        const {selectedDiscounts} = this.state;

        if(!Array.isArray(discountOptions)) return [];
        let filteredOptions = []; 
        
        if(selectedDiscounts?.length > 0)
        {
            discountOptions?.forEach((discount, index) => {
                selectedDiscounts?.forEach(selected => {
                    if(selected.discountId != discount.discountId && 
                        !filteredOptions.includes(discount))
                    {
                        filteredOptions.push(discount);
                    }
                    else
                    {
                        discountOptions[index].selected = true;
                    }
                })
            });
        }
        else
        {
            filteredOptions = [...discountOptions];
        }

        return filteredOptions.map((discount) => ({ 
            ...discount,
            value: discount.outputText,
            id: discount.discountId
        }));
    }

    onChangeCouponName = couponName => {
        const { couponOriginalName } = this.state;
        const { coupons } = this.props;
        
        let isUniqueName = true;
        coupons.forEach(coupon => {
            const originalName = couponOriginalName?.toLowerCase()?.trim();
            const newCouponName = couponName.toLowerCase().trim();
            const existingCouponName = coupon.couponName.toLowerCase().trim();

            if(
                newCouponName === existingCouponName &&
                newCouponName !== originalName
            ) {
                isUniqueName = false;
            }
        });

        this.setState({ 
            coupon: { 
                ...this.state.coupon, 
                couponName 
            },
            isUniqueName
        });
    }

    onChangeCouponCode = code => {
        this.setState({ 
            coupon: { 
                ...this.state.coupon, 
                code 
            }
        });
    }

    onChangeOutputText = outputText => {
        this.setState({ 
            coupon: { 
                ...this.state.coupon, 
                outputText 
            }
        });
    }

    onActivateCoupon = (active = false) => {
        this.setState({ 
            coupon: { 
                ...this.state.coupon, 
                active 
            }
        });
    }

    onToggleLicenseRequired = licenseRequired => {
        this.setState({ 
            coupon: { 
                ...this.state.coupon, 
                licenseRequired
            }
        });
    }

    onSelectDiscount = (discounts) => {
        this.setState({ 
            coupon: { 
                ...this.state.coupon, 
                discounts: discounts.map(({ discountId }) => discountId)
            },
            selectedDiscounts: discounts
        });
    }

    getSelectedDiscounts = () => {
        const { selectedDiscounts } = this.state;

        if(!Array.isArray(selectedDiscounts) || !selectedDiscounts?.length) return [];
        return selectedDiscounts;
    }

    enableSaveButton = () => {
        const { coupon } = this.state;

        return coupon &&
            coupon.couponName &&
            coupon.outputText &&
            coupon.discounts?.length
    }

    onDelete = couponId => {
        const {
            onHide,
            deleteCoupon,
            templateId
        } = this.props;
        
        deleteCoupon(couponId, templateId, onHide);
    }

    onSave = (coupon = null) => {
        const { isEditing } = this.state;
        const {
            templateId,
            onHide,
            saveCoupon,
            updateCoupon    
        } = this.props;

        if(isEditing) {
            updateCoupon(coupon, templateId, onHide);
        } else {
            saveCoupon(coupon, templateId, onHide);
        }
    }
    
    componentDidMount() {
        const {
            templateId,
            selectedCoupon,
            getDiscounts,
            getCouponDiscounts,
            discountOptions
        } = this.props;

        getDiscounts(templateId);
        let filteredOptions = [];
        
        if(selectedCoupon.discounts?.length) {
            getCouponDiscounts(templateId, selectedCoupon.couponId, selectedCoupon.discounts);
        }

        this.setState({ 
            coupon: { ...selectedCoupon },
            couponOriginalName: selectedCoupon.couponName,
            isEditing: selectedCoupon?.couponId ? true : false,
        });
    }

    componentWillUnmount() {
        const { resetCouponDiscounts } = this.props;
        resetCouponDiscounts();
    }

    UNSAFE_componentWillUpdate(nextProps) {
        const {couponDiscounts} = this.props;
        const {selectedDiscounts} = this.state;

        if(couponDiscounts != nextProps.couponDiscounts)
        {
            const mappedDiscounts = nextProps.couponDiscounts.map((discount) => ({ 
                ...discount,
                value: discount.outputText,
                id: discount.discountId
            }));
            this.setState({selectedDiscounts: mappedDiscounts});
        }
    }

    render() {
        const {
            coupon,
            isEditing,
            isUniqueName
        } = this.state;

        const {
            onHide,
            saving,
            updating,
            deleting,
            loadingDiscounts,
            loadingCouponDiscounts,
        } = this.props;

        return (
            <Modal
                className='coupon-config-modal'
                show={true}
                onHide={onHide}
            >
                <Modal.Body>
                    <Panel
                        header={isEditing ? `${coupon.couponName}` : 'New Coupon'}
                        headerStyling={{
                            fontSize: '20px',
                            paddingTop: '0.75rem',
                            paddingBottom: '0.75rem'
                        }}
                        hasShadow={false}
                    >
                        <div className='d-flex flex-column row-gap-1 mt-1 mb-1 mr-2 ml-2'>
                            <div className='d-flex row-gap-1 column-gap-1 flex-wrap'>
                                <div className='flex-grow-1'>
                                    <Input
                                        label='Coupon Name'
                                        value={coupon?.couponName}
                                        placeholder='Coupon name'
                                        onChange={({ target: { value } }) => this.onChangeCouponName(value)}
                                        error={!isUniqueName || !coupon?.couponName}
                                        errorMsg={!isUniqueName ? 'A coupon with this name already exists' : 'This field is required'}
                                    />
                                </div>
                                <div className='flex-grow-1'>
                                    <Input
                                        label='Code'
                                        value={coupon?.code}
                                        placeholder='Coupon code'
                                        onChange={({ target: { value } }) => this.onChangeCouponCode(value)}
                                    />
                                </div>
                                <div className='flex-grow-2'>
                                    <Input
                                        label='Display Text'
                                        value={coupon?.outputText}
                                        placeholder='Text to display in brochure'
                                        onChange={({ target: { value } }) => this.onChangeOutputText(value)}
                                        error={!coupon?.outputText}
                                        errorMsg='This field is required'
                                    />
                                </div>
                                <div className='d-flex column-gap-2'>
                                    <Switch 
                                        id='couponConfigActive'
                                        label='Active'
                                        labelTop
                                        checked={coupon?.active}
                                        onToggle={checked => this.onActivateCoupon(checked)}
                                    />
                                    <Switch 
                                        id='couponConfigLicenseRequired'
                                        label='License Required'
                                        labelTop
                                        checked={coupon?.licenseRequired}
                                        onToggle={checked => this.onToggleLicenseRequired(checked)}
                                    />
                                </div>
                            </div>
                            {
                                (loadingDiscounts || loadingCouponDiscounts) ? 
                                    <Loader loadingMessage='Loading discounts...' />
                                :
                                    <MultiSelect 
                                        label='Discounts'
                                        enableSelection
                                        placeholder='You can select multiple discounts'
                                        onChange={discounts => this.onSelectDiscount(discounts)}
                                        options={this.generateDiscountOptions()}
                                        selectedOptions={this.getSelectedDiscounts()}
                                        error={!coupon?.discounts?.length}
                                        errorMsg='This field is required'
                                    />
                            }
                        </div>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button 
                            btnType='outline-danger'
                            label={isEditing ? 'Delete Coupon' : 'Cancel'}
                            action={() => {
                                if(isEditing) {
                                    this.onDelete(coupon?.couponId);
                                } else {
                                    onHide();
                                }
                            }}
                            loading={deleting}
                            loadingMessage='Deleting coupon...'
                            disabled={saving || updating}
                        />
                        <Button
                            btnType='primary'
                            label={isEditing ? 'Save Changes' : 'Create Coupon'}
                            disabled={!this.enableSaveButton() || deleting || !isUniqueName}
                            action={() => this.onSave(coupon)}
                            loading={saving || updating}
                            loadingMessage={saving ? 'Saving coupon...' : 'Updating coupon...'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    saving: state.couponsConfig?.saving,
    updating: state.couponsConfig?.updating,
    deleting: state.couponsConfig?.deleting,
    loadingCouponDiscounts: state.couponsConfig?.loadingCouponDiscounts,
    couponDiscounts: state.couponsConfig?.selectedCouponDiscounts,
    loadingDiscounts: state.discounts?.loading,
    discountOptions: state.discounts?.discounts,
    coupons: state.couponsConfig?.coupons
});

export default connect(mapStateToProps, {
    saveCoupon: saveCouponAction,
    updateCoupon: updateCouponAction,
    deleteCoupon: deleteCouponAction,
    getDiscounts: getDiscountsAction,
    getCouponDiscounts: getCouponDiscountsAction,
    resetCouponDiscounts: resetCouponDiscountsAction
})(CouponConfigModal);

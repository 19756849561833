import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Button from 'components/common/button/Button';
import PlanSubmissionCard from './PlanSubmissionCard';

import userSession from 'utils/userSession';

import { 
    getMailingPlan as getMailingPlanAction 
} from 'actions/mailingPlanActions';

export class SubmissionsModal extends Component {

    static propTypes = {
        show: PropTypes.string,
        onHide: PropTypes.func
    }
    
    renderSubmissions = () => {
        const { 
            onHide,
            submissions,
            selectedPlanId,
            getMailingPlan
        } = this.props;

        if(!submissions || !submissions.length) {
            return (
                <div>No additional submissions to display</div>
            );
        }

        return submissions.map(sub => {
            return <PlanSubmissionCard 
                key={sub.mailingPlanId}
                year={sub.mailingPlanYear}
                status={sub.status}
                date={sub.updatedDate}
                selected={sub.mailingPlanId === selectedPlanId}
                onSelectSubmission={() => {
                    getMailingPlan(
                        userSession.getBrandId(), 
                        userSession.getFranchiseId(), 
                        sub.mailingPlanId
                    );
                    onHide();
                }}
            />;
        });
    }

    render() {
        const { 
            show,
            onHide
        } = this.props;

        return (
            <Modal 
                show={show} 
                onHide={() => onHide()} 
                className='mailing-plan-submissions-modal'
            >
                <Modal.Header>
                    <div className='modal-title'>
                        Plan submissions
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center justify-content-center flex-wrap column-gap-1 row-gap-1'>
                        {this.renderSubmissions()}
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button 
                        btnType='outline-primary'
                        label='Return to Dashboard'
                        action={() => onHide()}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    submissions: state.planSubmissions?.submissions,
    selectedPlanId: state.mailingPlan?.mailingPlanId
});

export default connect(mapStateToProps, {
    getMailingPlan: getMailingPlanAction
})(SubmissionsModal);

import {
    TOGGLE_CAMPAIGN_SELECTION,
    SAVE_CAMPAIGN_CONFIG
} from './types';

export const toggleCampaignSelection = (
    campaignId = null
) => {
    return {
        type: TOGGLE_CAMPAIGN_SELECTION,
        payload: campaignId
    };
}

export const saveCampaignConfig = campaign => {
    const selectedCoupons = [];

    campaign.selectedCoupons?.forEach(coupon => {
        selectedCoupons.push({
            couponId: coupon.couponId,
            couponName: coupon.couponName,
            outputText: coupon.outputText,
            selected: coupon.selected,
            discount: {
                discountId: coupon.discount?.discountId,
                value: coupon.discount?.value,
                outputText: coupon.discount?.outputText,
                selected: coupon.discount?.selected,
            }
        });
    })

    const selectedServices = campaign.services?.filter(service => {
        if(service.selected) {
            return service;
        }
        
        return false;
    }) || [];

    const selectedBadges = campaign.selectedBrochure?.badges?.filter(badge => {
        if(badge.selected) {
            return badge;
        }
        return false;
    }) || [];
    return {
        type: SAVE_CAMPAIGN_CONFIG,
        payload: {...campaign, selectedServices, selectedCoupons: [...selectedCoupons],selectedBadges:[...selectedBadges]}
    };
}
import { 
    ACTIVATE_MAILING_PLAN_TEMPLATE,
    ACTIVATE_MAILING_PLAN_TEMPLATE_LOADING,
    ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS,
    DELETE_CAMPAIGNS_FROM_TEMPLATE_LOADING,
    DELETE_MAILING_PLAN_TEMPLATE_LOADING,
    DELETE_MAILING_PLAN_TEMPLATE_SUCCESS,
    GET_BUSINESS_RULES,
    GET_BUSINESS_RULES_LOADING,
    GET_MAILING_PLAN_TEMPLATE,
    GET_MAILING_PLAN_TEMPLATE_LOADING,
    GET_SEND_ORDER_OPTIONS,
    ON_UPDATE_PLAN_CONFIG,
    POST_NEW_CAMPAIGN_TEMPLATES,
    POST_NEW_CAMPAIGN_TEMPLATES_LOADING,
    RESET_PLAN_CONFIG_STATE,
    UPDATE_CAMPAIGN_TEMPLATES,
    UPDATE_CAMPAIGN_TEMPLATES_LOADING,
    UPDATE_MAILING_PLAN_TEMPLATE,
    UPDATE_MAILING_PLAN_TEMPLATE_LOADING
} from 'actions/types';

const planConfigReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case ON_UPDATE_PLAN_CONFIG:
            return { ...state, mailingPlanTemplate: action.payload, pendingChanges: true };

        case RESET_PLAN_CONFIG_STATE:
            return action.payload;

        case GET_MAILING_PLAN_TEMPLATE:
            return { ...state, mailingPlanTemplate: action.payload, pendingChanges: true };
            
        case GET_MAILING_PLAN_TEMPLATE_LOADING:
            return { ...state, loadingMailingPlanTemplate: action.payload };
                
        case GET_BUSINESS_RULES:
            return { ...state, templateBusinessRules: action.payload };
        
        case GET_BUSINESS_RULES_LOADING:
            return { ...state, loadingTemplateBusinessRules: action.payload };

        case POST_NEW_CAMPAIGN_TEMPLATES:
            return { ...state, campaignTemplates: action.payload };

        case POST_NEW_CAMPAIGN_TEMPLATES_LOADING:
            return { ...state, savingCampaignTemplates: action.payload };

        case UPDATE_CAMPAIGN_TEMPLATES:
            return { ...state, updateCampaignTemplates: action.payload };

        case UPDATE_CAMPAIGN_TEMPLATES_LOADING:
            return { ...state, updatingCampaignTemplates: action.payload };

        case UPDATE_MAILING_PLAN_TEMPLATE:
            return { ...state, updateMailingPlanTemplate: action.payload };

        case UPDATE_MAILING_PLAN_TEMPLATE_LOADING:
            return { ...state, updatingMailingPlanTemplate: action.payload };

        case ACTIVATE_MAILING_PLAN_TEMPLATE:
            return { ...state, planActivated: action.payload };

        case ACTIVATE_MAILING_PLAN_TEMPLATE_LOADING:
            return { ...state, activatingMailingPlan: action.payload };

        case ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS:
            return { ...state, mailingPlanTemplate: { ...state.mailingPlanTemplate, active: true } };

        case DELETE_CAMPAIGNS_FROM_TEMPLATE_LOADING:
            return { ...state, deletingCampaignsFromTemplate: action.payload };

        case DELETE_MAILING_PLAN_TEMPLATE_LOADING:
            return { ...state, deletingMailingPlanTemplate: action.payload };

        case DELETE_MAILING_PLAN_TEMPLATE_SUCCESS:
            return { ...state, mailingPlanTemplateDeleted: true };

        case GET_SEND_ORDER_OPTIONS:
            return { ...state, sendOrderOptions: action.payload };

        default:
            return state;
    }
}

export default planConfigReducer;
import { useMutation, useQueryClient } from 'react-query';
import useAlert from 'hooks/useAlert';
import { addFranchiseWebsite } from 'api/lmpbp/websites';
import api from "api/lmpbp";

function useAddFranchiseWebsite(websiteId, onClose, queryKeyToInvalidate) {
  const queryClient = useQueryClient();

  const addFranchiseWebsiteSuccessAlert = useAlert({
    title: 'Success',
    id: 'add-franchise-website-success',
    message: 'Website added to franchise successfully',
    type: 'success',
  });

  const addFranchiseWebsiteErrorAlert = useAlert({
    id: 'add-franchise-website-error',
    message: 'Error adding website to franchise',
    type: 'danger',
  });

  const addFranchiseWebsiteMutation = useMutation(
    ({franchiseId, tacticId, isParent}) =>
      api().post(`franchise/${franchiseId}/tactic/${tacticId}/website`,
        {
          websiteId: websiteId
        },
        {
          params: {
            isParent: isParent
          }
        }),
    {
      onError: (error) => {
        const serverError = {
          message: error.response.data.error,
          code: error.response.status
        }
        addFranchiseWebsiteErrorAlert(serverError);
      },
      onSuccess: () => {
        addFranchiseWebsiteSuccessAlert();
        onClose();
        queryClient.invalidateQueries(queryKeyToInvalidate);
      }
    }
  );
  return addFranchiseWebsiteMutation;
}

export default useAddFranchiseWebsite;

const overviewWrapper = overviewData => {
    if(
        !overviewData
        || Array.isArray(overviewData)
        || typeof overviewData !== 'object'
    ) return {};

    return {
        submitted: overviewData?.submitted,
        approved: overviewData?.approved,
        unsubmitted: overviewData?.unsubmitted,
        pendings: overviewData?.pendings,
        totalFranchises: overviewData?.totalFranchises,
        dmExemptedFranchises: overviewData?.dmExemptedFranchises
    }
}

export default overviewWrapper;
import {
    GET_BRAND_INFO_ERROR,
    GET_BROCHURES_ERROR,
    GET_FRANCHISE_INFO_ERROR,
    GET_MAILING_PLAN_ERROR,
    GET_MAILING_PLANS_ERROR,
    GET_ZIP_CODES_ERROR,
    SUBMIT_MAILING_PLAN_ERROR,
    CLOSE_NOTIFICATION,
    GET_MAILING_PLAN_STATUSES_ERROR,
    APPROVE_MAILING_PLAN_ERROR,
    REJECT_MAILING_PLAN_ERROR,
    FRANCONNECT_SYNC_ERROR,
    FRANCONNECT_SYNC_SUCCESS,
    GET_ADDRESS_IMPORTS_LOG_ERROR,
    INSUFFICIENT_ADDRESSES_WARNING,
    OVERRIDE_MAILING_PLAN_ERROR,
    GET_MAILING_YEARS_ERROR,
    GET_CAMPAIGNS_TEMPLATES_ERROR,
    GET_YEARLY_PLANS_ERROR,
    GET_BUSINESS_RULES_ERROR,
    DELETE_ADDRESSES_FILE_ERROR,
    DELETE_ADDRESSES_FILE_SUCCESS,
    EXPORT_CAMPAIGN_ERROR,
    EXPORT_CAMPAIGN_SUCCESS,
    EXPORT_PLAN_ERROR,
    EXPORT_PLAN_SUCCESS,
    GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_ERROR,
    POST_NEW_CAMPAIGN_TEMPLATES_ERROR,
    UPDATE_CAMPAIGN_TEMPLATES_ERROR,
    ACTIVATE_MAILING_PLAN_TEMPLATE_ERROR,
    UPDATE_MAILING_PLAN_TEMPLATE_ERROR,
    GET_MAILING_PLAN_TEMPLATE_ERROR,
    DOWNLOAD_CAMPAIGN_EXPORT_ERROR,
    ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS,
    UPDATE_MAILING_PLAN_TEMPLATE_SUCCESS,
    DELETE_CAMPAIGNS_FROM_TEMPLATE_ERROR,
    GET_SEND_ORDER_OPTIONS_ERROR,
    DELETE_MAILING_PLAN_TEMPLATE_ERROR,
    DELETE_MAILING_PLAN_TEMPLATE_SUCCESS,
    GET_COUPONS_ERROR,
    SAVE_BROCHURE_ERROR,
    SAVE_BROCHURE_IMAGE_ERROR,
    GET_BROCHURE_COUPONS_ERROR,
    UPDATE_BROCHURE_ERROR,
    GET_BROCHURES_FOR_CONFIG_LIST_ERROR,
    DELETE_BROCHURE_ERROR,
    SAVE_BROCHURE_SUCCESS,
    GET_COUPONS_FOR_CONFIG_LIST_ERROR,
    UPDATE_BROCHURE_SUCCESS,
    DELETE_BROCHURE_SUCCESS,
    SAVE_COUPON_ERROR,
    SAVE_COUPON_SUCCESS,
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_ERROR,
    DELETE_COUPON_ERROR,
    DELETE_COUPON_SUCCESS,
    GET_COUPON_DISCOUNTS_ERROR,
    CREATE_YEARLY_PLAN_SUCCESS,
    CREATE_YEARLY_PLAN_ERROR,
    GET_DISCOUNTS_ERROR,
    GET_DISCOUNTS_FOR_CONFIG_LIST_ERROR,
    SAVE_DISCOUNT_ERROR,
    SAVE_DISCOUNT_SUCCESS,
    UPDATE_DISCOUNT_ERROR,
    UPDATE_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_ERROR,
    DELETE_DISCOUNT_SUCCESS,
    GET_SERVICES_FOR_CONFIG_LIST_ERROR,
    SAVE_SERVICE_ERROR,
    SAVE_SERVICE_SUCCESS,
    UPDATE_SERVICE_ERROR,
    UPDATE_SERVICE_SUCCESS,
    DELETE_SERVICE_ERROR,
    DELETE_SERVICE_SUCCESS,
    GET_CAMPAIGNS_REPORT_ERROR,
    DOWNLOAD_ADDRESSES_FILE_ERROR,
    GET_MAILING_PLAN_SUBMISSIONS_ERROR,
    CONFIRM_EMAIL_REMINDERS_ERROR,
    CONFIRM_EMAIL_REMINDERS_SUCCESS,
    SHOW_NOTIFICATION,
} from 'actions/types';

const notificationsReducer = (state = {}, action = {}) => {
    switch (action.type) {
        case CLOSE_NOTIFICATION:
            return {
                ...state,
                [action.payload.id]: null,
            };

        case SHOW_NOTIFICATION:
            return {
                ...state,
                [action.payload.id]: { ...action.payload },
            };

        case GET_BRAND_INFO_ERROR:
            return {
                ...state,
                GET_BRAND_INFO_ERROR: { ...action.payload }
            };

        case GET_BROCHURES_ERROR:
            return {
                ...state,
                GET_BROCHURES_ERROR: { ...action.payload }
            };

        case GET_COUPONS_ERROR:
            return {
                ...state,
                GET_COUPONS_ERROR: { ...action.payload }
            };

        case GET_DISCOUNTS_ERROR:
            return {
                ...state,
                GET_DISCOUNTS_ERROR: { ...action.payload }
            };

        case GET_FRANCHISE_INFO_ERROR:
            return {
                ...state,
                GET_FRANCHISE_INFO_ERROR: { ...action.payload }
            };

        case GET_MAILING_PLAN_ERROR:
            return {
                ...state,
                GET_MAILING_PLAN_ERROR: { ...action.payload }
            };

        case GET_MAILING_PLANS_ERROR:
            return {
                ...state,
                GET_MAILING_PLANS_ERROR: { ...action.payload }
            };

        case GET_ZIP_CODES_ERROR:
            return {
                ...state,
                GET_ZIP_CODES_ERROR: { ...action.payload }
            };

        case SUBMIT_MAILING_PLAN_ERROR:
            return {
                ...state,
                SUBMIT_MAILING_PLAN_ERROR: { ...action.payload }
            };

        case GET_MAILING_PLAN_STATUSES_ERROR:
            return {
                ...state,
                GET_MAILING_PLAN_STATUSES_ERROR: { ...action.payload }
            };

        case APPROVE_MAILING_PLAN_ERROR:
            return {
                ...state,
                APPROVE_MAILING_PLAN_ERROR: { ...action.payload }
            };

        case REJECT_MAILING_PLAN_ERROR:
            return {
                ...state,
                REJECT_MAILING_PLAN_ERROR: { ...action.payload }
            };

        case FRANCONNECT_SYNC_ERROR:
            return {
                ...state,
                FRANCONNECT_SYNC_ERROR: { ...action.payload }
            };

        case FRANCONNECT_SYNC_SUCCESS:
            return {
                ...state,
                FRANCONNECT_SYNC_SUCCESS: { ...action.payload }
            };

        case GET_ADDRESS_IMPORTS_LOG_ERROR:
            return {
                ...state,
                GET_ADDRESS_IMPORTS_LOG_ERROR: { ...action.payload }
            };

        case INSUFFICIENT_ADDRESSES_WARNING:
            return {
                ...state,
                INSUFFICIENT_ADDRESSES_WARNING: { ...action.payload }
            };

        case OVERRIDE_MAILING_PLAN_ERROR:
            return {
                ...state,
                OVERRIDE_MAILING_PLAN_ERROR: { ...action.payload }
            };

        case GET_MAILING_YEARS_ERROR:
            return {
                ...state,
                GET_MAILING_YEARS_ERROR: { ...action.payload }
            };

        case GET_CAMPAIGNS_TEMPLATES_ERROR:
            return {
                ...state,
                GET_CAMPAIGNS_TEMPLATES_ERROR: { ...action.payload }
            };

        case GET_YEARLY_PLANS_ERROR:
            return {
                ...state,
                GET_YEARLY_PLANS_ERROR: { ...action.payload }
            };

        case GET_BUSINESS_RULES_ERROR:
            return {
                ...state,
                GET_BUSINESS_RULES_ERROR: { ...action.payload }
            };

        case DELETE_ADDRESSES_FILE_ERROR:
            return {
                ...state,
                DELETE_ADDRESSES_FILE_ERROR: { ...action.payload }
            };

        case DELETE_ADDRESSES_FILE_SUCCESS:
            return {
                ...state,
                DELETE_ADDRESSES_FILE_SUCCESS: { ...action.payload }
            };

        case EXPORT_CAMPAIGN_ERROR:
            return {
                ...state,
                EXPORT_CAMPAIGN_ERROR: { ...action.payload }
            };

        case EXPORT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                EXPORT_CAMPAIGN_SUCCESS: { ...action.payload }
            };

            case EXPORT_PLAN_ERROR:
                return {
                    ...state,
                    EXPORT_PLAN_ERROR: { ...action.payload }
                };
    
            case EXPORT_PLAN_SUCCESS:
                return {
                    ...state,
                    EXPORT_PLAN_SUCCESS: { ...action.payload }
                };

        case GET_MAILING_PLAN_TEMPLATE_ERROR:
            return {
                ...state,
                GET_MAILING_PLAN_TEMPLATE_ERROR: { ...action.payload }
            };

        case GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_ERROR:
            return {
                ...state,
                GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_ERROR: { ...action.payload }
            };

        case POST_NEW_CAMPAIGN_TEMPLATES_ERROR:
            return {
                ...state,
                POST_NEW_CAMPAIGN_TEMPLATES_ERROR: { ...action.payload }
            };

        case UPDATE_CAMPAIGN_TEMPLATES_ERROR:
            return {
                ...state,
                UPDATE_CAMPAIGN_TEMPLATES_ERROR: { ...action.payload }
            };

        case UPDATE_MAILING_PLAN_TEMPLATE_ERROR:
            return {
                ...state,
                UPDATE_MAILING_PLAN_TEMPLATE_ERROR: { ...action.payload }
            };

        case ACTIVATE_MAILING_PLAN_TEMPLATE_ERROR:
            return {
                ...state,
                ACTIVATE_MAILING_PLAN_TEMPLATE_ERROR: { ...action.payload }
            };

        case ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS:
            return {
                ...state,
                ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS: { ...action.payload }
            };

        case UPDATE_MAILING_PLAN_TEMPLATE_SUCCESS:
            return {
                ...state,
                UPDATE_MAILING_PLAN_TEMPLATE_SUCCESS: { ...action.payload }
            };

        case DELETE_CAMPAIGNS_FROM_TEMPLATE_ERROR:
            return {
                ...state,
                DELETE_CAMPAIGNS_FROM_TEMPLATE_ERROR: { ...action.payload }
            };

        case DOWNLOAD_CAMPAIGN_EXPORT_ERROR:
            return {
                ...state,
                DOWNLOAD_CAMPAIGN_EXPORT_ERROR: { ...action.payload }
            };

        case GET_SEND_ORDER_OPTIONS_ERROR:
            return {
                ...state,
                GET_SEND_ORDER_OPTIONS_ERROR: { ...action.payload }
            };

        case DELETE_MAILING_PLAN_TEMPLATE_ERROR:
            return {
                ...state,
                DELETE_MAILING_PLAN_TEMPLATE_ERROR: { ...action.payload }
            };

        case DELETE_MAILING_PLAN_TEMPLATE_SUCCESS:
            return {
                ...state,
                DELETE_MAILING_PLAN_TEMPLATE_SUCCESS: { ...action.payload }
            };

        case SAVE_BROCHURE_SUCCESS:
            return {
                ...state,
                SAVE_BROCHURE_SUCCESS: { ...action.payload }
            };

        case SAVE_BROCHURE_ERROR:
            return {
                ...state,
                SAVE_BROCHURE_ERROR: { ...action.payload }
            };

        case UPDATE_BROCHURE_ERROR:
            return {
                ...state,
                UPDATE_BROCHURE_ERROR: { ...action.payload }
            };

        case UPDATE_BROCHURE_SUCCESS:
            return {
                ...state,
                UPDATE_BROCHURE_SUCCESS: { ...action.payload }
            };

        case SAVE_BROCHURE_IMAGE_ERROR:
            return {
                ...state,
                SAVE_BROCHURE_IMAGE_ERROR: { ...action.payload }
            };

        case GET_BROCHURE_COUPONS_ERROR:
            return {
                ...state,
                GET_BROCHURE_COUPONS_ERROR: { ...action.payload }
            };

        case GET_BROCHURES_FOR_CONFIG_LIST_ERROR:
            return {
                ...state,
                GET_BROCHURES_FOR_CONFIG_LIST_ERROR: { ...action.payload }
            };

        case DELETE_BROCHURE_ERROR:
            return {
                ...state,
                DELETE_BROCHURE_ERROR: { ...action.payload }
            };

        case DELETE_BROCHURE_SUCCESS:
            return {
                ...state,
                DELETE_BROCHURE_SUCCESS: { ...action.payload }
            };

        case GET_COUPONS_FOR_CONFIG_LIST_ERROR:
            return {
                ...state,
                GET_COUPONS_FOR_CONFIG_LIST_ERROR: { ...action.payload }
            };

        case SAVE_COUPON_ERROR:
            return {
                ...state,
                SAVE_COUPON_ERROR: { ...action.payload }
            };

        case SAVE_COUPON_SUCCESS:
            return {
                ...state,
                SAVE_COUPON_SUCCESS: { ...action.payload }
            };

        case UPDATE_COUPON_ERROR:
            return {
                ...state,
                UPDATE_COUPON_ERROR: { ...action.payload }
            };

        case UPDATE_COUPON_SUCCESS:
            return {
                ...state,
                UPDATE_COUPON_SUCCESS: { ...action.payload }
            };

        case DELETE_COUPON_ERROR:
            return {
                ...state,
                DELETE_COUPON_ERROR: { ...action.payload }
            };

        case DELETE_COUPON_SUCCESS:
            return {
                ...state,
                DELETE_COUPON_SUCCESS: { ...action.payload }
            };

        case GET_COUPON_DISCOUNTS_ERROR:
            return {
                ...state,
                GET_COUPON_DISCOUNTS_ERROR: { ...action.payload }
            };

        case GET_DISCOUNTS_FOR_CONFIG_LIST_ERROR:
            return {
                ...state,
                GET_DISCOUNTS_FOR_CONFIG_LIST_ERROR: { ...action.payload }
            };

        case SAVE_DISCOUNT_ERROR:
            return {
                ...state,
                SAVE_DISCOUNT_ERROR: { ...action.payload }
            };

        case SAVE_DISCOUNT_SUCCESS:
            return {
                ...state,
                SAVE_DISCOUNT_SUCCESS: { ...action.payload }
            };

        case UPDATE_DISCOUNT_ERROR:
            return {
                ...state,
                UPDATE_DISCOUNT_ERROR: { ...action.payload }
            };

        case UPDATE_DISCOUNT_SUCCESS:
            return {
                ...state,
                UPDATE_DISCOUNT_SUCCESS: { ...action.payload }
            };

        case DELETE_DISCOUNT_ERROR:
            return {
                ...state,
                DELETE_DISCOUNT_ERROR: { ...action.payload }
            };

        case DELETE_DISCOUNT_SUCCESS:
            return {
                ...state,
                DELETE_DISCOUNT_SUCCESS: { ...action.payload }
            };

        case GET_SERVICES_FOR_CONFIG_LIST_ERROR:
            return {
                ...state,
                GET_SERVICES_FOR_CONFIG_LIST_ERROR: { ...action.payload }
            };

        case SAVE_SERVICE_ERROR:
            return {
                ...state,
                SAVE_SERVICE_ERROR: { ...action.payload }
            };

        case SAVE_SERVICE_SUCCESS:
            return {
                ...state,
                SAVE_SERVICE_SUCCESS: { ...action.payload }
            };

        case UPDATE_SERVICE_ERROR:
            return {
                ...state,
                UPDATE_SERVICE_ERROR: { ...action.payload }
            };

        case UPDATE_SERVICE_SUCCESS:
            return {
                ...state,
                UPDATE_SERVICE_SUCCESS: { ...action.payload }
            };

        case DELETE_SERVICE_ERROR:
            return {
                ...state,
                DELETE_SERVICE_ERROR: { ...action.payload }
            };

        case DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                DELETE_SERVICE_SUCCESS: { ...action.payload }
            };

        case CREATE_YEARLY_PLAN_SUCCESS:
            return {
                ...state,
                CREATE_YEARLY_PLAN_SUCCESS: { ...action.payload }
            };

        case CREATE_YEARLY_PLAN_ERROR:
            return {
                ...state,
                CREATE_YEARLY_PLAN_ERROR: { ...action.payload }
            };

        case GET_CAMPAIGNS_REPORT_ERROR:
            return {
                ...state,
                GET_CAMPAIGNS_REPORT_ERROR: { ...action.payload }
            };

        case DOWNLOAD_ADDRESSES_FILE_ERROR:
            return {
                ...state,
                DOWNLOAD_ADDRESSES_FILE_ERROR: { ...action.payload }
            };

        case GET_MAILING_PLAN_SUBMISSIONS_ERROR:
            return {
                ...state,
                GET_MAILING_PLAN_SUBMISSIONS_ERROR: { ...action.payload }
            };

        case CONFIRM_EMAIL_REMINDERS_ERROR:
            return {
                state,
                CONFIRM_EMAIL_REMINDERS_ERROR: { ...action.payload }
            };

        case CONFIRM_EMAIL_REMINDERS_SUCCESS:
            return {
                ...state,
                CONFIRM_EMAIL_REMINDERS_SUCCESS: { ...action.payload }
            };
        default:
            return { ...state };
    }
};

export default notificationsReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavButton = ({
    label = '',
    icon = '',
    to = '',
    loading = false,
    loadingMessage = 'Navigating...',
    disabled = false,
    onClickCallback = null,
    ...props
}) => {
    const renderIcon = () => {
        if(loading) {
            return (
                <i className='d-flex align-items-center justify-content-center'>
                    <div className='spinner-border spinner-border-sm' role='status'></div>
                </i>
            );
        }

        return (icon ? <i className={icon}></i> : null);
    }

    const renderLabel = () => {
        if(loading) {
            return <span className='nav-button-title'>{loadingMessage}</span>;
        }

        return <span className='nav-button-title'>{label}</span>;
    }

    const enableNav = () => {
        if(disabled) {
            return '';
        }

        return to;
    }

    return (
        <Link 
            to={enableNav()} 
            className={`nav-button ${loading ? 'brand-primary-color' : ''}`.trim()}
            onClick={() => {
                if(!disabled && onClickCallback) {
                    onClickCallback();
                }
            }}
            {...props}
        >
            {renderIcon()}
            {renderLabel()}
        </Link>
    );
}

NavButton.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    to: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    disabled: PropTypes.bool,
    onClickCallback: PropTypes.func
}

export default NavButton;

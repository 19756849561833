import moment from "moment";
import formatDate from "utils/formatDate";
import userSession from "utils/userSession";

export const brandSpecificInstructions = {
    "MSQ": {
        'PulseM': <div>Consumer Fusion and Management is HIGHLY RECOMMENDED to improve your SEO.</div>,
    },
    "HWC": {
        'Scorpion':
            <div>
                The Scorpion One Digital Marketing tactic includes, pay per click, remarketing, display ads, video ads, social
                media ads and directory/listing services.
            </div>,
        'ConsumerFusionSocialMedia':
            <div>
                The Consumer Fusion Social Media tactic includes, 12 local posts per month plus holiday posts on Facebook,
                Instagram, Twitter, LinkedIn and Google Business Profile (depending on what channels you run). You recieve
                (4) boosted posts per month ($150 Direct Ad Spend Included). You have access to a social library,
                a dashboard and a dedicated representative.
            </div>,
        'TargetedEmailMarketing':
            <div>
                Gray Media Targeted Email Marketing tactic includes a minimum of 35,000 emails per month, then a second push to those
                who have not opened the emails, seven days later. The emails target your owned zip codes and predetermined affinities.
                Creative development is included, however, standard creative is used unless otherwise determined prior to mailing.
            </div>,
        'RingProgrammaticMarketing':
            <div>
                The Ring Digital Programmatic Display tactic includes household targeted animated digital display ads, non0skippable,
                preproll video ads, smart TV ads, ongoing campaign optimizations and monthly matchback reporting.
            </div>,
        'LeadGeneratorsAPlaceForMom':
            <div>
                A Place for Mom:
                <ul>
                    <li>10 client leads; 5 Hot Transfers $875/mo</li>
                    <li>15 client leads; 5 Hot Transfers $1250/mo</li>
                    <li>20 client leads; 10 Hot Transfers $1750/mo</li>
                    <li>40 client leads; 20 Hot Transfers $3480/mo</li>
                </ul>
            </div>,
        'LeadGeneratorsAgingCare':
            <div>
                Aging Care:
                <ul>
                    <li>15 leads for $690 - $46/lead</li>
                    <li>24 leads for $984 - $41/lead</li>
                    <li>40 leads for $1560 - $39/lead</li>
                    <li>100 leads for $690 - $36/lead (Annual package)</li>
                </ul>
            </div>,
    }
}

export const tacticCommonInstructions = (tacticName, template, brandSpecificInstructionsByTactic) => {
    if (brandSpecificInstructionsByTactic && brandSpecificInstructionsByTactic[tacticName]) {
        return brandSpecificInstructionsByTactic[tacticName];
    }
    switch (tacticName) {
        case 'PulseM':
            return (
                <div>
                    {
                        (userSession.isDcsLmpbpUser() && userSession.isFranchiseOwer()) &&
                        <div className={`tactic-lock-date warning`}>
                            <i className='fas fa-exclamation-triangle'></i> &nbsp;
                            <strong> {renderLockDate(template.lockDates, template.year, tacticName)}</strong>
                        </div>
                    }
                    This tactic is $450/month in your first month of usage
                    if you are starting/restarting service and $350/month
                    thereafter. Please verify your selection accordingly.
                </div>

            );
        case 'Spectrum':
            return userSession.getBrandId() == 1 && (
                <div>
                    The amount entered below will be finalized upon MSQ owner
                    approval of Spectrum’s Mail Plan.  As Spectrum will be
                    pre-printing all pieces, this amount cannot be lowered or
                    canceled once you have approved your Spectrum Invoice.
                </div>
            );
        case 'LocalSocialMediaContent':
            return (
                <div>
                    {
                        (userSession.isDcsLmpbpUser() && userSession.isFranchiseOwer()) &&
                        <div className={`tactic-lock-date warning`}>
                            <i className='fas fa-exclamation-triangle'></i> &nbsp;
                            <strong> {renderLockDate(template.lockDates, template.year, tacticName)}</strong>
                        </div>
                    }
                    This tactic is $750/month in your first month of usage
                    if you are starting/restarting service and $600/month
                    thereafter. Please verify your selection accordingly.
                </div>

            );
        case 'OutOfHome':
            return (
                <div>
                    Prices vary greatly depending on market, location, size, and campaign length. If interested in this tactic, please reach out to your Marketing Manager first.
                </div>
            );
        default:
            return null;
    }
};

const renderLockDate = (lockDates, year, tacticName) => {
    const currentMonth = new moment().month();
    const currentYear = new moment().year();

    let lockedMonth;
    if (currentYear < year) {
        lockedMonth = 0;
    } else if (currentYear > year || currentYear === year && currentMonth === 11) {
        lockedMonth = 11;
    } else {
        lockedMonth = currentMonth + 1;
    }

    const currentMonthName = new moment().month(lockedMonth).format('MMMM');
    let lockedDate = new moment(lockDates[lockedMonth]);
    if (tacticName === 'PulseM' || tacticName === 'LocalSocialMediaContent') {
        lockedDate.subtract(1, 'months')
    }
    const formattedLockDateUTC = formatDate.dateUTC(lockedDate);
    return (
        <div>
            {currentMonthName} Lock Date: {formattedLockDateUTC}
        </div>
    );
};

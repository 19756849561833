import React, { useState } from "react";
import Panel from "components/common/panel/Panel";
import GoBackButton from "../goBackButton";
import { useLocation } from "react-router-dom";
import { Form, OverlayTrigger, Tooltip, Card, Container, Row, Col } from "react-bootstrap";
import AddWebsiteForm from "./AddWebsite";
import IconButton from "../custom-buttons/IconButton";
import SearchByFranchise from "./SearchByFranchise";
import SearchByWebsite from "./SearchByWebsite";

function ManageWebsites() {
  const location = useLocation();
  const selectedTemplate = location.state.selectedTemplate;

  const [value, setValue] = useState('Franchise');
  const [addingWebsite, setAddingWebsite] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);


  const handleChange = (e) => {
    setValue(e.target.value);
    setActivePage(1);
    setItemsPerPage(10);
  };

  return (
    <Panel header="Manage Websites" headerStyling={{ fontSize: "1.3rem" }}>
      <GoBackButton />
      <Container>
        <Row>
          <Col>
            <Form>
              <Form.Group>
                <Form.Label>Search By:</Form.Label>
                <div key={`inline-radio`} className="mb-3">

                  <Form.Check
                    inline
                    label=<span><i className="fa fa-building brand-primary-color" /> Franchise</span>
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    value="Franchise"
                    checked={value === 'Franchise'}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    label=<span><i className="fa fa-globe brand-primary-color" /> Website</span>
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    value="Website"
                    checked={value === 'Website'}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            {value === "Franchise"
              ? <SearchByFranchise
                selectedTemplate={selectedTemplate}
                activePage={activePage}
                setActivePage={setActivePage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
              : <SearchByWebsite
                selectedTemplate={selectedTemplate}
                activePage={activePage}
                setActivePage={setActivePage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}

              />
            }
          </Col>
        </Row>
      </Container>
      <div
        className="fixed-bottom m-5 d-flex justify-content-end align-items-center"
      >
        {addingWebsite
          ? <Card>
            <AddWebsiteForm onClose={() => setAddingWebsite(false)} />
          </Card>
          : <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="add-website-tooltip">Add new website</Tooltip>}
          >
            <IconButton
              className="position-fixed bottom-0 right-0"
              id="add-website"
              iconName="fa fa-plus"
              style={{ fontSize: '1.5rem' }}
              onClick={() => setAddingWebsite(true)}
            />
          </OverlayTrigger>
        }
      </div>
    </Panel>
  );
}

export default ManageWebsites;

import { LMPBP_DELETE_FRANCHISE_CUSTOM_TACTIC_ERROR } from 'actions/types';
import { deleteFranchiseCustomTactic } from 'api/lmpbp/tactics';
import DeleteButton from 'components/lmpbp/custom-buttons/DeleteButton/DeleteButton';
import { DELETE_CUSTOM_TACTIC_NOTIFICATION_ERROR_TEMPLATE, DELETE_CUSTOM_TACTIC_NOTIFICATION_SUCCESS_TEMPLATE } from 'constants/notificationTemplates';
import useCloseAlert from 'hooks/useCloseNotification';
import useAlert from 'hooks/useAlert';
import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { BudgetPlanContext } from '../../BudgetPlan';
import Tactic from './Tactic';
import ConfirmationModal from 'components/common/confirmation-modal/ConfirmationModal';
import validateStatus from 'utils/validateStatus';

const CustomTactic = (props) => {
    const { tactic } = props;
    const [showModal, setShowModal] = useState(false);
    const { template, budgetPlan } = useContext(BudgetPlanContext);
    const queryClient = useQueryClient();
    const showErrorNotification = useAlert(DELETE_CUSTOM_TACTIC_NOTIFICATION_ERROR_TEMPLATE);
    const showSuccessNotification = useAlert(DELETE_CUSTOM_TACTIC_NOTIFICATION_SUCCESS_TEMPLATE);
    const closeNotificationHandler = useCloseAlert();

    const mutation = useMutation(
        deleteFranchiseCustomTactic,
        {
            onError: showErrorNotification,
            onSuccess: () => {
                closeNotificationHandler(LMPBP_DELETE_FRANCHISE_CUSTOM_TACTIC_ERROR);
                showSuccessNotification();
                queryClient.invalidateQueries(['tactics', template.id, budgetPlan.externalId]);
            },
        });

    const handleDeleteCustomTactic = () => {
        // remind to double confirm
        setShowModal(false);
        mutation.mutate({
            budgetPlanId: budgetPlan.id,
            tacticId: tactic.id
        });
    }

    return (
        <div className='franchise-custom-tactic'>
            <ConfirmationModal
                show={showModal}
                type='warning' // 'warning' could also work, but 'danger' is more typical for deletions
                headerType='warning'
                header='Confirm Tactic Deletion'
                title='Are You Sure You Want to Delete This Tactic?'
                message='This will permanently remove the tactic and its associated monthly budget entries. This action cannot be undone.'
                headerIconLeft='fas fa-trash-alt'
                headerIconRight='fas fa-trash-alt'
                confirmBtnLabel='Delete Tactic'
                cancelBtnLabel='Cancel'
                showConfirmBtn={true}
                showCancelBtn={true}
                onConfirm={handleDeleteCustomTactic}
                onClose={() => setShowModal(false)}
                centered={true}
            />
            {
                validateStatus.isDraft(budgetPlan.status)
                && <DeleteButton 
                onClick={() => setShowModal(true)} 
                disabled={!tactic.deletable} 
                disabledTooltip="This tactic cannot be deleted since it is part of the approved plan. Contact your Marketing Manager for assistance."/>
            }
            <Tactic {...props} />
        </div>
    );
};

export default CustomTactic;
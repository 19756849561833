import React, { Component } from 'react';

import Loader from 'components/common/loader/Loader';
import userSession from 'utils/userSession';

class LmpbpDirectMail extends Component {
    componentDidMount() {
        document.title = 'Local Marketing Plan Budget Platform';
        document.getElementById("favicon").href = window.location.origin + '/lmpbp.png';
    }

    render() {
        return <Loader loadingMessage='Redirecting to Direct Mail...' />;
    }
}

export default LmpbpDirectMail;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ToggleButton = ({ onToggle }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <i 
            onClick={() => {
                setExpanded(!expanded);
                onToggle(!expanded);
            }}
            className={`brand-primary-color ${expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}`}>
        </i>
    );
}

ToggleButton.propTypes = {
    onToggle: PropTypes.func.isRequired
}

export default ToggleButton;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Panel from 'components/common/panel/Panel';
import Select from 'components/common/select/Select';
import Button from 'components/common/button/Button';
import DateRangePicker from 'components/common/date-range-picker/DateRangePicker';
import PlanDetailsModal from './PlanDetailsModal';
import Table from 'components/common/table/Table';

import mailingPlanColumns from './config/mailingPlanColumns';
import mailingPlansColumnsWrapper from './config/mailingPlansColumnsWrapper';
import userSession from 'utils/userSession';
import formatDate from 'utils/formatDate';
import validateStatus from 'utils/validateStatus';

import {
    getMailingPlans as getMailingPlansAction,
    getMailingPlanStatuses as getMailingPlanStatusesAction,
    getFranchises as getFranchisesAction,
    getMailingYears as getMailingYearsAction,
    getMailingPlansOverview as getMailingPlansOverviewAction,
    getCampaignsTemplatesByYear as getCampaignsTemplatesByYearAction,
    setMailingPlansStartDate as setMailingPlansStartDateAction,
    setMailingPlansEndDate as setMailingPlansEndDateAction,
    setMailingPlansStatusFilter as setMailingPlansStatusFilterAction,
    setMailingPlansFranchiseFilter as setMailingPlansFranchiseFilterAction,
    setMailingPlansYearFilter as setMailingPlansYearFilterAction,
    setMailingPlansCampaignDateFilter as setMailingPlansCampaignDateFilterAction,
    setMailingPlansBrochureFilter as setMailingPlansBrochureFilterAction,
    setMailingPlansCouponFilter as setMailingPlansCouponFilterAction,
    setMailingPlansStartDate,
    setMailingPlansEndDate,
    changeDmExemptStatus as changeDmExemptStatusAction,
    setMailingPlansDmExemptStatusFilter as setMailingPlansDmExemptStatusFilterAction,

} from 'actions/dashboardActions';
import { getCoupons as getCouponsAction } from 'actions/couponsActions';
import { getBrochures as getBrochuresAction } from 'actions/brochuresActions';
export class MailingPlans extends Component {
    state = {
        showPlanDetails: false,
        planDetails: {},
        hideDmExempt:  userSession.isASPUser() ? false :true,
    };
    onShowPlanDetails = (planDetails = {}) => {
        this.setState({
            showPlanDetails: true,
            planDetails: planDetails,
        });
    };
    onChangeDmExemptStatus = (id,isDmExempt) => {
        const {
            yearFilter,
        } = this.props;
        isDmExempt = isDmExempt?0:1;
        this.props.changeDmExemptStatus(id,isDmExempt,yearFilter);
    }
    onClosePlanDetails = () => {
        this.setState({ showPlanDetails: false });
    };

    campaignsContainBrochure = (campaigns = [], selectedBrochureId = null) => {
        if (!selectedBrochureId || !campaigns.length) return false;

        let containsBrochure = false;

        campaigns?.forEach(({ brochureId }) => {
            if (Number(brochureId) === Number(selectedBrochureId)) {
                containsBrochure = true;
            }
        });

        return containsBrochure;
    };

    campaignsContainCoupon = (campaigns = [], selectedCouponId = null) => {
        if (!selectedCouponId || !campaigns.length) return false;

        let containsCoupon = false;

        campaigns?.forEach(({ coupons }) => {
            coupons?.forEach(({ couponId }) => {
                if (Number(couponId) === Number(selectedCouponId)) {
                    containsCoupon = true;
                }
            });
        });

        return containsCoupon;
    };

    campaignsContainCampaignDate = (
        campaigns = [],
        selectedCampaignDate = null
    ) => {
        if (!selectedCampaignDate || !campaigns.length) return false;

        let containsCampaignDate = false;

        campaigns?.forEach(({ date }) => {
            if (formatDate.dateUTC(date) === selectedCampaignDate) {
                containsCampaignDate = true;
            }
        });

        return containsCampaignDate;
    };

    planWithinDateRange = (
        mailingPlan = {},
        selectedStartDate = null,
        selectedEndDate = null
    ) => {
        if (!mailingPlan) return false;
        if (!selectedStartDate && !selectedEndDate) return false;

        let planDate = new moment(mailingPlan?.submittedDate)
            .startOf('day')
            .valueOf();
        let startDate = new moment(selectedStartDate).startOf('day').valueOf();
        let endDate = new moment(selectedEndDate).endOf('day').valueOf();

        if (!planDate) {
            return true;
        }

        if (startDate && endDate) {
            return startDate <= planDate && planDate <= endDate;
        }

        if (startDate) {
            return planDate >= startDate;
        }

        if (endDate) {
            return planDate <= endDate;
        }

        return false;
    };

    hasValidStatus = (statusFilter, planStatus) => {
        if(!statusFilter) return true;
        
        if(statusFilter.toLowerCase() === 'all statuses') {
            return validateStatus.isUnsubmitted(planStatus) || validateStatus.isApproved(planStatus) || validateStatus.isRejected(planStatus) ||  validateStatus.isSubmitted(planStatus) || validateStatus.isDraft(planStatus);
        }

        return planStatus?.toLowerCase() === statusFilter?.toLowerCase();
    }

    hasValidDmExemptStatus = (dmExemptStatusFilter, planStatus) => {
        if(!dmExemptStatusFilter) return true;
        if(dmExemptStatusFilter.toLowerCase() === 'all statuses') {
            return true;
        }
        return planStatus == dmExemptStatusFilter;
    }

    hasValidFranchise = (franchiseFilter, franchise) => {
        if(
            !franchiseFilter || 
            franchiseFilter.toLowerCase() === 'all franchises'
        ) return true;

        return franchise?.toLowerCase() === franchiseFilter?.toLowerCase();
    }

    isWithinValidDateRange = (startDateFilter, endDateFilter, plan) => {
        if(!startDateFilter && !endDateFilter) return true;
        return this.planWithinDateRange(plan, startDateFilter, endDateFilter);
    }

    hasValidBrochure = (brochureFilter, plan) => {
        if(
            !brochureFilter || 
            brochureFilter.toLowerCase() === 'all brochures'
        ) return true;

        return this.campaignsContainBrochure(plan?.campaigns, brochureFilter);
    }

    hasValidCoupon = (couponFilter, plan) => {
        if(
            !couponFilter ||
            couponFilter.toLowerCase() === 'all coupons'
        ) return true;

        return this.campaignsContainCoupon(plan.campaigns, couponFilter);
    }

    hasValidCampaignDate = (campaignDateFilter, plan) => {
        if(
            !campaignDateFilter ||
            campaignDateFilter.toLowerCase() === 'all dates'
        ) return true;

        return this.campaignsContainCampaignDate(plan.campaigns, campaignDateFilter);
    }

    applyMailingPlanFilters = (mailingPlans = []) => {
        const {
            statusFilter,
            franchiseFilter,
            brochureFilter,
            couponFilter,
            campaignDateFilter,
            startDateFilter,
            endDateFilter,
            dmExemptStatusFilter
        } = this.props;

        if (!mailingPlans) return [];

        return mailingPlans?.filter((plan) => {
            if (!this.hasValidStatus(statusFilter, plan.status)) return false;
            if (!this.hasValidFranchise(franchiseFilter, plan.franchise)) return false;
            if (!this.isWithinValidDateRange(startDateFilter, endDateFilter, plan)) return false;
            if (!this.hasValidBrochure(brochureFilter, plan)) return false;
            if (!this.hasValidCoupon(couponFilter, plan)) return false;
            if (!this.hasValidCampaignDate(campaignDateFilter, plan)) return false;
            if (!this.hasValidDmExemptStatus(dmExemptStatusFilter, plan.isDmExempt)) return false;
            return true;
        });
    };

    generateTableData = () => {
        const { 
            mailingPlans,
            yearFilter,
        } = this.props;
           
        let customId = 0;

        if (!mailingPlans || !mailingPlans?.entries?.length) return [];
        const mappedPlansEntries = mailingPlans?.entries?.map((entry) => {
            customId += 1;
            return mailingPlansColumnsWrapper({
                entry,
                customId,
                viewDetailsCallback: this.onShowPlanDetails,
                onChangeDmExemptStatus: this.onChangeDmExemptStatus,
                yearFilter
            });
        });
        return this.applyMailingPlanFilters(mappedPlansEntries);
    };

    generateStatusOptions = () => {
        const { statuses } = this.props;

        if (!statuses || !statuses.length) return [];

        let mappedStatuses = statuses.map(({ statusId, statusName }) => ({
            id: statusId,
            label: statusName,
            value: statusName,
        }));

        mappedStatuses.unshift({
            id: 'all',
            label: 'All statuses',
            value: 'All statuses',
        });

        return mappedStatuses;
    };

    generateFranchiseOptions = () => {
        const { franchises } = this.props;

        if (!franchises || !franchises.length) return [];

        let mappedFranchises = [];
        franchises.forEach(
            ({ franchiseId, franchiseName, franchiseExternalId }) => {
                mappedFranchises.push({
                    id: franchiseId,
                    label: franchiseName || franchiseExternalId,
                    value: franchiseName || franchiseExternalId,
                });
            }
        );

        mappedFranchises.unshift({
            id: 'all',
            label: 'All franchises',
            value: 'All franchises',
        });

        return mappedFranchises;
    };

    generateBrochureOptions = () => {
        const { brochures } = this.props;

        if (!brochures || !brochures.length) return [];

        let mappedBrochures = [];
        brochures.forEach(({ brochureId, brochureName }) => {
            mappedBrochures.push({
                id: brochureId,
                label: brochureName,
                value: brochureId,
            });
        });

        mappedBrochures.unshift({
            id: 'all',
            label: 'All brochures',
            value: 'All brochures',
        });

        return mappedBrochures;
    };

    generateCouponOptions = () => {
        const { coupons } = this.props;

        if (!coupons || !coupons.length) return [];

        let mappedCoupons = [];
        coupons.forEach(({ couponId, couponName }) => {
            mappedCoupons.push({
                id: couponId,
                label: couponName,
                value: couponId,
            });
        });

        mappedCoupons.unshift({
            id: 'all',
            label: 'All coupons',
            value: 'All coupons',
        });

        return mappedCoupons;
    };

    generateCampaignDateOptions = () => {
        const { campaignsTemplates } = this.props;

        if (!campaignsTemplates || !campaignsTemplates?.entries?.length) return [];

        let mappedTemplates = [];
        campaignsTemplates?.entries?.forEach(({ date, templateId }) => {
            mappedTemplates.push({
                id: `${date}-${templateId}`,
                label: formatDate.dateUTC(date),
                value: formatDate.dateUTC(date),
            });
        });

        mappedTemplates.unshift({
            id: 'all',
            label: 'All dates',
            value: 'All dates',
        });

        return mappedTemplates;
    };

    generateYearOptions = () => {
        const { mailingYears } = this.props;

        if(!Array.isArray(mailingYears)) return [];

        return mailingYears?.map(mailingYear => {
            return {
                id: mailingYear.templateId,
                label: mailingYear.year,
                value: mailingYear.year
            };
        });
    };

    generateDmExemptStatusOptions = () => {
        const dmStatuses =  [{"id": 1,"name": "DM Exempt",},{"id": 0, "name": "Not Exempt",},];
        let mappedStatuses = dmStatuses.map(({ id, name }) => ({
            id: id,
            label: name,
            value: id,
        }));
        mappedStatuses.unshift({
            id: 'all',
            label: 'All request statuses',
            value: 'All statuses',
        });
        return mappedStatuses;
    };
    onSearchMailingPlans = (mailingPlansYear = null) => {
        const { 
            getMailingPlans
        } = this.props;

        getMailingPlans(mailingPlansYear);
    };

    onSelectMailingYear = (year, templateId) => {
        const { 
            getCampaignsTemplatesByYear,
            getMailingPlansOverview,
            getMailingPlans,
            getBrochures,
            getCoupons
        } = this.props;

        getBrochures({
            brandId: userSession.getBrandId(),
            templateYear: year
        });

        getCoupons({ 
            brandId: userSession.getBrandId(), 
            templateId 
        });

        getMailingPlansOverview(year);
        getCampaignsTemplatesByYear(year);
        getMailingPlans(year);
    };
    
    getIdFromMailingYear = mailingYear => {
        const { mailingYears } = this.props;

        if(!Array.isArray(mailingYears)) return null;

        return mailingYears.filter(({ year }) => 
            Number(mailingYear) === Number(year)
        )[0]?.templateId;
    }

    componentDidMount() {
        const {
            yearFilter,
            getMailingPlans,
            getMailingPlanStatuses,
            getFranchises,
            getBrochures,
            getCoupons,
            getMailingYears,
            getCampaignsTemplatesByYear,
        } = this.props;

        getMailingPlans(yearFilter);
        getCampaignsTemplatesByYear(yearFilter);
        
        getBrochures({
            brandId: userSession.getBrandId(),
            templateYear: yearFilter,
        });
        getCoupons({ 
            brandId: userSession.getBrandId(), 
            year: yearFilter 
        });
        
        getMailingPlanStatuses();
        getFranchises();
        getMailingYears();
        document.getElementById("dm-exempt-status-dropdown").style.display = this.state.hideDmExempt ?'none':'';
    }

    componentDidUpdate(prevProps)
    {
        (async () => {
            if(this.props.mailingPlans.length != prevProps.mailingPlans.length && this.props.yearFilter
                || this.props.yearFilter != prevProps.yearFilter)
            {
                const currentYear = this.props.yearFilter;
                const prevYear = parseInt(currentYear) - 1;
                
                this.props.setMailingPlansStartDate(new moment(`${prevYear}-12-01`, "YYYY-MM-DD"));
                this.props.setMailingPlansEndDate(new moment().year(currentYear).endOf('year'));
            }
        })();
    }

    render() {
        const { showPlanDetails, planDetails } = this.state;

        const {
            mailingPlans,
            setMailingPlansStartDate,
            setMailingPlansEndDate,
            setMailingPlansStatusFilter,
            setMailingPlansFranchiseFilter,
            setMailingPlansYearFilter,
            setMailingPlansCampaignDateFilter,
            setMailingPlansBrochureFilter,
            setMailingPlansCouponFilter,
            startDateFilter,
            endDateFilter,
            statusFilter,
            franchiseFilter,
            yearFilter,
            campaignDateFilter,
            brochureFilter,
            couponFilter,
            dmExemptStatusFilter,
            setMailingPlansDmExemptStatusFilter
        } = this.props;

        //Code to show column dmExempt if brand is asp 
        let newCoulmns = mailingPlanColumns;
        if(! userSession.isASPUser()){
            newCoulmns =   mailingPlanColumns.filter(object => {
                return object.dataField != "dmExempt";
            });
        }

        return (
            <Panel
                refreshing={(mailingPlans?.loading && mailingPlans?.entries) ? true : false}
                floatingRefreshLoader={true}
            >
                <PlanDetailsModal
                    show={showPlanDetails}
                    onClose={this.onClosePlanDetails}
                    planDetails={planDetails}
                />
                <div className='mailing-plan-filters'>
                    <div className='d-flex flex-wrap row-gap-1 column-gap-1'>
                        <Select
                            id='dashboard-year-filter'
                            label='Year'
                            value={yearFilter || this.generateYearOptions()[0]?.value}
                            selectStyling={'filter'}
                            // placeholder='Year'
                            options={this.generateYearOptions()}
                            onChange={({ target: {value} })=> {
                                const year = value;
                                const id = this.getIdFromMailingYear(year);
                                this.onSelectMailingYear(year, id);
                                setMailingPlansYearFilter(year);
                            }}
                        />
                        <Select
                            id='dashboard-campaign-date-filter'
                            label='Campaign Date'
                            value={
                                campaignDateFilter ||
                                this.generateCampaignDateOptions()[0]?.value
                            }
                            selectStyling={'filter'}
                            //placeholder='Campaign Date'
                            options={this.generateCampaignDateOptions()}
                            onChange={(e) =>
                                setMailingPlansCampaignDateFilter(e.target.value)
                            }
                        />
                        <Select
                            id='dashboard-status-filter'
                            label='Status'
                            value={statusFilter || this.generateStatusOptions()[0]?.value}
                            selectStyling={'filter'}
                            //placeholder='Status'
                            options={this.generateStatusOptions()}
                            onChange={(e) => setMailingPlansStatusFilter(e.target.value)}
                        />
                        <Select
                            id='dashboard-franchise-filter'
                            label='Franchise'
                            value={
                                franchiseFilter || this.generateFranchiseOptions()[0]?.value
                            }
                            selectStyling={'filter'}
                            //placeholder='Franchise'
                            options={this.generateFranchiseOptions()}
                            onChange={(e) => setMailingPlansFranchiseFilter(e.target.value)}
                        />
                        <Select
                            id='dashboard-borchure-filter'
                            label='Brochure'
                            value={brochureFilter || this.generateBrochureOptions()[0]?.value}
                            selectStyling={'filter'}
                            //placeholder='Brochure'
                            options={this.generateBrochureOptions()}
                            onChange={(e) => setMailingPlansBrochureFilter(e.target.value)}
                        />
                        <Select
                            id='dashboard-coupon-filter'
                            label='Coupon'
                            value={couponFilter || this.generateCouponOptions()[0]?.value}
                            selectStyling={'filter'}
                           // placeholder='Coupon'
                            options={this.generateCouponOptions()}
                            onChange={(e) => setMailingPlansCouponFilter(e.target.value)}
                        />
                        <div id= "dm-exempt-status-dropdown">
                            <Select
                                id='dashboard-dm-exempt-status-filter'
                                label='DM Exempt Status'
                                value={dmExemptStatusFilter || this.generateDmExemptStatusOptions()[0]?.value}
                                selectStyling={'filter'}
                                //placeholder='DM Exempt Status'
                                options={this.generateDmExemptStatusOptions()}
                                onChange={(e) => setMailingPlansDmExemptStatusFilter(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='d-flex align-items-end justify-content-between'>
                        <div className='mt-3'>
                            <DateRangePicker
                                idStartDate='dashboard-start-date-filter'
                                idEndDate='dashboard-end-date-filter'
                                label='Submission date range'
                                startDate={startDateFilter}
                                endDate={endDateFilter}
                                minStartDate={startDateFilter}
                                maxEndDate={endDateFilter}
                                onStartDateChange={date => setMailingPlansStartDate(date)}
                                onEndDateChange={date => setMailingPlansEndDate(date)}
                            />
                        </div>
                        <Button
                            id='dashboard-search-plans-btn'
                            btnType='primary'
                            icon='fas fa-search'
                            label='Search'
                            action={() => this.onSearchMailingPlans(yearFilter)}
                        />
                    </div>
                </div>
                <div 
                    id='dashboard-mailing-plans-table'
                    className='mailing-plans-table border-top pt-3'
                >
                    {
                        <Table
                            keyField={'id'}
                            columns={newCoulmns}
                            data={this.generateTableData()}
                            enableExport={true}
                            enablePagination={true}
                            loading={mailingPlans?.loading && !mailingPlans?.entries}
                            loadingMessage={'Loading mailing plans...'}
                            exportFileName={`mailing_plans_${yearFilter}.csv`}
                            idExportBtn='dashboard-table-export-btn'
                        />
                    }
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    mailingPlans: state.dashboard?.mailingPlans || [],
    statuses: state.dashboard?.statuses || [],
    franchises: state.dashboard?.franchises || [],
    brochures: state.brochures?.brochures || [],
    coupons: state.coupons?.coupons || [],
    mailingYears: state.dashboard?.mailingYears || [],
    campaignsTemplates: state.dashboard?.campaignsTemplates || [],
    startDateFilter:
        state.dashboard?.mailingPlans?.filters?.startDate ||
        new moment().startOf('year'),
        endDateFilter:
        state.dashboard?.mailingPlans?.filters?.endDate || new moment().endOf('year'),
    statusFilter: state.dashboard?.mailingPlans?.filters?.status || 'Submitted',
    dmExemptStatusFilter: state.dashboard?.mailingPlans?.filters?.isDmExempt,
    franchiseFilter: state.dashboard?.mailingPlans?.filters?.franchise,
    yearFilter:
        state.dashboard?.mailingPlans?.filters?.year || 
        (new moment().month() === 11 ? new moment().year() + 1 : new moment().year()),
    campaignDateFilter: state.dashboard?.mailingPlans?.filters?.campaignDate,
    brochureFilter: state.dashboard?.mailingPlans?.filters?.brochure,
    couponFilter: state.dashboard?.mailingPlans?.filters?.coupon,
});

export default connect(mapStateToProps, {
    getMailingPlans: getMailingPlansAction,
    getMailingPlanStatuses: getMailingPlanStatusesAction,
    getFranchises: getFranchisesAction,
    getBrochures: getBrochuresAction,
    getCoupons: getCouponsAction,
    getMailingYears: getMailingYearsAction,
    getCampaignsTemplatesByYear: getCampaignsTemplatesByYearAction,
    setMailingPlansStartDate: setMailingPlansStartDateAction,
    getMailingPlansOverview: getMailingPlansOverviewAction,
    setMailingPlansEndDate: setMailingPlansEndDateAction,
    setMailingPlansStatusFilter: setMailingPlansStatusFilterAction,
    setMailingPlansFranchiseFilter: setMailingPlansFranchiseFilterAction,
    setMailingPlansYearFilter: setMailingPlansYearFilterAction,
    setMailingPlansCampaignDateFilter: setMailingPlansCampaignDateFilterAction,
    setMailingPlansBrochureFilter: setMailingPlansBrochureFilterAction,
    setMailingPlansCouponFilter: setMailingPlansCouponFilterAction,
    changeDmExemptStatus :changeDmExemptStatusAction,
    setMailingPlansDmExemptStatusFilter :setMailingPlansDmExemptStatusFilterAction
})(MailingPlans);

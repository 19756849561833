import React from 'react';
import PropTypes from 'prop-types';

const ImageView = ({
    label = '',
    src = null,
    alt = 'No preview available',
    imgStyling = null,
    width = '100%',
    height = '100%',
    maxWidth = '100%',
    maxHeight = '100%',
    minWidth = '100%',
    minHeight = '100%',
    rounded = true,
    clickable = false,
    onClick = null,
    fit = 'fill',
    noPlaceholder = false,
    ...props
}) => {
    const renderImage = () => {
        if(!src) {
            if(noPlaceholder) {
                return <div></div>;
            }
            
            return (
                <div className='image-placeholder'>
                    <i className='far fa-image'></i>
                </div>
            );
        }

        return (
            <img
                className={`image-${fit} ${rounded ? 'rounded' : ''}`.trim()} 
                style={
                    {
                        width: `${width}`,
                        height: `${height}`, 
                        maxWidth: `${maxWidth}`, 
                        maxHeight: `${maxHeight}`,
                        minWidth: `${minWidth}`, 
                        minHeight: `${minHeight}`, 
                        ...imgStyling
                    }
                } 
                src={src} 
                alt={alt}
            />
        );
    }

    return (
        <div 
            {...props}
            onClick={(e) => 
                clickable ? onClick(e) : null
            }
            className={`
                image-view 
                ${rounded ? 'rounded' : ''} 
                ${clickable ? 'cursor-pointer' : ''}
            `.trim()} 
            style={
                {
                    width: `${width}`, 
                    height: `${height}`, 
                    maxWidth: `${maxWidth}`, 
                    maxHeight: `${maxHeight}`,
                    minWidth: `${minWidth}`, 
                    minHeight: `${minHeight}`
                }
            }
        >
            <div 
                className='d-flex flex-column'
                style={
                    {
                        width: `${width}`, 
                        height: `${height}`, 
                        maxWidth: `${maxWidth}`, 
                        maxHeight: `${maxHeight}`,
                        minWidth: `${minWidth}`, 
                        minHeight: `${minHeight}`
                    }
                }
            >
                {label &&
                    <small className='mb-2'>{label}</small>
                }
                {renderImage()}
            </div>
        </div>
    );
}

ImageView.propTypes = {
    label: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    imgStyling: PropTypes.object,
    width: PropTypes.string,
    height: PropTypes.string,
    rounded: PropTypes.bool,
    clickable: PropTypes.bool,
    onClick: PropTypes.func,
    fit: PropTypes.string,
    noPlaceholder: PropTypes.bool
}

export default ImageView;

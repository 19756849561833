import { DOWNLOAD_PLAN_EXPORT_ERROR, EXPORT_PLAN_ERROR, EXPORT_PLAN_SUCCESS, GET_ACTIVE_PLAN_TEMPLATES_ERROR, LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_ERROR, LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_SUCCESS, LMPBP_DELETE_FRANCHISE_CUSTOM_TACTIC_ERROR, LMPBP_GET_EXPORTED_FILES_ERROR, LMPBP_GET_AWS_IOT_CREDENTIALS_ERROR, LMPBP_AWS_IOT_TIMEOUT_ERROR, LMPBP_GET_TACTICS_ERROR } from "actions/types";

export const ADD_CUSTOM_TACTIC_NOTIFICATION_ERROR_TEMPLATE = {
    id: LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_ERROR,
    message: 'Error adding new custom tactic',
    type: 'danger',
    serverError: null, // fill this with the request error
};

export const ADD_CUSTOM_TACTIC_NOTIFICATION_SUCCESS_TEMPLATE = {
    title: 'Tactic added',
    id: LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_SUCCESS,
    message: 'Custom tactic added successfully',
    type: 'success',
};

export const DELETE_CUSTOM_TACTIC_NOTIFICATION_ERROR_TEMPLATE = {
    id: LMPBP_DELETE_FRANCHISE_CUSTOM_TACTIC_ERROR,
    message: 'Error deleting custom tactic',
    type: 'danger',
    serverError: null, // fill this with the request error
};

export const DELETE_CUSTOM_TACTIC_NOTIFICATION_SUCCESS_TEMPLATE = {
    title: 'Tactic deleted',
    id: LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_SUCCESS,
    message: 'Custom tactic deleted successfully',
    type: 'success',
};

export const GET_TEMPLATES_ERROR_TEMPLATE = {
    id: GET_ACTIVE_PLAN_TEMPLATES_ERROR,
    message: 'Error getting active budget plan templates',
    type: 'danger',
    serverError: null, // fill this with the request error
}

export const GET_EXPORED_FILES_ERROR_TEMPLATE = {
    id: LMPBP_GET_EXPORTED_FILES_ERROR,
    message: 'Error getting previously exported files',
    type: 'danger',
    serverError: null, // fill this with the request error
};

export const GET_TEMPLATE_TACTICS_ERROR_TEMPLATE = {
    id: LMPBP_GET_TACTICS_ERROR,
    message: 'Error getting template tactics',
    type: 'danger',
    serverError: null, // fill this with the request error
};

export const EXPORT_PLAN_ERROR_TEMPLATE = {
    id: EXPORT_PLAN_ERROR,
    message: 'Error exporting file',
    type: 'danger',
    serverError: null, // fill this with the request error
};

export const EXPORT_PLAN_SUCCESS_TEMPLATE = {
    id: EXPORT_PLAN_SUCCESS,
    title: "Export Successful",
    message: 'Plan has been successfully exported and is now available for download',
    type: 'success',
};

export const DOWNLOAD_FILE_ERROR_TEMPLATE = {
    id: DOWNLOAD_PLAN_EXPORT_ERROR,
    message: 'Error downloading exported file',
    type: 'danger',
    serverError: null, // fill this with the request error
};

export const AWS_IOT_ERROR_TEMPLATE = {
    id: LMPBP_GET_AWS_IOT_CREDENTIALS_ERROR,
    message: 'Error subscribing to AWS IoT',
    type: 'danger',
    serverError: null, // fill this with the request error
};

export const AWS_IOT_TIMEOUT_ERROR_TEMPLATE = {
    id: LMPBP_AWS_IOT_TIMEOUT_ERROR,
    title: 'Export timed out',
    message: 'This might be due to the size of the export. Please try again or contact IT.',
    type: 'danger',
    serverError: null, // fill this with the request error
};
export const AWS_IOT_EXPORT_ERROR_TEMPLATE = {
    id: LMPBP_AWS_IOT_TIMEOUT_ERROR,
    title: 'Export Error',
    message: '',
    type: 'danger',
    serverError: null, // fill this with the request error
};
export const validateConsecutiveness = (elements, n, isElementSignificant = (val) => true) => {
    if (!Array.isArray(elements)) {
        throw new Error("The parameter must be an array!");
    }

    let subStrSize = 0;
    for (let i = 0; i < elements.length; i++) {
        if (isElementSignificant(elements[i])) {
            subStrSize ++;
        }
        else {
            if (subStrSize > 0 && subStrSize < n) {
                return false;
            }
            subStrSize = 0;
        }
    }

    return !(subStrSize > 0 && subStrSize < n);
}

export function allEqual(array) {
    if(array.length === 0) return true;
    return array.every(element => element === array[0]);
}
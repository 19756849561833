import sortBy from 'utils/sortBy';

const createEntryWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    return {
        budgetEntryId: data.budget_entry_id,
        budgets: sortBy({
            data: data.months,
            attr: 'month',
            type: 'number'
        })?.map(budget => ({
            id: budget.id,
            value: budget.budget,
            month: budget.month
        }))
    }
}

export default createEntryWrapper;
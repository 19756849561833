import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';

import Panel from 'components/common/panel/Panel';
import MailingPlanHeader from './mailing-plan-header/MailingPlanHeader';
import CampaignsList from './campaigns-list/CampaignsList';
import MailingOrder from './mailing-order/MailingOrder';
import ZipCodes from './zip-codes/ZipCodes';
import FranchiseInfo from './franchise-info/FranchiseInfo';
import OwnerInfo from './owner-info/OwnerInfo';
import PlanComments from './plan-comments/PlanComments';

import userSession from 'utils/userSession';

import { 
    getMailingPlan as getMailingPlanAction, 
    getZipCodes as getZipCodesAction, 
    enableMailingPlanOverride as enableMailingPlanOverrideAction
} from 'actions/mailingPlanActions';

import { 
    getFranchiseInfo as getFranchiseInfoAction 
} from 'actions/franchiseActions';

import { 
    setCurrentRoute as setCurrentRouteAction 
} from 'actions/utilityActions';

export class MailingPlan extends Component {
    state = {
        coporateUserSubmitOverride: false,
        //hide Postal Codes tab if brand is MSQ and  if franchise owner and divisional user
        hidePostalCode : userSession.isMSQUser() ? true:false,
    }

    fetchMailingPlan = () => {
        const {
            match,
            toggleMailingPlanOverride,
            getMailingPlan,
            getFranchiseInfo,
            getZipCodes
        } = this.props;

        const mailingPlanId = match?.params?.mailingPlanId?.trim();
        let franchiseId = match?.params?.franchiseId?.trim();
        let year = match?.params?.year?.trim();
        const redirection = JSON.parse(sessionStorage.getItem('redirect'));

        if(redirection)
        {
            const franchaiseExternalId = redirection.franchiseId;
            const sessionJSON = JSON.parse(sessionStorage.getItem('user_session'));
            // const index = sessionJSON.Franchises.findIndex((franchise) => franchise.ExternalID == franchaiseExternalId);
            // console.log(sessionJSON.Franchises[index].ID);

            // franchiseId = sessionJSON.Franchises[index].ID;
            franchiseId = sessionJSON.franchiseId
            year = redirection.year;
        }

        if(userSession.isDivisionalUser()) {
            const isEditingApprovedPlan = match?.path?.includes('edit');
            const isCreatingNewPlan = match?.path?.includes('create');

            if(isEditingApprovedPlan || isCreatingNewPlan) {
                this.setState({ coporateUserSubmitOverride: true });
                toggleMailingPlanOverride(true);
            }

            getMailingPlan(userSession.getBrandId(), franchiseId, mailingPlanId, year);
            getFranchiseInfo(userSession.getBrandId(), franchiseId);
            getZipCodes(franchiseId);
            
        } else {
            toggleMailingPlanOverride(false);
            this.setState({ coporateUserSubmitOverride: false });

            if(redirection)
            {
                getMailingPlan(userSession.getBrandId(), franchiseId, mailingPlanId, year);
                getFranchiseInfo(userSession.getBrandId(), franchiseId);
                getZipCodes(franchiseId);
            }
            else
            {
                getMailingPlan(userSession.getBrandId(), userSession.getFranchiseId());
                getFranchiseInfo(userSession.getBrandId(), userSession.getFranchiseId());
                getZipCodes(userSession.getFranchiseId());
            }
        }
    }

    componentDidMount() {            
        this.fetchMailingPlan();
        this.props.setCurrentRoute(this.props.match);
       // document.getElementById("zip-code-tab").style.display = this.state.hidePostalCode ?'none':'';

    }

    componentDidUpdate() {
        this.props.setCurrentRoute(this.props.match);
    }

    render() {
        const { loadingZipCodes } = this.props;
        const { coporateUserSubmitOverride } = this.state;

        return (
            <div className='mailing-plan-layout'>
                <div className='mailing-plan-header'>
                    <MailingPlanHeader 
                        coporateUserSubmitOverride={coporateUserSubmitOverride}
                    />
                </div>
                <div className='mailing-plan-content'>
                    <CampaignsList />
                </div>
                <div className='mailing-plan-side'>
                    <div>
                        <Panel header='Franchise Information'>
                            <FranchiseInfo />
                        </Panel>
                    </div>
                    <div>
                        <Panel header='Owner Information'>
                            <OwnerInfo />
                        </Panel>
                    </div>
                    {
                        ( !userSession.isMTUser() ) &&
                        <div>
                            <Panel header='Send Order'> 
                                <MailingOrder />
                            </Panel>
                        </div>
                    }
                    {
                       ( ! userSession.isMSQUser() && ! userSession.isMTUser() ) &&  
                        <div id='zip-code-tab'>
                        <Panel header='Postal Codes' loading={loadingZipCodes}> 
                            <ZipCodes />
                        </Panel>
                    </div>
                    }
                    <div>
                        <Panel header='Comments'> 
                            <PlanComments />
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    franchiseId: state.utility?.currentRoute?.params?.franchiseId,
    overrideEnabled: state.mailingPlan?.overrideEnabled,
    loadingZipCodes: state.mailingPlan?.loadingZipCodes
});

export default connect(mapStateToProps, {
    getMailingPlan: getMailingPlanAction,
    getZipCodes: getZipCodesAction,
    getFranchiseInfo: getFranchiseInfoAction,
    setCurrentRoute: setCurrentRouteAction,
    toggleMailingPlanOverride: enableMailingPlanOverrideAction
})(MailingPlan);
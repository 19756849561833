import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getCampaignsReport = ({
    campaignTemplateId,
    itemsPerPage,
    page
} = {}) => {
    return handleResponse(
        api().get('/corporate/addresses/export/summary', {
            params: {
                campaignTemplateId,
                itemsPerPage,
                page
            }
        })
    );
}
export const exportCampaignReportApi = ({
    campaignTemplateId,
    iotEndpoint
} = {}) => {
    return handleResponse(
        api().get('/corporate/addresses/export-summary-detail', {
            params: {
                campaignTemplateId,
                iotEndpoint
            }
        })
    );
}
import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getMailingPlan = ({
    brandId,
    franchiseId,
    mailingPlanId,
    year
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/franchise/${franchiseId}/mailing-plan/get`, {
            mailingPlanId,
            mailingPlanTemplateYear: year
        })
    );
}

export const submitMailingPlan = ({
    brandId,
    franchiseId,
    mailingPlan
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/franchise/${franchiseId}/mailing-plan/`, {
            ...mailingPlan   
        })
    );
}

export const saveDraftMailingPlan = ({
    brandId,
    franchiseId,
    mailingPlan
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/franchise/${franchiseId}/mailing-plan/save`, {
            ...mailingPlan   
        })
    );
}

export const copyDraftMailingPlan = ({
    brandId,
    franchiseId,
    mailingPlan
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/franchise/${franchiseId}/mailing-plan/copy`, {
            ...mailingPlan
        })
    );
}

export const overrideMailingPlan = ({
    mailingPlan
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/override', {
            ...mailingPlan   
        })
    );
}

export const approveMailingPlan = ({
    mailingPlanId
} = {}) => {
    return handleResponse(
        api().put(`/corporate/mailing-plan/approve`, {
            mailingPlanId
        })
    );
}

export const rejectMailingPlan = ({
    mailingPlanId,
    feedback
} = {}) => {
    return handleResponse(
        api().put(`/corporate/mailing-plan/reject`, {
            mailingPlanId,
            feedback
        })
    );
}

export const getSubmissions = ({
    brandId,
    franchiseId
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/franchise/${franchiseId}/mailing-plan/recent`)
    );
}
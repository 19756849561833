import api from 'api/lmpbp';
import handleResponse from './utils/handleResponse';
import resComWrapper from './utils/backend-wrappers/resComWrapper';

// export const getResCom = () => {
//     return handleResponse({
//         promise: api().get(`budgets/getresvscomm`),
//         wrapper: resComWrapper,
//     });
// };

export const getResCom = () => {
    return handleResponse({
        promise: api().get('budgets/getsegmentratio'),
        wrapper: resComWrapper,
    });
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CouponItem from './CouponItem';
import CouponConfigModal from './coupon-config-modal/CouponConfigModal';
import AddButton from 'components/common/add-button/AddButton';
import Loader from 'components/common/loader/Loader';

import userSession from 'utils/userSession';
import { 
    getCouponsForConfigList as getCouponsForConfigListAction,
    resetCouponsList as resetCouponsListAction 
} from 'actions/couponsConfigActions';

export class CouponsList extends Component {
    state = {
        showConfigModal: false,
        selectedCoupon: null
    }

    static propTypes = {
        templateId: PropTypes.number.isRequired
    }

    onShowConfigModal = (coupon = null) => {
        this.setState({ 
            showConfigModal: true,
            selectedCoupon: {...coupon}
        });
    }

    onHideConfigModal = () => {
        this.setState({ 
            showConfigModal: false,
            selectedCoupon: null
        });
    }

    renderCoupons = () => {
        const { coupons } = this.props;

        if(!Array.isArray(coupons)) return null;

        return coupons.map(coupon => {
            return <CouponItem 
                key={coupon.couponId}
                name={coupon.couponName}
                code={coupon.code}
                onEditCoupon={() => this.onShowConfigModal(coupon)}
            />;
        });
    }

    componentDidMount() {
        const { 
            templateId,
            getCouponsForConfigList
        } = this.props;

        getCouponsForConfigList(userSession.getBrandId(), templateId);
    }

    componentWillUnmount() {
        this.props.resetCouponsList();
    }

    render() {
        const {
            showConfigModal,
            selectedCoupon
        } = this.state;

        const {
            templateId,
            loading,
            saving
        } = this.props;

        return (
            <div>
                { 
                    showConfigModal &&
                        <CouponConfigModal 
                            templateId={templateId}
                            onHide={this.onHideConfigModal}
                            selectedCoupon={selectedCoupon} 
                        />
                }
                <div className='d-flex column-gap-1 row-gap-1 flex-wrap'>
                    <Fragment>
                        <AddButton 
                            style={{width: '200px', height: '90px', textAlign: 'center'}}
                            title='Create New'
                            action={this.onShowConfigModal}
                        />
                        {this.renderCoupons()}
                        {
                            (loading || saving) &&
                                <Loader loadingMessage={loading ? 'Loading coupons...' : 'Saving coupons...'} />
                        }
                    </Fragment>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    coupons: state.couponsConfig?.coupons,
    loading: state.couponsConfig?.loading,
    saving: state.couponsConfig?.saving
});

export default connect(mapStateToProps, {
    getCouponsForConfigList: getCouponsForConfigListAction,
    resetCouponsList: resetCouponsListAction
})(CouponsList);

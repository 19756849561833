import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Input from 'components/common/input/Input';
import SubmissionsModal from './submissions-modal/SubmissionsModal';

import userSession from 'utils/userSession';
import formatDate from 'utils/formatDate';

import { 
    getMailingPlanSubmissions as getMailingPlanSubmissionsAction 
} from 'actions/submissionsActions';

export class PlanSubmissions extends Component {
    state = {
        showSubmissions: false
    }

    onCloseModal = () => {
        this.setState({ showSubmissions: false });
    }

    renderLastSubmissionDate = () => {
        const { 
            createdDate,
            updatedDate,
            status,
            loadingSubmissions
        } = this.props;

        if(loadingSubmissions) return 'Loading ...';

        return updatedDate && status ?
            `${status}: ${formatDate.descriptiveDateAndTime(updatedDate)}`
        : 'No submissions yet';
    }

    componentDidMount() {
        this.props.getMailingPlanSubmissions(
            userSession.getBrandId(), 
            userSession.getFranchiseId()
        );
    }

    render() {
        const { showSubmissions } = this.state;

        const {
            submissions,
            loadingSubmissions
        } = this.props;
        return (
            <Fragment>
                <SubmissionsModal 
                    show={showSubmissions}
                    onHide={this.onCloseModal}
                />
                <Input 
                    onClick={() => this.setState({ showSubmissions: true })}
                    type='button'
                    selectionName={
                        <div className='d-flex column-gap-1'>
                            <div>{this.renderLastSubmissionDate()}</div>
                        </div>
                    }
                    disabled={loadingSubmissions || !submissions?.length}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    createdDate: state.mailingPlan?.createdDate,
    updatedDate: state.mailingPlan?.updatedDate,
    status: state.mailingPlan?.status,
    loadingSubmissions: state.planSubmissions?.loading,
    submissions: state.planSubmissions?.submissions
});

export default connect(mapStateToProps, {
    getMailingPlanSubmissions: getMailingPlanSubmissionsAction
})(PlanSubmissions);



import _ from 'lodash';

// Note: commented code by stakeholder request on date 2023-12-20
// export const validatePrefferedTacticsAnnualBudgets = (budgetPlan, updatedBudgetEntries) => {
//     const { tacticsMinimumAnnualSpend } = budgetPlan;
//     let allBudgets = [];
//     let preffredTacticsBudgets = updatedBudgetEntries.filter(({ isPrefferedTactic }) => isPrefferedTactic);
//     preffredTacticsBudgets?.forEach(
//         ({ budgets }) => (allBudgets = budgets ? [...allBudgets, ...budgets ] : allBudgets)
//     );

//     // Set Annual as default
//     if (tacticsMinimumAnnualSpend) {
//         return Boolean(_.sumBy(allBudgets, 'value') < tacticsMinimumAnnualSpend);
//     }
    
//     return false;
// };

export const validateMonthlyBudgets = (budgetPlan, updatedBudgetEntries) => {
      
    const { scorpionMinMonthlySpendAmount, targatedDmMinMonthlySpendAmount, nativeAdvertisingMinMonthlySpendAmount, youtubeAdvertisingMinMonthlySpendAmount, mirroredDisplayMinMonthlySpendAmount, multiviewMinMonthlySpendAmount} = budgetPlan;

    let returnResult=[];
    let updatedResult = updatedBudgetEntries.some((entry)=>{
        const lockedMonths = entry.lockedMonths ?? [];
        if(entry?.isScorpionTactic){
            let scorpionBudgets = [entry];
            scorpionBudgets.forEach(
                ({ budgets }) => (scorpionBudgets = budgets ? [...scorpionBudgets, ...budgets ] : scorpionBudgets)
            );

            let haveInvaliScorpiondSpends = false;
            if(scorpionMinMonthlySpendAmount){
                haveInvaliScorpiondSpends = scorpionBudgets ? 
                scorpionBudgets.some((budget) => (budget.value < scorpionMinMonthlySpendAmount && !lockedMonths[budget.month] && budget.value != 0)) 
                : true;
            }
            returnResult.push({scorpion_err : haveInvaliScorpiondSpends});
        }
         if(entry?.isTdmTactic ){
            let targatedDmBudgetsEntries =[entry];
    
            let targatedDmBudgets = [];
            targatedDmBudgetsEntries?.forEach(
                ({ budgets }) => (targatedDmBudgets = budgets ? [...targatedDmBudgets, ...budgets ] : targatedDmBudgets)
            );
        
            let haveInvaliTdmSpends = false;
            if(targatedDmMinMonthlySpendAmount){
                haveInvaliTdmSpends = targatedDmBudgets ? targatedDmBudgets.some((budget) => (budget.value < targatedDmMinMonthlySpendAmount  && budget.value != 0)) : true;
            }
            returnResult.push({tdm_err : haveInvaliTdmSpends});
        } 
        if(entry?.isNativeAdvertising){
            let nativeAdvertisingBudgetsEntries = [entry];
    
            let nativeAdvertisingBudgets = [];
            nativeAdvertisingBudgetsEntries?.forEach(
                ({ budgets }) => (nativeAdvertisingBudgets = budgets ? [...nativeAdvertisingBudgets, ...budgets ] : nativeAdvertisingBudgets)
            );

            let haveInvaliNativeAdvertisingSpends = false;
            if(nativeAdvertisingMinMonthlySpendAmount){
                haveInvaliNativeAdvertisingSpends = nativeAdvertisingBudgets ? nativeAdvertisingBudgets.some((budget) => (budget.value < nativeAdvertisingMinMonthlySpendAmount && !lockedMonths[budget.month] && budget.value != 0)) : true;
            }
            
            returnResult.push({native_adv_err : haveInvaliNativeAdvertisingSpends});
        }
        
        if(entry?.isYoutubeAdvertising){
            let youtubeAdvertisingBudgetsEntries = [entry];
        
            let youtubeAdvertisingBudgets = [];
            youtubeAdvertisingBudgetsEntries?.forEach(
                ({ budgets }) => (youtubeAdvertisingBudgets = budgets ? [...youtubeAdvertisingBudgets, ...budgets ] : youtubeAdvertisingBudgets)
            );
        
            let haveInvalidYoutubeAdvertisingSpends = false;
            if(youtubeAdvertisingMinMonthlySpendAmount){
                haveInvalidYoutubeAdvertisingSpends = youtubeAdvertisingBudgets ? youtubeAdvertisingBudgets.some((budget) => (budget.value < youtubeAdvertisingMinMonthlySpendAmount && !lockedMonths[budget.month] && budget.value != 0)) : true;
            }
            
            returnResult.push({youtube_adv_err : haveInvalidYoutubeAdvertisingSpends});
        }

        if(entry?.isMirroredDisplay){
            let mirroredDisplayBudgetsEntries = [entry];
    
            let mirroredDisplayBudgets = [];
            mirroredDisplayBudgetsEntries?.forEach(
                ({ budgets }) => (mirroredDisplayBudgets = budgets ? [...mirroredDisplayBudgets, ...budgets ] : mirroredDisplayBudgets)
            );

            let haveInvalidMirroredDisplaySpends = false;
            if(mirroredDisplayMinMonthlySpendAmount){
                haveInvalidMirroredDisplaySpends = mirroredDisplayBudgets ? mirroredDisplayBudgets.some((budget) => (budget.value < mirroredDisplayMinMonthlySpendAmount && !lockedMonths[budget.month] && budget.value != 0)) : true;
            }
            
            returnResult.push({mirror_display_err : haveInvalidMirroredDisplaySpends});
        }

        if(entry?.isMultiview){
            let multiviewBudgetsEntries = [entry];
            let multiviewBudgets = [];
            multiviewBudgetsEntries?.forEach(
                ({ budgets }) => (multiviewBudgets = budgets ? [...multiviewBudgets, ...budgets ] : multiviewBudgets)
            );
        
            let haveInvalidMultiviewSpends = false;
            if(multiviewMinMonthlySpendAmount){
                haveInvalidMultiviewSpends = multiviewBudgets ? multiviewBudgets.some((budget) => (budget.value < multiviewMinMonthlySpendAmount && !lockedMonths[budget.month] && budget.value != 0)) : true;
            }
            
            returnResult.push({multiview_err : haveInvalidMultiviewSpends});
        }
    })
   return returnResult;
};

export const validateConsecutiveMonthsTactics = (updatedBudgetEntries) => {
    //LMPBP-439
    // Minimum campaign length for Native, CTV, YouTube is 3 months - if amount other than zero, must have nonzero value for 3 consecutive months

    let consecutiveMonthsTacticsBudgetsEntries = updatedBudgetEntries.filter(({ isNativeAdvertising , isYoutubeAdvertising , isMirroredDisplay }) => isNativeAdvertising || isYoutubeAdvertising || isMirroredDisplay);

    let consecutiveResults = [];
    consecutiveMonthsTacticsBudgetsEntries?.forEach(
        ({ budgets,isNativeAdvertising , isYoutubeAdvertising , isMirroredDisplay }) =>
        {
          let arr = budgets.filter(bud=>bud.value);
            var results = [];
            var consecutive = [];
            var limit   = arr.length - 1; 

            var sequence = 0;
            for (var i = 0; i < limit; ++i) {
            var diff = arr[i+1]["month"] - arr[i]["month"];
            if (sequence && sequence === diff) {
                results.push(i-1);
                consecutive.push(i);
                consecutive.push(i+1);
                consecutive.push(i-1);
                continue;
            }
            
            sequence = (diff === 1 || diff === -1) // or ... Math.abs(diff) === 1
                    ? diff
                    : 0;
            }
          
            consecutive = [...new Set(consecutive)].sort();
            let isNotConsecutive = ( arr.length && consecutive.length != arr.length ) ? true:false
            
            if(isNativeAdvertising){
                consecutiveResults.push({nativeAdvertisingConsecutive : isNotConsecutive});
            }else if(isYoutubeAdvertising ){
                consecutiveResults.push({youtubeAdvertisingConsecutive : isNotConsecutive})
            }else if(isMirroredDisplay){
                consecutiveResults.push({mirrorDisplayConsecutive : isNotConsecutive})
            }
        }
    );
   return consecutiveResults;
};

export const validateAnnualBudgetsExeptDiscretionaryMarketingSpendTactic = (budgetPlan, updatedBudgetEntries) => {
    const { tacticsMinimumAnnualSpend } = budgetPlan;
    let allBudgets = [];
    let preffredTacticsBudgets = updatedBudgetEntries.filter(({ isDiscretionaryMarketingSpendTactic }) => !isDiscretionaryMarketingSpendTactic);
    preffredTacticsBudgets?.forEach(
        ({ budgets }) => (allBudgets = budgets ? [...allBudgets, ...budgets ] : allBudgets)
    );

    // Set Annual as default
    if (tacticsMinimumAnnualSpend) {
        return Boolean(_.sumBy(allBudgets, 'value') < tacticsMinimumAnnualSpend);
    }
    
    return false;
};



import { 
    getBadge
} from 'api/direct-mail/badges';

import {
    notifyGetBrochuresError
} from './notificationActions';

import { 
    GET_BADGES, 
    GET_BADGES_LOADING
} from './types';
import badgeWrapper from 'api/direct-mail/utils/backend-wrappers/badgeWrapper';

export const getBadges = ({
    brandId = null, 
    templateId = null,
    badges = [],
    templateYear = null
}) => async dispatch => {
    dispatch(notifyGetBrochuresError({ display: false }));

    dispatch({
        type: GET_BADGES_LOADING,
        payload: true
    });

    const [bagesErr, badgesRes] = await getBadge({
        brandId, 
        templateId, 
        badges, 
        templateYear
    });

    if(bagesErr) {
        dispatch(notifyGetBrochuresError({ serverError: bagesErr }));
    }

    dispatch({
        type: GET_BADGES_LOADING,
        payload: false
    });

    return dispatch({
        type: GET_BADGES,
        payload: badgeWrapper.sortByName(badgesRes)
    });
}
import api from 'api/lmpbp';
import handleResponse from './utils/handleResponse';

export async function searchWebsitesRequest(searchTerm, itemsPerPage, page) {
  const response = await api().get(`brand/websites/search`, {
    params: {
      searchTerm: searchTerm,
      itemsPerPage: itemsPerPage,
      page: page,
    }
  });

  return {
    data: response.data.data,
    metadata: response.data.metadata
  };
};

export function getFranchiseWebsites(franchiseId) {
  return handleResponse({
    promise: api().get(`franchise/${franchiseId}/websites`)
  })
};

export async function searchFranchiseWebsites(franchiseId, itemsPerPage, page) {
  const response = await api().get(`brand/franchises/websites`, {
    params: {
      franchiseId: franchiseId,
      itemsPerPage: itemsPerPage,
      page: page,
    }
  })
  return {
    data: response.data.data,
    metadata: response.data.metadata
  };
}

export function getBrandWebsites() {
  return handleResponse({
    promise: api().get(`brand/websites`)
  });
}

export function getWebsiteParent(websiteId) {
  return handleResponse({
    promise: api().get(`website/${websiteId}/parent-franchise`)
  });
}

export function addFranchiseWebsite(franchiseId, websiteId, tacticId, isParent ) {
  return handleResponse({
    promise: api().post(`franchise/${franchiseId}/tactic/${tacticId}/website`,
      {
        websiteId: websiteId
      },
      {
        params: {
          isParent: isParent
        }
      }),
  });
}

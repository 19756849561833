import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';

export class ServiceSelection extends Component {
    state = {
        selectedServicesCount: 0
    }

    static propTypes = {
        services: PropTypes.array,
        onSetServiceSelected: PropTypes.func,
        minServices: PropTypes.number,
        maxServices: PropTypes.number,
        onLockNextStep: PropTypes.func,
        unselectAllServices: PropTypes.func
    }

    countServicesSelected = () => {
        const { services } = this.props;

        let servicesCount = 0;
        services?.forEach(service => {
            if(service.selected) {
                servicesCount += 1;
            }
        });

        return servicesCount;
    }

    onUpdateSelectedServicesCounter = (valueToAdd) => {
        const {
            minServices,
            maxServices,
            onLockNextStep
        } = this.props;

        this.setState({ selectedServicesCount: this.state.selectedServicesCount + valueToAdd }, () => {
            if(!minServices && !maxServices) {
                onLockNextStep(false);
            } else if(maxServices && this.state.selectedServicesCount > maxServices) {
                onLockNextStep(true);
            } else if(minServices && this.state.selectedServicesCount < minServices) {
                onLockNextStep(true);
            } else if(maxServices==1 && this.state.selectedServicesCount != maxServices) {
                onLockNextStep(true);
            } else {
                onLockNextStep(false);
            }
        });
        
    }

    isValidServiceCount = () => {
        const {
            minServices,
            maxServices
        } = this.props;
        
        const servicesCount = this.countServicesSelected();

        if(!minServices && !maxServices) { 
            return true; 
        }

        if(minServices && maxServices) {
            return servicesCount >= minServices && servicesCount <= maxServices;
        }

        if(minServices) {
            return servicesCount >= minServices;
        }

        return servicesCount <= maxServices;
    }

    isSelectionComplete = () => {
        const {
            minServices,
            maxServices
        } = this.props;
        
        const { selectedServicesCount } = this.state;

        if(!minServices && !maxServices) {
            return false;
        }

        if(minServices && maxServices) {
            return selectedServicesCount >= minServices && selectedServicesCount <= maxServices;
        }

        if(minServices && selectedServicesCount >= minServices) {
            return true;
        }

        if(maxServices && selectedServicesCount === maxServices) {
            return true;
        }

        return false;
    }

    isDisabledService = () => {
        const { 
            minServices,
            maxServices,
        } = this.props;

        const { selectedServicesCount } = this.state;

        if(!minServices && !maxServices) {
            return false;
        }

        if(
            (minServices === 1 && maxServices === 1) ||
            (!minServices && maxServices === 1) ||
            (minServices === 1 && !minServices) ||
            (minServices && !maxServices)
        ) {
            return false;
        }

        if(maxServices && selectedServicesCount < maxServices) {
            return false;
        }

        return true;
    }

    onSingleService = (serviceId, selected) => {
        const { selectedServicesCount } = this.state;
        const {
            onSetServiceSelected,
            unselectAllServices
        } = this.props;

        if(selected) {
            onSetServiceSelected(serviceId, false);
            this.onUpdateSelectedServicesCounter(-1);
        } else {
            unselectAllServices(() => {
                onSetServiceSelected(serviceId, true, () => {
                    if(selectedServicesCount === 0) {
                        this.onUpdateSelectedServicesCounter(1);
                    }
                });
            });
        }
    }

    onUnlimitedServices = (serviceId, selected) => {
        const { onSetServiceSelected } = this.props;

        if(selected) {
            onSetServiceSelected(serviceId, false);
            this.onUpdateSelectedServicesCounter(-1);
        } else {
            onSetServiceSelected(serviceId, true, () => {
                this.onUpdateSelectedServicesCounter(1);
            });
        }
    }

    onServicesRange = (serviceId, selected) => {
        const { selectedServicesCount } = this.state;
        const { 
            maxServices,
            onSetServiceSelected 
        } = this.props;

        if(selected) {
            onSetServiceSelected(serviceId, false, () => {
                this.onUpdateSelectedServicesCounter(-1);
            });
        } else {
            if(!maxServices || selectedServicesCount < maxServices) {
                onSetServiceSelected(serviceId, true, () => {
                    this.onUpdateSelectedServicesCounter(1);
                });
            }
        }
    }

    onServiceClicked = (serviceId, selected) => {
        const { maxServices } = this.props;

        if(maxServices === 1) {
            this.onSingleService(serviceId, selected);
        } else if(!maxServices) {
            this.onUnlimitedServices(serviceId, selected);
        } else {
            this.onServicesRange(serviceId, selected);
        }
    }

    renderServiceCounter = () => {
        const {
            minServices,
            maxServices
        } = this.props;

        if(minServices && maxServices) {
            return (`${minServices} Min / ${maxServices} Max Services`);
        }

        if(maxServices) {
            return (`${maxServices} Max Services`);
        }

        if(minServices) {
            return (`${minServices} Min Services`);
        }
    }

    renderServices = () => {
        const { 
            services
        } = this.props;
        
        if(!services.length) {
            return <h4>No services available</h4>;
        }

        return services.map(({ serviceId, serviceName, selected }) => {
            return (
                <div 
                    key={serviceId} 
                    className={`
                        service-item rounded border 
                        ${selected ? 'selected' : ''}
                        ${this.isDisabledService() ? 'disabled' : ''}
                    `.trim()}
                    onClick={() => this.onServiceClicked(serviceId, selected)}
                >
                    {serviceName}
                </div>
            );
        });
    }

    componentDidMount() {
        this.props.onLockNextStep(!this.isValidServiceCount());
        this.setState({ selectedServicesCount: this.countServicesSelected() });
    }

    render() {
        return (
            <Fragment>
                <div className={`services-counter ${this.isSelectionComplete() ? 'completed' : ''}`.trim()}>
                    {this.renderServiceCounter()}
                </div>
                <div className='services-grid'>
                    {this.renderServices()}
                </div>
            </Fragment>
        );
    }
}

export default ServiceSelection;
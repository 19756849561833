import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getCoupons = ({
    brandId = null, 
    templateId = null,
    year
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/coupon`, {
            mailingPlanTemplateID: templateId
        }, {
            params: {
                year
            }
        })
    );
}

// Coupons must have attributes with these names:
// - name
// - code
// - active
// - licenseRequired
// - outputText
// - discounts
// - mailingPlanTemplateID
export const createCoupon = (coupon = {}) => {
    return handleResponse(
        api().post('/corporate/coupons/create', {
            ...coupon
        })
    );
}

// Coupons must have attributes with these names:
// - id
// - name
// - code
// - active
// - licenseRequired
// - outputText
// - discounts
// - mailingPlanTemplateID
export const updateCoupon = (coupon = {}) => {
    return handleResponse(
        api().post('/corporate/coupons/update', {
            ...coupon
        })
    );
}

// Coupons is an array of ids
export const deleteCoupons = (coupons = []) => {
    return handleResponse(
        api().post('/corporate/coupons/delete', {
            coupons
        })
    );
}
import { downloadExportedFile, exportPlan } from 'api/lmpbp/exports';
import Button from 'components/common/button/Button';
import { DOWNLOAD_FILE_ERROR_TEMPLATE, EXPORT_PLAN_ERROR_TEMPLATE, EXPORT_PLAN_SUCCESS_TEMPLATE } from 'constants/notificationTemplates';
import useAlert from 'hooks/useAlert';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import formatDate from 'utils/formatDate';

function PlanExportsItem({ campaign }) {

    const queryClient = useQueryClient();

    const exportPlanSuccessAlert = useAlert(EXPORT_PLAN_SUCCESS_TEMPLATE);
    const exportPlanMutation = useMutation(exportPlan, {
        onError: useAlert(EXPORT_PLAN_ERROR_TEMPLATE),
        onSuccess: (successRes) => {
            if(successRes === "success"){
                exportPlanSuccessAlert();
            }
            queryClient.invalidateQueries('exported-files');
        },
    });

    const downloadFileMutation = useMutation(downloadExportedFile, {
        onError: useAlert(DOWNLOAD_FILE_ERROR_TEMPLATE),
        onSuccess: (data) => {
            const link = document.createElement('a');
            link.href = data;
            link.setAttribute('download', `${campaign.fileMetadata.filename}`);
            document.body.appendChild(link);
            link.click();
        }
    });

    return (
        <div className='plan-exports-item shadow'>
            <div className='d-flex justify-content-between flex-wrap column-gap-1 w-100'>
                <div className='d-flex flex-column'>
                    <div className='mb-2'></div>
                    <div className='header-name d-flex align-items-center column-gap-1'>
                        {`${campaign.value} - ${campaign.year}`}
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end flex-wrap row-gap-1 column-gap-1'>
                    {
                        campaign.fileMetadata?.date &&
                        <small>Last export: {formatDate.descriptiveDateAndTime(campaign.fileMetadata?.date)}</small>
                    }
                    <Button
                        btnType='primary'
                        label='Export Manually'
                        action={() => {
                            exportPlanMutation.mutate({
                                tactic: campaign.tactic?.name,
                                year: campaign.year,
                                month: campaign.id,
                                iotEndpoint: campaign.iotEndpoint
                            });
                        }}
                        loading={exportPlanMutation.isLoading}
                        loadingMessage='Exporting...'
                        disabled={campaign.year < new moment().year()} // TODO: Verify this bussines rule
                    />
                    {
                        campaign.fileMetadata &&
                        <Button
                            btnType='outline-orange'
                            label='Download'
                            action={() => {
                                downloadFileMutation.mutate({
                                    filename: campaign.fileMetadata.filename,
                                    tactic: campaign.tactic.name,
                                    year: campaign.year
                                });
                            }}
                            loading={downloadFileMutation.isLoading}
                            loadingMessage='Downloading...'
                            disabled={downloadFileMutation.isLoading}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

PlanExportsItem.propTypes = {
    campaign: PropTypes.object.isRequired,
}

export default PlanExportsItem;



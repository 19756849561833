import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Panel from 'components/common/panel/Panel';
import Input from 'components/common/input/Input';
import Switch from 'components/common/switch/Switch';
import Button from 'components/common/button/Button';

import { 
    saveBadge as saveBadgeAction,
    updateBadge as updateBadgeAction,
    deleteBadge as deleteBadgeAction
} from 'actions/badgesConfigActions';

export class BadgeConfigModal extends Component {
    state = {
        badge: null,
        isEditing: false,
        imageName: null,
    }

    static propTypes = {
        templateId: PropTypes.number.isRequired,
        onHide: PropTypes.func.isRequired,
        selectedBadge: PropTypes.object
    }

    onBadgeNameChange = value => {
        this.setState({ badge: { ...this.state.badge, badgeName: value } });
    }
    onChangeBadgeCode = code => {
        this.setState({ 
            badge: { 
                ...this.state.badge, 
                code 
            }
        });
    }

    onChangeBadgeText = value => {
        this.setState({ 
            badge: { 
                ...this.state.badge, 
                badgeText :value
            }
        });
    }
    onToggleIsActive = active => {
        this.setState({ badge: { ...this.state.badge, active } });
    }

    onEnableSaveButton = () => {
        const { 
            badge,
        } = this.state;

        const {
            badgeName,
        } = badge || {};
        
        return badge &&
            badgeName  ;
    }

    onDelete = (badgeId = null) => {
        const { 
            templateId,
            deleteBadge,
            onHide
        } = this.props;

        deleteBadge(templateId, badgeId, onHide);
    }

    onSave = (badge = null) => {
        const { isEditing } = this.state;
        const {
            templateId,
            updateBadge,
            saveBadge,
            onHide
        } = this.props;

        if(isEditing) {
            updateBadge(templateId, badge, onHide);
        } else {
            saveBadge(templateId, badge, onHide);
        }
    }

    componentDidMount() {
        const { 
            selectedBadge,
        } = this.props;

        this.setState({ 
            badge: { ...selectedBadge },
            isEditing: selectedBadge?.badgeId ? true : false,
            validMaxServices: selectedBadge?.maxServices >= selectedBadge?.minServices,
            enoughBadgeSelected: selectedBadge?.badges?.length >= selectedBadge?.numberOfBadges
        }, () => {

        });
    }

    render() {
        const { 
            isEditing,
            badge,
        } = this.state;

        const { 
            onHide,
            saving,
            updating,
            deleting
        } = this.props;
        return (
            <Modal
                className='brochure-config-modal'
                show={true}
                onHide={onHide}
            >
                <Modal.Body>
                    <Panel
                        header={isEditing ? `${badge?.badgeName}` : 'New Badge'}
                        headerStyling={{
                            fontSize: '20px',
                            paddingTop: '0.75rem',
                            paddingBottom: '0.75rem'
                        }}
                        hasShadow={false}
                    >
                        <div className='config-fields-container'>
                            <div className='d-flex column-gap-1 align-items-start flex-wrap'>
                                <div className='flex-grow-2'>
                                    <Input 
                                        label='Badge Name'
                                        placeholder='Enter Badge name'
                                        value={badge?.badgeName}
                                        onChange={({target: {value}}) => this.onBadgeNameChange(value)}
                                        error={!badge?.badgeName}
                                        errorMsg='This field is required'
                                    />
                                </div>
                                <div className='flex-grow-1'>
                                    <Input
                                        label='Code'
                                        value={badge?.code}
                                        placeholder='Badge code'
                                        onChange={({ target: { value } }) => this.onChangeBadgeCode(value)}
                                        error={!badge?.code}
                                        errorMsg='This field is required'
                                    />
                                </div>
                                <div className='flex-grow-2'>
                                    <Input
                                         label='Badge Display Text'
                                         value={badge?.badgeText}
                                         placeholder='Text to display in badge'
                                         onChange={({ target: { value } }) => this.onChangeBadgeText(value)}
                                         error={!badge?.badgeText}
                                         errorMsg='This field is required'
                                    />
                                </div>
                                <div className='d-flex flex-wrap align-items-start column-gap-2 row-gap-1'>
                                   
                                    <Switch 
                                        id='badgeConfigActive'
                                        label='Active'
                                        labelTop
                                        checked={badge?.active}
                                        onToggle={checked => this.onToggleIsActive(checked)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button 
                            btnType='outline-danger'
                            label={isEditing ? 'Delete Badge' : 'Cancel'}
                            action={() => {
                                if(isEditing) {
                                    this.onDelete(badge?.badgeId);
                                } else {
                                    onHide();
                                }
                            }}
                            loading={deleting}
                            loadingMessage='Deleting badge...'
                            disabled={saving || updating}
                        />
                        <Button 
                            btnType='primary'
                            label={isEditing ? 'Save Changes' : 'Create Badge'}
                            action={() => this.onSave(badge)}
                            disabled={!this.onEnableSaveButton() || deleting}
                            loading={saving || updating}
                            loadingMessage={saving ? 'Saving badge...' : 'Updating badge...'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    saving: state.badgesConfig?.saving,
    updating: state.badgesConfig?.updating,
    deleting: state.badgesConfig?.deleting
});

export default connect(mapStateToProps, {
    saveBadge: saveBadgeAction,
    updateBadge: updateBadgeAction,
    deleteBadge: deleteBadgeAction
})(BadgeConfigModal);

import moment from 'moment';

const sortStrings = (data, attr, desc) => {
    if(!Array.isArray(data)) return data;

    if(attr) {
        return data.sort((a, b) => {
            if(a[attr] === b[attr]) return 0;
            
            if(desc) {
                return a[attr] > b[attr] ? -1 : 1;
            }
            
            return a[attr] > b[attr] ? 1 : -1;
        });
    }

    return data.sort((a, b) => {
        if(a === b) return 0;

        if(desc) {
            return a > b ? -1 : 1;
        }
        
        return a > b ? 1 : -1;
    });
};

const sortNumbers = (data, attr, desc) => {
    if(!Array.isArray(data)) return data;

    if(attr) {
        return data.sort((a, b) => {
            if(a[attr] === b[attr]) return 0;
            
            if(desc) {
                return a[attr] > b[attr] ? -1 : 1;
            }
            
            return a[attr] > b[attr] ? 1 : -1;
        });
    }

    return data.sort((a, b) => {
        if(a === b) return 0;

        if(desc) {
            return a > b ? -1 : 1;
        }
        
        return a > b ? 1 : -1;
    });
};

const sortDates = (data, attr, desc) => {
    if(!Array.isArray(data)) return data;

    if(attr) {
        if(desc) {
            return data.sort((a, b) => new moment(b[attr]).valueOf() - new moment(a[attr]).valueOf());
        }
        
        return data.sort((a, b) => new moment(a[attr]).valueOf() - new moment(b[attr]).valueOf());
    }

    if(desc) {
        return data.sort((a, b) => new moment(b).valueOf() - new moment(a).valueOf());
    }
    
    return data.sort((a, b) => new moment(a).valueOf() - new moment(b).valueOf());
};

const sortBy = ({
    data,
    type = 'string',
    attr = null,
    desc = false
}) => {
    switch(type) {
        case 'string':
            return sortStrings(data, attr, desc);
        case 'number':
            return sortNumbers(data, attr, desc);
        case 'date':
            return sortDates(data, attr, desc);
        default:
            return sortStrings(data, attr, desc);
    }
}

export default sortBy;
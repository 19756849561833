import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import moment from 'moment';

import Tactics from './tactics/Tactics';
import Button from 'components/common/button/Button';
import Loader from 'components/common/loader/Loader';
import ConfirmationModal from 'components/common/confirmation-modal/ConfirmationModal';

import loginUser from 'utils/loginUser';
import userSession from 'utils/userSession';
import validateStatus from 'utils/validateStatus';
import { validatePrefferedTacticsAnnualBudgets, validateMonthlyBudgets, validateAnnualBudgetsExeptDiscretionaryMarketingSpendTactic, validateConsecutiveMonthsTactics } from 'utils/validateLmpbpPreffredTacticBudgets';

import CommentsModal from './comments-modal/CommentsModal';
import InlineNotification from 'components/common/inline-notification/InlineNotification';

import {
    submitBudgetPlan,
    saveBudgetEntry,
    copyBudgetPlan,
    approveBudgetPlan,
    rejectBudgetPlan,
    exportBudgetPlan,
} from 'api/lmpbp/budgetPlans';

import {
    closeNotification,
    showNotification,
} from 'actions/notificationActions';

import {
    LMPBP_SAVE_ALL_ENTRIES_ERROR,
    LMPBP_SUBMIT_BUDGET_PLAN_ERROR,
    LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS,
    LMPBP_COPY_BUDGET_PLAN_ERROR,
    LMPBP_APPROVE_BUDGET_PLAN_ERROR,
    LMPBP_APPROVE_BUDGET_PLAN_SUCCESS,
    LMPBP_REJECT_BUDGET_PLAN_ERROR,
    LMPBP_REJECT_BUDGET_PLAN_SUCCESS,
    LMPBP_SAVE_ALL_ENTRIES_SUCCESS,
    LMPBP_GET_EXPORT_FILE_ERROR,
    LMPBP_SET_HAVE_UNSAVED_CHANGES,
    LMPBP_CLEAN_MODIFIED_TACTICS,
    LMPBP_MOVE_TO,
} from 'actions/types';
import CustomButton from '../custom-buttons/CustomButton';

export const BudgetPlanContext = React.createContext();

const BudgetPlan = ({
    franchiseId,
    budgetPlan = null,
    template = null,
    onCreateNewDraftSuccess = null,
    onSubmitSuccess = null,
    onApproveSuccess = null,
    onRejectSuccess = null,
    validateEntries = null
}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const brand = useSelector(state => state.brand);
    const directMailTactics = ["Directmail", "WilenTargetedDirectMail"/*, "Spectrum"*/];

    const [isLoading, setIsLoading] = useState(false);
    const [updatedBudgetEntries, setUpdatedBudgetEntries] = useState([]);
    const [lastSaved, setLastSaved] = useState(null);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [fromSubmit, setFromSubmit] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [tactics, setTactics] = useState([]);
    const [requiresSubmitPlan, setRequiresSubmitPlan] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [goodForSubmit, setGoodForSubmit] = useState(true);
    const [addingCustomTactic, setAddingCustomTactic] = useState(false);

    const tacticsDic = useMemo(() => {
        const dict = {}
        if (!tactics || tactics.length === 0) {
            return null;
        }
        tactics.forEach((tactic) => dict[tactic.id] = tactic);
        return dict;
    }, [tactics]);

    const moveTo = useSelector((state) => state.status.moveTo);
    const haveUnsavedChanges = useSelector((state) => state.status.unsavedBudgetPlanChanges);

    useEffect(() => {
        // if(!fromSubmit && showMessage)
        // {
        //     dispatch(
        //         showNotification({
        //             id: LMPBP_SAVE_ALL_ENTRIES_SUCCESS,
        //             title: 'Saved!',
        //             message: '',
        //             type: 'success',
        //         })
        //     );
        // }
        setFromSubmit(null);
    }, [fromSubmit]);

    useEffect(() => {
        if (redirect && userSession.isFranchiseOwer) {
            setTimeout(redirectToDM, 5000);
        }
    }, [redirect]);

    useEffect(() => {
        setUpdatedBudgetEntries([]);
    }, [])

    const createNewDraft = useMutation(
        () => copyBudgetPlan({ budgetPlanId: budgetPlan.id }),
        {
            onError: error => {
                dispatch(
                    showNotification({
                        id: LMPBP_COPY_BUDGET_PLAN_ERROR,
                        message: 'Error copying budget plan',
                        type: 'danger',
                        serverError: error,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_COPY_BUDGET_PLAN_ERROR));
                if (onCreateNewDraftSuccess) onCreateNewDraftSuccess();
            },
        }
    );

    const onExportData = useQuery(
        ['onExportData', budgetPlan],
        async () =>
            await exportBudgetPlan({
                budgetPlanIds: [budgetPlan.id],
            }),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_EXPORT_FILE_ERROR,
                        message: 'Error fetching Export File',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: res => {
                dispatch(closeNotification(LMPBP_GET_EXPORT_FILE_ERROR));
                var link = document.createElement("a");
                link.href = res;
                document.body.appendChild(link);
                link.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(link);
                }, 200);
                setIsLoading(false)
            },
        }
    );

    const isSomeDMTacticWithValue = () => {
        let dmTacticsIds = [];

        directMailTactics.forEach((dmTactic) => {
            let tacticId = tactics.find((tactic) => tactic.name === dmTactic)?.id;
            if (tacticId) {
                dmTacticsIds.push(tacticId);
            }
        });

        return updatedBudgetEntries.some((budgetEntry) =>
            dmTacticsIds.some((tacticId) => budgetEntry.tacticId === tacticId) &&
            budgetEntry.budgets.some((budget) => budget.value > 0));
    }

    const submitPlan = useMutation(
        () =>
            submitBudgetPlan({
                franchiseId,
                budgetPlanId: budgetPlan.id,
            }),
        {
            onError: error => {
                //To display multiple errors in next line
                error.message = error.message.split(",");
                dispatch(closeNotification(LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS));
                dispatch(
                    showNotification({
                        id: LMPBP_SUBMIT_BUDGET_PLAN_ERROR,
                        title: 'Submission Failed',
                        message: 'Error submitting budget plan',
                        type: 'danger',
                        serverError: error,
                    })
                );
            },
            onSuccess: () => {
                if (userSession.isFranchiseOwer() && budgetPlan.directMailAccess && isSomeDMTacticWithValue()) {
                    setRedirect(true);
                    dispatch(closeNotification(LMPBP_SUBMIT_BUDGET_PLAN_ERROR));
                    dispatch(
                        showNotification({
                            id: LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS,
                            title: 'Submitted!',
                            message: 'Budget plan has been submitted successfully.You will be redirected to Direct Mail in 5 sec...',
                            type: 'success'
                        })
                    );
                }
                else {
                    dispatch(closeNotification(LMPBP_SUBMIT_BUDGET_PLAN_ERROR));
                    dispatch(
                        showNotification({
                            id: LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS,
                            title: 'Submitted!',
                            message: 'Budget plan has been submitted successfully',
                            type: 'success',
                        })
                    );
                }
                if (onSubmitSuccess) onSubmitSuccess();
            },
        }
    );

    const approvePlan = useMutation(
        ({ comments = null } = {}) =>
            approveBudgetPlan({
                budgetPlanId: budgetPlan.id,
                comments,
            }),
        {
            onError: error => {
                dispatch(closeNotification(LMPBP_APPROVE_BUDGET_PLAN_SUCCESS));
                dispatch(
                    showNotification({
                        id: LMPBP_APPROVE_BUDGET_PLAN_ERROR,
                        title: 'Approve Failed',
                        message: 'Error approving budget plan',
                        type: 'danger',
                        serverError: error,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_APPROVE_BUDGET_PLAN_ERROR));
                dispatch(
                    showNotification({
                        id: LMPBP_APPROVE_BUDGET_PLAN_SUCCESS,
                        title: 'Approved!',
                        message: 'Budget plan has been approved successfully',
                        type: 'success',
                    })
                );
                if (onApproveSuccess) onApproveSuccess();
            },
            onSettled: () => {
                setShowApproveModal(false);
            },
        }
    );

    const rejectPlan = useMutation(
        ({ comments } = {}) =>
            rejectBudgetPlan({
                budgetPlanId: budgetPlan.id,
                comments,
            }),
        {
            onError: error => {
                dispatch(closeNotification(LMPBP_REJECT_BUDGET_PLAN_SUCCESS));
                dispatch(
                    showNotification({
                        id: LMPBP_REJECT_BUDGET_PLAN_ERROR,
                        title: 'Reject Failed',
                        message: 'Error rejecting budget plan',
                        type: 'danger',
                        serverError: error,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_REJECT_BUDGET_PLAN_ERROR));
                dispatch(
                    showNotification({
                        id: LMPBP_REJECT_BUDGET_PLAN_SUCCESS,
                        title: 'Rejected',
                        message: 'Budget plan has been rejected successfully',
                        type: 'success',
                    })
                );
                if (onRejectSuccess) onRejectSuccess();
            },
            onSettled: () => {
                setShowRejectModal(false);
            },
        }
    );

    const saveEntry = useMutation(
        ({
            franchiseId,
            setupFranchiseId,
            entryId,
            tacticId,
            websiteId,
            budgetPlanId,
            resComId,
            budgets,
        } = {}) =>
            saveBudgetEntry({
                franchiseId,
                setupFranchiseId,
                entryId,
                tacticId,
                websiteId,
                budgetPlanId,
                resComId,
                budgets,
            }),
        {
            onError: error => {
                dispatch(closeNotification(LMPBP_SAVE_ALL_ENTRIES_SUCCESS));
                dispatch(
                    showNotification({
                        id: LMPBP_SAVE_ALL_ENTRIES_ERROR,
                        title: 'Save Failed',
                        message: 'Error saving budget plan',
                        type: 'danger',
                        serverError: error,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_SAVE_ALL_ENTRIES_ERROR));
                onUpdateLastSaved();
            },
        }
    );

    const onSaveAllEntries = ({ budgetPlanId } = {}) => {
        if (
            updatedBudgetEntries.length ||
            validateStatus.isDraft(budgetPlan.status)
        ) {
            updatedBudgetEntries.forEach(({ id: entryId, tacticId, websiteId, resComId, budgets, setupFranchiseId }) => {
                if (setupFranchiseId === franchiseId) {
                    saveEntry.mutate({
                        franchiseId,
                        setupFranchiseId,
                        entryId,
                        tacticId,
                        websiteId,
                        budgetPlanId,
                        resComId,
                        budgets
                    });
                }
            }
            );

            dispatch({ type: LMPBP_CLEAN_MODIFIED_TACTICS });
        }
    };

    useEffect(() => {
        if (requiresSubmitPlan && saveEntry.isSuccess) {
            submitPlan.mutate();
            setRequiresSubmitPlan(false);
        }
    }, [requiresSubmitPlan, saveEntry]);

    const onUpdateBudgetEntries = (entry, isLoadingFromDB = false) => {
        setUpdatedBudgetEntries(prevBudgetEntries => [
            ...prevBudgetEntries.filter(({ id }) => id !== entry.id),
            entry,
        ]);

        // if(!isLoadingFromDB && validateStatus.isDraft(budgetPlan.status))
        // {
        //     dispatch({type:LMPBP_SET_HAVE_UNSAVED_CHANGES, payload: false});
        // }
    };

    const onResetBudgetEntries = () => {
        setUpdatedBudgetEntries(() => []);
    };

    const onUpdateLastSaved = () => {
        setLastSaved(moment().calendar());
    };

    const onConfirmApprove = (comments = null) => {
        comments = comments ? comments.trim() : null;
        approvePlan.mutate({ comments });
    };

    const onConfirmReject = comments => {
        rejectPlan.mutate({ comments: comments.trim() });
    };

    useEffect(() => {
        if (validateStatus.isApproved(budgetPlan.status)
            || validateStatus.isSubmitted(budgetPlan.status)) {
            return;
        }
        setGoodForSubmit(!areUpdateEntriesInvalid());
    }, [updatedBudgetEntries]);

    const areUpdateEntriesInvalid = () => {
        if (userSession.isDivisionalUser()) {
            return false;
        }
        let result = false;

        let budgetPlanMetadata = {
            tactics: tacticsDic,
            requiredAnnualAmount: budgetPlan?.requiredAnnualAmount,
            requiredMonthlyAmount: budgetPlan?.requiredMonthlyAmount
        }
        const errors = validateEntries(updatedBudgetEntries, budgetPlanMetadata);
        setValidationErrors(errors);
        result = errors?.length > 0;

        if (userSession.isDcsLmpbpUser()) {
            // Note: commented code by stakeholder request on date 2023-12-20
            result = result //|| invalidSpendPrefferedTactics() 
                || invalidSpendTargatedDmScorpionForDcs()
                || invalidSpendExcepDiscretionaryMarketingTactic()
                || invalidConsecutiveMonthsTacticsForDcs();
        }
        return result;
    }

    const redirectToDM = () => {
        userSession.setArrivedAtLmpbp(false);
        const sessionJSON = JSON.parse(sessionStorage.getItem("user_session"));
        const index = sessionJSON.FranchisesDetails.findIndex((franchise) => franchise.id == franchiseId);

        sessionStorage.setItem("redirect", JSON.stringify(
            { year: template.year, franchiseId: sessionJSON.FranchisesDetails[index].external_id }));

        console.log("Hello");

        loginUser({
            authorizerURL: process.env.REACT_APP_AUTHORIZER_URL,
            ssoURL: process.env.REACT_APP_SSO_URL
        });
    }

    const onSubmit = (budgetPlanId) => {
        dispatch({ type: LMPBP_SET_HAVE_UNSAVED_CHANGES, payload: false });
        dispatch({ type: LMPBP_MOVE_TO, payload: null });
        setShowMessage(false);
        onSaveAllEntries(budgetPlanId);
        setRequiresSubmitPlan(true);
    }

    const onSave = (budgetPlanId) => {
        dispatch({ type: LMPBP_SET_HAVE_UNSAVED_CHANGES, payload: false });
        dispatch({ type: LMPBP_MOVE_TO, payload: null });
        setFromSubmit(false);
        setShowMessage(true);
        onSaveAllEntries(budgetPlanId);
    }

    // Note: commented code by stakeholder request on date 2023-12-20
    // const invalidSpendPrefferedTactics = () => {
    //     validatePrefferedTacticsAnnualBudgets(budgetPlan,updatedBudgetEntries);
    // }

    const invalidSpendTargatedDmScorpionForDcs = () => {
        let monthlyErrors = validateMonthlyBudgets(budgetPlan, updatedBudgetEntries);
        return monthlyErrors?.find(err => err.scorpion_err || err.tdm_err || err.native_adv_err || err.youtube_adv_err || err.mirror_display_err || err.multiview_err)
    };

    const invalidSpendExcepDiscretionaryMarketingTactic = () => {
        validateAnnualBudgetsExeptDiscretionaryMarketingSpendTactic(budgetPlan, updatedBudgetEntries);
    }

    const invalidConsecutiveMonthsTacticsForDcs = () => {
        let consecutiveErrors = validateConsecutiveMonthsTactics(updatedBudgetEntries);
        return consecutiveErrors?.find(err => err.nativeAdvertisingConsecutive || err.youtubeAdvertisingConsecutive || err.mirrorDisplayConsecutive)
    };

    const renderFOControls = () => {
        return (
            <div className='d-flex' id="FO-Controls">
                <div className='d-flex justify-content-end flex-grow-1'>
                    {validateStatus.isDraft(budgetPlan.status) && (
                        <RenderBudgetPlanActions
                            isDraft={validateStatus.isDraft(budgetPlan.status)}
                            budgetPlanId={budgetPlan.id}
                            saveEntry={saveEntry}
                            goodForSubmit={goodForSubmit}
                            templateYear={template.year}
                            submitPlan={submitPlan}
                            lastSaved={lastSaved}
                            onSave={onSave}
                            onSubmit={(id) => {
                                setFromSubmit(true);
                                onSubmit(id);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };

    const renderDUControls = () => {
        return (
            <div className='d-flex'>
                <div className="flex-grow-1"></div>
                <div className='d-flex justify-content-center column-gap-1 flex-grow-2'>
                    {validateStatus.isSubmitted(budgetPlan.status) ? (
                        <Fragment>
                            <Button
                                id='budget-plan-reject-btn'
                                btnType='outline-danger'
                                label='Reject Plan'
                                disabled={!budgetPlan?.id}
                                action={() => setShowRejectModal(true)}
                            />
                            <Button
                                id='budget-plan-approve-btn'
                                btnType='primary'
                                label='Approve Plan'
                                disabled={!budgetPlan?.id}
                                action={() => setShowApproveModal(true)}
                            />
                        </Fragment>
                    ) : null}
                    {validateStatus.isDraft(budgetPlan.status) && (
                        <RenderBudgetPlanActions
                            isDraft={validateStatus.isDraft(budgetPlan.status)}
                            budgetPlanId={budgetPlan.id}
                            saveEntry={saveEntry}
                            goodForSubmit={goodForSubmit}
                            templateYear={template.year}
                            submitPlan={submitPlan}
                            lastSaved={lastSaved}
                            onSave={onSave}
                            onSubmit={(id) => {
                                setFromSubmit(true);
                                onSubmit(id);
                            }}
                        />
                    )}
                </div>
                <div className='flex-grow-1 d-flex justify-content-end'>
                    <Button
                        action={() => { onExportData.refetch(); setIsLoading(true) }}
                        btnType='outline-primary'
                        label='Export Plan'
                    />
                </div>
            </div>
        );
    };

    const renderDUEdit = () => {
        return (
            <div className='d-flex' id="du-edit-action">
                <div className='flex-grow-1'>
                    {!validateStatus.isDraft(budgetPlan.status) ? (
                        <Button
                            id='budget-plan-edit-btn'
                            btnType='secondary'
                            label='Edit Plan'
                            loading={createNewDraft.isLoading}
                            loadingMessage='Loading...'
                            action={() => createNewDraft.mutate()}
                        />
                    ) : null}
                </div>
            </div>
        );
    }

    const handleAddCustomTactic = () => {
        setAddingCustomTactic(true);
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth', // for smooth scrolling
        });
    }

    const renderFOEdit = () => {
        return (
            <div className='d-flex' id="fo-edit-action">
                <div className='flex-grow-1'>
                    {!validateStatus.isDraft(budgetPlan.status) ? (
                        <Button
                            id='budget-plan-edit-btn'
                            btnType='secondary'
                            label='Edit Plan'
                            loading={createNewDraft.isLoading}
                            loadingMessage='Loading...'
                            action={() => createNewDraft.mutate()}
                        />
                    ) : (brand.symbol === 'HWC'
                        && !addingCustomTactic
                        && <Button
                            id='add-custom-tactic-btn'
                            btnType='secondary'
                            label='Add custom tactic'
                            loading={createNewDraft.isLoading}
                            loadingMessage='Loading...'
                            action={handleAddCustomTactic}
                        />)}
                </div>
            </div>
        );
    }

    const renderStatus = () => {
        if (validateStatus.isDraft(budgetPlan.status)) {
            return (
                <div className='mt-4'>
                    <InlineNotification
                        message={<div>You MUST click <strong className="text-danger">Submit Plan</strong> to complete your budget (and any subsequent adjustments) for those to be
                            fully captured in the platform for review and approval. Upon doing so, you will receive a submission
                            notification email to confirm it has been received and is under review by the Marketing Team.</div>}
                        icon='fas fa-exclamation-triangle'
                        type='warning'
                    />
                </div>
            );
        }

        if (userSession.isDivisionalUser()) {
            if (validateStatus.isSubmitted(budgetPlan.status)) {
                return (
                    <div className='mt-4'>
                        <InlineNotification
                            message={
                                <div class='d-flex align-items-baseline column-gap-1'>
                                    <strong>Submitted:</strong>
                                    <span>
                                        This budget plan is pending review,
                                        please Approve or Reject below.
                                    </span>
                                </div>
                            }
                            icon='fas fa-arrow-circle-up'
                            type='primary'
                        />
                    </div>
                );
            }

            if (validateStatus.isApproved(budgetPlan.status)) {
                return (
                    <div className='mt-4'>
                        <InlineNotification
                            message={
                                <div class='d-flex align-items-baseline column-gap-1'>
                                    <strong>Approved:</strong>
                                    <span>
                                        This budget plan has been approved.
                                    </span>
                                </div>
                            }
                            icon='fas fa-clipboard-check'
                            type='success'
                        />
                    </div>
                );
            }

            if (validateStatus.isRejected(budgetPlan.status)) {
                return (
                    <div className='mt-4'>
                        <InlineNotification
                            message={
                                <div class='d-flex align-items-baseline column-gap-1'>
                                    <strong>Rejected:</strong>
                                    <span>
                                        This budget plan has been rejected.
                                    </span>
                                </div>
                            }
                            icon='fas fa-exclamation-circle'
                            type='danger'
                        />
                    </div>
                );
            }
        }
    };

    const moveAway = () => {
        switch (moveTo) {
            case 'Logout':
                userSession.logout();
                window.open(process.env.REACT_APP_LOGOUT_URL, "_self");
                break;
            case 'Dashboard':
                history.push('/lmpbp/dashboard');
                break;
        }
    }

    return (
        <div className='d-flex flex-column' id="budget-plan">
            <ConfirmationModal
                show={haveUnsavedChanges && moveTo}
                centered
                type='warning'
                headerType='warning'
                headerIconLeft='fas fa-exclamation-triangle'
                headerIconRight='fas fa-exclamation-triangle'
                header='Unsaved changes'
                confirmBtnLabel='Navigate away'
                showConfirmBtn={true}
                showCancelBtn={true}
                onConfirm={moveAway}
                message='Your plan has unsaved changes, are you sure you want to navigate away?'
            />
            <CommentsModal
                show={showApproveModal}
                title='Approve Budget Plan'
                textAreaLabel='Approval Reason (Optional)'
                onCancel={() => setShowApproveModal(false)}
                onConfirm={onConfirmApprove}
                confirmBtnLabel='Approve'
                isLoading={approvePlan.isLoading}
            />
            <CommentsModal
                show={showRejectModal}
                title='Reject Budget Plan'
                textAreaLabel='Rejection Reason'
                onCancel={() => setShowRejectModal(false)}
                onConfirm={onConfirmReject}
                confirmBtnLabel='Reject'
                isLoading={rejectPlan.isLoading}
                isRequired
            />
            {renderStatus()}
            <div className='d-flex'>
                <div className="flex-grow-1 mt-4 d-flex justify-content-start column-gap-1">
                    {userSession.isDivisionalUser()
                        ? renderDUEdit()
                        : renderFOEdit()}
                </div>
                <div className='font-family-bold font-size-30 brand-primary-color text-center mt-4 d-flex justify-content-center column-gap-1 flex-grow-2'>
                    TACTICS
                </div>
                <div className="flex-grow-1 d-flex justify-content-end"></div>
            </div>
            <BudgetPlanContext.Provider
                value={{
                    budgetPlan,
                    template,
                    saveEntry,
                    submittingPlan: submitPlan.isLoading,
                    savingPlan: saveEntry.isLoading && !fromSubmit,
                    onResetBudgetEntries,
                    onUpdateBudgetEntries,
                    addingCustomTactic,
                    setAddingCustomTactic,
                    tacticsDic,
                }}
            >
                {!template.id ? (
                    <Loader loadingMessage='Fetching plan...' />
                ) : (
                    <Fragment>
                        <Tactics
                            templateId={template.id}
                            updatedBudgetEntries={updatedBudgetEntries}
                            onTacticsLoaded={setTactics}
                            validationErrors={validationErrors}
                        />
                        <div className='m-4'>
                            {userSession.isDivisionalUser()
                                ? renderDUControls()
                                : renderFOControls()}
                        </div>
                    </Fragment>
                )}
            </BudgetPlanContext.Provider>
        </div>
    );
};

BudgetPlan.propTypes = {
    franchiseId: PropTypes.number.isRequired,
    budgetPlan: PropTypes.object.isRequired,
    template: PropTypes.object,
    onCreateNewDraftSuccess: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onApproveSuccess: PropTypes.func,
    onRejectSuccess: PropTypes.func,
    validator: PropTypes.func
};


const RenderBudgetPlanActions = ({ budgetPlanId, saveEntry, goodForSubmit, templateYear, submitPlan, lastSaved, onSave, onSubmit }) => {
    return (
        <div className='d-flex flex-column'>
            <div className='d-flex column-gap-1'>
                <CustomButton
                    id='budget-plan-save-btn'
                    className='dm-button outline-secondary'
                    disableConditions={
                        [
                            {
                                disabled: saveEntry.isLoading,
                                reason: "Cannot save while a submitting."
                            }
                        ]
                    }
                    loading={saveEntry.isLoading}
                    loadingMessage='Saving...'
                    onClick={() => onSave({ budgetPlanId })}
                > Save </CustomButton>
                <CustomButton
                    id='budget-plan-submit-btn'
                    className='dm-button primary'
                    disableConditions={
                        [
                            {
                                disabled: templateYear < new Date().getFullYear(),
                                reason: "Submissions of plans from previous years are not allowed."
                            },
                            {
                                disabled: !goodForSubmit,
                                reason: "Fix errors before submitting the plan."
                            },
                            {
                                disabled: saveEntry.isLoading,
                                reason: "Cannot submit while saving."
                            }
                        ]
                    }
                    loading={submitPlan.isLoading}
                    loadingMessage={'Submitting...'}
                    onClick={() => onSubmit({ budgetPlanId })}
                > Submit </CustomButton>
            </div>
            {lastSaved &&
                <small className='mt-1'>
                    Last saved {lastSaved}
                </small>
            }
        </div>
    );
};

export default BudgetPlan;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import { closeNotification } from 'actions/notificationActions';
import { connect } from 'react-redux';

import formatNumber from 'utils/formatNumber';

import { 
    DELETE_ADDRESSES_FILE_SUCCESS, 
    INSUFFICIENT_ADDRESSES_WARNING 
} from 'actions/types';

export class Notification extends Component {
    state = {
        show: false
    }

    static propTypes = {
        notificationId: PropTypes.string,
        notify: PropTypes.bool,
        variant: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        customConfigObj: PropTypes.object,
        customConfigKey: PropTypes.string
    }

    setShow = show => {
        this.setState({ show });
    }

    renderCustomContent = () => {
        const { 
            customConfigObj,
            customConfigKey
        } = this.props;

        switch(customConfigKey) {
            case INSUFFICIENT_ADDRESSES_WARNING:
                return customConfigObj.campaigns?.map(campaign => {
                    return (
                        <div>
                            <span className='font-family-bold'>
                                {campaign.campaignName}
                            </span>
                            <span> - {formatNumber(campaign.addresses)} addresses</span>
                        </div>
                    );
                });
            
            case DELETE_ADDRESSES_FILE_SUCCESS:
                return (
                    <div>
                        <span className='font-family-bold mr-1'>{formatNumber(customConfigObj.totalAddresses)}</span>
                        <span className='mr-1'>addresses have been deleted from file:</span>
                        <span className='font-family-bold'>{customConfigObj.fileName}</span>
                    </div>
                );

            default: 
                return null;
        }
    }

    componentDidMount() {
        this.setShow(this.props.notify);
    }

    render() {
        const {
            title,
            content,
            variant,
            notificationId,
            closeNotification,
            customConfigObj
        } = this.props;

        return (
            <div className='notification-popup'>
                <Alert 
                    show={this.state.show}
                    variant={variant || 'danger'} 
                    onClose={() => {
                        if(notificationId) {
                            closeNotification(notificationId);
                        }
                        this.setShow(false);
                    }} 
                    dismissible
                >
                    {
                        title &&
                            <Alert.Heading>
                                <span className='font-family-bold'>{title}</span>
                            </Alert.Heading>
                    }
                    {content}
                    {
                        customConfigObj &&
                            <div className='mt-2'>
                                {this.renderCustomContent()}
                            </div>
                    }
                </Alert>
            </div>
        );
    }
}

export default connect(null, {
    closeNotification
})(Notification);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BrochureItem from './BrochureItem';
import AddButton from 'components/common/add-button/AddButton';
import BrochureConfigModal from './brochure-config-modal/BrochureConfigModal';
import Loader from 'components/common/loader/Loader';

import userSession from 'utils/userSession';
import { 
    getBrochuresForConfig as getBrochuresForConfigAction,
    resetBrochuresList as resetBrochuresListAction
} from 'actions/brochuresConfigActions';

export class BrochuresList extends Component {
    state = {
        showConfigModal: false,
        selectedBrochure: null
    }

    static propTypes = {
        templateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }

    onShowConfigModal = (brochure = null) => {
        this.setState({ 
            showConfigModal: true,
            selectedBrochure: {...brochure}
        });
    }

    onHideConfigModal = () => {
        this.setState({ 
            showConfigModal: false, 
            selectedBrochure: null
        });
    }

    renderBrochures = () => {
        const { 
            brochures
        } = this.props;

        if(!Array.isArray(brochures) || !brochures.length) return null;

        return brochures.map(brochure => {
            return <BrochureItem 
                key={brochure?.brochureId}
                name={brochure?.brochureName}
                src={brochure?.src}
                onShowConfigModal={() => this.onShowConfigModal(brochure)}
            />;
        });
    }

    componentDidMount() {
        const { 
            templateId,
            getBrochuresForConfig 
        } = this.props;

        getBrochuresForConfig(userSession.getBrandId(), templateId);
    }

    componentWillUnmount() {
        this.props.resetBrochuresList();
    }

    render() {
        const { 
            showConfigModal,
            selectedBrochure
        } = this.state;

        const {
            loading,
            saving,
            templateId
        } = this.props;
        
        return (
            <div className='d-flex column-gap-1 row-gap-1 flex-wrap mt-3 mb-3'>
                { showConfigModal &&
                    <BrochureConfigModal
                        onHide={this.onHideConfigModal}
                        selectedBrochure={selectedBrochure}
                        templateId={templateId}
                    />
                }
                {
                    <Fragment>
                        <AddButton 
                            style={{width: '200px', height: '130px', textAlign: 'center'}}
                            title='Create new'
                            loading={false}
                            action={this.onShowConfigModal}
                        />
                        {this.renderBrochures()}
                        {
                            (loading || saving) &&
                                <Loader loadingMessage={loading ? 'Loading brochures...' : 'Refreshing brochures...'} />
                        }
                    </Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    brochures: state.brochuresConfig?.brochures,
    loading: state.brochuresConfig?.loading,
    saving: state.brochuresConfig?.savingBrochure
});

export default connect(mapStateToProps, {
    getBrochuresForConfig: getBrochuresForConfigAction,
    resetBrochuresList: resetBrochuresListAction
})(BrochuresList);

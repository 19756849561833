import formatDate from 'utils/formatDate';

const campaignsColumnsWrapper = (campaign = {}) => {
    if(!campaign) return {};

    return {
        id: campaign?.campaignId || '-',
        date: campaign?.date ? formatDate.dateUTC(campaign?.date) : '-',
        coupons: campaign?.coupons?.map(coupon => coupon?.outputText).join(' | '),
        brochure: campaign?.brochureName,
        services: campaign?.services?.map(service => service?.outputText).join(' | '),
        badges: campaign?.badges?.map(badge => badge?.badgeText).join(' | '),
        addresses: campaign?.addresses || '-',
        investment: campaign?.investment || '-', 
        expiration_date: formatDate.dateUTC(campaign?.expiration_date) || '-',
        desired_in_home_date: formatDate.dateUTC(campaign?.desired_in_home_date) || '-',
        budget_intention: campaign?.budget_intention || '-',
        piece_size: campaign?.piece_size || '-'
    }
};

export default campaignsColumnsWrapper;
const isCompleteCampaign = (campaign, servicesRequired,minimumAddressesPerCampaign) => {
    if (!campaign || Array.isArray(campaign) || typeof campaign !== 'object')
        return false;

    const {
        selectedBrochure = {},
        selectedServices = [],
        selectedCoupons = [],
        addresses = null,
    } = campaign;
    if (!selectedBrochure?.brochureId) return false;
    if (servicesRequired && !selectedServices?.length) return false;
    if (!selectedCoupons?.length && campaign?.is_no_coupon!=true) return false;
    //Apply address required validation as per minimumAddressesPerCampaign
    if (!addresses && (minimumAddressesPerCampaign?.active  || 
        minimumAddressesPerCampaign.strict)) return false;

    return true;
};

const validateCampaigns = (campaigns, servicesRequired = false,minimumAddressesPerCampaign) => {
    if (!Array.isArray(campaigns) || !campaigns.length) return false;
    if (typeof servicesRequired !== 'boolean') return false;

    const incompleteCampaigns = campaigns.filter(
        campaign =>
            campaign.selected && !isCompleteCampaign(campaign, servicesRequired,minimumAddressesPerCampaign)
    );

    return !incompleteCampaigns.length;
};

export default validateCampaigns;

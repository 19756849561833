import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SubmitConfirmation from './SubmitConfirmation';
import SaveDraftConfirmation from './SaveDraftConfirmation';
import Button from 'components/common/button/Button';
import BusinessRulesList from './BusinessRulesList';

import userSession from 'utils/userSession';
import formatCurrency from 'utils/formatCurrency';
import validateMailingPlan from 'utils/mailing-plan/validateMailingPlan';
import validateStatus from 'utils/validateStatus';
import { 
    confirmMailingPlanSubmission as confirmMailingPlanSubmissionAction,
    confirmMailingPlanSaveDraft as confirmMailingPlanSaveDraftAction,
    submitMailingPlan as submitMailingPlanAction,
    saveDraftMailingPlan as saveDraftMailingPlanAction,
    copyDraftMailingPlan as copyDraftMailingPlanAction,
    saveOwnerDetails as saveOwnerDetailsAction
} from 'actions/mailingPlanActions';

export class HeaderFO extends Component {

    static propTypes = {
        mailingPlan: PropTypes.object,
        franchiseInfoConfirmed: PropTypes.bool,
        correctFranchiseInfo: PropTypes.bool,
        calculateTotalCost: PropTypes.func
    }

    dismissSubmissionModal = () => {
        const {
            mailingPlan,
            franchiseInfo
        } = this.props;
        const franchiseId = franchiseInfo?.franchiseId;
        this.props.confirmMailingPlanSubmission(false);
        this.props.confirmMailingPlanSaveDraft(false);
        if(userSession.isDivisionalUser() && validateStatus.isSubmitted(mailingPlan?.status)){
            window.location.href=`/mailing-plan/${franchiseId}/${mailingPlan?.mailingPlanId}/${mailingPlan?.mailingPlanYear}`;
        }
    }

    enablePlanSubmission = () => {
        const {
            mailingPlan,
            franchiseInfoConfirmed,
            commentsRequired
        } = this.props;

        if(!mailingPlan?.mailingPlanId || mailingPlan?.loading) return false;
        if(!franchiseInfoConfirmed) return false;
        if(commentsRequired && !mailingPlan?.comments) return false;

        return validateMailingPlan(mailingPlan).enablePlanSubmission;
    }

    onSubmit = () => {
        const {
            mailingPlan,
            franchiseInfo,
            correctFranchiseInfo,
            submitMailingPlan,
            saveOwnerDetails
        } = this.props;

        const brandId = userSession.getBrandId();
        const franchiseId = franchiseInfo?.franchiseId;
        const { enablePlanSubmission } = validateMailingPlan(mailingPlan);
        
        if(enablePlanSubmission) {
            saveOwnerDetails(franchiseId, mailingPlan);
            submitMailingPlan(brandId, franchiseId, mailingPlan, correctFranchiseInfo);
        }
    }

    onSave = () => {
        const {
            mailingPlan,
            franchiseInfo,
            correctFranchiseInfo,
            saveDraftMailingPlan,
            copyDraftMailingPlan,
            saveOwnerDetails
        } = this.props;

        const brandId = userSession.getBrandId();
        const franchiseId = franchiseInfo?.franchiseId;
        const { enablePlanSubmission } = validateMailingPlan(mailingPlan);
        
        if(enablePlanSubmission) {
            saveOwnerDetails(franchiseId, mailingPlan);
            //If Mailing plan is not created before
            if(mailingPlan?.isTemplate){
                copyDraftMailingPlan(brandId, franchiseId,mailingPlan,correctFranchiseInfo);
            }else{
                saveDraftMailingPlan(brandId, franchiseId, mailingPlan, correctFranchiseInfo);
            }
        }
    }

    render() {
        const {
            mailingPlan,
            calculateTotalCost
        } = this.props;
        return (
            <div className='mailing-plan-header-container'>
                { !mailingPlan?.loading && 
                <SubmitConfirmation
                    onHide={this.dismissSubmissionModal}
                    show={mailingPlan?.submitted}
                    planYear={Number(mailingPlan?.mailingPlanYear)}
                />
                }
                 <SaveDraftConfirmation
                    onHide={this.dismissSubmissionModal}
                    show={mailingPlan?.saved}
                    planYear={Number(mailingPlan?.mailingPlanYear)}
                />
                <BusinessRulesList />
                <div className='d-flex align-items-center'>
                    <span className='mr-4 mailing-plan-total-spend'>
                        Total annual spend = ${formatCurrency(calculateTotalCost())}
                    </span>
                    <div className='d-flex flex-column'>
                        <div className='d-flex column-gap-1'>
                            { !validateStatus.isSubmitted(mailingPlan?.status) && !validateStatus.isApproved(mailingPlan?.status) &&  !validateStatus.isRejected(mailingPlan?.status)? (
                            <>
                            <Button
                                            btnType='primary'
                                            loading={mailingPlan?.saving}
                                            loadingMessage={'Saving...'}
                                            disabled={!this.enablePlanSubmission() ||
                                                mailingPlan?.loading}
                                            action={() => this.onSave()}
                                        >
                                            Save
                                        </Button><Button
                                            btnType='primary'
                                            loading={mailingPlan?.submitting}
                                            loadingMessage={'Sending request...'}
                                            disabled={!this.enablePlanSubmission() ||
                                                mailingPlan?.loading}
                                            action={() => this.onSubmit()}
                                        >
                                                Submit Request
                                            </Button></>
                    
                            ) : null}
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    franchiseInfo: state.franchise,
    commentsRequired: state.franchise?.commentsRequired
});

export default connect(mapStateToProps, {
    confirmMailingPlanSubmission: confirmMailingPlanSubmissionAction,
    confirmMailingPlanSaveDraft:confirmMailingPlanSaveDraftAction,
    submitMailingPlan: submitMailingPlanAction,
    saveDraftMailingPlan: saveDraftMailingPlanAction,
    copyDraftMailingPlan: copyDraftMailingPlanAction,
    saveOwnerDetails: saveOwnerDetailsAction
})(HeaderFO);

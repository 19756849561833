import { LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_ERROR } from 'actions/types';
import { addFranchiseCustomTactic } from 'api/lmpbp/tactics';
import Button from "components/common/button/Button";
import useCloseAlert from 'hooks/useCloseNotification';
import useAlert from 'hooks/useAlert';
import { useContext, useState } from "react";
import { useMutation, useQueryClient } from 'react-query';
import { BudgetPlanContext } from '../../BudgetPlan';
import { ADD_CUSTOM_TACTIC_NOTIFICATION_ERROR_TEMPLATE, ADD_CUSTOM_TACTIC_NOTIFICATION_SUCCESS_TEMPLATE } from 'constants/notificationTemplates';

const AddCustomTacticForm = (props) => {
    const { onCancel, onSuccess } = props;
    const { budgetPlan } = useContext(BudgetPlanContext);

    const [displayName, setDisplayName] = useState('');

    const queryClient = useQueryClient();
    const showErrorNotification = useAlert(ADD_CUSTOM_TACTIC_NOTIFICATION_ERROR_TEMPLATE);
    const showSuccessNotification = useAlert(ADD_CUSTOM_TACTIC_NOTIFICATION_SUCCESS_TEMPLATE);
    const closeNotificationHandler = useCloseAlert();

    const mutation = useMutation(
        addFranchiseCustomTactic, {
        onError: showErrorNotification,
        onSuccess: () => {
            closeNotificationHandler(LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_ERROR);
            showSuccessNotification();
            queryClient.invalidateQueries('tactics');
            onSuccess();
        },
    });

    const handleInputChange = (val) => {
        setDisplayName(val.target.value);
    }
    
    const handleAddCustomTactic = () => {
        mutation.mutate({
            budgetPlanId: budgetPlan.id, 
            displayName: displayName
        })
    }

    return (
        <div className='tactic-container border rounded py-3 px-4 mb-3 d-flex column-gap-1'>
            <input
                type="text"
                value={displayName}
                onChange={handleInputChange}
                placeholder="Add your custom tactic name here"
                className="tactic-title-input flex-fill border"
            />
            <Button
                id='create-custom-tactic-btn'
                btnType='outline-secondary'
                label='Create tactic'
                action={handleAddCustomTactic}
            />
            <Button
                id='cancel-custom-tactic-btn'
                btnType='outline-danger'
                label='Cancel'
                action={onCancel}
                className="m-1"
            />
        </div>
    );
}

export default AddCustomTacticForm;
import React from 'react';
import { Form, FormControl, InputGroup, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from 'components/lmpbp/custom-buttons/CustomButton';

function SearchBar({
  searchTerm,
  setSearchTerm,
  onSubmit,
  searchBy,
  icon,
  className = ""
}) {

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Form
      className={`${className} d-flex justify-content-center align-items-center`}
      inline
      onSubmit={handleSearchSubmit}
    >
      <Container>
        <Row>
          <Col className="p-0">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className={`mr-2 brand-primary-color font-size-20 fas ${icon}`}></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                placeholder={`Search by ${searchBy}`}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </Col>
          <Col xs={3} className="d-flex justify-content-end p-0">
            <CustomButton type="submit" className="ml-2 dm-button outline-primary">Search</CustomButton>
          </Col>
        </Row>
      </Container>
    </Form>
  );
}

export default SearchBar;

import awsIoTConnect from 'api/utils/awsIoTConnect';
import mailingPlanTemplateWrapper from 'api/direct-mail/utils/backend-wrappers/mailingPlanTemplateWrapper';
import userSession from 'utils/userSession';

import { 
    notifyDownloadCampaignExportError,
    notifyExportCampaignError, 
    notifyExportCampaignSuccess, 
    notifyGetActiveCampaignTemplatesError
} from './notificationActions';

import {
    DOWNLOAD_CAMPAIGN_EXPORT,
    DOWNLOAD_CAMPAIGN_EXPORT_LOADING,
    EXPORT_CAMPAIGN,
    EXPORT_CAMPAIGN_LOADING,
    GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES,
    GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_LOADING
} from './types';

import { getMailingPlanTemplates } from 'api/direct-mail/planTemplates';

import { 
    downloadAddressesExport, 
    exportAddresses 
} from 'api/direct-mail/addressesExport';

import { subscribeToAwsIot } from 'api/direct-mail/awsIot';

export const getActivePlanCampaignTemplates = () => async dispatch => {
    dispatch(notifyGetActiveCampaignTemplatesError({ display: false }));

    dispatch({
        type: GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_LOADING,
        payload: true
    });

    const [templatesErr, templatesRes] = await getMailingPlanTemplates({
        active: true,
        withCampaigns: true,
        withExports: true
    });

    if(templatesErr) {
        dispatch(notifyGetActiveCampaignTemplatesError({ serverError: templatesErr }));
    }

    dispatch({
        type: GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_LOADING,
        payload: false
    });

    return dispatch({ 
        type: GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES, 
        payload: mailingPlanTemplateWrapper(templatesRes).campaignTemplates
    });
}

export const exportCampaign = (campaignTemplateId = null, iotEndpoint = null) => async dispatch => {
    dispatch(notifyExportCampaignError({ display: false }));
    dispatch(notifyExportCampaignSuccess({ display: false }));

    dispatch({
        type: EXPORT_CAMPAIGN_LOADING,
        payload: true
    });

    const [exportErr, exportRes] = await exportAddresses({ 
        campaignTemplateId,
        iotEndpoint
    });

    if(exportErr) {
        dispatch(notifyExportCampaignError({ serverError: exportErr }));
    }

    return dispatch({ 
        type: EXPORT_CAMPAIGN, 
        payload: exportRes 
    });
}

export const downloadCampaignExport = (filename = '') => async dispatch => {
    dispatch(notifyDownloadCampaignExportError({ display: false }));

    dispatch({
        type: DOWNLOAD_CAMPAIGN_EXPORT_LOADING,
        payload: true
    });

    const [downloadUrlErr, downloadUrl] = await downloadAddressesExport({ filename });

    if(downloadUrlErr) {
        dispatch(notifyDownloadCampaignExportError({ serverError: downloadUrlErr }));
    } else {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
    }

    dispatch({
        type: DOWNLOAD_CAMPAIGN_EXPORT_LOADING,
        payload: false
    });

    return dispatch({
        type: DOWNLOAD_CAMPAIGN_EXPORT,
        payload: downloadUrl
    });
}

export const initializeAwsIoT = async dispatch => {
    const [initErr, initRes] = await subscribeToAwsIot();

    if(initErr) {
        return null;

    } else {
        awsIoTConnect.connect(
            `exports-${userSession.getUserId()}-${userSession.getBrandId()}`, 
            initRes.iotEndpoint,
            initRes.region,
            initRes.accessKey,
            initRes.secretKey,
            initRes.sessionToken
        );

        awsIoTConnect.onMessageCallback = ({ success, timeout }) => {
            dispatch({
                type: EXPORT_CAMPAIGN_LOADING,
                payload: false
            });
            
            if(success) {
                dispatch(notifyExportCampaignSuccess());
                dispatch(getActivePlanCampaignTemplates());
            } else if(timeout) {
                dispatch(notifyExportCampaignError({
                    title: 'Export timed out',
                    message: 'This might be due to the size of the export. Please try again or contact IT.',
                    type: 'danger'
                }));
            } else {
                dispatch(notifyExportCampaignSuccess({
                    title: 'Export Empty',
                    message: 'There is nothing to export for this campaign',
                    type: 'warning'
                }));
            }

        };

        awsIoTConnect.onCloseCallback = () => {
            dispatch({
                type: EXPORT_CAMPAIGN_LOADING,
                payload: false
            });

            dispatch(notifyExportCampaignError());
        }

        return initRes.iotEndpoint;
    }
}
import brochureWrapper from 'api/direct-mail/utils/backend-wrappers/brochureWrapper';

import { 
    getBrochure
} from 'api/direct-mail/brochures';

import {
    notifyGetBrochuresError
} from './notificationActions';

import { 
    GET_BROCHURES, 
    GET_BROCHURES_LOADING
} from './types';

export const getBrochures = ({
    brandId = null, 
    templateId = null,
    images = false,
    brochures = [],
    templateYear = null
}) => async dispatch => {
    dispatch(notifyGetBrochuresError({ display: false }));

    dispatch({
        type: GET_BROCHURES_LOADING,
        payload: true
    });

    const [brochuresErr, brochuresRes] = await getBrochure({
        brandId, 
        templateId, 
        images, 
        brochures, 
        templateYear
    });

    if(brochuresErr) {
        dispatch(notifyGetBrochuresError({ serverError: brochuresErr }));
    }

    dispatch({
        type: GET_BROCHURES_LOADING,
        payload: false
    });

    return dispatch({
        type: GET_BROCHURES,
        payload: brochureWrapper.sortByName(brochuresRes)
    });
}
import planSubmissionWrapper from 'api/direct-mail/utils/backend-wrappers/planSubmissionWrapper';

import {
    GET_MAILING_PLAN_SUBMISSIONS_LOADING,
    GET_MAILING_PLAN_SUBMISSIONS
} from './types';

import {
    notifyGetMailingPlanSubmissionsError
} from './notificationActions';

import { getSubmissions } from 'api/direct-mail/mailingPlan';

export const getMailingPlanSubmissions = (
    brandId, 
    franchiseId
) => async dispatch => {
    dispatch(notifyGetMailingPlanSubmissionsError({ display: false }));

    dispatch({
        type: GET_MAILING_PLAN_SUBMISSIONS_LOADING,
        payload: true
    });

    let [submissionsErr, submissionsRes] = await getSubmissions({
        brandId,
        franchiseId
    });

    if(submissionsErr) {
        dispatch(notifyGetMailingPlanSubmissionsError({ serverError: submissionsErr }));
    }

    dispatch({
        type: GET_MAILING_PLAN_SUBMISSIONS_LOADING,
        payload: false
    });

    return submissionsErr ? 
        dispatch({ type: GET_MAILING_PLAN_SUBMISSIONS, payload: [] })
    : 
        dispatch({
            type: GET_MAILING_PLAN_SUBMISSIONS,
            payload: planSubmissionWrapper.sortByDate(submissionsRes).filter(sub => {
                return sub.isTemplate ? false : true;
            })
        });
}
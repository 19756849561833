import React from 'react';
import { Link } from 'react-router-dom';

function GoBackButton() {
  return (
    <div className='d-flex'>
      <Link
        to='/lmpbp/dashboard/'
        className='review-budget-plan-label brand-primary-color p-2 mr-2' >
        <i className='fas fa-chevron-left' />
      </Link>
    </div>
  );
}

export default GoBackButton;

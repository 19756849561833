import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Button from 'components/common/button/Button';

const CommentsModal = ({
    show,
    onConfirm,
    onCancel,
    title,
    confirmBtnLabel,
    cancelBtnLabel = 'Cancel',
    textAreaLabel = '',
    isRequired = false,
    isLoading = false,
}) => {
    const [comments, setComments] = useState(null);

    const validComments = () => {
        if (!isRequired) return true;
        return comments?.trim().toLowerCase();
    };

    return (
        <Modal
            id="comments-modal"
            show={show}
            onHide={() => {
                if (!isLoading) onCancel();
            }}
            className='lmpbp-comments-modal'
        >
            <Modal.Body className='d-flex flex-column'>
                {title ? <div className='title my-2'>{title}</div> : null}
                <div>{textAreaLabel}</div>
                <textarea
                    className='comments'
                    value={comments}
                    onChange={({ target: { value } }) => setComments(value)}
                ></textarea>
                <div className='d-flex justify-content-center column-gap-2 mt-3'>
                    <Button
                        label={cancelBtnLabel}
                        btnType='outline-secondary'
                        action={onCancel}
                        disabled={isLoading}
                    />
                    <Button
                        label={confirmBtnLabel}
                        btnType='primary'
                        action={() => onConfirm(comments)}
                        disabled={!validComments() || isLoading}
                        loading={isLoading}
                        loadingMessage='Loading...'
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

CommentsModal.propTypes = {
    isRequired: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    confirmBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string,
    textAreaLabel: PropTypes.string,
    isRequired: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default CommentsModal;

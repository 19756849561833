import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectReact from 'react-select';
import Select from 'components/common/select/Select';
import Table from 'components/common/table/Table';
import Panel from 'components/common/panel/Panel';

import { setCurrentRoute as setCurrentRouteAction } from 'actions/utilityActions';
import {
    getFranchises as getFranchisesAction,
    getFranchisesDropdown as getFranchisesDropdownAction,
    changeFranchiseDmExemptStatus as changeFranchiseDmExemptStatusAction,
    changeFranchiseQrCode as changeFranchiseQrCodeAction,
    saveFranchiseLicense as saveFranchiseLicenseAction
} from 'actions/dashboardActions';
import importsColumns from './config/importsColumns';
import userSession from 'utils/userSession';

export class FranchiseList extends Component {    
    state = {
        selectedIDOption: null,
        selectedNameOption: null,
        selectedDmExpemtOption: null,
        selectedQrCodeOption: null,
        selectedLicense: []
    }
 
    onChangeDmExemptStatus = (franchiseId,isDmExempt) => {
        const { 
            selectedIDOption,
            selectedNameOption,
            selectedDmExpemtOption
        } = this.state;
        let params ={};
        isDmExempt = isDmExempt?0:1;
        if(selectedNameOption!=null || selectedNameOption!=undefined){
            params ={ franchiseName: selectedNameOption.value}
        }
        if(selectedDmExpemtOption!=null || selectedDmExpemtOption!=undefined){
            params ={ isDmExempt:  selectedDmExpemtOption=='all'?'':selectedDmExpemtOption};
        }
        if(selectedIDOption!=null || selectedIDOption!=undefined){
            params ={ franchiseId: selectedIDOption.value};
        }
        this.props.changeFranchiseDmExemptStatus(franchiseId,isDmExempt,params);
    }

    onChangeQrCode = (franchiseId,isQrCode) => {
        const { 
            selectedIDOption,
            selectedNameOption,
            selectedQrCodeOption
        } = this.state;
        let params ={};
        isQrCode = isQrCode?0:1;
        if(selectedNameOption!=null || selectedNameOption!=undefined){
            params ={ franchiseName: selectedNameOption.value}
        }
        if(selectedIDOption!=null || selectedIDOption!=undefined){
            params ={ franchiseId: selectedIDOption.value};
        }
        if(selectedQrCodeOption!=null || selectedQrCodeOption!=undefined){
           params ={ isQrCode: selectedQrCodeOption=='all'?'':selectedQrCodeOption};
        }
        this.props.changeFranchiseQrCode(franchiseId,isQrCode,params);
    }
    
    onChangeLicense = (licenseParams) => {
        const { 
            selectedIDOption,
            selectedNameOption,
            selectedQrCodeOption
        } = this.state;
        let params ={};
     
        if( selectedNameOption != null || selectedNameOption != undefined ){
            Object.assign( params, { franchiseName: selectedNameOption.value })
        }
        if( selectedIDOption!=null || selectedIDOption != undefined ){
            Object.assign(params, { franchiseId: selectedIDOption.value});
        }
        if( selectedQrCodeOption !=null || selectedQrCodeOption != undefined ){
            Object.assign( params, { isQrCode: selectedQrCodeOption =='all' || 'Qr Code' ? '' : selectedQrCodeOption });
        }
        this.props.saveFranchiseLicense(licenseParams,params);
    }
    wrapActions = franchisesData => {
        const { 
            savingFranchise,
        } = this.props;
        if(!franchisesData || !franchisesData.length) return [];
      
        return franchisesData.map(franhcise => {
            return {
                ...franhcise,
                onChangeDmExemptStatus: this.onChangeDmExemptStatus,
                onChangeQrCode: this.onChangeQrCode,
                onChangeLicense: this.onChangeLicense,
                updateLicense: this.updateLicense,
                savingFranchise: savingFranchise
            }
        }) || [];
    }
    generateFranchiseOptions = (searchType) => {
        const { franchisesDropdown } = this.props;
        if ( !franchisesDropdown || !franchisesDropdown.length ) return [];

        let mappedFranchises = [];
        franchisesDropdown.forEach(
            ({ franchiseId, franchiseName, franchiseExternalId }) => {
                mappedFranchises.push({
                    id: franchiseId,
                    label:  searchType=="id" ? franchiseExternalId:franchiseName,
                    value: searchType=="id" ?franchiseExternalId :franchiseName,
                    type :searchType=="id" ?"id" :"name",
                });
            }
        );

        mappedFranchises.unshift({
            id: 'all',
            label: 'All franchises',
            value: '',
        });

        return mappedFranchises;
    };
    handleChangeID = (selectedIDOption) => {
        const { 
            getFranchises,
        } = this.props;
        this.setState({ selectedIDOption }, () =>
           this.state.selectedIDOption
        );
        this.state.selectedNameOption=null;
        this.state.selectedDmExpemtOption='DM Exempt Status';
        this.state.selectedQrCodeOption='Qr Code';
        if(selectedIDOption!=null || selectedIDOption!=undefined){
            getFranchises({ franchiseId: selectedIDOption.value});
        }
    };
    handleChangeName = (selectedNameOption) => {
        const { 
            getFranchises,
        } = this.props;
        this.setState({ selectedNameOption }, () =>
             this.state.selectedNameOption
        );
        this.state.selectedIDOption=null;
        this.state.selectedDmExpemtOption='DM Exempt Status';
        this.state.selectedQrCodeOption='Qr Code';
        if(selectedNameOption!=null || selectedNameOption!=undefined){
            getFranchises({ franchiseName: selectedNameOption.value});
        }
    };
    handleSelectDmExempt = (selectedDmExpemtOption) => {
        const { 
            getFranchises,
        } = this.props;
        this.setState({ selectedDmExpemtOption }, () =>
             this.state.selectedDmExpemtOption
        );
        this.state.selectedNameOption=null;
        this.state.selectedIDOption=null;
        if(selectedDmExpemtOption!=null || selectedDmExpemtOption!=undefined){
            getFranchises({ isDmExempt: selectedDmExpemtOption=='all'?'':selectedDmExpemtOption});
        }
    };
    generateDmExemptStatusOptions = () => {
        const dmStatuses =  [{"id": 1,"name": "DM Exempt",},{"id": 0, "name": "Not Exempt",},];
        let mappedStatuses = dmStatuses.map(({ id, name }) => ({
            id: id,
            label: name,
            value: id,
        }));
        mappedStatuses.unshift({
            id: 'all',
            label: 'All request statuses',
            value: 'all',
        });
        return mappedStatuses;
    };
    generateQrCodeOptions = () => {
        const qrCodes =  [{"id": 1,"name": "Yes",},{"id": 0, "name": "No",},];
        let mappedStatuses = qrCodes.map(({ id, name }) => ({
            id: id,
            label: name,
            value: id,
        }));
        mappedStatuses.unshift({
            id: 'all',
            label: 'All',
            value: 'all',
        });
        return mappedStatuses;
    }
    handleSelectQrCode = (selectedQrCodeOption) => {
        const { 
            getFranchises,
        } = this.props;
        this.setState({ selectedQrCodeOption }, () =>
             this.state.selectedQrCodeOption
        );
        this.state.selectedNameOption=null;
        this.state.selectedIDOption=null;
        if(selectedQrCodeOption!=null || selectedQrCodeOption!=undefined){
            getFranchises({ isQrCode: selectedQrCodeOption=='all'?'':selectedQrCodeOption});
        }
    }
    componentDidMount = async () => {
        const { 
            setCurrentRoute,
            getFranchises,
            getFranchisesDropdown,
            match,
        } = this.props;
        setCurrentRoute(match);
        getFranchises();
        getFranchisesDropdown();
    }

    componentDidUpdate() {
        this.props.setCurrentRoute(this.props.match);
    }

   
    render() {
        const { 
            loadingFranchises,
            franchises
        } = this.props;
        const { 
            selectedIDOption,
            selectedNameOption,
            selectedDmExpemtOption,
            selectedQrCodeOption
        } = this.state;

         //Code to show column dmExempt if brand is asp 
         let newCoulmns = importsColumns;
         if(! userSession.isASPUser()){
             newCoulmns =   importsColumns.filter(object => {
                 return object.dataField != "isDmExempt";
             });
         }
         //Code to show column isQrCode and license if brand is msq 
         if(! userSession.isMSQUser()){
            newCoulmns =   newCoulmns.filter(object => {
                return object.dataField != "isQrCode" && object.dataField != "license";
            });
        }
        let hideDmStatusDropdown = userSession.isASPUser() ?'':'hidden';
        let hideQrCodeDropdown = userSession.isMSQUser() ?'':'hidden';
        return (
            <Panel
                header='Franchise List'
                headerStyling={{fontSize: '20px'}}
                refreshing={loadingFranchises && franchises?.length}
            >
                <div className='d-flex mb-3 pb-3'>
                    <div className="d-flex flex-column">
                        <label className="font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1">
                        Franchise ID
                        </label>
                        <div className='d-flex align-items-center'>
                            <i className="mr-2 brand-primary-color font-size-20 fas fa-globe"></i>
                            <SelectReact
                            id='dashboard-franchise-id-filter'
                            label='Franchise ID'
                            value={
                                selectedIDOption || this.generateFranchiseOptions()[0]?.value
                            }
                            placeholder='Select Franchise ID'
                            options={this.generateFranchiseOptions("id")}
                            onChange={this.handleChangeID}
                            />     
                        </div>
                    </div>
                    <div className="d-flex flex-column" style={{marginLeft:'10px'}}>
                        <label className="font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1">
                        Franchise Name
                        </label>
                        <div className='d-flex align-items-center'>
                            <i className="mr-2 brand-primary-color font-size-20 fas fa-globe"></i>
                            <SelectReact
                            id='dashboard-franchise-filter'
                            label='Franchise Name'
                            value={
                                selectedNameOption || this.generateFranchiseOptions()[0]?.value
                            }
                            placeholder='Select Franchise Name'
                            options={this.generateFranchiseOptions("name")}
                           
                            onChange={this.handleChangeName}
                            />     
                        </div>
                    </div>
                    <div className={`d-flex mb-3 pb-3 ${hideDmStatusDropdown}`}>
                        <div className="d-flex flex-column" style={{marginLeft:'10px'}}>
                            <Select
                                id='dashboard-dm-exempt-status-filter'
                                label='DM Exempt Status'
                                value={ selectedDmExpemtOption || this.generateDmExemptStatusOptions()[0]?.value}
                                selectStyling={'filter'}
                                labelStyling='font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1 dm-filter-label'
                                placeholder='DM Exempt Status'
                                options={this.generateDmExemptStatusOptions()}
                                onChange={(e) => this.handleSelectDmExempt(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={`d-flex mb-3 pb-3 ${hideQrCodeDropdown}`}>
                        <div className="d-flex flex-column" style={{marginLeft:'10px'}}>
                            <Select
                                id='dashboard-qr-code-filter'
                                label='QR Code'
                                value={ selectedQrCodeOption || this.generateQrCodeOptions()[0]?.value}
                                selectStyling={'filter'}
                                labelStyling='font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1 dm-filter-label'
                                placeholder='Qr Code'
                                options={this.generateQrCodeOptions()}
                                onChange={(e) => this.handleSelectQrCode(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <div className='mt-3'>
                        <Table 
                            keyField='franchiseId'
                            data={this.wrapActions(franchises)}
                            columns={newCoulmns}
                            enablePagination={true}
                            loading={loadingFranchises && !franchises?.length}
                            loadingMessage={'Loading Franchises...'}
                        />
                    </div>
                </div>
                
            </Panel>
        );
    }
}

const mapStateToProps = state => ({
    loadingFranchises: state.dashboard?.loadingFranchises,
    savingFranchise: state.dashboard?.savingFranchise,
    franchises: state.dashboard?.franchises,
    franchisesDropdown: state.dashboard?.franchisesDropdown,
});

export default connect(mapStateToProps, {
    setCurrentRoute: setCurrentRouteAction,
    changeFranchiseDmExemptStatus :changeFranchiseDmExemptStatusAction,
    getFranchises: getFranchisesAction,
    getFranchisesDropdown:getFranchisesDropdownAction,
    changeFranchiseQrCode: changeFranchiseQrCodeAction,
    saveFranchiseLicense: saveFranchiseLicenseAction
})(FranchiseList);

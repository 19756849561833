const createPlanWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    return {
        id: data.budget_plan_id
    }
}

export default createPlanWrapper;
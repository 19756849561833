import React, { Component } from 'react';
import { connect } from 'react-redux';

import Notifications from './Notifications';
import HeaderTitle from './HeaderTitle';
import ImageView from '../common/image-view/ImageView';
import PlanSubmissions from './plan-submissions/PlanSubmissions';
import Select from 'components/common/select/Select';
import userSession from 'utils/userSession';
import ConfirmationModal from 'components/common/confirmation-modal/ConfirmationModal';

import { 
    getFranchiseInfo as getFranchiseInfoAction, 
    resetFranchiseInfo as resetFranchiseInfoAction
} from 'actions/franchiseActions';

import { setMoveToAction } from 'actions/statusAccions';

import { 
    getBrandInfo as getBrandInfoAction ,
    updateBrandInAuthorize as updateBrandInAuthorizeAction,
    updateDmBrandInAuthorize as updateDmBrandInAuthorizeAction
} from 'actions/brandActions';

import { 
    resetMailingPlan as resetMailingPlanAction 
} from 'actions/mailingPlanActions';
import BudgetGuideFactory from './lmpbp-business-rules/BudgetGuideFactory';

export class Header extends Component {
    state = {
        selectedBrandId : null,
        isBrandChanged :false,
        franchiseList:[],
        selectedFranchise:null,
        brandSymbol:null,
        isFranchiseChanged:false
    };
    componentDidMount() {
        const { getBrandInfo } = this.props;
        getBrandInfo(userSession.getBrandId());
        this.setState({ 
            selectedBrandId: userSession.getBrandId(),
            franchiseList: userSession.getFranchiseList().filter(({ BrandID }) => BrandID === Number(userSession.getBrandId())),
            selectedFranchise : userSession.getFranchiseId()
        });

    }

    componentDidUpdate() {
        const { brandConfig } = this.props;
        let root = document.documentElement;

        root.style.setProperty('--brandPrimaryColor', brandConfig?.primaryColor || '#1e9b8c');
        root.style.setProperty('--brandSecondaryColor', brandConfig?.secondaryColor || '#1e9b8c');
        root.style.setProperty('--brandDetailColor', brandConfig?.tertiaryColor || '#1e9b8c');
        root.style.setProperty('--brandPrimaryColor03', `${brandConfig?.primaryColor || '#1e9b8c'}30`);
        root.style.setProperty('--brandSecondaryColor03', `${brandConfig?.secondaryColor || '#1e9b8c'}30`);
        root.style.setProperty('--brandDetailColor03', `${brandConfig?.tertiaryColor || '#1e9b8c'}30`);
    }

    renderFranchiseName = () => {
        const { franchise } = this.props;

        if(userSession.isFranchiseOwer() && franchise?.name) {
            return <div className='user-info-franchise'>{franchise?.name}</div>;
        }

        return null;
    }

    renderSubmissionSelector = () => {
        const {
            currentRoute
        } = this.props;

        const viewingMailingPlan = currentRoute?.path.includes('/mailing-plan');

        if(userSession.isFranchiseOwer() && viewingMailingPlan) {
            return <PlanSubmissions />;
        }
    }

    renderLmpbpBudgetGuide = () => {
        const {
            currentRoute,
            brandConfig
        } = this.props;

        const viewingLmpbp = currentRoute?.path.includes('/lmpbp/territories');

        if(userSession.isFranchiseOwer() && viewingLmpbp) {
            return BudgetGuideFactory(brandConfig);
        }
    }

    onLogout = () => {
        if(this.props.haveUnsavedChanges)
        {
           this.props.setMoveTo('Logout'); 
        }
        else
        {
            userSession.logout();
            window.open(process.env.REACT_APP_LOGOUT_URL, "_self");
        }
    }
    generateBrandsOptions = () => {
        if (userSession.getBrandList()?.length) {
           
            return userSession.getBrandList().map(({ brand_id, symbol }) => ({
                brand_id,
                label: symbol,
                value: brand_id,
            }));
        }
    }
    generateFranchisesOptions = () => {
        const {
            franchiseList,
        } = this.state;
        let mappedOption = [];
        if (franchiseList?.length) {

            mappedOption =   franchiseList.map(({ ID, ExternalID }) => ({
                label: ExternalID,
                value: ID,
            }));
        }
        return mappedOption;
    }
    onChangeBrand = (event) => {
        const value = event.target.value;
        const brandValue = Number(value) ;
        this.setState({ selectedBrandId: Number(brandValue) });
        if(userSession.arrivedAtLmpbp()){
            this.setState({ isBrandChanged:true });
        }else{
            let franchises =  userSession.getFranchiseList().filter(({ BrandID }) => BrandID === brandValue);
            let symbol = userSession.getBrandList().find(({ brand_id }) => brand_id === brandValue).symbol
            this.setState({ franchiseList: franchises,brandSymbol: symbol });
            this.setState({ isBrandChanged:true,selectedFranchise: franchises[0]?.ID });
        }
    }
    onChangeFranchise = (event) => {
        const value = event.target.value;
        const franchiseValue = Number(value);
        this.setState({ selectedFranchise: franchiseValue, isBrandChanged: true, isFranchiseChanged: true });
    }
    onConfirmAction = () => {
        const {
            updateBrandInAuthorize,
            updateDmBrandInAuthorize
        } = this.props;
        const {
            selectedBrandId,
            selectedFranchise
        } = this.state;
        this.setState({isBrandChanged:false});
        if(userSession.arrivedAtLmpbp()){
            updateBrandInAuthorize({BrandID:selectedBrandId});
        }else{
            updateDmBrandInAuthorize({ BrandID : selectedBrandId, FranchiseID : selectedFranchise});
        }
    }
    onCancel = () => {
        this.setState({isBrandChanged:false,selectedBrandId: userSession.getBrandId()});
        if(!userSession.arrivedAtLmpbp()){
            let franchises =  userSession.getFranchiseList().filter(({ BrandID }) => BrandID === Number(userSession.getBrandId()));
            this.setState({Symbol: userSession.getBrandSymbol(), selectedFranchise: userSession.getFranchiseId(), franchiseList : franchises});
        }
    }
    render() {
        const { brandIcon } = this.props;
        let { selectedBrandId, isBrandChanged, franchiseList, selectedFranchise, isFranchiseChanged} =this.state;
        return (
            <div className='header-container '>
                <Notifications />
                <div className='d-flex align-items-center'>
                    <div className='border-right ml-4 pr-3'>
                        {
                            brandIcon &&
                                <ImageView 
                                    src={brandIcon}
                                    height='30px'
                                    width='62px'
                                    noPlaceholder={true}
                                    fit='contain'
                                />
                        }
                    </div>
                    <HeaderTitle />
                    { userSession.isFranchiseOwer() && userSession.getBrandList().length > 1 &&
                    <><span className='font-family-bold c-neutral font-size-14 mb-1 toggle-reminder multi-brand-control-padding'>Select brand </span>
                        <Select
                                id='multi-brands'
                                value={selectedBrandId}
                                options={this.generateBrandsOptions()}
                                onChange={this.onChangeBrand}
                        />
                    </>
                    }
                    { userSession.isFranchiseOwer() && userSession.getBrandList().length > 1 && franchiseList.length > 1  &&
                    <><span className='font-family-bold c-neutral font-size-14 mb-1 toggle-reminder multi-brand-control-margin multi-brand-control-padding'>Select Franchise </span>
                        <Select
                                id='multi-brands-franchises'
                                value={selectedFranchise}
                                options={this.generateFranchisesOptions()}
                               onChange={this.onChangeFranchise}
                        />
                    </>
    }
                </div>
                <div className='d-flex align-items-baseline justify-content-center flex-wrap row-gap-1 column-gap-1'>
                    <div className='ml-3 mr-3'>
                        {
                            this.renderSubmissionSelector()
                        }
                        {
                            this.renderLmpbpBudgetGuide()
                        }
                    </div>
                    <div className='user-info'>
                        <i className='far fa-user mr-2'></i>
                        <div className='d-flex flex-column'>
                            <div className='user-info-name'>{userSession.getUserFullName()}</div>
                            {this.renderFranchiseName()}
                        </div>
                    </div>
                    <button 
                        id='header-logout-btn'
                        className='btn btn-link logout' 
                        onClick={() => this.onLogout()}
                    >
                        <i className='fas fa-sign-out-alt mr-2'></i>
                        <div>Logoff</div>
                    </button>
                </div>
                <ConfirmationModal
                show={isBrandChanged}
                centered
                type='warning'
                headerType='warning'
                headerIconLeft='fas fa-exclamation-triangle'
                headerIconRight='fas fa-exclamation-triangle'
                header = {!isFranchiseChanged ?'Swtich Brand' :'Switch Franchise'}
                confirmBtnLabel='Ok'
                showConfirmBtn={true}
                showCancelBtn={true}
                onClose={this.onCancel}
                onConfirm={this.onConfirmAction}
                isCustomTabFlag={true}
                message={!isFranchiseChanged ?'Are you sure you want to switch brand?':'Are you sure you want to switch franchise?'} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    brandConfig: state.brand,
    mailingPlan: state.mailingPlan,
    brandIcon: state.brand?.icon,
    franchise: state.franchise,
    currentRoute: state.utility?.currentRoute,
    haveUnsavedChanges: state.status.unsavedBudgetPlanChanges,
    moveTo: state.status.moveTo, 
});

export default connect(mapStateToProps, {
    getBrandInfo: getBrandInfoAction,
    getFranchiseInfo: getFranchiseInfoAction,
    resetMailingPlan: resetMailingPlanAction,
    resetFranchiseInfo: resetFranchiseInfoAction,
    updateBrandInAuthorize: updateBrandInAuthorizeAction,
    setMoveTo: setMoveToAction,
    updateDmBrandInAuthorize: updateDmBrandInAuthorizeAction
})(Header);

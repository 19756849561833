import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from 'components/common/loader/Loader';
import Tooltip from 'components/common/tooltip/Tooltip';
import RulesConfigItem from './RulesConfigItem';
import userSession from 'utils/userSession';

import { getBusinessRules } from 'actions/planConfigActions';

export class RulesConfigList extends Component {
    state = { 
        allBusinessRules: [] 
    }

    static propTypes = {
        planBusinessRules: PropTypes.array,
        onUpdateBusinessRules: PropTypes.func
    }

    mergeConfiguredRules = () => {
        const {
            planBusinessRules,
            genericBusinessRules
        } = this.props;
        if(!planBusinessRules?.length) return genericBusinessRules || [];
        let mergedConfiguredRules = [...genericBusinessRules];

        // Exclude minimumAddressesPerCampaign and minimumAddressesPerCampaign Rule if user is MSQ
        let  filteredMSQArray = mergedConfiguredRules.filter(item => item.key.indexOf("minimumAddressesPerCampaign") && item.key.indexOf("minimumAmountPerYear") == -1);
        mergedConfiguredRules = userSession.isMSQUser() ? filteredMSQArray : mergedConfiguredRules
        
        for(let i = 0; i < mergedConfiguredRules.length; i++) {
            for(let rule of planBusinessRules)  {
                if(mergedConfiguredRules[i]?.key === rule?.key) {
                    mergedConfiguredRules[i] = { ...rule };
                }
            }
        }

        return mergedConfiguredRules;
    }

    onChangeBusinessRule = (ruleKey = null, updatedRule = {}) => {
        const {
            onUpdateBusinessRules
        } = this.props;
        
        let mergedRules = this.mergeConfiguredRules();
        let updatedRules = mergedRules.map(rule => {
            if(rule.key === ruleKey) {
                return { ...updatedRule };
            }

            return { ...rule };
        });

        onUpdateBusinessRules(updatedRules);
    }

    renderBusinessRules = () => {
        const { loading } = this.props;

        if(loading) {
            return (
                <div>
                    <Loader loadingMessage='Loading business rules...'/>
                </div>
            );
        }

        let mergedRules = this.mergeConfiguredRules();
        if(!mergedRules || !mergedRules.length) return (
            <div>
                No available rules to configure
            </div>
        );

        return mergedRules.map(rule => {
            return <RulesConfigItem 
                key={rule.key}
                ruleKey={rule.key}
                rule={rule}
                onChangeBusinessRule={this.onChangeBusinessRule}
            />;
        });
    }

    componentDidMount() {
        this.props.getBusinessRules();
    }

    render() {
        return (
            <div className='d-flex flex-column mt-4'>
                <span className='font-size-16 font-family-bold mb-2'>Plan Rules</span>
                <div className='d-flex justify-content-end column-gap-1 mt-0 mr-3 mb-3 ml-3'>
                    <div className='d-flex align-items-center font-size-12'>
                        <span className='mr-2'>Strict</span>
                        <Tooltip 
                            message='Rules marked as Strict will have to be met in order to allow for a mailing plan to be submitted, otherwise the rule can be igonred'
                        />
                    </div>
                    <div className='d-flex align-items-center font-size-12'>
                        <span className='mr-2'>Active</span>
                        <Tooltip 
                            message="Rules marked as Active will be displayed on all mailing plans, otherwise they won't be taken into account"
                        />
                    </div>
                </div>
                <div className='d-flex flex-column'>
                    {this.renderBusinessRules()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    genericBusinessRules: state.planConfig?.templateBusinessRules || [],
    loading: state.planConfig?.loadingTemplateBusinessRules
});

export default connect(mapStateToProps, {
    getBusinessRules
})(RulesConfigList);

import { useMutation, useQueryClient } from 'react-query';
import api from "api/lmpbp";
import useAlert from 'hooks/useAlert';

function useDeleteFranchiseWebsite(queryKeyToInvalidate) {
  const queryClient = useQueryClient();

  const successAlert = useAlert({
    title: 'Success',
    id: 'delete-franchise-website-success',
    message: 'Website removed from franchise successfully',
    type: 'success',
  });

  const errorAlert = useAlert({
    id: 'delete-franchise-website-error',
    message: 'Error removing website from franchise',
    type: 'danger',
    error: null
  });

  const deleteFranchiseWebsiteMutation = useMutation(
    ({franchiseId, tacticId}) => api().delete(`franchise/${franchiseId}/tactic/${tacticId}/website`),
    {
      onSuccess: () => {
        successAlert();
        queryClient.invalidateQueries(queryKeyToInvalidate);
      },
      onError: (error) => {
        const serverError = {
          message: error.response.data.error,
          code: error.response.status
        }
        errorAlert(serverError);
      }
    }
  );

  return deleteFranchiseWebsiteMutation;
}

export default useDeleteFranchiseWebsite;

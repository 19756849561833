import api from 'api/lmpbp';
import handleResponse from "./utils/handleResponse";
import exportFilesWrapper from './utils/backend-wrappers/exportFilesWrapper';

export const getExportedFiles = (tactic, year) => {
    return handleResponse({
        promise: api().get('/budgets/get/exportfile', {
            params: {
                tactic,
                year
            }
        }),
        wrapper: exportFilesWrapper,
    }
    );
}

export const exportPlan = ({
    tactic,
    year,
    month,
    iotEndpoint
}) => {
    return handleResponse({
        promise: api().post(`/budget-reports/${year}/csv`, null, { params:{
            tactic,
            month,
            iotEndpoint
        }}),
        wrapper: data => data,
    });
}

export const downloadExportedFile = ({
    filename,
    tactic,
    year
}) => {
    return handleResponse({
        promise: api().get('/budgets/download/tacticfile', {
            params: {
                filename,
                tactic,
                year
            }
        }),
        wrapper: data => data,
    });
}
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InlineNotification from 'components/common/inline-notification/InlineNotification';
import Panel from 'components/common/panel/Panel';
import FeedbackModal from './FeedbackModal';
import HeaderCU from './HeaderCU';
import HeaderFO from './HeaderFO';
import CommonHeader from './CommonHeader';

import validateStatus from 'utils/validateStatus';
import compareBusinessRules from 'utils/compareBusinessRules';
import userSession from 'utils/userSession';

export class MailingPlanHeader extends Component {
    state = {
        showFeedbackModal: true
    }

    static propTypes = {
        coporateUserSubmitOverride: PropTypes.bool
    }

    toggleFeedbackModal = (value = false) => {
        this.setState({ showFeedbackModal: value });
    }

    calculateTotalCost = () => {
        const { campaigns, costPerPiece } = this.props.mailingPlan;
        let totalCost = 0;

        campaigns?.forEach(campaign => {
            if(campaign.selected) {
                if(userSession.isMSQUser()){
                    totalCost += Number(campaign.budget_intention);
                }else{
                    totalCost += campaign.addresses * costPerPiece;
                }
               
            }
        });

        return totalCost;
    }

    countTotalAddresses = () => {
        const { campaigns } = this.props.mailingPlan;
        let totalAddresses = 0;

        campaigns?.forEach(campaign => {
            if(campaign.selected) {
                totalAddresses += Number(campaign.addresses);
            }
        });

        return totalAddresses;
    }

    renderMailingPlanStatus = () => {
        const { mailingPlan } = this.props;

        if(validateStatus.isRejected(mailingPlan?.status)) {
            return (
                <InlineNotification 
                    title={
                        <div>
                            <span>Status: </span>
                            <strong className='font-family-bold'>Rejected</strong>
                        </div>
                    }
                    type='danger'
                    icon='fas fa-exclamation-circle'
                    message='Please review your feedback and resubmit your plan.'
                    headerStyle={true}
                    action={() => this.toggleFeedbackModal(true)}
                    actionText='View Feedback'
                    displayAction={mailingPlan?.feedback ? true : false}
                />
            );
        }

        if(validateStatus.isSubmitted(mailingPlan?.status)) {
            return (
                <InlineNotification 
                    title={
                        <div>
                            <span>Status: </span>
                            <strong className='font-family-bold'>Submitted</strong>
                        </div>
                    }
                    type='primary'
                    icon='fas fa-arrow-circle-up'
                    message='Your mailing plan has been submitted.'
                    headerStyle={true}
                />
            );
        }

        if(mailingPlan?.overriden) {
            return (
                <InlineNotification 
                    title={
                        <div>
                            <span>Status: </span>
                            <strong className='font-family-bold'>Approved</strong>
                        </div>
                    }
                    type='success'
                    icon='fas fa-clipboard-check'
                    message='Your mailing plan has been approved.'
                    headerStyle={true}
                    action={() => this.toggleFeedbackModal(true)}
                    actionText='View Feedback'
                    displayAction={mailingPlan?.feedback ? true : false}
                />
            );
        }

        if(validateStatus.isApproved(mailingPlan?.status)) {
            return (
                <InlineNotification 
                    title={
                        <div>
                            <span>Status: </span>
                            <strong className='font-family-bold'>Approved</strong>
                        </div>
                    }
                    type='success'
                    icon='fas fa-clipboard-check'
                    message='Your mailing plan has been approved.'
                    headerStyle={true}
                />
            );
        }
        if(validateStatus.isDraft(mailingPlan?.status)) {
            return (
                <InlineNotification 
                    title={
                        <div>
                            <span>Status: </span>
                            <strong className='font-family-bold'>In Progress</strong>
                        </div>
                    }
                    type='primary'
                    icon='fas fa-arrow-circle-up'
                    message='Your mailing plan has been saved and remember to submit once you are done entering your plan.'
                    headerStyle={true}
                />
            );
        }
    }

    renderBusinessRulesStatus = () => {
        const { mailingPlan } = this.props;

        const { 
            status, 
            savedBusinessRules, 
            templateBusinessRules 
        } = mailingPlan || {};

        if(
            !validateStatus.isUnsubmitted(status) &&
            !compareBusinessRules(savedBusinessRules, templateBusinessRules)
        ) {
            return (
                <InlineNotification 
                    title={
                        <div className='font-family-bold'>Mailing plan requirements have changed</div>
                    }
                    type='warning'
                    icon='fas fa-exclamation-circle'
                    message={
                        mailingPlan?.status ? 
                            <span>
                                Your Mailing Plan has been previously <strong className='font-family-bold'>{mailingPlan?.status}</strong>. 
                                However, there has been some changes in the acceptance criteria for all mailing plans, 
                                please make sure to review your Mailing Plan and <u>resubmit</u> any necessary updates.
                                Thank you!
                            </span>
                        :
                            <span>
                                There has been some changes in the acceptance criteria for all mailing plans, 
                                please make sure to review your Mailing Plan and <u>resubmit</u> any necessary updates.
                                Thank you!
                            </span>
                    }
                    headerStyle={true}
                />
            );
        }
    }

    renderHeader = () => {
        const { 
            mailingPlan,
            franchiseInfoConfirmed,
            correctFranchiseInfo,
            coporateUserSubmitOverride
        } = this.props;
         if(userSession.isFranchiseOwer() || coporateUserSubmitOverride) {
            return (
                <HeaderFO 
                    mailingPlan={mailingPlan}
                    franchiseInfoConfirmed={franchiseInfoConfirmed}
                    correctFranchiseInfo={correctFranchiseInfo}
                    calculateTotalCost={this.calculateTotalCost}
                />
            );
        }
        
        if(userSession.isDivisionalUser()) {
            return (
                <HeaderCU 
                    calculateTotalCost={this.calculateTotalCost}
                    countTotalAddresses={this.countTotalAddresses}
                />
            );
        }
    }
    renderCommonHeader(){
        const { 
            mailingPlan,
            franchiseInfoConfirmed,
            correctFranchiseInfo,
        } = this.props;

            return (
                <CommonHeader 
                mailingPlan={mailingPlan}
                franchiseInfoConfirmed={franchiseInfoConfirmed}
                correctFranchiseInfo={correctFranchiseInfo}
            />
            );
    }
    render() {
        const { 
            mailingPlan,
            loadingMailingPlan
        } = this.props;

        const { showFeedbackModal } = this.state;

        return (
            <Fragment>
                {
                    mailingPlan?.feedback &&
                    userSession.isFranchiseOwer() &&
                        <FeedbackModal 
                            show={showFeedbackModal}
                            onClose={this.toggleFeedbackModal}
                            feedback={mailingPlan?.feedback}
                            year={mailingPlan?.mailingPlanYear}
                            status={mailingPlan?.status}
                            overriden={mailingPlan?.overriden}
                        />
                }
                {
                    (userSession.isFranchiseOwer() && !loadingMailingPlan)  ?
                        this.renderMailingPlanStatus() : null
                }
                {
                    userSession.isFranchiseOwer() && 
                        this.renderBusinessRulesStatus()
                }
                <Panel>
                    {(mailingPlan?.status ==undefined || validateStatus.isDraft(mailingPlan?.status))?'':this.renderCommonHeader()}
                    {this.renderHeader()}
                </Panel>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    mailingPlan: state.mailingPlan,
    loadingMailingPlan: state.mailingPlan?.loading,
    franchiseInfoConfirmed: state.mailingPlan?.isFranchiseInfoConfirmed,
    correctFranchiseInfo: state.mailingPlan?.isCorrectFranchiseInfo
});

export default connect(mapStateToProps, {})(MailingPlanHeader);

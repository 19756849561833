import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const BusinessRule = ({
    text = '',
    fulfilled = false,
    softRule = false
}) => {

    const renderRule = () => {
        if(softRule) {
            return (
                <div className={`mailing-plan-rule ${fulfilled ? 'completed' : 'warning'}`}>
                    {
                        fulfilled ?
                            <i className='fas fa-check'></i>
                        :
                        <i className='fas fa-exclamation-triangle'></i>
                    }
                    <p>{text}</p>
                </div>
            )
        }

        return (
            <div className={`mailing-plan-rule ${fulfilled ? 'completed' : 'error'}`}>
                {
                    fulfilled ?
                        <i className='fas fa-check'></i>
                    :
                    <i className='fas fa-times'></i>
                }
                <p>{text}</p>
            </div>
        );
    }

    return (
        <Fragment>
            {renderRule()}
        </Fragment>    
    );
}

BusinessRule.propTypes = {
    text: PropTypes.string.isRequired,
    fulfilled: PropTypes.bool.isRequired,
    softRule: PropTypes.bool
}

export default BusinessRule;



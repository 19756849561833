import { getCoupons as getAllCoupons } from 'api/direct-mail/coupons';
import couponWrapper from 'api/direct-mail/utils/backend-wrappers/couponWrapper';
import { notifyGetCouponsError } from './notificationActions';

import { 
    GET_COUPONS,
    GET_COUPONS_LOADING 
} from './types';

export const getCoupons = ({
    brandId = null, 
    templateId = null
} = {}) => async dispatch => {
    dispatch(notifyGetCouponsError({ display: false }));

    dispatch({
        type: GET_COUPONS_LOADING,
        payload: true
    });

    const [couponsErr, coupons] = await getAllCoupons({ brandId, templateId });

    if(couponsErr) {
        dispatch(notifyGetCouponsError({ serverError: couponsErr }));
    }

    dispatch({
        type: GET_COUPONS,
        payload: couponWrapper.sortByName(coupons)
    });

    return dispatch({
        type: GET_COUPONS_LOADING,
        payload: false
    });
}
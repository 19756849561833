import React from 'react';
import ReactSelect from 'react-select';

function CustomSelect({
    options,
    defaultValue,
    value,
    onChange,
    id,
    label,
    style
}) {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '0.25rem',
            borderColor: '#ced4da',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#adb5bd'
            },
            '&:focus': {
                borderColor: '#86b7fe',
                boxShadow: '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
            }
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '0.25rem'
        })
    };

    return (
        <div className="mb-3" style={style}>
            <label htmlFor={id}>{label}</label>
            <ReactSelect
                id={id}
                options={options}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
            />
        </div>
    );
};

export default CustomSelect;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/common/button/Button';
import userSession from 'utils/userSession';
import { 
    copyDraftMailingPlan as copyDraftMailingPlanAction,
} from 'actions/mailingPlanActions';
export class CommonHeader extends Component {
    static propTypes = {
        mailingPlan: PropTypes.object,
        correctFranchiseInfo: PropTypes.bool,
        franchiseInfoConfirmed: PropTypes.bool,
    }
   
    onEdit = async () => {
        const {
            mailingPlan,
            franchiseInfo,
            correctFranchiseInfo,
            copyDraftMailingPlan
        } = this.props;
 
        const brandId = userSession.getBrandId();
        const franchiseId = franchiseInfo?.franchiseId;
        const newMailingPlanId = await copyDraftMailingPlan(brandId, franchiseId,mailingPlan,correctFranchiseInfo);
        window.location.href=`/mailing-plan/edit/${franchiseId}/${newMailingPlanId}/${mailingPlan.mailingPlanYear}`;
    }

    render() {
        const {
            mailingPlan,
        } = this.props;
        return (
            <div className='mailing-plan-header-container'>
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex column-gap-1'>
                        <Button 
                        btnType='primary'
                        loading={mailingPlan?.saving}
                        loadingMessage={'Loading...'}
                        action={() => this.onEdit()}
                        >
                        Edit Plan
                        </Button>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    franchiseInfo: state.franchise,
    commentsRequired: state.franchise?.commentsRequired
});

export default connect(mapStateToProps, {
    copyDraftMailingPlan: copyDraftMailingPlanAction,
})(CommonHeader);

import moment from 'moment';

const invalidDate = (date) => {
    return !date 
        || Array.isArray(date)
        || typeof date === 'number'
        || typeof date === 'boolean'
} 

const formatDate = {
    date: date => {
        if(invalidDate(date)) return '-';    
        return new moment(date).format('MM/DD/YYYY');
    },
    dateUTC: date => {
        if(invalidDate(date)) return '-';    
        return new moment(date).utc().format('MM/DD/YYYY');
    },
    dateAndTime: date => {
        if(invalidDate(date)) return '-';    
        return new moment(date).format('MM/DD/YYYY hh:mm:ss A');
    },
    descriptiveDateAndTime: date => {
        if(invalidDate(date)) return '-';    
        return new moment(date).calendar();
    },
    toISOFormat: date => {
        if(invalidDate(date)) return '-';
        return new moment(date).format('YYYY-MM-DD');
    },
    UTCtoISOFormat: date => {
        if(invalidDate(date)) return '-';
        return new moment(date).utc().format('YYYY-MM-DD');
    }
}

export default formatDate;
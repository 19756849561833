import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getMailingPlanTemplates = ({
    templateId: id = null,
    planYear = null, 
    active = null,
    withCampaigns = null,
    withExports = null
} = {}) => {
    return handleResponse(
        api().get('/corporate/mailing-plans/templates/get', {
            params: {
                id,
                planYear,
                active,
                withCampaigns,
                withExports
            }
        })
    );
}

export const deletePlanTemplate = ({
    templateId: id
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/templates/delete', {
            id
        })
    );
}

export const activatePlanTemplate = ({
    templateId: templateID
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/templates/activate', {
            templateID
        })
    );
}

export const updatePlanTemplate = ({
    templateId: id,
    planTemplate: mailingPlanTemplateData
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/templates/edit', {
            id,
            mailingPlanTemplateData
        })
    );
}

export const savePlanTemplate = ({
    planTemplate: mailingPlanTemplate
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/templates/create', {
            mailingPlanTemplate
        })
    );
}
export const copyDataFromPreviousMailingPlan = ({
    newMailingPlanTemplateID,
    oldMailingPlanTemplateID
} = {}) => {
    return handleResponse(
        api().post('/corporate/mailing-plans/templates/copy-data-from-previous-mailing-plan', {
            newMailingPlanTemplateID,
            oldMailingPlanTemplateID
        })
    );
}
import { 
    DELETE_BADGE_LOADING,
    GET_BADGES_FOR_CONFIG_LIST,
    GET_BADGES_FOR_CONFIG_LIST_LOADING,
    SAVE_BADGE_LOADING,
    UPDATE_BADGE_LOADING
} from 'actions/types';

const badgesConfigReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_BADGES_FOR_CONFIG_LIST:
            return { ...state, badges: [...action.payload] };

        case GET_BADGES_FOR_CONFIG_LIST_LOADING:
            return { ...state, loading: action.payload };

        case SAVE_BADGE_LOADING:
            return { ...state, saving: action.payload };

        case UPDATE_BADGE_LOADING:
            return { ...state, updating: action.payload };

        case DELETE_BADGE_LOADING:
            return { ...state, deleting: action.payload };

        default:
            return state;
    }
}

export default badgesConfigReducer;
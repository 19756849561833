import {
	LMPBP_SET_HAVE_UNSAVED_CHANGES,
	LMPBP_CONFIRM_MODAL_ACCEPTED,
	LMPBP_SET_IS_LEAVING_PAGE,
	LMPBP_MOVE_TO
} from 'actions/types';

const statusReducer = (oldStore = {}, action = {}) =>
{
	const store = { ...oldStore };

	switch(action.type)
	{
		case LMPBP_SET_HAVE_UNSAVED_CHANGES:
			store.unsavedBudgetPlanChanges = action.payload;
		break;
		case LMPBP_SET_IS_LEAVING_PAGE:
			store.isLeavingPage = action.payload;
		break;
		case LMPBP_MOVE_TO:
			store.moveTo = action.payload;
		break;
		case LMPBP_CONFIRM_MODAL_ACCEPTED:
			store.confirmModalAccepted = action.payload;
	}

	return store;
};

export default statusReducer;
import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getFranchise = ({
    brandId,
    franchiseId
} = {}) => {
    return handleResponse(
        api().get(`/brand/${brandId}/franchise/${franchiseId}`)
    );
}
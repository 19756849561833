export function tacticBrandSpecificSettings(brandSymbol) {
    switch (brandSymbol) {
        case 'MSQ':
            return {
                needWebsite: ["SEO", "Scorpion"],
                annualOptIn: ["PulseM"],
                canHaveWebsite: ["SEO", "PulseM"],
            };
        default:
            return {
                needWebsite: ["SEO", "Scorpion", "PulseM"],
                canHaveWebsite: ["SEO", "Scorpion", "PulseM"],
            };
    }
}

function tacticRequiresWebsiteForBrand(brandSymbol, tacticName) {
    return tacticBrandSpecificSettings(brandSymbol)
        .needWebsite.some(tName => tName === tacticName);
}

function tacticCanHaveWebsiteForBrand(brandSymbol, tacticName) {
    return tacticBrandSpecificSettings(brandSymbol)
        .canHaveWebsite.some(tName => tName === tacticName);
}

function isAnnualOptInByBrand(brandSymbol, tacticName) {
    return tacticBrandSpecificSettings(brandSymbol)
        .annualOptIn?.some(tName => tName === tacticName);
}

export function enrichTacticWithBrandSettings(brandSymbol, tactic) {
    return {
        ...tactic,
        isAnnualOptIn: isAnnualOptInByBrand(brandSymbol, tactic.name),
        requiresWebsite: tacticRequiresWebsiteForBrand(brandSymbol, tactic.name),
        canHaveWebsite: tacticCanHaveWebsiteForBrand(brandSymbol, tactic.name),
    };
}

const invalidAddresses = addresses => {
    if(typeof addresses === 'boolean') return true;

    const numberOfAddresses = addresses != '' ? Number(addresses) : 0;
    if(Array.isArray(addresses)) return false;

    return !numberOfAddresses || isNaN(numberOfAddresses)
        || typeof numberOfAddresses !== 'number'
}

const invalidCost = cost => {
    return !cost
        || typeof cost !== 'number';
}

const calculateMailerSpend = (addresses = null, cost = null) => {
    if(invalidAddresses(addresses) || invalidCost(cost)) return 0;

    if(Array.isArray(addresses)) {
        if(!addresses.length) return 0;
        
        return addresses.map(num => num * cost)
            .reduce((total, amount) => total + amount);
    }

    return addresses * cost;
}

export default calculateMailerSpend;
const percentageType = 'percentage';
const amountType = 'amount';

const discountTypes = {
    isPercentage: (value) => {
        if(!value) return false;
        return String(value).toLowerCase().trim() === percentageType;
    },
    isAmount: (value) => {
        if(!value) return false;
        return String(value).toLowerCase().trim() === amountType;
    },
    getDiscountTypes: () => {
        return [percentageType, amountType];
    },
    percentage: percentageType,
    amount: amountType
}

export default discountTypes;
import { 
    DELETE_SERVICE_LOADING,
    GET_SERVICES_FOR_CONFIG_LIST, 
    GET_SERVICES_FOR_CONFIG_LIST_LOADING,
    SAVE_SERVICE_LOADING,
    UPDATE_SERVICE_LOADING
} from 'actions/types';

const servicesConfigReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_SERVICES_FOR_CONFIG_LIST:
            return { ...state, services: [...action.payload] };

        case GET_SERVICES_FOR_CONFIG_LIST_LOADING:
            return { ...state, loading: action.payload };

        case SAVE_SERVICE_LOADING:
            return { ...state, saving: action.payload };

        case UPDATE_SERVICE_LOADING:
            return { ...state, updating: action.payload };

        case DELETE_SERVICE_LOADING:
            return { ...state, deleting: action.payload };

        default:
            return state;
    }
}

export default servicesConfigReducer;
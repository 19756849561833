const parseDefaultValues = values => {
    if(!values || !values.length) return null;
    return values.split(',').map(value => Number(value));
}

const tacticWrapper = data => {
    if(
        !data
        || Array.isArray(data)
        || typeof data !== 'object'
    ) return {};

    return {
        id: data.id,
        name: data.name,
        displayName: data.display_name,
        hasPredefinedValues: data.default_values ? true : false,
        validValues: parseDefaultValues(data.default_values),
        sortingOrder: data.sorting_order,
        monthlyMin: data.min,
        monthlyMax: data.max,
        isFranchiseCustom: data.is_franchise_custom,
        deletable: data.deletable,
        defaultMonthlyBudget: data.default_monthly_budget,
        locked: data.locked
    }
}

export default tacticWrapper;
import { getDiscounts as getAllDiscounts } from 'api/direct-mail/discounts';
import discountWrapper from 'api/direct-mail/utils/backend-wrappers/discountWrapper';
import { notifyGetDiscountsError } from './notificationActions';

import { 
    GET_DISCOUNTS, 
    GET_DISCOUNTS_LOADING 
} from './types';

export const getDiscounts = templateId => async dispatch => {
    dispatch(notifyGetDiscountsError({ display: false }));

    dispatch({
        type: GET_DISCOUNTS_LOADING,
        payload: true
    });

    const [discountsErr, discountsRes] = await getAllDiscounts({ templateId });

    if(discountsErr) {
        dispatch(notifyGetDiscountsError({ serverError: discountsErr }));
    }

    dispatch({
        type: GET_DISCOUNTS,
        payload: discountWrapper.sortByType(discountsRes)
    });

    return dispatch({
        type: GET_DISCOUNTS_LOADING,
        payload: false
    });
}
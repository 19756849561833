import calculateMailerSpend from './calculateMailerSpend';

const sumAllAddresses = campaigns => {
    const addresses = campaigns?.map(({ addresses }) => addresses);
    const total = addresses.reduce((total, address) => total + Number(address), 0);
    
    return total;
};

export const validateMinSpend = (campaigns, costPerPiece, businessRule) => {
    if (!Array.isArray(campaigns)) return false;
    if (typeof costPerPiece !== 'number' || costPerPiece < 0) return false;

    const minSpend = Number(businessRule?.calculatedValue);
    if (isNaN(minSpend)) return false;

    const selectedCampaigns = campaigns.filter(({ selected }) => selected);
    if (!selectedCampaigns.length) return false;

    const totalAddresses = sumAllAddresses(selectedCampaigns);
    const totalSpend = calculateMailerSpend(totalAddresses, costPerPiece);

    if (totalSpend >= minSpend) return true;
    
    return false;
};

export const validateMinSpendMsq = (campaigns, businessRule,costPerPiece,isMts) => {
    if (!Array.isArray(campaigns)) return false;

    const minSpend = Number(businessRule?.calculatedValue);
    if (isNaN(minSpend)) return false;

    const selectedCampaigns = campaigns.filter(({ selected }) => selected);
    if (!selectedCampaigns.length) return false;

    let totalSpend = 0;
    campaigns?.forEach(campaign => {
        if(campaign.selected) {
            if(isMts){
                totalSpend += campaign.addresses * costPerPiece;
            }else{
                totalSpend += Number(campaign.budget_intention);
            }
        }
    });
    if (totalSpend >= minSpend) return true;
    return false;
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageView from 'components/common/image-view/ImageView';

export class BrochureSelection extends Component {
    static propTypes = {
        selectedBrochure: PropTypes.object,
        brochures: PropTypes.array,
        onBrochureSelected: PropTypes.func,
        onLockNextStep: PropTypes.func
    }
    
    renderBrochures = () => {
        const { 
            brochures,
            onBrochureSelected,
            selectedBrochure
        } = this.props;

        if(!brochures?.length) {
            return <h4>No brochures available</h4>;
        }

        return brochures?.map(brochure => {
            const  { brochureId, src, brochureName, selected } = brochure;

            return (
                <div
                    key={brochureId} 
                    className={`
                            m-1
                            brochure-item rounded 
                            ${(!selected && selectedBrochure?.brochureId) ? 'blured' : ''}
                        `.trim()}
                    onClick={() => onBrochureSelected(brochure)}
                >
                    <div 
                        className={`
                            brochure-item-image rounded-lg 
                            ${selected ? 'selected' : ''}
                        `.trim()}
                    >
                        <ImageView
                            src={src} 
                            alt={brochureName}
                        />
                    </div>
                </div>
            );
        });
    }

    componentDidMount() {
        this.props.onLockNextStep(false);
    }

    render() {
        const {
            selectedBrochure
        } = this.props;

        return (
            <Fragment>
                <div className='brochure-selection'>
                    <div className='brochure-item-expanded'>
                        <ImageView 
                            maxWidth='600px'
                            maxHeight='400px'
                            src={selectedBrochure?.src} 
                            alt={selectedBrochure?.brochureName}
                        />
                    </div>
                    <div className='brochure-list'>
                        {this.renderBrochures()}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default BrochureSelection;
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import ApproveConfirmation from './ApproveConfirmation';
import RejectConfirmation from './RejectConfirmation';
import OverrideConfirmation from './OverrideConfirmation';
import Button from 'components/common/button/Button';
import Switch from 'components/common/switch/Switch';

import validateStatus from 'utils/validateStatus';
import formatCurrency from 'utils/formatCurrency';
import formatNumber from 'utils/formatNumber';
import userSession from 'utils/userSession';

import {
    enableMailingPlanOverride as enableMailingPlanOverrideAction,
    approveMailingPlan as approveMailingPlanAction,
    rejectMailingPlan as rejectMailingPlanAction,
    confirmMailingPlanApproval as confirmMailingPlanApprovalAction,
    resetMailingPlan as resetMailingPlanAction,
    overrideMailingPlan as overrideMailingPlanAction,
    showOverrideConfirmationModal as showOverrideConfirmationModalAction
} from 'actions/mailingPlanActions';

import {
    resetFranchiseInfo as resetFranchiseInfoAction
} from 'actions/franchiseActions';

export class HeaderCU extends Component {
    state = {
        mailingPlanCopy: {},
        rejectingMailingPlan: false
    }

    static propTypes = {
        calculateTotalCost: PropTypes.func,
        countTotalAddresses: PropTypes.func
    }

    dismissApprovalModal = () => {
        this.props.confirmMailingPlanApproval(false);
    }

    dismissRejectModal = () => {
        this.setState({ rejectingMailingPlan: false });
    }

    dismissOverrideModal = () => {
        const { showOverrideConfirmationModal } = this.props;

        showOverrideConfirmationModal(false);
    }

    onOverrideToggle = (enableOverride = false) => {
        const { 
            toggleMailingPlanOverride,
            resetMailingPlan,
            mailingPlan
        } = this.props;

        const { mailingPlanCopy } = this.state;

        if(enableOverride) {
            this.setState({ mailingPlanCopy: JSON.parse(JSON.stringify(mailingPlan)) });
        } else {
            resetMailingPlan(mailingPlanCopy);
        }

        toggleMailingPlanOverride(enableOverride);
    }

    onApproveMailingPlan = () => {
        const { 
            mailingPlan, 
            approveMailingPlan,
            overrideEnabled,
            showOverrideConfirmationModal
        } = this.props;

        if(overrideEnabled) {
            showOverrideConfirmationModal(true);
        } else {
            approveMailingPlan(mailingPlan?.mailingPlanId);
        }
    }

    onRejectMailingPlan = () => {
        this.setState({ rejectingMailingPlan: true });
    }

    onRejectConfirmation = (feedback = null) => {
        const { mailingPlan, rejectMailingPlan } = this.props;

        rejectMailingPlan(mailingPlan?.mailingPlanId, feedback);
    }

    onOverrideConfirmation = (feedback = null) => {
        const { 
            mailingPlan, 
            overrideMailingPlan,
            isCorrectFranchiseInfo
        } = this.props;

        overrideMailingPlan(mailingPlan, feedback, isCorrectFranchiseInfo);
    }

    renderCorporateUserActions = () => {
        const {
            mailingPlan,
            overrideEnabled
        } = this.props;

        if(validateStatus.isRejected(mailingPlan?.status) || mailingPlan?.rejected) {
            return (
                <div className='alert alert-danger' role='alert'>
                    <i className='fas fa-exclamation-circle mr-2'></i>
                    Mailing plan has been <strong>Rejected</strong>
                </div>
            );
        }
        
        if(validateStatus.isApproved(mailingPlan?.status) || mailingPlan?.approved) {
            return (
                <div className='alert alert-success' role='alert'>
                    <i className='fas fa-clipboard-check mr-2'></i>
                    Mailing plan has been <strong>Approved</strong>!
                </div>
            );
        }
        if(validateStatus.isSubmitted(mailingPlan?.status)) {
        return (
            <Fragment>
                <Switch 
                    id='overridePlanSwitch'
                    label='Override Plan'
                    checked={overrideEnabled}
                    onToggle={(checked) => this.onOverrideToggle(checked)}
                />
                <div className='mailing-plan-actions border-left pl-3'>
                    <div className='mailing-plan-actions pl-3'>
                        <Button 
                            btnType='outline-danger'
                            disabled={!mailingPlan?.mailingPlanId || mailingPlan?.approving}
                            action={() => this.onRejectMailingPlan()}
                        >
                            Reject
                        </Button>
                        <Button 
                            btnType='primary'
                            disabled={!mailingPlan?.mailingPlanId || mailingPlan?.rejecting}
                            action={() => this.onApproveMailingPlan()}
                            loading={mailingPlan?.approving}
                            loadingMessage='Approving plan...'
                        >
                            Approve
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
        }
    }

    render() {
        const {
            mailingPlan, 
            franchiseName,
            calculateTotalCost,
            countTotalAddresses,
            resetMailingPlan,
            resetFranchiseInfo,
            showOverrideConfirmation
        } = this.props;

        const { rejectingMailingPlan } = this.state;

        return (
            <div className='mailing-plan-header-container'>
                <OverrideConfirmation
                    onHide={this.dismissOverrideModal}
                    show={showOverrideConfirmation}
                    onOverrideConfirmation={this.onOverrideConfirmation}
                />
                <ApproveConfirmation
                    onHide={this.dismissApprovalModal}
                    show={mailingPlan?.approved}
                    resetMailingPlan={resetMailingPlan}
                    resetFranchiseInfo={resetFranchiseInfo}
                />
                <RejectConfirmation 
                    onHide={this.dismissRejectModal}
                    show={rejectingMailingPlan && !mailingPlan?.rejected}
                    onRejectConfirmation={this.onRejectConfirmation}
                />
                <div className='d-flex flex-column'>
                    <div className='mailing-plan-id'>
                        
                    </div>
                    <div className='mailing-plan-info  pt-2'>
                        <div>
                            <div>
                                {
                                    franchiseName ?
                                        <span>{franchiseName} </span>
                                    :
                                        <span>Franchise name: Not available</span>
                                }
                                 
                            </div>
                            <div>
                            {
                                    mailingPlan?.mailingPlanId ?
                                        <span className='mailing-plan-id'>ID: {mailingPlan?.mailingPlanId} </span>
                                    :
                                        <span className='mailing-plan-id'>ID: None </span>
                                }
                                {
                                    mailingPlan?.createdDate ?
                                        <span>{`Submitted on ${new moment(mailingPlan?.createdDate).format('MM/DD/YYYY hh:mm:ss A')}`}</span>
                                        
                                    :
                                        <span>Submitted on: Not available</span>
                                }
                            </div>
                        </div>
                        <div>
                            <div>
                                <span className='mr-1'>Investment:</span>
                                <span className='brand-primary-color'>${formatCurrency(calculateTotalCost())}</span>
                            </div>
                            { ! userSession.isMSQUser() &&
                                <div>
                                    <span className='mr-1'>Number of Addresses:</span>
                                    <span className='brand-primary-color'>{formatNumber(countTotalAddresses())}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='mt-2'>
                    <div className='mailing-plan-actions'>
                        {this.renderCorporateUserActions()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    mailingPlan: state.mailingPlan,
    franchiseName: state.franchise?.name,
    overrideEnabled: state.mailingPlan?.overrideEnabled,
    isCorrectFranchiseInfo: state.mailingPlan?.isCorrectFranchiseInfo,
    showOverrideConfirmation: state.mailingPlan?.showOverrideConfirmation
});

export default connect(mapStateToProps, {
    toggleMailingPlanOverride: enableMailingPlanOverrideAction,
    approveMailingPlan: approveMailingPlanAction,
    rejectMailingPlan: rejectMailingPlanAction,
    confirmMailingPlanApproval: confirmMailingPlanApprovalAction,
    resetMailingPlan: resetMailingPlanAction,
    resetFranchiseInfo: resetFranchiseInfoAction,
    overrideMailingPlan: overrideMailingPlanAction,
    showOverrideConfirmationModal: showOverrideConfirmationModalAction
})(HeaderCU);

import campaignEntryWrapper from "./campaignEntryWrapper";
import moment from 'moment';

const mailingPlanEntryWrapper = plan => {
    if(
        !plan
        || Array.isArray(plan)
        || typeof plan !== 'object'
    ) return {};

    return {
        mailingPlanId: plan?.mailingPlanId,
        franchiseId: plan?.franchiseId,
        franchiseName: plan?.franchiseName,
        year: plan?.year,
        requestStatus: {
            id: plan?.requestStatus?.id,
            name: plan?.requestStatus?.name,
            code: plan?.requestStatus?.status_code,
            description: plan?.requestStatus?.description
        },
        submissionDate: plan?.submissionDate,
        modifiedDate: plan?.modifiedDate,
        totalAddresses: plan?.totalAddresses,
        totalSpend: plan?.totalSpend,
        campaigns: plan?.campaigns?.map(campaign => campaignEntryWrapper(campaign))
            .sort((a, b) => {
                return new moment(a.date).valueOf() - new moment(b.date).valueOf();
            }),
        isDmExempt : plan?.isDmExempt,
    }
}

export default mailingPlanEntryWrapper;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Input from '../input/Input';
import formatDate from 'utils/formatDate';
export class DateRangePicker extends Component {
    static propTypes = {
        label: PropTypes.string,
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        minStartDate: PropTypes.object,
        maxEndDate: PropTypes.object,
        onStartDateChange: PropTypes.func,
        onEndDateChange: PropTypes.func,
        idStartDate: PropTypes.string,
        idEndDate: PropTypes.string
    }

    convertToISOFormat = (date = null) => {
        if(!date) return new moment().format('YYYY-MM-DD');

        return formatDate.toISOFormat(date);
    }

    render() {
        const {
            startDate = new moment(), 
            endDate = new moment(),
            label,
            onStartDateChange,
            onEndDateChange,
            minStartDate,
            maxEndDate,
            idStartDate,
            idEndDate
        } = this.props;

        return (
            <div className='d-flex flex-column'>
                {
                    label &&
                        <small>{label}</small>
                }
                <div className='d-flex column-gap-1'>
                    <Input 
                        id={idStartDate}
                        type='date'
                        value={this.convertToISOFormat(startDate)}
                        min={minStartDate ? this.convertToISOFormat(minStartDate) : null}
                        max={this.convertToISOFormat(endDate)}
                        onChange={(e) => {
                            onStartDateChange(new moment(e.target.value));
                        }}
                    />
                    <div className='d-flex flex-column'>
                        <Input 
                            id={idEndDate}
                            type='date'
                            value={this.convertToISOFormat(endDate)}
                            min={this.convertToISOFormat(startDate)}
                            max={maxEndDate ? this.convertToISOFormat(maxEndDate) : null}
                            onChange={(e) => {
                                onEndDateChange(new moment(e.target.value));
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default DateRangePicker;

import { 
    GET_BADGES, 
    GET_BADGES_LOADING 
} from 'actions/types';

const badgesReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_BADGES:
            return { ...state, badges:  [...action.payload] };
        
        case GET_BADGES_LOADING:
            return { ...state, loading: action.payload };

        default:
            return state;
    }
}

export default badgesReducer;
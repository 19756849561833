import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getBrand = ({ brandId = null } = {}) => {
    return handleResponse(
        api().get(`brand/${brandId}`)
    );
}
export const updateLockDateReminderDm = ({ toggle_lock_date_reminder } = {}) => {
    sessionStorage.setItem("toggle_lock_date_reminder_dm",toggle_lock_date_reminder);
    return handleResponse(
        api().post(`corporate/brand/toggle-lock-date-reminder`, {
            toggle_lock_date_reminder: toggle_lock_date_reminder,
        }),
    );
};
export const updateDmBrandInAuthorizeApi = ({ BrandID, FranchiseID } = {}) => {
    return handleResponse(
     api().post(`brand/update-dm-brand-in-authorize`,{
            BrandID: BrandID,
            FranchiseID: FranchiseID
        }),
    );
};
const couponEntryWrapper = (couponEntryData) => {
    if(
        !couponEntryData
        || Array.isArray(couponEntryData)
        || typeof couponEntryData !== 'object'
    ) return {};

    return {
        couponId: couponEntryData?.id,
        name: couponEntryData?.name,
        outputText: couponEntryData?.output_text,
        couponCode: couponEntryData?.code,
        active: couponEntryData?.active ? true : false,
        brandId: couponEntryData?.brand_id,
        discountId: couponEntryData?.discount_id,
        licenseRequired: couponEntryData?.license_required ? true : false
    }
}

export default couponEntryWrapper;
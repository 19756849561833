import {
	LMPBP_MOVE_TO,
    LMPBP_CONFIRM_MODAL_ACCEPTED,
    LMPBP_SET_HAVE_UNSAVED_CHANGES
} from './types';

export const setMoveToAction = (movingTo) => async dispatch => {
    return dispatch({
        type: LMPBP_MOVE_TO,
        payload: movingTo
    });
}

export const setHaveUnsavedChangesAction = (haveUnsavedChanges) => async dispatch => {
    return dispatch({
        type: LMPBP_SET_HAVE_UNSAVED_CHANGES,
        payload: haveUnsavedChanges
    });
}

export const setConfirmModalAcceptedAction = (isAccepted) => async dispatch => {
    return dispatch({
        type: LMPBP_CONFIRM_MODAL_ACCEPTED,
        payload: isAccepted
    });
}

import React, { useState } from 'react';
import CustomTabHeader from './custom-tab-header';
import { Tab, Nav, Row, Col} from 'react-bootstrap';
import IconButton from 'components/common/icon-button/IconButton';

const CustomTabs = props => {
    const totalSlides = Math.ceil(props.tabs.length / props.tabsPerSlide);
    const [slide, setSlide] = useState(0);
    // const [activeTab, setActiveTab] = useState(0);
    let vv = localStorage.getItem("year")?JSON.parse(localStorage.getItem("year")).includes(props.selectedYear):true;
    let arr = localStorage.getItem("year")?JSON.parse(localStorage.getItem("year")):[];
    arr.push(props.selectedYear);
    localStorage.setItem("year",JSON.stringify([...new Set(arr)]))

    function changeSlide(direction)
    {
        const newSlide = direction === "left" ? slide - 1 : slide + 1;
        setSlide(newSlide);
        props.setActiveTab(newSlide*props.tabsPerSlide);
    }

    return <Tab.Container 
        id="territory" 
        defaultActiveKey={0} 
        activeKey={vv?props.activeTab:0} 
        onSelect={vv?props.setActiveTab:props.setActiveTab(0)}>
        <Row>
            <Col sm={11}>
                <Nav variant="tabs">
                    {
                        props.tabs.map((tab) =>
                        {
                            return <CustomTabHeader
                                key={tab.index}
                                tab={tab}
                                subclass={props.subclass}
                                isVisible={tab.index >= (slide*props.tabsPerSlide) 
                                    && tab.index < (slide*props.tabsPerSlide + props.tabsPerSlide)} 
                            />
                        })
                    }
                </Nav>
            </Col>
            <Col sm={1} className="tabs-actions">
                {
                    slide > 0 ?
                    <IconButton icon="fas fa-arrow-left" action={() => changeSlide("left")} shape="circle" className="prev-button" /> : null
                }
                {
                    slide < (totalSlides - 1) ?
                    <IconButton icon="fas fa-arrow-right" action={() => changeSlide("right")} shape="circle" /> : null
                }
            </Col>
        </Row>
        {
            <Tab.Content>
            {
                props.tabs.map((tab) =>
                {
                    return <Tab.Pane eventKey={tab.index} key={tab.index}>
                        {tab.content}
                    </Tab.Pane>
                })
            }
            </Tab.Content>
        }
    </Tab.Container>
};

export default CustomTabs;
import React, { useContext, useState } from "react";
import CustomButton from "../custom-buttons/CustomButton";
import { useQuery } from "react-query";
import { WebsiteContext } from "./WebsiteCard";
import SelectReact from 'react-select';
import { getFranchises } from "api/lmpbp/franchises";
import useAddFranchiseWebsite from "hooks/useAddFranchiseWebsite";

function AddFranchiseForm({ onClose, tactics, placeholder, isParent = false}) {
  const { website } = useContext(WebsiteContext);

  const [selectedFranchise, setSelectedFranchise] = useState([]);

  const { mutate, isLoading } = useAddFranchiseWebsite(website.id, onClose, 'search-websites')

  const franchises = useQuery('franchises', getFranchises);
  const franchisesOptions = franchises.data?.map(f =>
    ({ value: f, label: `${f.externalId} - ${f.name}` })
  );

  return (
    <div>
      <div className="d-flex flex-wrap">
        <SelectReact className="p-2 flex-grow-1"
          placeholder={placeholder || 'Select Franchise'}
          isClearable={true}
          value={selectedFranchise}
          onChange={setSelectedFranchise}
          options={franchisesOptions}
        />
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex">
          <CustomButton
            id='cancel-add-franchise-website-button'
            className='dm-button outline-danger mx-2'
            onClick={() => onClose(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            id='add-franchise-website-button'
            className='dm-button outline-primary mx-2'
            onClick={() => {
              for (const tactic of tactics) {
                console.log(tactic.id, 'tactic')
                console.log(selectedFranchise.value.id, 'tactic')
                mutate({
                  franchiseId: selectedFranchise.value.id,
                  tacticId: tactic.id,
                  isParent: isParent,
                })
              }
            }}
            loading={isLoading}
            loadingMessage='Adding...'
          >
          Add
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default AddFranchiseForm;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddButton from 'components/common/add-button/AddButton';
import DiscountConfigModal from './discount-config-modal/DiscountConfigModal';
import DiscountItem from './DiscountItem';
import Loader from 'components/common/loader/Loader';

import discountTypes from 'utils/discountTypes';
import { 
    getDiscounts as getDiscountsAction, 
    resetDiscountsList as resetDiscountsListAction
} from 'actions/discountsConfigActions';

export class DiscountsList extends Component {
    state = {
        showConfigModal: false,
        selectedDiscount: null
    }

    static propTypes = {
        templateId: PropTypes.number.isRequired
    }

    onShowConfigModal = (discount = null) => {
        this.setState({ 
            showConfigModal: true,
            selectedDiscount: {...discount}
        });
    }
    
    onHideConfigModal = () => {
        this.setState({ 
            showConfigModal: false,
            selectedDiscount: null 
        });
    }

    renderDiscounts = discountType => {
        const { 
            discounts
        } = this.props;

        if(!Array.isArray(discounts) || !discounts?.length) return <div>No discounts configured</div>;
        
        return discounts.filter(discount => 
            discount.discountType === discountType
        ).map(discount => {
            return <DiscountItem 
                key={discount.discountId}
                outputText={discount.outputText}
                onEditDiscount={() => this.onShowConfigModal(discount)}
            />;
        });

    }

    componentDidMount() {
        const { 
            templateId,
            getDiscounts
        } = this.props;

        getDiscounts(templateId);
    }

    componentWillUnmount() {
        this.props.resetDiscountsList();
    }

    render() {
        const {
            showConfigModal,
            selectedDiscount
        } = this.state;

        const {
            templateId,
            loading
        } = this.props;

        return (
            <div className='d-flex flex-column row-gap-2'>
                {
                    showConfigModal &&
                        <DiscountConfigModal 
                            templateId={templateId}
                            onHide={() => this.onHideConfigModal()}
                            selectedDiscount={selectedDiscount}
                        />
                }
                <AddButton 
                    title='Create New'
                    style={{width: '200px', height: '60px'}}
                    action={() => this.onShowConfigModal()}
                />
                <div>
                    <div className='font-family-bold'>Percentage</div>
                    <div className='d-flex flex-wrap column-gap-1'>
                        {this.renderDiscounts(discountTypes.percentage)}
                    </div>
                </div>
                <div>
                    <div className='font-family-bold'>Amount</div>
                    <div className='d-flex flex-wrap column-gap-1'>
                        {this.renderDiscounts(discountTypes.amount)}
                    </div>
                </div>
                {
                    loading &&
                        <Loader loadingMessage='Loading discounts...' />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    discounts: state.discountsConfig?.discounts,
    loading: state.discountsConfig?.loading
});

export default connect(mapStateToProps, {
    getDiscounts: getDiscountsAction,
    resetDiscountsList: resetDiscountsListAction
})(DiscountsList);

import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
    id = '',
    label = '',
    ...props
}) => {
    return (
        <div className='d-flex align-items-center'>
            <input 
                id={id}
                className={`${label ? 'mr-2' : ''}`}
                type='checkbox'
                {...props}
            />
            {
                label &&
                    <label className='mb-0' htmlFor={id}>{label}</label>
            }
        </div>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string
}

export default Checkbox;

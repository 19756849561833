import discountWrapper from './discountWrapper';
import couponWrapper from './couponWrapper';
import serviceWrapper from './serviceWrapper';
import brochureWrapper from './brochureWrapper';
import badgeWrapper from './badgeWrapper';

import moment from 'moment';

const hasSelectedBrochure = (selectedBrochure = null) => {
    if (!selectedBrochure) return false;
    if (!Object.keys(selectedBrochure).length) return false;
    return true;
};

const wrapper = campaign => ({
    campaignId: campaign?.id,
    campaignTemplateId: campaign?.campaign_template_id,
    brochureId: campaign?.brochure_id,
    campaignName: campaign?.name,
    date: campaign?.date,
    lockDate: campaign?.lock_date,
    addresses: campaign?.addresses || 0,
    selectedBrochure: brochureWrapper.single(campaign?.brochure, true),
    selected: hasSelectedBrochure(campaign?.brochure),
    brochures: campaign?.brochures?.map(brochure =>
        brochureWrapper.single(
            brochure,
            brochure?.id === campaign?.brochure?.id
        )
    ),
    services: serviceWrapper.sortByName(campaign?.services),
    selectedServices: serviceWrapper.sortByName(campaign?.selected_services),
    selectedCoupons:
        campaign?.selected_coupons?.map(coupon => ({
            ...couponWrapper.single(coupon, false),
            discount: discountWrapper.single(coupon.discount),
        })) || [],
        selectedBadges:
        campaign?.selected_badges?.map(badge => ({
            ...badgeWrapper.single(badge, true),
            
        })) || [],
        is_no_coupon :campaign?.is_no_coupon,
        is_no_badge :campaign?.is_no_badge,
        expiration_date :campaign?.expiration_date,
        desired_in_home_date :campaign?.desired_in_home_date,
        budget_intention :campaign?.budget_intention,
        piece_size :campaign?.piece_size,
});

const campaignWrapper = {
    single: campaign => {
        if (
            !campaign ||
            Array.isArray(campaign) ||
            typeof campaign !== 'object'
        )
            return {};

        return wrapper(campaign);
    },

    sortByDate: campaigns => {
        if (!Array.isArray(campaigns)) return [];

        return campaigns
            .map(campaign => wrapper(campaign))
            .sort((a, b) => {
                return (
                    new moment(a.date).valueOf() - new moment(b.date).valueOf()
                );
            });
    },
};

export default campaignWrapper;

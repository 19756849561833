import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import PropTypes from 'prop-types';
import moment from 'moment';

import Select from 'components/common/select/Select';
import Input from 'components/common/input/Input';
import Loader from 'components/common/loader/Loader';
import BudgetPlanHistoric from './budget-plan-historic/BudgetPlanHistoric';
import BudgetPlan from 'components/lmpbp/budget-plan/BudgetPlan';

import formatDate from 'utils/formatDate';
import formatCurrency from 'utils/formatCurrency';

import {
    createBudgetPlan,
    getBudgetPlansTemplates,
    getHistoric,
} from 'api/lmpbp/budgetPlans';

import {
    closeNotification,
    showNotification,
} from 'actions/notificationActions';

import {
    LMPBP_GET_BUDGET_HISTORIC_ERROR,
    LMPBP_GET_PLAN_TEMPLATES_ERROR,
    LMPBP_CREATE_BUDGET_PLAN_ERROR,
    LMPBP_MOVE_TO
} from 'actions/types';
import userSession from 'utils/userSession';
import { BudgetPlanValidatorFactory } from 'utils/lmpbp/BudgetPlanValidator';

const TerritoryBudgetPlan = ({ franchise, persistedYear, setPersistedYear }) => {
    const dispatch = useDispatch();
    const brand = useSelector(state => state.brand);
    const haveUnsavedChanges = useSelector((state) => state.status.unsavedBudgetPlanChanges);
    const confirmationModalAccepted = useSelector((state) => state.status.confirmModalAccepted);

    const [selectedYear, setSelectedYear] = useState(persistedYear);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState({});

    const templates = useQuery(
        ['templates', franchise.id],
        getBudgetPlansTemplates,
        {
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_PLAN_TEMPLATES_ERROR,
                        message: 'Error fetching budget plans templates',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: res => {
                dispatch(closeNotification(LMPBP_GET_PLAN_TEMPLATES_ERROR));
                if (res?.length) {
                    setSelectedTemplate(res.find(t => t.year === persistedYear) ?? res[0]);
                } else {
                    dispatch(
                        showNotification({
                            id: LMPBP_GET_PLAN_TEMPLATES_ERROR,
                            message: 'Error fetching budget plans templates',
                            type: 'danger',
                            serverError: 'There is not a template created for this year',
                        })
                    );
                }
            }
        }
    );

    const historicData = useQuery(
        ['submissions', franchise.id],
        async () =>
            await getHistoric({
                franchiseId: franchise.id,
                year: selectedTemplate.year,
            }),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_BUDGET_HISTORIC_ERROR,
                        message: 'Error fetching budget plans historic',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: res => {
                dispatch(closeNotification(LMPBP_GET_BUDGET_HISTORIC_ERROR));
                if (res.length) {
                    setSelectedPlan(res[0]);
                } else if (templates.data) {
                    createPlan.mutate({
                        franchiseId: franchise.id,
                        templateId: selectedTemplate.id,
                    });
                }
            },
        }
    );

    const createPlan = useMutation(
        ({ franchiseId, templateId } = {}) =>
            createBudgetPlan({ franchiseId, templateId }),
        {
            onError: error => {
                dispatch(
                    showNotification({
                        id: LMPBP_CREATE_BUDGET_PLAN_ERROR,
                        message: 'Error creating budget plan',
                        type: 'danger',
                        serverError: error,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_CREATE_BUDGET_PLAN_ERROR));
                historicData.refetch();
            },
        }
    );

    const renderRequiredBudget = () => {
        const { requiredAnnualAmount: annual, requiredMonthlyAmount: monthly } =
            selectedPlan;
        
        // Set Annual as default
        const type = monthly ? 'Monthly' : 'Annual';
        const value = monthly ? monthly : annual;

        return (
            <Input
                label={`Required ${type} Budget`}
                icon='fas fa-suitcase'
                labelStyling='font-family-bold c-neutral font-size-14 mb-1'
                value={`$${formatCurrency(value)}`}
                disabled
            />
        );
    };

    const renderLockDate = () => {
        const { year, lockDates } = selectedTemplate;
        const currentMonth = new moment().month();
        const currentYear = new moment().year();
        let monthName = '';
        let lockDate = '';
        let lockDateIndex = 0;

        if (currentYear < year) {
            monthName = new moment().month(0).format('MMMM');
            lockDate = formatDate.dateUTC(lockDates[0]);
            lockDateIndex = 0;
        }

        if (currentYear > year) {
            monthName = new moment().month(11).format('MMMM');
            lockDate = formatDate.dateUTC(lockDates[11]);
            lockDateIndex = 11;
        }

        if (currentYear === year) {
            monthName = new moment().month(currentMonth + 1).format('MMMM');
            lockDate = formatDate.dateUTC(lockDates[currentMonth + 1]);
            if(currentMonth == 11){
                lockDateIndex = currentMonth;
                monthName = new moment().month(currentMonth).format('MMMM');
                lockDate = formatDate.dateUTC(lockDates[currentMonth]);
            }else{
                lockDateIndex = currentMonth + 1;
            }     
        }

        let isOverdue = false;
        
        if(lockDates)
        {
            const lockdate = lockDates[lockDateIndex].substring(0, lockDates[lockDateIndex].indexOf('T'));

            if(userSession.isFranchiseOwer() && new moment().isAfter(lockdate, 'day'))
            {
                isOverdue = true;
            }
        }

        return <Input
            label={`${monthName} Budget Lock Date`}
            icon={` ${ isOverdue ? 'fas fa-exclamation-triangle' : 'far fa-calendar-alt'}`}
            labelStyling='font-family-bold c-neutral font-size-14 mb-1'
            value={lockDate}
            disabled
            inputStyling={` ${ isOverdue ? 'overdue-lockdate-input' : ""}`}
            primary={!isOverdue}
            iconClass={ isOverdue ? 'overdue-icon' : ''}
        />;
    };

    const generateTemplateOptions = () => {
        if (!templates.data?.length) {
            const currentYear = new moment().year();
            return [
                {
                    id: 1,
                    label: currentYear,
                    value: currentYear,
                },
            ];
        }

        return templates.data.map(({ id, year, lockDates }) => ({
            id,
            label: year,
            value: year,
            lockDates
        }));
    };

    useEffect(() => {
        if (selectedTemplate.id) {
            historicData.refetch();
        }
    }, [selectedTemplate]);

    useEffect(() => {
        if (confirmationModalAccepted && selectedYear) {
            setSelectedTemplate(templates.data.find(t => t.year === selectedYear));
        }
        else if (confirmationModalAccepted == false)
        {
            setSelectedYear(null);
        }
    }, [confirmationModalAccepted]);

    const onChangeYear = (event) => {
        const value = event.target.value;
        const selectedYear = Number(value);
        if (haveUnsavedChanges) {
            dispatch({ type: LMPBP_MOVE_TO, payload: 'Year' });
            setSelectedYear(selectedYear);
        }
        else {
            setSelectedTemplate(templates.data.find(t => t.year === selectedYear));
            setPersistedYear(selectedYear);
        }
    }

    return (
        <div className='bg-white border border-top-0 py-3 px-5'>
            <div className='d-flex flex-column'>
                <div className='d-flex justify-items-between column-gap-2'>
                    <Select
                        id='marketing-plan-year'
                        label='Year'
                        icon='far fa-calendar'
                        labelStyling='font-family-bold c-neutral font-size-14 mb-1'
                        value={selectedTemplate?.year}
                        options={generateTemplateOptions()}
                        onChange={onChangeYear}
                    />
                    <div className='flex-grow-1'>
                        {historicData.isLoading ? (
                            <div className='mt-4'>
                                <Loader loadingMessage='Loading budget plan submissions...' />
                            </div>
                        ) : (
                            <BudgetPlanHistoric
                                selectedPlan={selectedPlan}
                                data={historicData.data}
                                label='Historic'
                                icon='far fa-clock'
                                labelStyling='font-family-bold c-neutral font-size-14 mb-1'
                                onChange={plan => setSelectedPlan(plan)}
                            />
                        )}
                    </div>
                    {renderLockDate()}
                    {renderRequiredBudget()}
                </div>
                <BudgetPlan
                    budgetPlan={selectedPlan}
                    franchiseId={franchise.id}
                    franchiseName={franchise.name}
                    template={selectedTemplate}
                    onCreateNewDraftSuccess={() => historicData.refetch()}
                    onSubmitSuccess={() => historicData.refetch()}
                    validateEntries={BudgetPlanValidatorFactory(brand)}
                />
            </div>
        </div>
    );
};

TerritoryBudgetPlan.propTypes = {
    franchise: PropTypes.object.isRequired,
    selectedYear:PropTypes.number,
    setSelectedYear:PropTypes.func,
};

export default TerritoryBudgetPlan;

import React from 'react';
import PropTypes from 'prop-types';

import formatDate from 'utils/formatDate';

import Button from 'components/common/button/Button';

function CampaignExportsItem({
    campaign = null,
    onExport = null,
    onDownload = null,
    exporting = false,
    exportingId = null,
    downloading = false,
    downloadingFileName = null
}) {
    const disableExportButton = () => {
        if(downloading) return true;
        return exportingId && exportingId !== campaign.id;
    }

    const disableDownloadButton = () => {
        if(exporting) return true;
        return downloading && downloadingFileName !== campaign?.latestExport?.fileName;
    }

    return (
        <div className='campaign-exports-item shadow'>
            <div className='d-flex justify-content-between flex-wrap column-gap-1 w-100'>
                <div className='d-flex flex-column'>
                    <div className='header-name mb-1'>{campaign?.campaignName}</div>
                    <div className='d-flex align-items-center column-gap-1'>
                        <div className='header-icon'>
                            <i className='far fa-calendar-alt'></i>
                        </div>
                        <div className='header-date'>
                            {formatDate.dateUTC(campaign?.date)}
                        </div>
                    </div>
                    <small className='mt-1'>Lock Date: {formatDate.dateUTC(campaign?.lockDate)}</small>
                </div>
                <div className='d-flex align-items-center justify-content-end flex-wrap row-gap-1 column-gap-1'>
                    {
                        campaign?.latestExport?.createdDate &&
                            <small>Last export: {formatDate.descriptiveDateAndTime(campaign?.latestExport?.createdDate)}</small>
                    }
                    <Button
                        btnType='primary'
                        label='Export Manually'
                        action={() => onExport(campaign.id)}
                        loading={exporting && exportingId === campaign.id}
                        loadingMessage='Exporting...'
                        disabled={disableExportButton()}
                    />
                    {
                        campaign?.latestExport &&
                            <Button
                                btnType='outline-orange'
                                label='Download'
                                action={() => onDownload(campaign.latestExport?.fileName)}
                                loading={downloading && downloadingFileName === campaign.latestExport?.fileName}
                                loadingMessage='Downloading...'
                                disabled={disableDownloadButton()}
                            />
                    }
                </div>
            </div>
        </div>
    );
}

CampaignExportsItem.propTypes = {
    campaign: PropTypes.object.isRequired,
    onExport: PropTypes.func,
    onDownload: PropTypes.func,
    exporting: PropTypes.bool,
    exportingId: PropTypes.number,
    downloading: PropTypes.bool,
    downloadingFileName: PropTypes.string
}

export default CampaignExportsItem;



import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const MultiSelect = ({
    label = '',
    selectedOptions = [],
    placeholder = 'Select options',
    error = false,
    errorMsg = 'Please verify this field',
    enableSelection = false,
    options = null,
    onChange = null,
    required = false,
    ...props
}) => {
    const [selectionState, setSelectionState] = useState([]);
    const [selectionOptions, setSelectionOptions] = useState([]);

    useEffect(() => {
        initialSort();
    }, []);

    useEffect(() => {
        initialSort();
    }, [selectedOptions])

    const initialSort = () =>
    {
        if (Array.isArray(selectedOptions) && Array.isArray(options)) {
            setSelectionState([...selectedOptions]);
            setSelectionOptions(
                sortOptions([
                    ...options.filter(
                        option => !isSelectedOption(option, selectedOptions)
                    ),
                ])
            );
        } else if (Array.isArray(selectedOptions)) {
            setSelectionState([...selectedOptions]);
        } else if (Array.isArray(options)) {
            setSelectionOptions(sortOptions([...options]));
        }
    }

    const sortOptions = opts => {
        if (!Array.isArray(opts)) return opts;

        return opts.sort((a, b) => {
            if (a.value === b.value) return 0;
            return b.value > a.value ? -1 : 1;
        });
    };

    const isSelectedOption = (selection, opts = []) => {
        return opts.filter(({ value }) => value === selection?.value).length
            ? true
            : false;
    };

    const renderSelectedOptions = () => {
        if (!Array.isArray(selectedOptions) || !selectedOptions.length) {
            return <span>{placeholder}</span>;
        }

        return selectedOptions.map(option => {
            return (
                <div key={option} className='multi-select-tag'>
                    <span>{option}</span>
                </div>
            );
        });
    };

    const renderSelection = () => {
        if (!Array.isArray(selectionState) || !selectionState.length) {
            return <span>{placeholder}</span>;
        }

        return selectionState.map(option => {
            return (
                <div key={option.value} className='multi-select-tag'>
                    <span>{option.value}</span>
                    <i
                        className='fas fa-times ml-2 p-1'
                        onClick={() => {
                            const updatedSelection = selectionState.filter(({ value }) => value !== option.value);
                            if (onChange) onChange(updatedSelection);
                            setSelectionState(updatedSelection);
                            setSelectionOptions(
                                sortOptions([...selectionOptions, option])
                            );
                        }}
                    ></i>
                </div>
            );
        });
    };

    const renderSelectionOptions = () => {
        if (!selectionOptions || !Array.isArray(selectionOptions)) return null;

        return selectionOptions.map(option => {
            return (
                <div
                    key={option.value}
                    className='multi-select-option'
                    onClick={() => {
                        const updatedSelection = [
                            ...selectionState,
                            { ...option },
                        ];
                        if (onChange) onChange(updatedSelection);
                        setSelectionState(updatedSelection);
                        setSelectionOptions(
                            sortOptions(
                                selectionOptions.filter(
                                    ({ value }) => value !== option.value
                                )
                            )
                        );
                    }}
                >
                    {option.value}
                </div>
            );
        });
    };

    const renderMultiSelect = () => {
        if (enableSelection) {
            return (
                <div
                    className='multi-select-input-field-container'
                    tabIndex='0'
                >
                    <div className='multi-select-input-field'>
                        {renderSelection()}
                        <i className='fas fa-angle-down input-icon'></i>
                    </div>
                    <div className='multi-select-input-options'>
                        {renderSelectionOptions()}
                    </div>
                </div>
            );
        }

        if (Array.isArray(selectedOptions) && selectedOptions.length) {
            return (
                <button
                    {...props}
                    className='form-control multi-select-button-options'
                >
                    {renderSelectedOptions()}
                </button>
            );
        }

        return (
            <button {...props} className='form-control multi-select-button'>
                <span>{placeholder}</span>
                <i className='fas fa-angle-right'></i>
            </button>
        );
    };

    return (
        <div className='d-flex flex-column'>
            <label className='font-size-12'>{label}</label>
            {renderMultiSelect()}
            {error && (
                <div className='error-msg text-left w-100'>{errorMsg}</div>
            )}
        </div>
    );
};

MultiSelect.propTypes = {
    label: PropTypes.string,
    selectedOptions: PropTypes.arrayOf(String),
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    enableSelection: PropTypes.bool,
    options: PropTypes.array,
    onChange: PropTypes.func,
};

export default MultiSelect;

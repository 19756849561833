const handleResponse = (promise) => {
    return promise
        .then(({ data }) => data?.data ? [undefined, data?.data] : [undefined, data])
        .catch(err => {
            const message = err?.response?.data?.message;
            
            if(message) {
                return [{name: err.name, message}, undefined]
            } else {
                return [{name: err.name, message: err.message}, undefined]
            }
        });
}

export default handleResponse;

import { 
    notifyGetCampaignsReportError ,
    notifyDownloadCampaignExportError,
    notifyExportCampaignError, 
    notifyExportCampaignSuccess, 
} from './notificationActions';

import { 
    GET_CAMPAIGNS_REPORT,
    GET_CAMPAIGNS_REPORT_LOADING ,
    DOWNLOAD_CAMPAIGN_EXPORT,
    DOWNLOAD_CAMPAIGN_EXPORT_LOADING,
    EXPORT_CAMPAIGN,
    EXPORT_CAMPAIGN_LOADING,
} from './types';

import {
    getCampaignsReport as getCampaignsReportApi,
    exportCampaignReportApi as exportCampaignReportApi
} from 'api/direct-mail/reports';
import { 
    downloadAddressesExport, 
} from 'api/direct-mail/addressesExport';
export const getCampaignsReport = (campaignTemplateId = null,itemsPerPage,page) => async dispatch => {
    dispatch(notifyGetCampaignsReportError({ display: false }));

    dispatch({
        type: GET_CAMPAIGNS_REPORT_LOADING,
        payload: true
    });

    let [reportErr, reportRes] = await getCampaignsReportApi({
        campaignTemplateId,
        itemsPerPage,
        page
    });

    if(reportErr) {
        dispatch(notifyGetCampaignsReportError({ serverError: reportErr }));
    }
    dispatch({
        type: GET_CAMPAIGNS_REPORT,
        payload: reportRes.data
    });

    return dispatch({
        type: GET_CAMPAIGNS_REPORT_LOADING,
        payload: false
    });
}
export const exportCampaignsReport = (campaignTemplateId = null, iotEndpoint = null) => async dispatch => {
    dispatch(notifyExportCampaignError({ display: false }));
    dispatch(notifyExportCampaignSuccess({ display: false }));

    dispatch({
        type: EXPORT_CAMPAIGN_LOADING,
        payload: true
    });

    const [exportErr, exportRes] = await exportCampaignReportApi({ 
        campaignTemplateId,
        iotEndpoint
    });

    if(exportErr) {
        dispatch(notifyExportCampaignError({ serverError: exportErr }));
    }

    return dispatch({ 
        type: EXPORT_CAMPAIGN, 
        payload: exportRes 
    });
}

export const downloadCampaignReport = (filename = '') => async dispatch => {
    dispatch(notifyDownloadCampaignExportError({ display: false }));

    dispatch({
        type: DOWNLOAD_CAMPAIGN_EXPORT_LOADING,
        payload: true
    });

    const [downloadUrlErr, downloadUrl] = await downloadAddressesExport({ filename });

    if(downloadUrlErr) {
        dispatch(notifyDownloadCampaignExportError({ serverError: downloadUrlErr }));
    } else {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
    }

    dispatch({
        type: DOWNLOAD_CAMPAIGN_EXPORT_LOADING,
        payload: false
    });

    return dispatch({
        type: DOWNLOAD_CAMPAIGN_EXPORT,
        payload: downloadUrl
    });
}
export const resetCampaignsReport = () => ({
    type: GET_CAMPAIGNS_REPORT,
    payload: null
});
import React from 'react';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import "./AssistancePopover.scss";

function PopOverItem(props) {
  return (
    <div className="m-3">
      {props.children}
    </div>
  )
}

function AssistancePopover() {
  const popover = (
    <Popover id="popover-basic" className="assistance-popover">
      <Popover.Title as="h3">Need Assistance?</Popover.Title>
      <Popover.Content>
        <PopOverItem>
          <strong>Marketing Inquiries?</strong> - If you have general LMPBP questions regarding tactics,
          vendors or budgets, please reach out to the Marketing team for assistance: <a href="mailto:helpdesk@mosquitosquad.co">helpdesk@mosquitosquad.co</a>
        </PopOverItem>
        <PopOverItem>
          <strong>Technical Issues?</strong> - If you are experiencing any technical or functionality
          issues within the LMPBP, please reach out to the IT team for assistance: <a href="mailto:helpdesk@authoritybrandsllc.com">helpdesk@authoritybrandsllc.com</a>
        </PopOverItem>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Button variant="link" className="assistance-button text-decoration-none">
        Need assistance? Contact Us!
      </Button>
    </OverlayTrigger>
  );
};

export default AssistancePopover;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { REJECT_MAILING_PLAN_ERROR } from 'actions/types';

import Button from 'components/common/button/Button';
import InlineNotification from 'components/common/inline-notification/InlineNotification';

export class RejectConfirmation extends Component {
    state = { 
        feedbackMaxSize: 500,
        feedback: ''
    }

    static propTypes = {
        onHide: PropTypes.func,
        show: PropTypes.bool,
        onRejectConfirmation: PropTypes.func
    }

    countChars = (value = '') => {
        if(!value) return 0;

        return value.trim().length;
    }

    render() {
        const {
            onHide,
            show,
            onRejectConfirmation,
            rejecting,
            rejectError
        } = this.props;

        const { 
            feedbackMaxSize,
            feedback
        } = this.state;

        return (
            <Modal show={show} onHide={onHide} className='mailing-plan-reject-confirmation'>
                <Modal.Header>
                    <i onClick={() => onHide()} className='far fa-times-circle'></i>
                    <div className='modal-title'>
                        Reject Mailing Plan
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='font-size-12 pt-1 pb-1'>Add a comment</div>
                    <textarea 
                        className='comment-box pb-2'
                        value={feedback}
                        maxLength={feedbackMaxSize}
                        onChange={({target: {value}}) => this.setState({ feedback: value })}
                    ></textarea>
                    <small className='d-flex justify-content-end'>{this.countChars(feedback)}/{feedbackMaxSize}</small>
                </Modal.Body>
                <Modal.Footer className='d-flex flex-column align-items-center'>
                    <Button 
                        btnType='outline-danger' 
                        label='Reject'
                        loading={rejecting}
                        loadingMessage='Sending feedback...'
                        action={() => onRejectConfirmation(feedback)}
                        disabled={!feedback.trim()}
                    />
                    {
                        rejectError?.display &&
                            <InlineNotification
                                title={rejectError.name}
                                message={rejectError.message}
                                type={rejectError.type}
                            />
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    rejecting: state.mailingPlan?.rejecting,
    rejectError: state.notifications[REJECT_MAILING_PLAN_ERROR]
});

export default connect(mapStateToProps, {})(RejectConfirmation);

import React, { useState, useEffect } from 'react';
import Input from '../input/Input';
import IconButton from '../icon-button/IconButton';

function MultiInputForm({ elements, onUpdateElements }) {
  const handleInputChange = (index, event) => {
    const values = [...elements];
    values[index][event.target.name] = event.target.value;
    onUpdateElements(values);
  };

  const handleAddFields = () => {
    onUpdateElements([...elements, { size: '', price: '' }]);
  };

  const handleRemoveFields = index => {
    const values = [...elements];
    values.splice(index, 1);
    onUpdateElements(values);
  };

  return (
    <div>
      {elements?.map((input, index) => (
        <div className="d-flex justify-content-between align-items-center" key={index}>
          <Input
            label="size"
            name="size"
            type="text"
            placeholder="size"
            value={input.size}
            onChange={event => handleInputChange(index, event)}
            inputStyling=""
          />
          <Input
            label="price"
            name="price"
            type="number"
            placeholder="price"
            value={input.price}
            onChange={event => handleInputChange(index, event)}
          />
          <IconButton icon="fa fa-trash" action={_ => handleRemoveFields(index)} />
        </div>
      ))}
      <div className="d-flex justify-content-between align-items-center">
        <IconButton icon="fa fa-plus" action={handleAddFields} />
      </div>
    </div>
  );
}

export default MultiInputForm;

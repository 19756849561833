import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip as RBTooltip } from 'react-bootstrap';

const IconButton = ({
    icon,
    shape = 'square',
    btnType = 'primary',
    disabled = false,
    action = null,
    tooltipMsg = ''
}) => {
    const renderButton = () => {
        return (
            <button
                className={`icon-button ${btnType} ${shape} ${disabled ? 'disabled' : ''}`.trim()}
                onClick={() => {
                    if (!action) return;
                    if (disabled) return;
                    action();
                }}
                style={{
                    cursor: 'pointer',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none'
                }}
            >
                <i className={`${icon}`}></i>
            </button>
        );
    }

    const renderWrappedButton = () => {
        if (tooltipMsg) {
            return (
                <OverlayTrigger
                    key='icon-button-tooltip'
                    placement='top'
                    overlay={
                        <RBTooltip id={`icon-button-tooltip-top`}>
                            {tooltipMsg}
                        </RBTooltip>
                    }
                >
                    {renderButton()}
                </OverlayTrigger>
            );
        }

        return renderButton();
    }

    return renderWrappedButton();
}

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    shape: PropTypes.string,
    btnType: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.func,
    tooltipMsg: PropTypes.string
}

export default IconButton;

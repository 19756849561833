import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Switch from 'components/common/switch/Switch';
import MinimumCampaignsInput from './business-rules/MinimumCampaignsInput';

export class RulesConfigItem extends Component {
    static propTypes = {
        ruleKey: PropTypes.string.isRequired,
        rule: PropTypes.object,
        onChangeBusinessRule: PropTypes.func
    }

    isConfiguredInFranconnect = description => {
        return (
            <div>
                <div>{description}</div>
                <small className='font-family-bold'>This value is configured in FranConnect and depends on each franchise</small>
            </div>
        );
    }

    renderRuleValueField = () => {
        if(!this.props.rule) return null;
        
        const { 
            ruleKey, 
            rule,
            onChangeBusinessRule
        } = this.props;

        const {
            description,
        } = rule || {};

        switch(ruleKey) {
            case 'iterateListType':
                return null;

            case 'minimumAddressesPerCampaign':
                return this.isConfiguredInFranconnect(description);

            case 'minimumAmountPerYear':
                return this.isConfiguredInFranconnect(description);

            case 'minimumCampaigns':
                return (
                    <MinimumCampaignsInput 
                        ruleKey={ruleKey}
                        rule={rule} 
                        onChangeBusinessRule={onChangeBusinessRule}
                    />
                );

            case 'requireServices':
                return (
                    <div>
                        <div>{description}</div>
                        <small className='font-family-bold'>The total number of services depends on each brochure</small>
                    </div>
                );

            default:
                return <Fragment>Invalid rule value type</Fragment>
        }
    }

    render() {
        const {
            ruleKey,
            rule,
            onChangeBusinessRule
        } = this.props;

        return (
            <div className={`d-flex flex-wrap row-gap-1 align-items-center mb-4 rules-config-item ${rule?.active ? 'active' : ''}`.trim()}>
                <div className='d-flex column-gap-1 flex-wrap justify-content-between w-100'>
                    <div className='rule-name flex-grow-1'>{rule?.name}</div>
                    <div className='d-flex column-gap-1 justify-content-end'>
                        <div className='flex-grow-1'>
                            <Switch 
                                id={`${ruleKey}-strict`}
                                label='Strict'
                                labelTop={false}
                                checked={rule?.strict}
                                disabled={!rule?.active}
                                onToggle={(checked) => onChangeBusinessRule(ruleKey, { ...rule, strict: checked })}
                            />
                        </div>
                        <div className='flex-grow-1'>
                            <Switch 
                                id={`${ruleKey}-active`}
                                label='Active'
                                labelTop={false}
                                checked={rule?.active}
                                onToggle={(checked) => onChangeBusinessRule(ruleKey, { ...rule, active: checked })}
                            />
                        </div>
                    </div>
                </div>
                {this.renderRuleValueField()}
            </div>
        );
    }
}

export default RulesConfigItem;

import couponWrapper from 'api/direct-mail/utils/backend-wrappers/couponWrapper';

import { 
    getCoupons as getCouponsApi, 
    createCoupon as saveCouponApi, 
    updateCoupon as updateCouponApi,
    deleteCoupons as deleteCouponApi
} from 'api/direct-mail/coupons';

import { 
    notifyGetCouponsForConfigListError,
    notifySaveCouponError,
    notifySaveCouponSuccess,
    notifyUpdateCouponError,
    notifyUpdateCouponSuccess,
    notifyDeleteCouponError,
    notifyDeleteCouponSuccess,
    notifyGetCouponDiscountsError
} from './notificationActions';

import { 
    DELETE_COUPON_LOADING,
    GET_COUPONS_FOR_CONFIG_LIST, 
    GET_COUPONS_FOR_CONFIG_LIST_LOADING,
    GET_COUPON_DISCOUNTS,
    GET_COUPON_DISCOUNTS_LOADING,
    RESET_COUPON_DISCOUNTS,
    SAVE_COUPON_LOADING,
    UPDATE_COUPON_LOADING,
} from './types';
import userSession from 'utils/userSession';
import { getDiscounts } from 'api/direct-mail/discounts';
import discountWrapper from 'api/direct-mail/utils/backend-wrappers/discountWrapper';

export const resetCouponsList = () => {
    return {
        type: GET_COUPONS_FOR_CONFIG_LIST,
        payload: []
    };
}

export const getCouponsForConfigList = (brandId = null, templateId = null) => async dispatch => {
    dispatch(notifyGetCouponsForConfigListError({ display: false }));

    dispatch({
        type: GET_COUPONS_FOR_CONFIG_LIST_LOADING,
        payload: true
    });

    const [couponsErr, couponsRes] = await getCouponsApi({ brandId, templateId });

    if(couponsErr) {
        dispatch(notifyGetCouponsForConfigListError({ serverError: couponsErr }));
    }     
    
    dispatch({
        type: GET_COUPONS_FOR_CONFIG_LIST,
        payload: couponWrapper.sortByName(couponsRes)
    });

    return dispatch({
        type: GET_COUPONS_FOR_CONFIG_LIST_LOADING,
        payload: false
    });
}

export const saveCoupon = (coupon = {}, templateId = null, onComplete = null) => async dispatch => {
    dispatch(notifySaveCouponError({ display: false }));
    dispatch(notifySaveCouponSuccess({ display: false }));

    dispatch({
        type: SAVE_COUPON_LOADING,
        payload: true
    });

    const [saveErr] = await saveCouponApi({ 
        mailingPlanTemplateID: templateId,
        name: coupon?.couponName,
        code: coupon?.code,
        active: coupon?.active || false,
        licenseRequired: coupon?.licenseRequired || false,
        outputText: coupon?.outputText,
        discounts: coupon?.discounts
    });

    if(saveErr) {
        dispatch(notifySaveCouponError({ serverError: saveErr }));
    } else {
        dispatch(notifySaveCouponSuccess());
        dispatch(getCouponsForConfigList(userSession.getBrandId(), templateId));
    }

    if(onComplete) onComplete();

    return dispatch({
        type: SAVE_COUPON_LOADING,
        payload: false
    });
}

export const updateCoupon = (coupon = {}, templateId = null, onComplete = null) => async dispatch => {
    dispatch(notifyUpdateCouponError({ display: false }));
    dispatch(notifyUpdateCouponSuccess({ display: false }));

    dispatch({
        type: UPDATE_COUPON_LOADING,
        payload: true
    });

    const [saveErr] = await updateCouponApi({ 
        mailingPlanTemplateID: templateId,
        id: coupon?.couponId,
        name: coupon?.couponName,
        code: coupon?.code,
        active: coupon?.active,
        licenseRequired: coupon?.licenseRequired,
        outputText: coupon?.outputText,
        discounts: coupon?.discounts
    });

    if(saveErr) {
        dispatch(notifyUpdateCouponError({ serverError: saveErr }));
    } else {
        dispatch(notifyUpdateCouponSuccess());
        dispatch(getCouponsForConfigList(userSession.getBrandId(), templateId));
    }

    if(onComplete) onComplete();

    return dispatch({
        type: UPDATE_COUPON_LOADING,
        payload: false
    });
}

export const deleteCoupon = (couponId = null, templateId = null, onComplete = null) => async dispatch => {
    dispatch(notifyDeleteCouponError({ display: false }));
    dispatch(notifyDeleteCouponSuccess({ display: false }));

    dispatch({
        type: DELETE_COUPON_LOADING,
        payload: true
    });

    const [saveErr] = await deleteCouponApi([couponId]);

    if(saveErr) {
        dispatch(notifyDeleteCouponError({ serverError: saveErr }));
    } else {
        dispatch(notifyDeleteCouponSuccess());
        dispatch(getCouponsForConfigList(userSession.getBrandId(), templateId));
    }

    if(onComplete) onComplete();

    return dispatch({
        type: DELETE_COUPON_LOADING,
        payload: false
    });
}

export const getCouponDiscounts = (templateId = null, couponId = null, discountIds = []) => async dispatch => {
    dispatch(notifyGetCouponDiscountsError({ display: false }));

    dispatch({
        type: GET_COUPON_DISCOUNTS_LOADING,
        payload: true
    });

    const [discountsErr, discountsRes] = await getDiscounts({ templateId, discountIds });

    if(discountsErr) {
        dispatch(notifyGetCouponDiscountsError({ serverError: discountsErr }));
    }

    dispatch({
        type: GET_COUPON_DISCOUNTS,
        payload: discountWrapper.sortByType(discountsRes)
    });

    return dispatch({
        type: GET_COUPON_DISCOUNTS_LOADING,
        payload: false
    });
}

export const resetCouponDiscounts = () => ({
    type: RESET_COUPON_DISCOUNTS,
    payload: []
});
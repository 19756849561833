import React from 'react';
import PropTypes from 'prop-types';

const Select = ({
    label = '',
    options = [],
    children = null,
    containerStyling = '',
    selectStyling = '',
    labelStyling = '',
    keyPrefix = '',
    placeholder = '',
    defaultValue = placeholder,
    value = '',
    autoSelectSingle = false,
    autoSelectCallback = null,
    error = false,
    errorMsg = 'Please verify this field',
    icon = null,
    ...props
}) => {
    const renderOptions = () => {
        if(!Array.isArray(options)) return null;
        
        let keyCount = 0;
        return options.map((item, index) => {
            keyCount++;
            
            return (
                <option 
                    id={index}
                    key={`${label}-${keyPrefix || item?.id || item?.value}-${keyCount}`}
                    value={item?.value}
                >
                    {item?.label || item?.value}
                </option>
            );
        });
    }
    
    const setValue = () => {
        if(autoSelectSingle) {
            let autoSelectValue = null;

            if(options && options?.length === 1) {
                autoSelectValue = options[0]?.value;

            } else if(children && !children?.length) {
                autoSelectValue = children?.props?.value;
            }

            if(autoSelectCallback) autoSelectCallback(autoSelectValue);
            
            return autoSelectValue || defaultValue;
        }

        return value || defaultValue;
    }

    return (
        <div className={`d-flex flex-column ${containerStyling}`.trim()}>
            {label && 
                <label className={`font-size-12 ${icon ? 'select-icon-margin' : ''} ${labelStyling}`.trim()}>
                    {label}
                </label>
            }
            <div className='d-flex align-items-center'>
                {icon &&
                    <i className={`mr-2 brand-primary-color font-size-20 ${icon}`}></i>
                }
                <select
                    value={setValue()} 
                    className={`form-control ${selectStyling}`.trim()} 
                    {...props}
                >
                    {placeholder && 
                        <option disabled value={placeholder}>{placeholder}</option>
                    }
                    {children || renderOptions()}
                </select>
            </div>
            {error && 
                <div className='error-msg text-left w-100'>{errorMsg}</div>
            }
        </div>
    );
}

Select.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    children: PropTypes.node,
    containerStyling: PropTypes.string,
    selectStyling: PropTypes.string,
    labelStyling: PropTypes.string,
    keyPrefix: PropTypes.oneOfType([Number, String]),
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOfType([Number, String]),
    value: PropTypes.any,
    autoSelectSingle: PropTypes.bool,
    autoSelectCallback: PropTypes.func,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    icon: PropTypes.string
}

export default Select;

import React from "react";
import CustomButton from "../CustomButton";

function AddButton({ children, ...props }) {
  return (
    <CustomButton
      className="dm-button outline-primary"
      aria-label="Add"
      {...props}>
      <i className="fa fa-plus mb-2 d-block" style={{ fontSize: '1.5rem' }}></i>
      {children}
    </CustomButton>
  );
}

export default AddButton;

import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getBrochure = ({
    brandId = null,
    templateId: mailingPlanTemplateID = null,
    images = false,
    brochures = [],
    templateYear: mailingPlanTemplateYear = null
} = {}) => {
    return handleResponse(
        api().post(`/brand/${brandId}/brochure`, {
            mailingPlanTemplateID,
            images,
            brochures,
            mailingPlanTemplateYear
        })
    );
}

export const postBrochure = ({
    templateId: mailingPlanTemplateID,
    name,
    numberOfCoupons: couponAmount,
    minServices,
    maxServices,
    active = false,
    licenseRequired = false,
    isInternalUse: internalUse = false,
    coupons = [],
    badges = [],
    minBadges,
    maxBadges
} = {}) => {
    return handleResponse(
        api().post('/corporate/brochure/create', {
            mailingPlanTemplateID,
            name,
            couponAmount,
            minServices,
            maxServices,
            active,
            licenseRequired,
            internalUse,
            coupons,
            badges,
            minBadges,
            maxBadges
        })
    );
}

export const postBrochureImage = ({
    brochureId: brochureID,
    image: imageFile
} = {}) => {
    return handleResponse(
        api().post('/corporate/brochure/image/upload', {
            brochureID,
            imageFile
        })
    );

}

export const deleteBrochure = ({
    brochureId: id
} = {}) => {
    return handleResponse(
        api().post('/corporate/brochure/delete', {
            id
        })
    );
}

export const updateBrochure = ({
    brochureId: id,
    name,
    numberOfCoupons: couponAmount,
    minServices,
    maxServices,
    active = false,
    licenseRequired = false,
    isInternalUse: internalUse = false,
    coupons = [],
    badges = [],
    minBadges,
    maxBadges
} = {}) => {
    return handleResponse(
        api().post('/corporate/brochure/update', {
            id,
            name,
            couponAmount,
            minServices,
            maxServices,
            active,
            licenseRequired,
            internalUse,
            coupons,
            badges,
            minBadges,
            maxBadges
        })
    );
}

export const getBrochureCoupons = ({
    brandId = null,
    brochureId = null
} = {}) => {
    return handleResponse(
        api().get(`/brand/${brandId}/brochure/${brochureId}/coupon`)
    );
}

export const getBrochureBadges = ({
    brandId = null,
    brochureId = null
} = {}) => {
    return handleResponse(
        api().get(`/brand/${brandId}/brochure/${brochureId}/badge`)
    );
}
import {
    DELETE_DISCOUNT_LOADING,
    GET_DISCOUNTS_FOR_CONFIG_LIST, 
    GET_DISCOUNTS_FOR_CONFIG_LIST_LOADING, 
    SAVE_DISCOUNT_LOADING, 
    UPDATE_DISCOUNT_LOADING
} from 'actions/types';

const discountsConfigReducer = (state = {}, action = {}) => {
    switch(action.type) {
        case GET_DISCOUNTS_FOR_CONFIG_LIST:
            return { ...state, discounts: [...action.payload] };

        case GET_DISCOUNTS_FOR_CONFIG_LIST_LOADING:
            return { ...state, loading: action.payload };

        case SAVE_DISCOUNT_LOADING:
            return { ...state, saving: action.payload };

        case UPDATE_DISCOUNT_LOADING:
            return { ...state, updating: action.payload };

        case DELETE_DISCOUNT_LOADING:
            return { ...state, deleting: action.payload };

        default:
            return state;
    }
}

export default discountsConfigReducer;
const badgeEntryWrapper = badge => {
    if(
        !badge
        || Array.isArray(badge)
        || typeof badge !== 'object'
    ) return {};

    return {
        badgeId: badge?.id,
        name: badge?.name,
        badgeText: badge?.badge_text
    };
}

export default badgeEntryWrapper;
import api from 'api/lmpbp';
import handleResponse from './utils/handleResponse';
export const syncFranconnectFranchises = () => {
    return handleResponse({
        promise: api().get('franconnect/sync/franchises/update'),
    });
}

export const syncFranconnectFranchisees = () => {
    return handleResponse({
        promise: api().get('franconnect/sync/franchisees/update'),
    });
}
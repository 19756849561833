import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../loader/Loader';

const Panel = ({ 
    header = '',
    loading = false,
    loadingMessage = '',
    refreshing = '',
    refreshingMsg = '',
    headerStyling = null,
    floatingRefreshLoader = false,
    containerStyling = null,
    hasShadow = true,
    children
}) => {
    const renderHeader = () => {
        if(header && refreshing) {
            return (
                <div className='d-flex align-items-center justify-content-between border-bottom'>
                    <div className='panel-header' style={headerStyling}>
                        {header}
                    </div>
                    <div className={`${floatingRefreshLoader && 'position-relative'} pr-4 pl-4`}>
                        <div className={`${floatingRefreshLoader ? 'position-absolute p-abs-top-1' : 'mt-1 mb-1'}`}>
                            <Loader 
                                loadingMessage={refreshingMsg}
                                loaderStyling={{height: '1.7rem', width: '1.7rem'}}
                            />
                        </div>
                    </div>
                </div>
            );
        } 

        if(header) {
            return (
                <div className='panel-header border-bottom' style={headerStyling}>
                    {header}
                </div>
            );
        }

        if(refreshing) {
            return (
                <div className={`d-flex justify-content-end ${floatingRefreshLoader && 'position-relative'} pr-4 pl-4`}>
                    <div className={`${floatingRefreshLoader ? 'position-absolute p-abs-top-1' : 'mt-1 mb-1'}`}>
                        <Loader 
                            loadingMessage={refreshingMsg}
                            loaderStyling={{height: '1.7rem', width: '1.7rem'}}
                        />
                    </div>
                </div>
            );
        }

    }

    return (
        <div 
            className={`bg-white panel-border d-relative ${hasShadow ? 'shadow-sm' : ''}`.trim()}
            style={containerStyling}
        >
            {renderHeader()}
            <div className='p-3'>
                {
                    loading ? 
                        <Loader loadingMessage={loadingMessage}/>
                    :
                        children
                }
            </div>
        </div>
    );
}

Panel.propTypes = {
    header: PropTypes.string,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    refreshing: PropTypes.bool,
    refreshingMsg: PropTypes.string,
    headerStyling: PropTypes.object,
    floatingRefreshLoader: PropTypes.bool,
    containerStyling: PropTypes.object,
    hasShadow: PropTypes.bool,
    children: PropTypes.node
}

export default Panel;



import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Button from 'components/common/button/Button';

const ConfirmEmailNotificationsModal = ({
    showConfirmEmailReminders,
    sendEmailReminders,
    confirmEmailReminders,
}) => {
    return (
        <Modal
            show={confirmEmailReminders}
            onHide={() => showConfirmEmailReminders(false)}
            className='email-reminders-modal'
        >
            <Modal.Body className='d-flex justify-content-center'>
                Are you sure you want to send reminders?
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button
                    btnType='primary'
                    label='Confirm'
                    action={() => {
                        sendEmailReminders();
                        showConfirmEmailReminders(false);
                    }}
                ></Button>
                <Button
                    btnType='danger'
                    label='Cancel'
                    action={() => showConfirmEmailReminders(false)}
                ></Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmEmailNotificationsModal.propTypes = {
    showConfirmEmailReminders: PropTypes.func,
    sendEmailReminders: PropTypes.func,
    confirmEmailReminders: PropTypes.bool,
};

export default ConfirmEmailNotificationsModal;

const validateMinAddressesPerCampaign = (campaigns, businessRule) => {
    if (!Array.isArray(campaigns) || !campaigns.length) return false;

    const minAddresses = Number(businessRule?.calculatedValue);
    if (isNaN(minAddresses) || minAddresses < 0) return false;

    const selectedCampaigns = campaigns.filter(({ selected }) => selected);

    if(!selectedCampaigns.length) return false;

    let containsNegatives = false;
    const validCampaigns = selectedCampaigns.filter(({ addresses }) => {
        if (addresses < 0) containsNegatives = true;
        return addresses >= minAddresses;
    });

    if (containsNegatives) return false;
    
    let totalAddresses = 0;
    validCampaigns.forEach(campaign => {
        totalAddresses += campaign.addresses;
    });

    if(totalAddresses == 0 && minAddresses != 0) return false;
    else if(totalAddresses == 0 && minAddresses == 0) return true;
    if (selectedCampaigns.length === validCampaigns.length) return true;
    
    return false;
};

export default validateMinAddressesPerCampaign;

import formatCurrency from 'utils/formatCurrency';
import formatNumber from 'utils/formatNumber';

function investmentFormatter(cell, row) {
    if(isNaN(cell)) return cell;

    return `$${formatCurrency(Number(cell))}`;
}

function addressesFormatter(cell, row) {
    if(!cell) return '-';

    return formatNumber(cell);
}

const campaignColumns = [
    {
        dataField: 'id',
        text: '',
        hidden: true
    },
    {
        dataField: 'date',
        text: 'Campaign Date'
    },
    {
        dataField: 'services',
        text: 'Services'
    },
    {
        dataField: 'brochure',
        text: 'Brochure'
    },
    {
        dataField: 'coupons',
        text: 'Coupons'
    },
    {
        dataField: 'badges',
        text: 'Badges'
    },
    {
        dataField: 'investment',
        text: 'Investment',
        formatter: investmentFormatter
    },
    {
        dataField: 'addresses',
        text: 'Addresses',
        formatter: addressesFormatter
    },
    {
        dataField: 'expiration_date',
        text: 'Expiration Date'
    },
    {
        dataField: 'desired_in_home_date',
        text: 'Desired In Home Date'
    },
    {
        dataField: 'budget_intention',
        text: 'Intended Spend'
    },
    {
        dataField: 'piece_size',
        text: 'Piece Size'
    }
];

export default campaignColumns;
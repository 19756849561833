import {
    postBadge, 
    updateBadge as updateBadgeApi,
    getBadge,
    deleteBadge as deleteBadgeApi
} from 'api/direct-mail/badges';

import badgeWrapper from 'api/direct-mail/utils/backend-wrappers/badgeWrapper';
import userSession from 'utils/userSession';

import {
    notifyDeleteBrochureError,
    notifyGetBrochureCouponsError,
    notifyGetBrochuresForConfigError,
    notifySaveBrochureImageError,
    notifySaveBrochureError, 
    notifyUpdateBrochureError,
    notifySaveBrochureSuccess,
    notifyUpdateBrochureSuccess,
    notifyDeleteBrochureSuccess
} from './notificationActions';

import {
    DELETE_BADGE_LOADING,
    GET_BADGES_FOR_CONFIG_LIST,
    GET_BADGES_FOR_CONFIG_LIST_LOADING,
    SAVE_BADGE_LOADING, 
    UPDATE_BADGE_LOADING
} from './types';

export const resetBadgesList = () => {
    return {
        type: GET_BADGES_FOR_CONFIG_LIST,
        payload: []
    };
}

export const getBadgesForConfig = (brandId = null, templateId = null) => async dispatch => {
    dispatch(notifyGetBrochuresForConfigError({ display: false }));
    dispatch(notifyGetBrochureCouponsError({ display: false }));

    dispatch({
        type: GET_BADGES_FOR_CONFIG_LIST_LOADING,
        payload: true
    });

    const [badgeErr, badgesRes] = await getBadge({
        brandId,
       templateId,
    });

    let badges = badgeWrapper.sortByName(badgesRes);
    if(badgeErr) {
        let error = {
            message : 'Error fetching badges',
            serverError : badgeErr,
        }
        dispatch(notifyGetBrochuresForConfigError(error));
    } 

    dispatch({
        type: GET_BADGES_FOR_CONFIG_LIST_LOADING,
        payload: false
    });

    return dispatch({
        type: GET_BADGES_FOR_CONFIG_LIST,
        payload: badges
    });
}

export const saveBadge = (templateId = null, badge = {}, onComplete = null) => async dispatch => {
    dispatch(notifySaveBrochureError({ display: false }));
    dispatch(notifySaveBrochureSuccess({ display: false }));
    dispatch(notifySaveBrochureImageError({ display: false }));

    dispatch({
        type: SAVE_BADGE_LOADING,
        payload: true
    });

    const [postBadgeErr, newBadgeRes] = await postBadge({
        templateId,
        name: badge?.badgeName,
        active: badge?.active,
        code: badge?.code,
        badgeText: badge.badgeText ,
    });

    if(postBadgeErr) {
        dispatch(notifySaveBrochureError({
            title: 'Network Error',
            message: `Could not save badge: ${badge?.badgeName}`,
            type: 'danger',
            serverError: postBadgeErr
        }));
        
    } else {
        dispatch(notifySaveBrochureSuccess({
            title: 'Badge saved',
            message: `New Badge has been successfully`,
        }));
        dispatch(getBadgesForConfig(userSession.getBrandId(), templateId));
        if(onComplete) onComplete();
    }

    return dispatch({
        type: SAVE_BADGE_LOADING,
        payload: false
    });
}

export const updateBadge = (templateId = null, badge = {}, onComplete = null) => async dispatch => {
    dispatch(notifyUpdateBrochureError({ display: false }));
    dispatch(notifyUpdateBrochureSuccess({ display: false }));
    dispatch(notifySaveBrochureImageError({ display: false }));

    dispatch({
        type: UPDATE_BADGE_LOADING,
        payload: true
    });

    const [updateBadgeErr] = await updateBadgeApi({
        badgeId: badge?.badgeId,
        name: badge?.badgeName,
        active: badge?.active,
        code: badge?.code,
        badgeText: badge.badgeText 
    });

    if(updateBadgeErr) {
        dispatch(notifyUpdateBrochureError({
            title: 'Network Error',
            message: `Could not update badge: ${badge?.badgeName}`,
            type: 'danger',
            serverError: updateBadgeErr
        }));

    } else {
        dispatch(notifyUpdateBrochureSuccess({
            title: 'Badge updated',
            message: `Badge has been successfully updated`,
        }));
        dispatch(getBadgesForConfig(userSession.getBrandId(), templateId));
        if(onComplete) onComplete();
    }

    return dispatch({
        type: UPDATE_BADGE_LOADING,
        payload: false
    });
}

export const deleteBadge = (templateId = null, badgeId = null, onComplete = null) => async dispatch => {
    dispatch(notifyDeleteBrochureError({ display: false }));
    dispatch(notifyDeleteBrochureSuccess({ display: false }));

    dispatch({
        type: DELETE_BADGE_LOADING,
        payload: true
    });

    const [deleteErr] = await deleteBadgeApi([ badgeId ]);
    
    if(deleteErr) {
        dispatch(notifyDeleteBrochureError({
            title: 'Network Error',
            message: `Error deleting badge`,
            type: 'danger',
            serverError: deleteErr
        }));
    } else {
        dispatch(notifyDeleteBrochureSuccess({
            title: 'Badge deleted',
            message: `Badge has been successfully removed`,
        }));
        dispatch(getBadgesForConfig(userSession.getBrandId(), templateId));
    }
    
    if(onComplete) onComplete();

    return dispatch({
        type: DELETE_BADGE_LOADING,
        payload: false
    });
}
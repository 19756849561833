const addressesImportLogWrapper = importLogData => {
    if(
        !importLogData
        || Array.isArray(importLogData)
        || typeof importLogData !== 'object'
    ) return {};

    return {
        importLogId: importLogData?.importLogId,
        filename: importLogData?.filename,
        importType: importLogData?.importType,
        createdDate: importLogData?.createdDate,
        createdBy: importLogData?.createdBy,
        totalAddresses: importLogData?.totalAddresses,
    }
}

export default addressesImportLogWrapper;

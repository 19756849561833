import {
  GET_MAILING_PLANS,
  GET_MAILING_PLANS_LOADING,
  GET_MAILING_PLAN_STATUSES,
  GET_FRANCHISES_FILTER_DATA,
  GET_FRANCHISES_DROPDOWN_FILTER_DATA,
  SET_MAILING_PLANS_END_DATE,
  SET_MAILING_PLANS_START_DATE,
  SET_MAILING_PLANS_STATUS_FILTER,
  SET_MAILING_PLANS_FRANCHISE_FILTER,
  GET_FRANCHISES_LOADING,
  SET_MAILING_PLANS_YEAR_FILTER,
  SET_MAILING_PLANS_CAMPAIGN_DATE_FILTER,
  SET_MAILING_PLANS_BROCHURE_FILTER,
  SET_MAILING_PLANS_COUPON_FILTER,
  GET_MAILING_PLANS_OVERVIEW,
  SYNCHING_FRANCONNECT,
  GET_MAILING_YEARS,
  GET_CAMPAIGNS_TEMPLATES,
  GET_CAMPAIGNS_TEMPLATES_LOADING,
  CONFIRM_EMAIL_REMINDERS,
  SET_MAILING_PLANS_DM_EXEMPT_STATUS_FILTER,
  SAVING_FRANCHISE
} from "./types";

import {
  notifyGetMailingPlansError,
  notifyGetMailingPlanStatusesError,
  notifyGetFranchisesError,
  notifyGetMailingPlansOverviewError,
  notifyFranconnectSyncError,
  notifyFranconnectSyncSuccess,
  notifyGetMailingYearsError,
  notifyGetCampaignsTemplatesError,
  notifyCampaignRemindersError,
  notifyCampaignRemindersTriggerSuccess,
} from "./notificationActions";

import mailingPlanEntry from "api/direct-mail/utils/backend-wrappers/mailingPlanEntryWrapper";
import planStatus from "api/direct-mail/utils/backend-wrappers/statusWrapper";
import franchiseEntry from "api/direct-mail/utils/backend-wrappers/franchiseEntryWrapper";
import mailingPlansOverview from "api/direct-mail/utils/backend-wrappers/overviewWrapper";
import mailingPlanTemplateWrapper from "api/direct-mail/utils/backend-wrappers/mailingPlanTemplateWrapper";
import mailingYearWrapper from "api/direct-mail/utils/backend-wrappers/mailingYearWrapper";

import {
  getMailingPlans as getMailingPlansApi,
  getPlansSubmissionsOverview as getPlansSubmissionsOverviewApi,
  getPlanStatuses as getPlanStatusesApi,
  getMailingYears as getMailingYearsApi,
  changeDmExemptStatus as changeDmExemptStatusApi
} from "api/direct-mail/mailingPlans";

import { sendCampaignReminders as sendCampaignRemindersApi } from "api/direct-mail/notifications";

import { getFranchises as getFranchisesApi,
  changeFranchiseDmExemptStatus as changeFranchiseDmExemptStatusApi,
  changeFranchiseQrCode as changeFranchiseQrCodeApi,
  saveFranchiseLicense as saveFranchiseLicenseApi
} from "api/direct-mail/franchises";

import { getMailingPlanTemplates } from "api/direct-mail/planTemplates";
import userSession from 'utils/userSession';
import { syncWithFranconnect } from "api/direct-mail/franconnect";
import { subscribeToAwsIot } from 'api/direct-mail/awsIot';
import awsIoTConnect from 'api/utils/awsIoTConnect';

export const getMailingPlans =
  (mailingPlansYear = null) =>
  async (dispatch) => {
    dispatch(notifyGetMailingPlansError({ display: false }));

    dispatch({
      type: GET_MAILING_PLANS_LOADING,
      payload: true,
    });

    let [mailingPlansErr, mailingPlans] = await getMailingPlansApi({
      year: mailingPlansYear,
    });

    if (mailingPlansErr) {
      dispatch(notifyGetMailingPlansError({ serverError: mailingPlansErr }));
    }

    dispatch({
      type: GET_MAILING_PLANS_LOADING,
      payload: false,
    });

    return dispatch({
      type: GET_MAILING_PLANS,
      payload: mailingPlans?.map((entry) => mailingPlanEntry(entry)) || [],
    });
  };

export const getMailingPlanStatuses = () => async (dispatch) => {
  dispatch(notifyGetMailingPlanStatusesError({ display: false }));

  let [statusesErr, statuses] = await getPlanStatusesApi();

  if (statusesErr) {
    dispatch(notifyGetMailingPlanStatusesError({ serverError: statusesErr }));
  }

  return dispatch({
    type: GET_MAILING_PLAN_STATUSES,
    payload:
      statuses
        ?.map((status) => planStatus(status))
        .sort((a, b) => {
          if (a.statusName === b.statusName) return 0;
          return a.statusName > b.statusName ? 1 : -1;
        }) || [],
  });
};

export const getFranchises =  (params)  => async (dispatch) => {
  dispatch(notifyGetFranchisesError({ display: false }));
  dispatch({
    type: GET_FRANCHISES_LOADING,
    payload: true,
  });
  let [franchiseErr, franchises] = await getFranchisesApi(params);

  if (franchiseErr) {
    dispatch(notifyGetFranchisesError({ serverError: franchiseErr }));
  }

  dispatch({
    type: GET_FRANCHISES_LOADING,
    payload: false,
  });

  return dispatch({
    type: GET_FRANCHISES_FILTER_DATA,
    payload: franchiseEntry.wrap(franchises),
  });
};

export const getFranchisesDropdown =  (params)  => async (dispatch) => {
  dispatch(notifyGetFranchisesError({ display: false }));

  let [franchiseErr, franchisesDropdown] = await getFranchisesApi(params);

  if (franchiseErr) {
    dispatch(notifyGetFranchisesError({ serverError: franchiseErr }));
  }
  return dispatch({
    type: GET_FRANCHISES_DROPDOWN_FILTER_DATA,
    payload: franchiseEntry.sortByName(franchisesDropdown),
  });
};

export const getMailingPlansOverview = (year) => async (dispatch) => {
  dispatch(notifyGetMailingPlansOverviewError({ display: false }));

  let [overviewErr, overview] = await getPlansSubmissionsOverviewApi({ year });

  if (overviewErr) {
    dispatch(notifyGetMailingPlansOverviewError({ serverError: overviewErr }));
  }

  return dispatch({
    type: GET_MAILING_PLANS_OVERVIEW,
    payload: mailingPlansOverview(overview) || [],
  });
};

export const getMailingYears = () => async (dispatch) => {
  dispatch(notifyGetMailingYearsError({ display: false }));

  const [mailingYearsErr, mailingYears] = await getMailingYearsApi();

  if (mailingYearsErr) {
    dispatch(notifyGetMailingYearsError({ serverError: mailingYearsErr }));
  }

  return dispatch({
    type: GET_MAILING_YEARS,
    payload: mailingYearWrapper.sortAsc(mailingYears),
  });
};

export const getCampaignsTemplatesByYear =
  (planYear = null) =>
  async (dispatch) => {
    dispatch(notifyGetCampaignsTemplatesError({ display: false }));

    dispatch({
      type: GET_CAMPAIGNS_TEMPLATES_LOADING,
      payload: true,
    });

    const [planTemplatesErr, planTemplates] = await getMailingPlanTemplates({
      planYear,
      withCampaigns: true,
    });

    if (planTemplatesErr) {
      dispatch(
        notifyGetCampaignsTemplatesError({ serverError: planTemplatesErr })
      );
    }

    dispatch({
      type: GET_CAMPAIGNS_TEMPLATES_LOADING,
      payload: false,
    });

    return dispatch({
      type: GET_CAMPAIGNS_TEMPLATES,
      payload:
        mailingPlanTemplateWrapper(planTemplates).campaignTemplates || [],
    });
  };

export const setMailingPlansStartDate = (date = null) => {
  return {
    type: SET_MAILING_PLANS_START_DATE,
    payload: date,
  };
};

export const setMailingPlansEndDate = (date = null) => {
  return {
    type: SET_MAILING_PLANS_END_DATE,
    payload: date,
  };
};

export const setMailingPlansStatusFilter = (value = null) => {
  return {
    type: SET_MAILING_PLANS_STATUS_FILTER,
    payload: value,
  };
};

export const setMailingPlansDmExemptStatusFilter = (value = null) => {
  return {
    type: SET_MAILING_PLANS_DM_EXEMPT_STATUS_FILTER,
    payload: value,
  };
};

export const setMailingPlansFranchiseFilter = (value = null) => {
  return {
    type: SET_MAILING_PLANS_FRANCHISE_FILTER,
    payload: value,
  };
};

export const setMailingPlansYearFilter = (value = null) => {
  return {
    type: SET_MAILING_PLANS_YEAR_FILTER,
    payload: value,
  };
};

export const setMailingPlansCampaignDateFilter = (value = null) => {
  return {
    type: SET_MAILING_PLANS_CAMPAIGN_DATE_FILTER,
    payload: value,
  };
};

export const setMailingPlansBrochureFilter = (value = null) => {
  return {
    type: SET_MAILING_PLANS_BROCHURE_FILTER,
    payload: value,
  };
};

export const setMailingPlansCouponFilter = (value = null) => {
  return {
    type: SET_MAILING_PLANS_COUPON_FILTER,
    payload: value,
  };
};

export const syncFranConnect = () => async (dispatch) => {
  dispatch(notifyFranconnectSyncError({ display: false }));

  dispatch({
    type: SYNCHING_FRANCONNECT,
    payload: true,
  });

  let [franConnectSyncErr] = await syncWithFranconnect();
  if (franConnectSyncErr) {
    dispatch(notifyFranconnectSyncError({ serverError: franConnectSyncErr }));
  }else {
    dispatch(notifyFranconnectSyncSuccess());
  }

  return dispatch({
    type: SYNCHING_FRANCONNECT,
    payload: false,
  });
};

export const showConfirmEmailReminders = (state) => {
  return {
    type: CONFIRM_EMAIL_REMINDERS,
    payload: state,
  };
};

export const sendEmailReminders = () => async (dispatch) => {
  let [remindersErr] = await sendCampaignRemindersApi();
  if (remindersErr) {
    dispatch(notifyCampaignRemindersError());
  } else {
    dispatch(notifyCampaignRemindersTriggerSuccess());
  }
};
export const changeDmExemptStatus =
  (id,isDmExempt,mailingPlansYear) =>
  async (dispatch) => {
    dispatch(notifyGetMailingPlansError({ display: false }));

    dispatch({
      type: GET_MAILING_PLANS_LOADING,
      payload: true,
    });

    let [dmExemptError, res] = await changeDmExemptStatusApi({
      id: id,isDmExempt:isDmExempt
    });

    if (dmExemptError) {
      dispatch(notifyGetMailingPlansError({ serverError: dmExemptError }));
    }
    //getm
    dispatch(notifyGetMailingPlansError({ display: false }));

    dispatch({
      type: GET_MAILING_PLANS_LOADING,
      payload: true,
    });

    let [mailingPlansErr, mailingPlans] = await getMailingPlansApi({
      year: mailingPlansYear,
    });

    if (mailingPlansErr) {
      dispatch(notifyGetMailingPlansError({ serverError: mailingPlansErr }));
    }

    dispatch({
      type: GET_MAILING_PLANS_LOADING,
      payload: false,
    });

    return dispatch({
      type: GET_MAILING_PLANS,
      payload: mailingPlans?.map((entry) => mailingPlanEntry(entry)) || [],
    });
  };
  export const changeFranchiseDmExemptStatus =
  (id,isDmExempt,params) =>
  async (dispatch) => {
    dispatch(notifyGetMailingPlansError({ display: false }));

    dispatch({
      type: GET_FRANCHISES_LOADING,
      payload: true,
    });

    let [dmExemptError, res] = await changeFranchiseDmExemptStatusApi({
      id: id,isDmExempt:isDmExempt
    });

    if (dmExemptError) {
      dispatch(notifyGetMailingPlansError({ serverError: dmExemptError }));
    }
    //getm
    dispatch(notifyGetMailingPlansError({ display: false }));
   
    let [franchiseErr, franchises] = await getFranchisesApi(params);

  if (franchiseErr) {
    dispatch(notifyGetFranchisesError({ serverError: franchiseErr }));
  }
  dispatch({
    type: GET_FRANCHISES_LOADING,
    payload: false,
  });
  return dispatch({
    type: GET_FRANCHISES_FILTER_DATA,
    payload: franchiseEntry.sortByName(franchises),
  });
  };
  export const changeFranchiseQrCode =
  (id,isQrCode,params) =>
  async (dispatch) => {
    dispatch(notifyGetMailingPlansError({ display: false }));

    dispatch({
      type: GET_FRANCHISES_LOADING,
      payload: true,
    });

    let [qrCodeError, res] = await changeFranchiseQrCodeApi({
      id: id,isQrCode:isQrCode
    });

    if (qrCodeError) {
      dispatch(notifyGetMailingPlansError({ serverError: qrCodeError }));
    }

    dispatch(notifyGetMailingPlansError({ display: false }));
   
    let [franchiseErr, franchises] = await getFranchisesApi(params);

    if (franchiseErr) {
      dispatch(notifyGetFranchisesError({ serverError: franchiseErr }));
    }
    dispatch({
      type: GET_FRANCHISES_LOADING,
      payload: false,
    });
    return dispatch({
      type: GET_FRANCHISES_FILTER_DATA,
      payload: franchiseEntry.sortByName(franchises),
    });
  };
  export const saveFranchiseLicense =
  (licenseParams,params) =>
  async (dispatch) => {
    dispatch(notifyGetMailingPlansError({ display: false }));

    dispatch({
      type: GET_FRANCHISES_LOADING,
      payload: true,
    });
    dispatch({
      type: SAVING_FRANCHISE,
      payload: licenseParams.id,
    });
    let [licenseError, res] = await saveFranchiseLicenseApi({
      licenseParams
    });

    if (licenseError) {
      dispatch(notifyGetFranchisesError({ serverError: licenseError }));
    }

    dispatch(notifyGetFranchisesError({ display: false }));
    dispatch({
      type: SAVING_FRANCHISE,
      payload: null,
    });
    let [franchiseErr, franchises] = await getFranchisesApi(params);

    if (franchiseErr) {
      dispatch(notifyGetFranchisesError({ serverError: franchiseErr }));
    }
    dispatch({
      type: GET_FRANCHISES_LOADING,
      payload: false,
    });
    return dispatch({
      type: GET_FRANCHISES_FILTER_DATA,
      payload: franchiseEntry.sortByName(franchises),
    });
  };
  export const initializeAwsIoT = async dispatch => {
    const [initErr, initRes] = await subscribeToAwsIot();

    if(initErr) {
        return null;

    } else {
        awsIoTConnect.connect(
            `dm-franconnectsync-${userSession.getUserId()}-${userSession.getBrandId()}`, 
            initRes.iotEndpoint,
            initRes.region,
            initRes.accessKey,
            initRes.secretKey,
            initRes.sessionToken
        );
    
        awsIoTConnect.onMessageCallback = ({ success, timeout }) => {
            dispatch({
                type: SYNCHING_FRANCONNECT,
                payload: false
            });
            
            if(success) {
                dispatch(notifyFranconnectSyncSuccess());
            } else if(timeout) {
                dispatch(notifyFranconnectSyncError({
                    title: 'Franconnect Sync timed out',
                    message: 'This might be due to the heavy data from franconnect . Please try again or contact IT.',
                    type: 'danger'
                }));
            } else {
                dispatch(notifyFranconnectSyncSuccess({
                    title: 'Server error',
                    message: 'Some thing went wrong',
                    type: 'danger'
                }));
            }

        };

        awsIoTConnect.onCloseCallback = () => {
            dispatch({
                type: SYNCHING_FRANCONNECT,
                payload: false
            });
            dispatch(notifyFranconnectSyncError());
        }

        return initRes.iotEndpoint;
    }
}
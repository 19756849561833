import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import Panel from 'components/common/panel/Panel';
import CampaignsReport from './campaigns-report/CampaignsReport';

import { setCurrentRoute as setCurrentRouteAction } from 'actions/utilityActions';

export class PlanReports extends Component {
    state = {
        selectedTab: 'campaigns'
    }

    onClickTab = (tab = 'campaigns') => {
        this.setState({ selectedTab: tab });
    }

    componentDidMount() {
        const {
            setCurrentRoute
        } = this.props;

        setCurrentRoute(this.props.match);
    }

    render() {
        const { selectedTab } = this.state;

        return (
            <Panel>
                <div className='plan-reports-tabs'>
                    <Tabs
                        id='controlled-tab-example'
                        activeKey={selectedTab}
                        onSelect={(tab) => this.onClickTab(tab)}
                    >
                        {/* Conditionally render tabs' content for lazy rendering*/}
                        <Tab eventKey='campaigns' title='Campaigns'>
                            {
                                selectedTab === 'campaigns' ?
                                    <CampaignsReport />
                                : null
                            }
                        </Tab>
                    </Tabs>
                </div>
            </Panel>
        );
    }
}

export default connect(null, {
    setCurrentRoute: setCurrentRouteAction
})(PlanReports);

import React from 'react';
import PropTypes from 'prop-types';

const MailingOrderItem = ({
    label,
    position
}) => {
    return (
        <div className='order-item'>
            <div className='order-position'>{position}</div>
            <div className='order-label'>
                <i className='fas fa-bars'></i>
                <div>{label}</div>
            </div>
        </div>
    );
}

MailingOrderItem.propTypes = {
    label: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired
}

export default MailingOrderItem;


import React from 'react';
import PropTypes from 'prop-types';

import ImageView from 'components/common/image-view/ImageView';
import ConfigCard from 'components/common/config-card/ConfigCard';

const BrochureItem = ({
    name = 'No Name',
    src = null,
    onShowConfigModal = null,
    markedForRemoval = false
}) => {
    return (
        <ConfigCard
            title={name}
            onClickEditButton={onShowConfigModal}
            markedForRemoval={markedForRemoval}
        >
            <ImageView 
                width='200px'
                height='130px'
                maxWidth='200px'
                maxHeight='130px'
                minHeight='130px'
                minWidth='200px'
                src={src}
            />
        </ConfigCard>
    );
}

BrochureItem.propTypes = {
    name: PropTypes.string.isRequired,
    src: PropTypes.any.isRequired,
    onShowConfigModal: PropTypes.func.isRequired,
    markedForRemoval: PropTypes.bool
}

export default BrochureItem;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import userSession from 'utils/userSession';

const unauthorized = WrappedComponent => {
    class Unauthorized extends Component {
        render() {
            if(!userSession.isActive()) {
                return <Redirect to='/login' />;
            }

            if(userSession.isDivisionalUser()) {
                if(userSession.arrivedAtLmpbp()) {
                    return <Redirect to='/lmpbp/dashboard' />;
                } 
                return <Redirect to='/' />;
            }  
            
            if(userSession.isFranchiseOwer()) {
                if(userSession.arrivedAtLmpbp()) {
                    return <Redirect to='/lmpbp/territories' />;
                }
                return <Redirect to='/mailing-plan' />;
            }

            return <WrappedComponent {...this.props} />;
        }
    }

    return Unauthorized;
}

export default unauthorized;

import _ from 'lodash';
import { validateConsecutiveness } from 'utils/arrayUtils';
import formatCurrency from "utils/formatCurrency";

export const DefaultValidator = (entries, budgetPlanMetadata) => {
    const {tactics, requiredAnnualAmount, requiredMonthlyAmount} = budgetPlanMetadata;
    if (!tactics || !entries?.length) return [];
    let errors = [];
    errors = errors.concat(validateBudgetsAreInRange(entries, tactics));
    const annualError = validateAnnualTotal(entries, requiredAnnualAmount)
    if (annualError) {
        errors.push(annualError);
    }

    let totals = [];
    if (entries.length) {
        entries.forEach(e => {
                for (let i = 0; i < 12; i++) {
                    if (e.lockedMonths && !e.lockedMonths[i])
                    {
                        totals[i] =
                            Number(totals[i] || 0) +
                            Number(e.budgets[i].value || 0);
                        }
                    }
                });
            }
    const monthlyError = validateMonthlyTotals(totals, requiredMonthlyAmount)
    if (monthlyError) {
        errors.push(monthlyError);
    }
    return errors;

    function validateMonthlyTotals(totals, requiredMonthlyBudget) {
        if (requiredMonthlyBudget) {
                let invalidValues = totals.some(total => total < requiredMonthlyBudget);
                if(invalidValues)
                {
                  return MonthlyTotalError(requiredMonthlyBudget);
                }
                else { 
                    return null;
                }
        }
    }

    function validateAnnualTotal(entries, requiredAnnualAmount) {
        let allBudgets = [];
        entries?.forEach(
            ({ budgets }) => (allBudgets = budgets ? [...allBudgets, ...budgets] : allBudgets)
        );
        if (requiredAnnualAmount && Boolean(_.sumBy(allBudgets, 'value') < requiredAnnualAmount)) {
            return AnnualTotalError(requiredAnnualAmount);
        }
    }
}

const MonthlyTotalError = (amount) => {
    return {
        message: `All months must have a minimum of $${formatCurrency(amount)}`
    }
};

const AnnualTotalError = (amount) => {
    return {
        message: `Total annual spend must reach a minimum of $${formatCurrency(amount)}`
    }
};


export const BudgetPlanValidatorFactory = (brand) => {
    switch(brand.symbol) {
        case 'HWC':
            return HWCFOValidator;
        case 'DCS':
            return DCSFOValidator;
        default:
            return DefaultValidator;
    }
} 

const HWCFOValidator = (entries, budgetPlanMetadata) => {
    // TODO: Use tactic names instead of database ids since this inmplementation is database dependable
    const consecutiveTactics = [consecutiveTactic(3, 6), consecutiveTactic(23, 6)];  // Scorpion and RingProgrammaticMarketing
    return Validator(entries, budgetPlanMetadata, consecutiveTactics)
}

const DCSFOValidator = (entries, budgetPlanMetadata) => {
    const consecutiveTactics = [consecutiveTactic(19, 3)]; // Multiview
    return Validator(entries, budgetPlanMetadata, consecutiveTactics)
}

const consecutiveTactic = (id, requiredConsecutive) => {
    return {
        id,
        requiredConsecutive
    };
}

export const Validator = (entries, budgetPlanMetadata, consecutiveTacticIds = []) => {
    const {tactics} = budgetPlanMetadata;
    if (!tactics || !entries?.length) return [];
    let errors = [];
    errors = errors.concat(DefaultValidator(entries, budgetPlanMetadata));
    if (consecutiveTacticIds.length) {
        const consecutiveTactics = consecutiveTacticIds.map(t => {
            const tactic = tactics[t.id];
            return { ...tactic, requiredConsecutive: t.requiredConsecutive}
        } );
        errors = errors.concat(validateConsecutiveMonths(entries, consecutiveTactics));
    }
    return errors;
}

const isBudgetSignificant = (budget) => budget?.value !== 0

const validateConsecutiveMonths = (entries, consecutiveTactics) => {
    const errors = [];
    for(var tactic of consecutiveTactics) {
        const entryToValidate = entries.find(entry => entry?.tacticId === tactic.id);
        if (!entryToValidate) {
            continue;
        }
        const error = validateConsecutiveBudgets(entryToValidate, tactic);
        if (error != null) {
            errors.push(error);
        }
    }
    return errors;
}

const validateConsecutiveBudgets = (entry, tactic) => {
    if(!validateConsecutiveness(entry.budgets, tactic.requiredConsecutive, isBudgetSignificant)) {
       return ConsecutiveRequiredBudgetsError(tactic.displayName, tactic.requiredConsecutive);
    };
    return null;
}

const ConsecutiveRequiredBudgetsError = (tacticName, reqConsecutive) => {
    return {
        message: `For ${tacticName}, if amount other than zero, must have nonzero value for ${reqConsecutive} consecutive months`
    };
}

export const validateBudgetsAreInRange = (entries, tacticsRanges) => {
    const errors = [];
    entries.forEach((entry) => {
        const tacticInfo = tacticsRanges[entry.tacticId];
        const isMinimumSpendMeet = !entry.budgets.some((monthlyBudget) =>
        entry.lockedMonths && !entry.lockedMonths[monthlyBudget.month]
        && validateMonthlyBudget(monthlyBudget.value, tacticInfo) === -1);
        if (!isMinimumSpendMeet) {
            errors.push(MonthlyBudgetError(tacticInfo.displayName, tacticInfo.monthlyMin, "minimum"));
        }
        const isMaximumSpendMeet = !entry.budgets.some((monthlyBudget) => 
        entry.lockedMonths && !entry.lockedMonths[monthlyBudget.month] 
        && validateMonthlyBudget(monthlyBudget.value, tacticInfo) === 1);
        if (!isMaximumSpendMeet) {
            errors.push(MonthlyBudgetError(tacticInfo.displayName, tacticInfo.monthlyMax, "maximum"));
        }
    });
    return errors;
    
    function validateMonthlyBudget (budget, range) {
        if (!range || budget === 0) return 0;
        if (range.monthlyMin && budget < range.monthlyMin) {
            return -1;
        }
        if (range.monthlyMax && budget > range.monthlyMax) {
            return 1;
        }
        return 0;
    }

    function MonthlyBudgetError(tacticDisplayName, treshold, type) {
        return {
            message: `For ${tacticDisplayName} you have to spend a ${type} of $${formatCurrency(treshold)} dollars per month.`
        }
    }
}
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import Panel from 'components/common/panel/Panel';
import Tooltip from 'components/common/tooltip/Tooltip';
import PlanConfig from './plan-config/PlanConfig';
import BrochuresList from './brochures-list/BrochuresList';
import CouponsList from './coupons-list/CouponsList';
import DiscountsList from './discounts-list/DiscountsList';
import ServicesList from './services-list/ServicesList';
import BadgeList from './badges-list/BadgeList';

import { setCurrentRoute as setCurrentRouteAction } from 'actions/utilityActions';
import { resetPlanConfigState as resetPlanConfigStateAction } from 'actions/planConfigActions';

export class PlanManager extends Component {
    state = {
        showActivationConfirmation: false
    }

    componentDidMount() {
        const {
            setCurrentRoute,
            location,
            match
        } = this.props;

        this.setState({
            planYear: location?.state?.planYear
        });

        setCurrentRoute(match);
    }

    componentWillUnmount() {
        this.props.resetPlanConfigState(null);
    }

    render() {
        const {
            planYear
        } = this.state;

        const {
            planTemplateDeleted,
            pendingChanges,
            location
        } = this.props;

        const { selectedPlanId } = location?.state || {};

        return (
            <Fragment>
                {
                    planTemplateDeleted &&
                        <Redirect to='/yearly-plans' />
                }
                <div className='d-flex align-items-center column-gap-1 mb-4'>
                    <h1 className='font-family-bold flex-grow-1'>{
                            `Plan ${planYear}`
                    }</h1>
                </div>
                <Panel>
                    <div className='plan-management-tabs'>
                        <Tabs 
                            id='plan-configuration-tabs'
                            defaultActiveKey='configuration'
                        >
                            <Tab eventKey='configuration' 
                                title={
                                    pendingChanges ? 
                                        <div>
                                            Configuration 
                                            <Tooltip 
                                                iconClassName='fas fa-save c-warning ml-2' 
                                                message='You have pending changes to save' 
                                            />
                                        </div>
                                    : 
                                        'Configuration' 
                                }
                            >
                                <PlanConfig 
                                    templateId={selectedPlanId}
                                />
                            </Tab>
                            <Tab eventKey='brochures' title='Brochures'>
                                <BrochuresList 
                                    templateId={selectedPlanId}
                                />
                            </Tab>
                            <Tab eventKey='coupons' title='Coupons'>
                                <CouponsList 
                                    templateId={selectedPlanId}
                                />
                            </Tab>
                            <Tab eventKey='discounts' title='Discounts'>
                                <DiscountsList 
                                    templateId={selectedPlanId}
                                />
                            </Tab>
                            <Tab eventKey='services' title='Services'>
                                <ServicesList 
                                    templateId={selectedPlanId}
                                />
                            </Tab>
                            <Tab eventKey='badges' title='Badges'>
                                <BadgeList 
                                    templateId={selectedPlanId}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </Panel>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    planTemplateDeleted: state.planConfig?.mailingPlanTemplateDeleted,
    pendingChanges: state.planConfig?.pendingChanges
});

export default connect(mapStateToProps, {
    setCurrentRoute: setCurrentRouteAction,
    resetPlanConfigState: resetPlanConfigStateAction
})(PlanManager);
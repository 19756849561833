import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Panel from 'components/common/panel/Panel';
import ImageView from 'components/common/image-view/ImageView';
import Input from 'components/common/input/Input';
import MultiSelect from 'components/common/multi-select/MultiSelect';
import Switch from 'components/common/switch/Switch';
import Button from 'components/common/button/Button';
import Loader from 'components/common/loader/Loader';

import userSession from 'utils/userSession';
import { getCoupons as getCouponsAction } from 'actions/couponsActions';
import { getBadges as getBadgesAction } from 'actions/badgesActions';
import { 
    saveBrochure as saveBrochureAction,
    updateBrochure as updateBrochureAction,
    deleteBrochure as deleteBrochureAction
} from 'actions/brochuresConfigActions';

export class BrochureConfigModal extends Component {
    state = {
        brochure: null,
        isEditing: false,
        imageName: null,
        validMaxServices: null,
        enoughCouponsSelected: null,
        minBadges: 0,
        maxBadges: 1,
        validMaxBadges: true,
    }

    static propTypes = {
        templateId: PropTypes.number.isRequired,
        onHide: PropTypes.func.isRequired,
        selectedBrochure: PropTypes.object
    }

    onBrochureNameChange = value => {
        this.setState({ brochure: { ...this.state.brochure, brochureName: value } });
    }

    onCouponsAmountChange = value => {
        this.setState({ 
            brochure: { 
                ...this.state.brochure, numberOfCoupons: value 
            },
            enoughCouponsSelected: this.state.brochure.coupons?.length >= value
        });
    }

    onMinServicesChange = value => {
        this.setState({ 
            brochure: { 
                ...this.state.brochure, minServices: value 
            },
            validMaxServices: Number(this.state.brochure.maxServices) >= Number(value)
        });
    }

    onMaxServicesChange = value => {
        this.setState({ 
            brochure: { 
                ...this.state.brochure, maxServices: value 
            },
            validMaxServices: Number(this.state.brochure.minServices) <= Number(value) 
        });
    }

    onMinBadgesChange = value => {
        this.setState({ 
            brochure: { 
                ...this.state.brochure, minBadges: value 
            },
            validMaxBadges: Number(this.state.brochure.maxBadges) >= Number(value)
        });
    }

    onMaxBadgesChange = value => {
        this.setState({ 
            brochure: { 
                ...this.state.brochure, maxBadges: value 
            },
            validMaxBadges: Number(this.state.brochure.minServices) <= Number(value) 
        });
    }

    onCouponsSelected = coupons => {
        this.setState({ 
            brochure: { 
                ...this.state.brochure, coupons: [...coupons] 
            },
            enoughCouponsSelected: this.state.brochure.numberOfCoupons <= coupons.length
        });
    }

    onToggleInternalUse = internalUse => {
        this.setState({ brochure: { ...this.state.brochure, internalUse } });
    }
    
    onToggleLicenseRequired = licenseRequired => {
        this.setState({ brochure: { ...this.state.brochure, licenseRequired } });
    }

    onToggleIsActive = active => {
        this.setState({ brochure: { ...this.state.brochure, active } });
    }

    onUploadImage = image => {
        let reader = new FileReader();

        if(image && image?.type?.includes('image')) {
            reader.readAsDataURL(image);
        }

        reader.onloadend = () => {
            this.setState({ 
                brochure: { 
                    ...this.state.brochure, 
                    src: reader.result,
                    srcUpdated: true
                } 
            });
        }

    }

    isValidNumber = value => {
        if(value === '') return false;
        if(Number(value) === 0) return true;
        if(Number(value)) return true;
        return false;
    }

    onEnableSaveButton = () => {
        const { 
            brochure,
            validMaxServices,
            validMaxBadges,
            enoughCouponsSelected
        } = this.state;

        const {
            brochureName,
            src,
            numberOfCoupons,
            coupons,
            minServices,
            maxServices,
            minBadges,
            maxBadges
        } = brochure || {};
        
        return brochure &&
            brochureName &&
            src &&
            this.isValidNumber(numberOfCoupons) &&
            this.isValidNumber(minServices) &&
            this.isValidNumber(maxServices) &&
            this.isValidNumber(minBadges) &&
            this.isValidNumber(maxBadges) &&
            validMaxServices &&
            validMaxBadges &&
            enoughCouponsSelected &&
            coupons?.length;
    }

    onDelete = (brochureId = null) => {
        const { 
            templateId,
            deleteBrochure,
            onHide
        } = this.props;

        deleteBrochure(templateId, brochureId, onHide);
    }

    onSave = (brochure = null) => {
        const { isEditing } = this.state;
        const {
            templateId,
            updateBrochure,
            saveBrochure,
            onHide
        } = this.props;

        if(isEditing) {
            updateBrochure(templateId, brochure, onHide);
        } else {
            saveBrochure(templateId, brochure, onHide);
        }
    }

    generateCouponOptions = () => {
        const { coupons } = this.props;

        if(!Array.isArray(coupons) || !coupons.length) return [];

        return coupons
            .filter(coupon => coupon.active)
            .map(coupon => ({ ...coupon, value: coupon?.couponName }));
    }

    getSelectedCoupons = () => {
        const { brochure } = this.state;
        if(!Array.isArray(brochure?.coupons) || !brochure?.coupons?.length) return [];

        return brochure?.coupons.map(coupon => ({ ...coupon, value: coupon?.couponName }));
    }

    generateBadgeOptions = () => {
        const { badges } = this.props;

        if(!Array.isArray(badges) || !badges.length) return [];

        return badges
            .filter(badge => badge.active)
            .map(badge => ({ ...badge, value: badge?.badgeName }));
    }
    onBadgesSelected = badges => {
        this.setState({ 
            brochure: { 
                ...this.state.brochure, badges: [...badges] 
            },
            enoughbadgesSelected: this.state.brochure.numberOfbadges <= badges.length
        });
    }
    getSelectedBadges = () => {
        const { brochure } = this.state;

        if(!Array.isArray(brochure?.badges) || !brochure?.badges?.length) return [];

        return brochure?.badges.map(badge => ({ ...badge, value: badge?.badgeName }));
    }

    renderCouponSelection = () => {
        const { enoughCouponsSelected } = this.state;
        const { loadingCoupons } = this.props;

        if(loadingCoupons) return <Loader loadingMessage='Loading coupons...' />;

        return (
            <MultiSelect 
                label='Select available coupons'
                placeholder='You can select multiple coupons'
                enableSelection
                options={this.generateCouponOptions()}
                onChange={selection => this.onCouponsSelected(selection)}
                selectedOptions={this.getSelectedCoupons()}
                error={!this.getSelectedCoupons().length || !enoughCouponsSelected}
                errorMsg={!this.getSelectedCoupons().length ? 'This field is required' : 'Not enough coupons selected'}
            />
        );
    }

    renderBadgeSelection = () => {
        const { loadingBadges } = this.props;

        if(loadingBadges) return <Loader loadingMessage='Loading badges...' />;

        return (
            <MultiSelect 
                label='Select available badges'
                placeholder='You can select multiple badges'
                enableSelection
                options={this.generateBadgeOptions()}
                onChange={selection => this.onBadgesSelected(selection)}
                selectedOptions={this.getSelectedBadges()}
                errorMsg={!this.getSelectedBadges().length ? 'This field is required' : 'Not enough badges selected'}
            />
        );
    }
    componentDidMount() {
        const { 
            templateId,
            getCoupons,
            getBadges,
            selectedBrochure
        } = this.props;

        if(!selectedBrochure) return;

        this.setState({
            brochure:
            {
                ...selectedBrochure,
                maxBadges: userSession.isMTUser()
                    ? selectedBrochure?.maxBadges
                    : 1,
                minBadges: userSession.isMTUser()
                    ? selectedBrochure?.minBadges
                    : 0
            },
            imageName: selectedBrochure.image1,
            isEditing: selectedBrochure?.brochureId ? true : false,
            validMaxServices: selectedBrochure?.maxServices >= selectedBrochure?.minServices,
            enoughCouponsSelected: selectedBrochure?.coupons?.length >= selectedBrochure?.numberOfCoupons,
            validMaxBadges: selectedBrochure && userSession.isMTUser() 
            ? selectedBrochure?.maxBadges >= selectedBrochure?.minBadges
            : true
        }, () => {
            getCoupons({ brandId: userSession.getBrandId(), templateId });
            getBadges({ brandId: userSession.getBrandId(), templateId });
        });
    }

    render() {
        const { 
            isEditing,
            brochure,
            imageName,
            validMaxServices,
            validMaxBadges,
        } = this.state;

        const { 
            onHide,
            saving,
            updating,
            deleting
        } = this.props;

        return (
            <Modal
                className='brochure-config-modal'
                show={true}
                onHide={onHide}
            >
                <Modal.Body>
                    <Panel
                        header={isEditing ? `${brochure?.brochureName}` : 'New Brochure'}
                        headerStyling={{
                            fontSize: '20px',
                            paddingTop: '0.75rem',
                            paddingBottom: '0.75rem'
                        }}
                        hasShadow={false}
                    >
                        <div className='config-fields-container'>
                            <div className='flex-grow-1 minw-fit-content minh-fit-content'>
                                <ImageView 
                                    label='Preview cover image'
                                    src={brochure?.src}
                                    width='100%'
                                    height='250px'
                                    minWidth='350px'
                                    minHeight='250px'
                                    maxWidth='400px'
                                    maxHeight='250px'
                                />
                            </div>
                            <div className='d-flex flex-column row-gap-1'>
                                <div className='flex-grow-1 d-flex flex-wrap column-gap-1 row-gap-1'>
                                    <Input 
                                        label='Brochure Name'
                                        placeholder='Enter brochure name'
                                        value={brochure?.brochureName}
                                        onChange={({target: {value}}) => this.onBrochureNameChange(value)}
                                        error={!brochure?.brochureName}
                                        errorMsg='This field is required'
                                    />
                                    <Input 
                                        label='Number of Coupons'
                                        placeholder='Enter number of coupons'
                                        type='number'
                                        min={0}
                                        value={brochure?.numberOfCoupons}
                                        onChange={({target: {value}}) => this.onCouponsAmountChange(value)}
                                        error={!this.isValidNumber(brochure?.numberOfCoupons)}
                                        errorMsg='This field is required'
                                    />
                                    <Input 
                                        label='Min. Services'
                                        placeholder='Enter minimum number of services'
                                        type='number'
                                        min={0}
                                        value={brochure?.minServices}
                                        onChange={({target: {value}}) => this.onMinServicesChange(value)}
                                        error={!this.isValidNumber(brochure?.minServices)}
                                        errorMsg='This field is required'
                                    />
                                    <Input 
                                        label='Max. Services'
                                        placeholder='Enter maximum number of services'
                                        type='number'
                                        min={0}
                                        value={brochure?.maxServices}
                                        onChange={({target: {value}}) => this.onMaxServicesChange(value)}
                                        error={!this.isValidNumber(brochure?.maxServices) || !validMaxServices}
                                        errorMsg={!this.isValidNumber(brochure?.maxServices) ? 'This field is required' : 'Max. Services cannot be less than Min. Services'}
                                    />
                                    {userSession.isMTUser() &&
                                        <div className={`flex-grow-1 d-flex flex-wrap column-gap-1 row-gap-1`}>
                                            <Input
                                                label='Min. Badges'
                                                placeholder='Enter minimum number of badges'
                                                type='number'
                                                min={0}
                                                value={brochure?.minBadges}
                                                onChange={({ target: { value } }) => this.onMinBadgesChange(value)}
                                                error={!this.isValidNumber(brochure?.minBadges)}
                                                errorMsg='This field is required'
                                            />
                                            <Input
                                                label='Max. Badges'
                                                placeholder='Enter maximum number of badges'
                                                type='number'
                                                min={0}
                                                value={brochure?.maxBadges}
                                                onChange={({ target: { value } }) => this.onMaxBadgesChange(value)}
                                                error={!this.isValidNumber(brochure?.maxBadges) || !validMaxBadges}
                                                errorMsg={!this.isValidNumber(brochure?.maxBadges) ? 'This field is required' : 'Max. Badges cannot be less than Min. Badges'}
                                            />
                                        </div>
                                    }
                                </div>
                                {
                                    this.renderCouponSelection()
                                }
                                {
                                    this.renderBadgeSelection()
                                }
                                <div className='d-flex flex-wrap align-items-start column-gap-2 row-gap-1'>
                                    <Input 
                                        type='file'
                                        label='Brochure Image'
                                        onChange={(e) => {
                                            this.setState({ imageName: e.target.files[0].name });
                                            this.onUploadImage(e.target.files[0]);
                                        }}
                                        value={imageName}
                                        error={!imageName}
                                        errorMsg='This field is required'
                                    />
                                    <Switch 
                                        id='brochureConfigInternalUse'
                                        label='Internal use only'
                                        labelTop
                                        checked={brochure?.internalUse}
                                        onToggle={checked => this.onToggleInternalUse(checked)}
                                    />
                                    <Switch 
                                        id='brochureConfigLicenseRequired'
                                        label='License Required'
                                        labelTop
                                        checked={brochure?.licenseRequired}
                                        onToggle={checked => this.onToggleLicenseRequired(checked)}
                                    />
                                    <Switch 
                                        id='brochureConfigActive'
                                        label='Active'
                                        labelTop
                                        checked={brochure?.active}
                                        onToggle={checked => this.onToggleIsActive(checked)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button 
                            btnType='outline-danger'
                            label={isEditing ? 'Delete Brochure' : 'Cancel'}
                            action={() => {
                                if(isEditing) {
                                    this.onDelete(brochure?.brochureId);
                                } else {
                                    onHide();
                                }
                            }}
                            loading={deleting}
                            loadingMessage='Deleting brochure...'
                            disabled={saving || updating}
                        />
                        <Button 
                            btnType='primary'
                            label={isEditing ? 'Save Changes' : 'Create Brochure'}
                            action={() => this.onSave(brochure)}
                            disabled={!this.onEnableSaveButton() || deleting}
                            loading={saving || updating}
                            loadingMessage={saving ? 'Saving brochure...' : 'Updating brochure...'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    coupons: state.coupons?.coupons,
    loadingCoupons: state.coupons?.loading,
    badges: state.badges?.badges,
    loadingBadges: state.badges?.loading,
    saving: state.brochuresConfig?.savingBrochure,
    updating: state.brochuresConfig?.updatingBrochure,
    deleting: state.brochuresConfig?.deletingBrochure
});

export default connect(mapStateToProps, {
    getCoupons: getCouponsAction,
    saveBrochure: saveBrochureAction,
    updateBrochure: updateBrochureAction,
    deleteBrochure: deleteBrochureAction,
    getBadges: getBadgesAction
})(BrochureConfigModal);

import React from 'react';
import LmpbpBudgetGuide from './LmpbpBudgetGuide';
import BusinessRulesModal from './lmpbp-business-rules-modal/LmpbpBusinessRulesModal';

function LmpbpBudgetGuideDefault() {
  return (
    <LmpbpBudgetGuide
      modalComponent={BusinessRulesModal}
    />
  );
}

export default LmpbpBudgetGuideDefault;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Panel from 'components/common/panel/Panel';
import { ProgressBar } from 'react-bootstrap';

import { getMailingPlansOverview as getMailingPlansOverviewAction } from 'actions/dashboardActions';
import userSession from 'utils/userSession';

export class Overview extends Component {
    componentDidMount() {
        const {
            getMailingPlansOverview,
            year
        } = this.props;

        getMailingPlansOverview(year);
    }

    render() {
        const { overview, year } = this.props;
        let showExemptCount = userSession.isASPUser()?"block":"none";
        return (
            <div className='overview'>
                <Panel>
                    <div className='overview-header'>Overview {year}</div>
                    <div className='plan-progress'>
                        <ProgressBar>
                            <ProgressBar 
                                max={overview?.totalFranchises}
                                variant='success' 
                                now={overview?.approved || 0} 
                                key={1} 
                            />
                            <ProgressBar 
                                max={overview?.totalFranchises}
                                variant='warning' 
                                now={overview?.submitted || 0}
                                key={2} 
                            />
                            <ProgressBar 
                                max={overview?.totalFranchises}
                                variant='danger' 
                                now={overview?.pendings || 0}
                                key={3} 
                            />
                        </ProgressBar>
                        <div className='plan-counters'>
                            <div className='plan-counter approved'>
                                <span className='dot'></span>
                                <span className='d-flex flex-column align-items-center text-center'>
                                    <span className='amount'>{overview?.approved || 0}</span> 
                                    <span>Approved</span>
                                </span>
                            </div>
                            <div className='plan-counter submitted'>
                                <span className='dot'></span>
                                <span className='d-flex flex-column align-items-center text-center'>
                                    <span className='amount'>{overview?.submitted || 0}</span> 
                                    <span>Submitted</span>
                                </span>
                            </div>
                            <div className='plan-counter pendings'>
                                <span className='dot'></span>
                                <span className='d-flex flex-column align-items-center text-center'>
                                    <span className='amount'>{overview?.pendings || 0}</span> 
                                    <span>Resubmitted</span>
                                </span>
                            </div>
                            <div className='plan-counter unsubmitted'>
                                <span className='dot'></span>
                                <span className='d-flex flex-column align-items-center text-center'>
                                    <span className='amount'>{overview?.unsubmitted || 0}</span> 
                                    <span>Not Submitted</span>
                                </span>
                            </div>
                            <div className='plan-counter pendings' style={{display:showExemptCount}}>
                                <span className='dot'></span>
                                <span className='d-flex flex-column align-items-center text-center'>
                                    <span className='amount'>{overview?.dmExemptedFranchises || 0}</span> 
                                    <span>DM Exempted</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    overview: state.dashboard?.overview,
    year: state.dashboard?.mailingPlans?.filters?.year  || new moment().year()
});

export default connect(mapStateToProps, {
    getMailingPlansOverview: getMailingPlansOverviewAction
})(Overview);


import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/common/select/Select';

export class CouponFields extends Component {
    state = {
        couponDiscounts: [],
        selectedCouponId: null,
        selectedCouponDiscountId: null,
        couponDiscountsOptions: [],
        couponOptions: [],
        onCouponInitComplete: false,
        generatingDiscounts: false,
        couponsInitialized: false,
        disableAutoSelection: false
    }

    static propTypes = {
        fieldId: PropTypes.number,
        coupons: PropTypes.array,
        selectedCOupon: PropTypes.object,
        onCouponSelected: PropTypes.func,
        onDiscountSelected: PropTypes.func
    }

    generateCouponOptions = () => {
        const { coupons } = this.props;

        if(!coupons?.length) return [];

        return this.props.coupons?.map(({ couponId, couponName }) => {
            return {
                label: couponName,
                value: couponId,
                id: couponId
            }
        });
    }

    generateCouponDiscounts = (selectedCouponId) => {
        const { coupons } = this.props;

        if(!coupons?.length) return [];

        let discounts = [];
        coupons?.forEach(coupon => {
            if(coupon.couponId === Number(selectedCouponId)) {
                discounts = [...coupon.discounts];
            }
        });

        if(!discounts?.length) return [];

        return discounts.map(discount => {
            return {
                label: discount.outputText,
                value: discount.discountId,
                id: discount.discountId
            }
        });
    }

    getCouponDiscounts = couponId => {
        let discounts = null;

        this.props.coupons?.forEach(coupon => {
            if(Number(coupon.couponId) === Number(couponId)) {
                discounts = coupon.discounts; 
            }
        });

        return [...discounts] || [];
    }

    onSelectCoupon = couponId => {
        const { 
            fieldId,
            coupons,
            onCouponSelected
        } = this.props;

        const updatedCoupons = coupons?.map(coupon => {
            if(Number(coupon.couponId) === Number(couponId)) {
                return {...coupon, selected: true};
            }

            return {...coupon, selected: false};
        });

        onCouponSelected(fieldId, updatedCoupons);
    }

    verifyCouponSelected = () => {
        let couponId = null;

        this.props.coupons?.forEach(coupon => {
            if(coupon.selected) {
                couponId = coupon.couponId;
            }
        });

        return couponId;
    }

    verifyDiscountSelected = () => {
        const { selectedCouponId } = this.state;
        let discountId = null;

        this.props.coupons?.forEach(coupon => {
            if(coupon.couponId === selectedCouponId) {
                coupon.discounts?.forEach(discount => {
                    if(discount.selected) {
                        discountId = discount.discountId;
                    }
                });
            }
        });

        return discountId;
    }

    componentDidMount() {
        const { selectedCoupon } = this.props;
        
        if(selectedCoupon) {
            this.setState({ 
                selectedCouponId: selectedCoupon.couponId,
                selectedCouponDiscountId: selectedCoupon.discount?.discountId
            });
        }

        this.setState({ 
            couponDiscounts: this.generateCouponDiscounts(selectedCoupon?.couponId) 
        }, () => {
            this.setState({ couponsInitialized: true });
        });
    }
    componentDidUpdate(prevProps, prevState) {
        const { selectedCoupon } = this.props;
        if(prevProps.selectedCoupon !== null &&
           prevProps.selectedCoupon !== this.props.selectedCoupon)
        {
            if(selectedCoupon) {
                this.setState({ 
                    selectedCouponId: selectedCoupon.couponId,
                    selectedCouponDiscountId: selectedCoupon.discount?.discountId
                });
            }else{
                this.setState({ 
                    selectedCouponId: undefined,
                    selectedCouponDiscountId: undefined
                });
            }
    
            this.setState({ 
                couponDiscounts: this.generateCouponDiscounts(selectedCoupon?.couponId) 
            }, () => {
                this.setState({ couponsInitialized: true });
            });
        }
    }
    render() {
        const {
            fieldId,
            onCouponSelected,
            onDiscountSelected
        } = this.props;

        const {
            couponDiscounts,
            selectedCouponId,
            selectedCouponDiscountId,
            couponsInitialized,
            disableAutoSelection
        } = this.state;

        return (
            <Fragment>
                <div className='mt-2 col-lg-6'>
                    {
                        couponsInitialized &&
                            <Select 
                                value={selectedCouponId}
                                label='Select an offer'
                                placeholder='View offers'
                                labelStyling='font-size-16 font-family-bold'
                                options={this.generateCouponOptions()}
                                onChange={(e) => {
                                    this.setState({ 
                                        selectedCouponId: e.target.value,
                                        selectedCouponDiscountId: null,
                                        couponDiscounts: null,
                                        disableAutoSelection: false
                                    }, () => {
                                        this.setState({ couponDiscounts: this.generateCouponDiscounts(Number(e.target.value)) });
                                        onCouponSelected(fieldId, Number(e.target.value));
                                    });
                                }}
                            />
                    }
                </div>
                <div className='mt-2 col-lg-6'>
                    {
                        (couponDiscounts && couponsInitialized) &&
                            <Select 
                                value={selectedCouponDiscountId}
                                label='Discount'
                                placeholder='View discounts'
                                labelStyling='font-size-16 font-family-bold'
                                options={couponDiscounts}
                                autoSelectSingle={couponDiscounts?.length === 1}
                                autoSelectCallback={value => {
                                    if(value && !disableAutoSelection) {
                                        this.setState({ disableAutoSelection: true });
                                        onDiscountSelected(fieldId, selectedCouponId, Number(value));
                                    }
                                }}
                                onChange={(e) => {
                                    this.setState({ 
                                        selectedCouponDiscountId: e.target.value,
                                        disableAutoSelection: true
                                    }, () => {
                                        onDiscountSelected(fieldId, selectedCouponId, Number(e.target.value));
                                    })
                                }}
                            />
                    }
                </div>
            </Fragment>
        );
    }
}

export default CouponFields;
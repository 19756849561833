import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import formatDate from 'utils/formatDate';

function BudgetPlanHistoric({
    data = [],
    label = '',
    icon = '',
    error = false,
    errorMsg = 'Please verify this field',
    labelStyling = null,
    onChange = null,
    selectedPlan = {},
    disabled = false,
}) {
    const inputRef = useRef();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', function() { setWindowWidth(window.innerWidth) });
    }, []);

    const setStatusColor = status => {
        return status?.toLowerCase().trim().replace(/\s/, '-');
    };

    const renderHistoricOptions = () => {
        return data?.map((budgetPlan, index) => {
            const { status, updatedOn } = budgetPlan;

            return (
                <div
                    key={`budget-plan-historic-option-${index}`}
                    id={`budget-plan-historic-option-${index}`}
                    onClick={() => {
                        inputRef.current.blur();
                        if (onChange) onChange(budgetPlan);
                    }}
                    className='budget-plan-historic-option'
                >
                    <span
                        className={`budget-plan-historic-option-status ${setStatusColor(
                            status
                        )}`}
                    >
                        {status !== 'Draft' ? status : 'IN PROGRESS'}
                    </span>
                    <span className='budget-plan-historic-option-date'>
                        {formatDate.descriptiveDateAndTime(updatedOn)}
                    </span>
                </div>
            );
        });
    };

    return (
        <div className='d-flex flex-column'>
            {label ? (
                <label
                    className={`budget-plan-historic-label font-size-12 ${labelStyling}`}
                >
                    {label}
                </label>
            ) : null}
            <div className='d-flex align-items-center gap-1'>
                {icon && (
                    <i
                        className={`mr-2 brand-primary-color font-size-20 ${icon}`}
                    ></i>
                )}
                <div
                    id='budget-plan-historic-select'
                    className='budget-plan-historic-container'
                    tabIndex='0'
                    ref={inputRef}
                >
                    <div className='budget-plan-historic-value'>
                        <div className='budget-plan-historic-option'>
                            <span
                                className={`budget-plan-historic-option-status ${setStatusColor(
                                    selectedPlan.status
                                )}`}
                            >
                                {selectedPlan.status !== 'Draft' ? selectedPlan.status : 'IN PROGRESS'}
                            </span>
                            {
                                windowWidth >= 1156 ? 
                                <span className='mkplan-historic-option-date'>
                                    {formatDate.descriptiveDateAndTime(
                                        selectedPlan.updatedOn
                                    )}
                                </span> : null
                            }
                        </div>
                        <i className='fas fa-angle-down'></i>
                    </div>
                    <div className='budget-plan-historic-options'>
                        {!disabled ? renderHistoricOptions() : null}
                    </div>
                </div>
                {error && (
                    <div className='error-msg text-left w-100'>{errorMsg}</div>
                )}
            </div>
        </div>
    );
}

BudgetPlanHistoric.propTypes = {
    data: PropTypes.array,
    label: PropTypes.string,
    icon: PropTypes.string,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    labelStyling: PropTypes.string,
    onChange: PropTypes.func,
    selectedPlan: PropTypes.object,
    disabled: PropTypes.bool,
};

export default BudgetPlanHistoric;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Button = ({
    label = 'button',
    btnType = 'primary',
    action = null,
    disabled = false,
    loading = false,
    loadingMessage = 'Loading...',
    icon = '',
    children,
    btnStyling = {},
    disabledCountDown = 0,
    ...props
}) => {

    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        if(disabledCountDown) {
            setCountDown(disabledCountDown);
        } 
    }, []);

    useEffect(() => {
        if(countDown > 0) {
            const timer = setTimeout(() => {
                setCountDown(countDown-1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [countDown]);

    const renderLabel = () => {
        if(countDown) {
            return (
                <div className='font-size-14 font-family-bold'>Enabling in: {countDown}</div>
            );
        }

        if(loading) {
            return (
                <div className='d-flex align-items-center justify-content-center'>
                    <div className='spinner-border spinner-border-sm' role='status'></div>
                    <span className='font-family-bold ml-2'>{loadingMessage}</span>
                </div>
            );
        }

        return children || label;
    }

    return (
        <button
            {...props}
            style={btnStyling}
            className={`dm-button ${btnType} ${disabled || countDown > 0 ? 'disabled' : ''}`.trim()}
            disabled={disabled || loading}
            onClick={() => {
                if(countDown > 0) return;
                if(disabled) return;
                if(!action) return;
                action();
            }}
        >
            {renderLabel()}
            {
                icon &&
                    <i className={`ml-2 ${icon}`}></i>
            }
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    btnType: PropTypes.string,
    action: PropTypes.func,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    btnStyling: PropTypes.object,
    disabledCountDown: PropTypes.number
}

export default Button;
import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../tooltip/Tooltip';

const ConfigCard = ({
    title = null,
    onClickEditButton = null,
    markedForRemoval = false,
    removalTooltipMsg = 'This item has been marked for removal',
    children = null,
    ...props
}) => {
    return (
        <div 
            className='config-card-container rounded'
            {...props}
        >
            {
                markedForRemoval &&
                    <Tooltip 
                        icon='fas fa-trash'
                        message={removalTooltipMsg}
                        iconClassName='c-danger removal-mark'
                    />
            }
            <div className='config-card-actions rounded'>
                {
                    title &&
                        <div className='config-card-title text-center mb-4'>{title}</div>
                }
                <i 
                    className='config-card-action fas fa-pencil-alt'
                    onClick={() => {
                        if(onClickEditButton) {
                            onClickEditButton();
                        }
                    }}
                ></i>
            </div>
            {children}
        </div>
    );
}

ConfigCard.propTypes = {
    title: PropTypes.string,
    onClickEditButton: PropTypes.func,
    markedForRemoval: PropTypes.bool,
    removalTooltipMsg: PropTypes.string,
    children: PropTypes.node
};

export default ConfigCard;
